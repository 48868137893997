import React,{useState} from 'react';
import styles from './sidebar.module.css';

import { checkProp, numFormat } from '../../../../../redux/check';
import { viewAuctionType } from '../../../../../redux/aucTypes';
import {useSelector} from 'react-redux';

// import DateBlock from './dateBlock'
import {dateTransform} from '../../../../../redux/modifiDate'
import { isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD, isBRW,
isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE } from "../../../../../redux/aucTypes";
import { getAuctionsStatus } from "../../../../../api/dictonaries/statusAuctions"

const Sidebar = ( { data, ...props}) => {
    const [imagePopup, setImagePopup] = useState(styles.noDisplay)
    const [img, setImg] = useState(0)
    const auction = useSelector((state) => state.start.auctionsMy)

    function togglePopup(){
        if(imagePopup === styles.noDisplay) {
            setImagePopup(styles.imageWrapperBg)
        } else {
            setImagePopup(styles.noDisplay)
            setImg(0)
        }
    }


    let aucCreate = {
        title: 'Створення аукціону', active: true, date: '2111-01-11',
        description: 'Заповніть дані аукціону'
    }
    let pullAucInfo = {
        title: 'Заповнення данних про лот', active: false, date: null,
        description: <><div>Заповніть дані про лот</div>
            <div>Заповніть дані про лот</div></>
    }
    
    return (
        <>
            <div className={styles.sideBlock}>
                <div className={styles.aucType}>
                    {viewAuctionType(auction.sellingMethod)}
                </div>
                <div className={styles.aucStatus}>
                    <p>Статус</p>
                    <p className={styles.lotStatus}>
                        {getAuctionsStatus(auction.sellingMethod, auction.status)}
                    </p>
                    { checkProp(auction, 'auctionId') ? 
                        <>
                            <p>ID:</p>
                            <p> {auction.auctionId} </p>
                        </> : null
                    }
                    {/*dataAccessEditAuction(auction)*/}
                </div>
                <div className={styles.lotStartPriceBlock}>
                    <p>Початкова ціна продажу лоту</p>
                    <p className={styles.lotStartPrice}>
                        {startCostAuction(auction)}
                        <span> грн
                            {/* {taxStatusAuction(auctionId)} */}
                        </span></p>
                </div>
                {/* {avatarKa(auctionId)} */}
                
                {/* <div className={styles.auctionSteps}>
                    <div className={styles.auctionStepsTitle}>Кроки проведення</div>
                    <StepItem data={aucCreate} />
                    <StepItem data={pullAucInfo} />
                </div> */}
                {/* {viewButtonsPP(auctionId)} */}
            </div>
        </>
    )
}

const StepItem = (props) => {
    let active = props.data.active ? styles.stepInfo : styles.stepInfo + ' ' + styles.unActive
    let playSvg = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#00AFF0"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.33331 16C9.33331 19.6819 12.3181 22.6667 16 22.6667C19.6819 22.6667 22.6666 19.6819 22.6666 16C22.6666 12.3181 19.6819 9.33333 16 9.33333C12.3181 9.33333 9.33331 12.3181 9.33331 16ZM13.8895 19.193C14.0987 19.5171 14.531 19.6101 14.855 19.4009L19.2131 16.5867C19.2963 16.5329 19.3671 16.4621 19.4209 16.3788C19.6301 16.0548 19.5371 15.6225 19.2131 15.4133L14.855 12.5991C14.7421 12.5261 14.6106 12.4874 14.4762 12.4874C14.0904 12.4874 13.7778 12.8 13.7778 13.1858V18.8142C13.7778 18.9486 13.8165 19.0801 13.8895 19.193Z" fill="white"/>
    </svg>
    
    let lockSvg = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="#E2E2EA"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6838 13.9491V12.7294C12.6838 10.8558 14.1875 9.33328 16.047 9.33328C17.9065 9.33328 19.4103 10.8558 19.4103 12.7294V13.9491H20.3622C20.9033 13.9491 21.3334 14.393 21.3334 14.9304V21.6853C21.3334 22.2227 20.9033 22.6666 20.3622 22.6666H11.6378C11.0967 22.6666 10.6667 22.2227 10.6667 21.6853V14.9304C10.6667 14.393 11.0967 13.9491 11.6378 13.9491H12.6838ZM11.8819 15.1612V21.4545H20.1182V15.1612H11.8819ZM18.1951 13.9304V12.7294C18.1951 11.5212 17.2313 10.5454 16.047 10.5454C14.8627 10.5454 13.8989 11.5212 13.8989 12.7294V13.9304H18.1951Z" fill="white"/>
                    </svg>


    return  <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {props.data.active ? playSvg : lockSvg} 
                </div>
                <div className={active}>
                    <div className={styles.stepTitle}>{props.data.title}</div>
                { props.data.date ?
                    <div className={styles.stepDate}>{props.data.date}</div>
                    : null }
                { props.data.description ?
                    <div className={styles.stepDate}>{props.data.description}</div>
                    : null }
                </div>
            </div>
}

function dataAccessEditAuction(data){
    if(data.status !== 'active_rectification') return null

    return <p className={styles.lotStatus}>По {viewDate(data, 'rectificationPeriod', 'end')}</p>
}

function viewDate(data, typeDate, startEnd, flag){
    if(data !== null){
        if(data?.auctionPeriods !== null){
            if(data?.auctionPeriods?.length > 0){
                for(let i = 0; i < data.auctionPeriods.length; i++){
                    if(data.auctionPeriods[i].status === typeDate){
                        if(startEnd === 'start'){
                            return dateTransform(data.auctionPeriods[i].startDate, 'time')
                        }
                        if(startEnd === 'end'){
                            return dateTransform(data.auctionPeriods[i].endDate, 'time')
                        }    
                    }
                }
            }
        }
        
    }
}

function viewStatusType(data){
    if (typeof data.id === 'undefined' || data.id === '' ) return 'Створення'
    switch (data.status){
        case 'active_rectification':
            return 'Редагування доступне'
        case 'active_tendering':
            return 'Прийняття заяв на участь'
        case 'active_auction':
            return 'Аукціон'
        case 'active_qualification':
            return 'Очікується опублікування протоколу'
        case 'active_awarded':
            if(data.sellingMethod === "bankRuptcy-withoutAuction"){
                return "ОЧІКУЄТЬСЯ ПІДПИСАННЯ ПРОТОКОЛУ ПРО ПРОДАЖ МАЙНА ЗК"
            }
            return 'Очікується підписання договору'
        case 'complete':
            return 'Аукціон завершено. Договір підписано'
        case 'cancelled':
            return 'Аукціон скасовано'
        case 'unsuccessful':
            return 'Аукціон не відбувся'
        case null:
            return 'Чернетка'
        default:
            return null
    }
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(viewAmount(data));
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'
            }
        }
    }
    if(tmp === "n'ull") tmp = 0
    return tmp + ',' + amount
}

function taxStatusAuction(data){
    let tmp = ''
    if(data !== null){
        if(data.value !== null){
            if(data.value.valueAddedTaxIncluded !== null){
                if(data.value.valueAddedTaxIncluded){
                    tmp += ', з ПДВ'
                }else{
                    tmp += ', без ПДВ'
                }
            }
        }
    }
    return tmp
}

function viewAmount(auction){
    let tmp = null;
    if(auction.hasOwnProperty('value') === true){
        if(auction.value !== null){
            if(auction.value.hasOwnProperty('amount') === true){
                if(auction.value.amount !== null){
                    tmp = auction.value.amount;
                }
            }
        }
    }
    return tmp
}

function viewButtonsPP(data){
    if(data !== null){
        if(data.createdByCurrentUser === true){
            switch(data.sellingMethod){
                case "legitimatePropertyLease-priorityEnglish":
                case "legitimatePropertyLease-priorityEnglish-fast":
                case "legitimatePropertyLease-priorityEnglish-fast-manual":
                case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-auction":
                case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                    if(
                        data.status === 'cancelled' ||
                        data.status === 'unsuccessful'
                    ){
                        let tmp = ''
                        if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                            tmp = `https://registry.dto.com.ua/object/${data.registryObjectId}`
                        }else{
                            tmp = `https://staging-registry.dto.com.ua/object/${data.registryObjectId}`
                            //tmp = `https://sandbox-registry.dto.com.ua/object/${data.registryObjectId}`
                        }
                        return (
                            <div className={styles.lotStartPriceBlock + ' ' + styles.lineButtonLink}>
                                <p>Для публікації аукціону з оренди після втрати переважного права, необхідно змінити або зазначити тип об'єкта в переліку “Перший” тип та перейти до публікації аукціону</p>
                                <a href={tmp} taget='blank'>
                                    <div className={styles.buttonLink}>
                                        Створити аукціон (перший англійський)
                                    </div>
                                </a>
                            </div>
                        )
                    }
                    return null
                default:
                    return null
            }
        }
    }
    return null
}

export default Sidebar