import React from 'react';
import styles from './procedure.module.css'

import {useSelector} from 'react-redux';
import {
    isLRE, isLSE, isLSP,
    isLLE, isLLD, isLLP,
    isBSE, isBSD,
    isALE,
    isCSE, isCSD,
    isBRE, isBRD, isBRW,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD
} from '../../../../../redux/aucTypes';
import EditAuctionMonga from '../../createEditAndCloneAuction/edit/editAuctionMonga';
import Sidebar from '../../../auctionEditNew/components/sidebar/sidebar';
import WindowAuctionType from '../../auctionsCommon/windowAuctionType/windowAuctionType';
import ReturnButtonMonga from '../../../returnButtonMonga';

import AuctionLRE from './lre/lre';
import AuctionLSE from './lse/lse';
import AuctionLSP from './lsp/lsp';

import AuctionLLE from './lle/lle';
import AuctionLLD from './lld/lld';
import AuctionLLP from './llp/llp';

import AuctionBSE from './bse/bse';
import AuctionBSD from './bsd/bsd';
import AuctionALE from './ale/ale';
import AuctionCSE from './cse/cse';
import AuctionCSD from './csd/csd';
import AuctionBRE from './bre/bre';
import AuctionBRD from './brd/brd';
import AuctionBRW from './brw/brw';

import AuctionRLE from './rle/rle';
import AuctionRLD from './rld/rld';

import AuctionCLE from './cle/cle';
import AuctionCLD from './cld/cld';

import AuctionSPE from './spe/spe';
import AuctionSPD from './spd/spd';

import AuctionNLE from './nle/nle';
import AuctionNLD from './nld/nld';

import AuctionLAE from './lae/lae';
import AuctionLAW from './law/law';
import AuctionLAP from './lap/lap';

import AuctionLPE from './lpe/lpe';
import AuctionAPE from './ape/ape';
import AuctionAPD from './apd/apd';
import AuctionRMA from "./rma/rma";

const Procedure =( props )=> {
    const auction = useSelector(state => state.start.auctionsMy)

    function setProcedure(){
        switch(true){
            case isLRE(auction.sellingMethod):
                return <AuctionLRE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLSE(auction.sellingMethod):
                return <AuctionLSE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLSP(auction.sellingMethod):
                return <AuctionLSP
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLLE(auction.sellingMethod):
                return <AuctionLLE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLLD(auction.sellingMethod):
                return <AuctionLLD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLLP(auction.sellingMethod):
                return <AuctionLLP
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isBSE(auction.sellingMethod):
                return <AuctionBSE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isBSD(auction.sellingMethod):
                return <AuctionBSD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isALE(auction.sellingMethod):
                return <AuctionALE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isCSE(auction.sellingMethod):
                return <AuctionCSE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isCSD(auction.sellingMethod):
                return <AuctionCSD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isBRE(auction.sellingMethod):
                return <AuctionBRE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isBRD(auction.sellingMethod):
                return <AuctionBRD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isBRW(auction.sellingMethod):
                return <AuctionBRW
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isRLE(auction.sellingMethod):
                return <AuctionRLE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isRLD(auction.sellingMethod):
                return <AuctionRLD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isCLE(auction.sellingMethod):
                return <AuctionCLE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isCLD(auction.sellingMethod):
                return <AuctionCLD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isSPE(auction.sellingMethod):
                return <AuctionSPE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isSPD(auction.sellingMethod):
                return <AuctionSPD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isNLE(auction.sellingMethod):
                return <AuctionNLE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isNLD(auction.sellingMethod):
                return <AuctionNLD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLAE(auction.sellingMethod):
                return <AuctionLAE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLAW(auction.sellingMethod):
                return <AuctionLAW
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLAP(auction.sellingMethod):
                return <AuctionLAP
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isLPE(auction.sellingMethod):
                return <AuctionLPE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isAPE(auction.sellingMethod):
                return <AuctionAPE
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case isAPD(auction.sellingMethod):
                return <AuctionAPD
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            case auction.sellingMethod.startsWith('renewables-multiAwards'):
                return <AuctionRMA
                            data={auction}
                            updateAuc={props.updateAuc}
                        />
            default:
                return null
        }
    }

    function setView(){
        if (setProcedure() === null) return <EditAuctionMonga />
        else {
            return (
            <div className={styles.mainWrap}>
                <Sidebar data={auction} />
                <div className={styles.wrapBlock}>
                    <WindowAuctionType />
                    { setProcedure() }
                    <ReturnButtonMonga />
                </div>
            </div>
            )
        }
    }

    return setView()
}

export default Procedure