import React,{useState} from 'react';
import styles from './rightBlock.module.css';

import {connect} from 'react-redux';

import {useSelector} from 'react-redux';

import DateBlock from './dateBlock'
import {modifiDateString} from '../../../redux/modifiDate'

import {changeLoader, setUniversalError} from '../../../redux/actions.js';
import { auctionGetId } from '../../../redux/actions/auctions/auctions.js';
import { viewAuctionType } from '../../../redux/aucTypes';
import {setDataAddress} from '../../../redux/createAddress';

import { isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD } from "../../../redux/aucTypes";

const RightBlock = (props) => {
    const [imagePopup, setImagePopup] = useState(styles.noDisplay)
    const [img, setImg] = useState(0)
    const auctionId = useSelector((state) => state.start.auctionId)

    function togglePopup(){
        if(imagePopup === styles.noDisplay) {
            setImagePopup(styles.imageWrapperBg)
        } else {
            setImagePopup(styles.noDisplay)
            setImg(0)
        }
    }

    let images = []

    function getIllustration(data) {
        if(data !== null){
            let tmpImg = data.documents
            
            for (let key in tmpImg){
                if (tmpImg[key].documentType.indexOf('illustration') !== -1) {
                    if (tmpImg[key].index === 1) {
                        images.push(tmpImg[key].url)
                    } else {images.push(tmpImg[key].url)}
                } 
            }
            return images
        }
        return null
    }

    function avatarKa(data) {
        if(data !== null){
            getIllustration(data)

            if (data.hasOwnProperty('documents') && images.length > 0) {

                function forward() {
                    if (img === images.length - 1) {
                        setImg(0)
                    } else {
                        setImg(img + 1)
                    }
                }
                
                function backward() {
                    if (img === 1) {
                        setImg(0)
                    } else {
                        setImg(img - 1)
                    }
                }
                
                return (
                    <>
                    <div className={styles.sidelotImage}
                        onClick={togglePopup}>
                        <img src={images[0]} />
                    </div>
                    <div className={imagePopup}>
                            {img !== 0 ? <div className={styles.imageArrow}
                                onClick={backward}>&#10094;</div>
                            : <div className={styles.imageArrowPlaceholder}></div>}
                        <div className={styles.imageWrapper}>
                            <div className={styles.popUpClose} onClick={togglePopup}>&times;</div>
                            <div className={styles.image}>
                                <img src={images[img]} />
                            </div>
                        </div>
                            {images.length > 1 && img < images.length - 1 ? <div className={styles.imageArrow}
                                onClick={forward}>&#10095;</div>
                            : <div className={styles.imageArrowPlaceholder}></div>}
                    </div>
                    </>
                )
            } else {
                return (
                        <div className={styles.sidelotImage}>
                        </div>
                )
            }
        }
        return null
    }
    

    return (
        <>
            <div className={styles.sideBlock}>
                <div className={styles.lotStartPriceBlock}>
                    <p>Початкова ціна продажу лоту</p>
                    <p className={styles.lotStartPrice}>{startCostAuction(props.auctionId)}<span> грн{taxStatusAuction(props.auctionId)}</span></p>
                </div>
                {avatarKa(props.auctionId)}
                <div className={styles.aucType}>{viewAuctionType(props.auctionId.sellingMethod)}</div>
                <div className={styles.aucStatus}>
                    <p>Статус</p>
                    <p className={styles.lotStatus}>{viewStatusType(props.auctionId)}</p>
                    {dataAccessEditAuction(props.auctionId)}
                </div>
                <DateBlock />
                {viewButtonsPP(props.auctionId)}
                {viewcompleteAuction(props, props.auctionId)}
            </div>
        </>
    )
}

function dataAccessEditAuction(data){
    if(data !== null){
        if(data.status === 'active_rectification'){
            return (
                <p className={styles.lotStatus}>По {viewDate(data, 'rectificationPeriod', 'end', 3)}</p>
            )
        }
        /*if(data.status === 'active_tendering'){
            return (
                <p className={styles.lotStatus}>По {viewDate(data, 'tenderPeriod', 'end', 3)}</p>
            )
        }*/
    }
    return null
}

function viewDate(data, typeDate, startEnd, flag){
    if(data !== null){
        if(data.rectificationPeriod !== null){
            //if(data.auctionPeriods.length > 0){
                //for(let i = 0; i < data.auctionPeriods.length; i++){
                    //if(data.auctionPeriods[i].status === typeDate){
                        if(startEnd === 'start'){
                            return modifiDateString(data.rectificationPeriod.startDate, flag, false)
                        }
                        if(startEnd === 'end'){
                            return modifiDateString(data.rectificationPeriod.endDate, flag, false)
                        }    
                    //}
                //}
            //}
        }
        
    }
}

function viewStatusType(data){
    if(
        data.sellingMethod === 'landRental-english' ||
        data.sellingMethod === 'landRental-english-fast' ||
        data.sellingMethod === 'landRental-english-fast-manual' ||
        data.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        data.sellingMethod === 'landRental-english-initial-auction' ||
        data.sellingMethod === 'landRental-english-initial-auction-manual' ||
        data.sellingMethod === 'landRental-english-initial-qualification' ||
        data.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        data.sellingMethod === 'landSell-english' ||
        data.sellingMethod === 'landSell-english-fast' ||
        data.sellingMethod === 'landSell-english-fast-manual' ||
        data.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        data.sellingMethod === 'landSell-english-initial-auction' ||
        data.sellingMethod === 'landSell-english-initial-auction-manual' ||
        data.sellingMethod === 'landSell-english-initial-qualification' ||
        data.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        data.sellingMethod === 'landSell-priorityEnglish' ||
        data.sellingMethod === 'landSell-priorityEnglish-fast' ||
        data.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        data.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        data.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        data.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        data.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        data.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(data !== null){
            switch (data.status){
                case 'active_rectification':
                    return 'Редагування доступне'
                case 'active_tendering':
                    return 'Прийняття заяв на участь'
                case 'active_auction':
                    return 'Земельні торги'
                case 'active_qualification':
                    return 'Очікується оприлюднення протоколу'
                case 'active_awarded':
                    return 'Очікується підписання договору'
                case 'pending_payment':
                    return 'Очікується оплата'
                case 'complete':
                    return 'Торги завершено. Договір/акт підписано'
                case 'cancelled':
                    return 'Торги скасовано'
                case 'unsuccessful':
                    return 'Торги не відбулися'
                case null:
                    return 'Чернетка'
                default:
                    return null
            }
        }
    }else{
        if(data !== null){
            switch (data.status){
                case 'active_rectification':
                    return 'Редагування доступне'
                case 'active_tendering':
                    return 'Прийняття заяв на участь'
                case 'active_auction':
                    return 'Аукціон'
                case 'active_qualification':
                    return 'Очікується опублікування протоколу'
                case 'active_awarded':
                    if(isBRE(data.sellingMethod) || isBRD(data.sellingMethod)){
                        return 'Очікується підписання акту/договору'
                    }else if(isBRW(data.sellingMethod)){
                        return 'Очікується підписання протоколу про продаж майна ЗК'
                    }
                    return 'Очікується підписання договору'
                case 'pending_admission':
                    return 'Аукціон завершено. Очікується виконання умов договору'
                case 'pending_payment':
                    return 'Очікується оплата'
                case 'complete':
                    if(
                        isBSE(data.sellingMethod) || isBSD(data.sellingMethod) ||
                        isALE(data.sellingMethod) ||
                        isCSE(data.sellingMethod) || isCSD(data.sellingMethod) ||
                        isRLE(data.sellingMethod) || isRLD(data.sellingMethod) ||
                        isCLE(data.sellingMethod) || isCLD(data.sellingMethod)
                    ){
                        return 'Аукціон завершено.'
                    }else if(isBRE(data.sellingMethod) || isBRD(data.sellingMethod)){
                        return 'Аукціон завершено. Акт/договір підписано'
                    }else if(isBRW(data.sellingMethod)){
                        return 'Процедуру завершено. Протокол підписано'
                    }
                    return 'Аукціон завершено. Договір підписано'
                case 'cancelled':
                    if(isBRW(data.sellingMethod)){
                        return 'Процедуру відмінено'
                    }
                    return 'Аукціон скасовано'
                case 'unsuccessful':
                    return 'Аукціон не відбувся'
                case null:
                    return 'Чернетка'
                default:
                    return null
            }
        }
    }
    return null
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(viewAmount(data));
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}

function taxStatusAuction(data){
    let tmp = ''
    if(data !== null){
        if(data.value !== null){
            if(data.value.valueAddedTaxIncluded !== null){
                if(data.value.valueAddedTaxIncluded){
                    tmp += '., з ПДВ'
                }else{
                    tmp += '., без ПДВ'
                }
            }
        }
    }
    return tmp
}

function viewAmount(auction){
    let tmp = null;
    if(auction.hasOwnProperty('value') === true){
        if(auction.value !== null){
            if(auction.value.hasOwnProperty('amount') === true){
                if(auction.value.amount !== null){
                    tmp = auction.value.amount;
                }
            }
        }
    }
    return tmp
}

function viewButtonsPP(data){
    if(data !== null){
        if(data.createdByCurrentUser === true){
            switch(data.sellingMethod){
                case "legitimatePropertyLease-priorityEnglish":
                case "legitimatePropertyLease-priorityEnglish-fast":
                case "legitimatePropertyLease-priorityEnglish-fast-manual":
                case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-auction":
                case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                    if(
                        data.status === 'cancelled' ||
                        data.status === 'unsuccessful'
                    ){
                        let tmp = ''
                        if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                            tmp = `https://registry.dto.com.ua/object/${data.registryObjectId}`
                        }else{
                            //tmp = `https://staging-registry.dto.com.ua/object/${data.registryObjectId}`
                            tmp = `https://sandbox-registry.dto.com.ua/object/${data.registryObjectId}`
                            //tmp = `https://dev-registry.dto.com.ua/object/${data.registryObjectId}`
                        }
                        return (
                            <div className={styles.lotStartPriceBlock + ' ' + styles.lineButtonLink}>
                                <p>Для публікації аукціону з оренди після втрати переважного права, необхідно змінити або зазначити тип об'єкта в переліку “Перший” тип та перейти до публікації аукціону</p>
                                <a href={tmp} taget='blank'>
                                    <div className={styles.buttonLink}>
                                        Створити аукціон (перший англійський)
                                    </div>
                                </a>
                            </div>
                        )
                    }
                    return null
                default:
                    return null
            }
        }
    }
    return null
}

function viewcompleteAuction(props, i){
    if(i !== null){
        if(props.auctionId.privateData.isOwner === true){
            if(i.status === "unsuccessful" || i.status === "cancelled" || i.status === "complete") return null
            let tmpSellingMethod = null
            if(i.sellingMethod !== null){
                tmpSellingMethod = i.sellingMethod.indexOf('railwayCargo')
            }
            if( tmpSellingMethod === -1){
                if(i.hasOwnProperty('contracts') === true){
                    if(i.contracts.length > 0){
                        if(i.contracts.length > 0){
                            for(let t=0; t < i.contracts.length; t++){
                                if(i.contracts[t].status === 'active' || i.contracts[t].status === 'paid'){
                                    return <div
                                        className={styles.title_button}
                                        onClick={
                                            () => {
                                                completeAuction(props, i.id)
                                            }
                                        }
                                    >
                                        Завершення аукціону
                                    </div>
                                }
                            }
                        }
                    }
                }
            }else{
                if(i.status === 'active_qualification'){
                //if(i.hasOwnProperty('awards') === true){
                    //if(i.awards.length > 0){
                        //if(i.awards[0].status === 'active'){
                            return <div
                                className={styles.title_button}
                                onClick={
                                    () => {
                                        completeAuction(props, i.id)
                                    }
                                }
                            >
                                Завершення аукціону
                            </div>
                        //}
                    //}
                //}
                }
            }
        }
    }
    return null
}

async function completeAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)})
	        //props.setUniversalError(tmp)
        }else{
            /*const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`;
            const response2 = await fetch(url2, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                const json2 = await response2.json()
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: 'uk',
                    id: id
                }
                props.auctionGetId(data)
            }else{
                const json2 = await response2.json()
                let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: 'uk',
                    id: id
                }
                props.auctionGetId(data)
            }*/
            props.setDataAddress(json1, 'SET_FULL_AUCTION')
        }
        props.changeLoader(false)
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
    }
}

const mapDispatchToProps = {
    setUniversalError,
    changeLoader,
    auctionGetId,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(RightBlock)