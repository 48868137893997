import React from 'react';
import styles from './windowAuctionType.module.css';

import {connect} from 'react-redux';

import {setUniversalError} from '../../../../../redux/actions.js';

import {changeCreateAuctionType} from '../../../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import {PROCEDURE_NAME} from '../../../../../redux/procedure_v3.35.2.uk';

import Select from '../../../../elements/inputs/inputSelect';
import { getOptions } from '../../../../../redux/check';

import {
    changeCreateAuctionValueTax,
} from '../../../../../redux/actions.js';

import {disabletElem} from '../../../disabled'
import { isLLD, isLLE, isLLP } from '../../../../../redux/aucTypes';

const WindowAuctionType = ( props ) => {
    let tmp = null;
    if(props.auctionsMy !== null && props.userProfiles !== null){
        if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
            tmp = <Select
                        data={{
                            label:PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.sellingMethod.legalName,
                            target: props.auctionsMy.sellingMethod,
                            info: 'оберіть тип процедури, за яким проходитиме аукціон',
                            required: true
                        }}
                        onChange={(event)=>{
                                    props.changeCreateAuctionType(event)
                                }}
                        options={getOptions(viewOption(props, true), 1)}
                        error = {redBlock(props, props.auctionsMy.sellingMethod)}
                        descr='Оберіть тип аукціону'
                    />
                {/* <TextField
                    error = {redBlock(props, props.auctionsMy.sellingMethod)}
                    disabled={editClose(props)}
                    className={styles.inputText}
                    id="outlined-select-currency-native"
                    select
                    label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.sellingMethod.legalName}
                    value={props.auctionsMy.sellingMethod}
                    onChange={(event)=>{
                        props.changeCreateAuctionType(event.target.value)
                    }}
                    helperText='Оберіть тип аукціону'
                    variant="outlined"
                >
                    <option key={0} className={styles.hoverOptions} selected disabled value='null'>Обрати</option>
                    {viewOption(props, true)}
                </TextField> */}
        }else{
            tmp = <Select
                    data={{
                        label:PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.sellingMethod.legalName,
                        target: props.auctionsMy.sellingMethod,
                        info: 'оберіть тип процедури, за яким проходитиме аукціон',
                        required: true
                    }}
                    onChange={(event)=>{
                                props.changeCreateAuctionType(event)
                            }}
                    options={getOptions(viewOption(props, false), 1)}
                    disabled={props.auctionsMy.id ? true : false}
                    descr='Оберіть тип аукціону'
                />
                {/* <TextField
                    error = {redBlock(props, props.auctionsMy.sellingMethod)}
                    disabled={editClose(props)}
                    className={styles.inputText}
                    id="outlined-select-currency-native"
                    select
                    label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.sellingMethod.legalName}
                    value={props.auctionsMy.sellingMethod}
                    onChange={(event)=>{
                        props.changeCreateAuctionType(event.target.value)
                    }}
                    helperText='Оберіть тип аукціону'
                    variant="outlined"
                >
                    <option key={0} className={styles.hoverOptions} selected disabled value='null'>Обрати</option>
                    {viewOption(props, false)}
                </TextField> */}
        }
    }
    return tmp
}

function editClose(props){
    if(window.location.pathname.indexOf('/editNew/') !== -1){
        return 'disabled'
    }
    return disabletElem(props.auctionsMy, '11')
}

function arrayTypeAuction(){
    return [
        /*{type: "timber-english", name: "Ліс", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "timber-english-fast", name: "Ліс", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "timber-english-fast-manual", name: "Ліс", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "timber-english-initial-auction", name: "Ліс", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "timber-english-initial-qualification", name: "Ліс", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "timber-english-initial-qualification-fast", name: "Ліс", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},*/
        {type: "subsoil-english", name: "Надра. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-english-fast", name: "Надра. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-english-fast-manual", name: "Надра. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-english-initial-auction", name: "Надра. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-english-initial-qualification", name: "Надра. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-dutch", name: "Надра. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-dutch-fast", name: "Надра. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-dutch-fast-manual", name: "Надра. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-dutch-initial-auction", name: "Надра. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-dutch-initial-qualification", name: "Надра. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-dutch-initial-auction-manual", name: "Надра. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "subsoil-english-fast-auction-manual-qualification", name: "Надра. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-english", name: "Вантажні залізничні вагони. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-english-fast", name: "Вантажні залізничні вагони. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-english-fast-manual", name: "Вантажні залізничні вагони. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-english-initial-auction", name: "Вантажні залізничні вагони. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-english-initial-qualification", name: "Вантажні залізничні вагони. Англійський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-dutch", name: "Вантажні залізничні вагони. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-dutch-fast", name: "Вантажні залізничні вагони. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-dutch-fast-manual", name: "Вантажні залізничні вагони. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-dutch-initial-auction", name: "Вантажні залізничні вагони. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-dutch-initial-qualification", name: "Вантажні залізничні вагони. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-dutch-fast-auction-manual-qualification", name: "Вантажні залізничні вагони. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "railwayCargo-dutch-initial-auction-manual", name: "Вантажні залізничні вагони. Голландський", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english-fast", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english-fast-manual", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english-fast-auction-manual-qualification", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english-initial-auction", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english-initial-auction-manual", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english-initial-qualification", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-english-initial-qualification-prod", name: "Оренда державного/комунального майна", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-dutch", name: "Оренда державного/комунального майна з покроковим зниженням", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-dutch-fast", name: "Оренда державного/комунального майна з покроковим зниженням", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-dutch-fast-manual", name: "Оренда державного/комунального майна з покроковим зниженням", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-dutch-fast-auction-manual-qualification", name: "Оренда державного/комунального майна з покроковим зниженням", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-dutch-initial-auction", name: "Оренда державного/комунального майна з покроковим зниженням", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-dutch-initial-auction-manual", name: "Оренда державного/комунального майна з покроковим зниженням", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-dutch-initial-qualification-prod", name: "Оренда державного/комунального майна з покроковим зниженням", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish-fast", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish-fast-manual", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish-initial-auction", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish-initial-auction-manual", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish-initial-qualification", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "legitimatePropertyLease-priorityEnglish-initial-qualification-prod", name: "Оренда державного/комунального майна з використанням переважного права", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: true, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english-fast", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english-fast-manual", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english-fast-auction-manual-qualification", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english-initial-auction", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english-initial-auction-manual", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english-initial-qualification", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landRental-english-initial-qualification-prod", name: "Земельні торги - оренда", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english-fast", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english-fast-manual", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english-fast-auction-manual-qualification", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english-initial-auction", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english-initial-auction-manual", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english-initial-qualification", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-english-initial-qualification-prod", name: "Земельні торги - продаж", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish-fast", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish-fast-manual", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish-fast-auction-manual-qualification", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish-initial-auction", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish-initial-auction-manual", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish-initial-qualification", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "landSell-priorityEnglish-initial-qualification-prod", name: "Земельні торги - продаж з переважним правом", canCreateAuction: false, canCreateLandAuction: true, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "basicSell-english", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-english-fast", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-english-fast-manual", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-english-fast-auction-manual-qualification", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-english-initial-auction", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-english-initial-auction-manual", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-english-initial-qualification", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-english-initial-qualification-prod", name: "Базова продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch-fast", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch-fast-manual", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch-fast-auction-manual-qualification", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch-initial-auction", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch-initial-auction-manual", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch-initial-qualification", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "basicSell-dutch-initial-qualification-prod", name: "Базова продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false},
        {type: "alienation-english", name: "Відчуження майна. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: true, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "alienation-english-fast", name: "Відчуження майна. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: true, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "alienation-english-fast-manual", name: "Відчуження майна. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: true, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "alienation-english-fast-auction-manual-qualification", name: "Відчуження майна. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: true, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "alienation-english-initial-auction-manual", name: "Відчуження майна. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: true, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "alienation-english-initial-qualification", name: "Відчуження майна. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: true, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "alienation-english-initial-qualification-prod", name: "Відчуження майна. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: true, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        /*{type: "arrestedAssets-english", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true},
        {type: "arrestedAssets-english-fast", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false},
        {type: "arrestedAssets-english-fast-manual", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false},
        {type: "arrestedAssets-english-fast-auction-manual-qualification", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false},
        {type: "arrestedAssets-english-initial-auction", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false},
        {type: "arrestedAssets-english-initial-auction-manual", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false},
        {type: "arrestedAssets-english-initial-qualification", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false},
        {type: "arrestedAssets-english-initial-qualification-prod", name: "Базова продаж. Арештоване майно", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false},*/
        {type: "commercialSell-english", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-english-fast", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-english-fast-manual", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-english-fast-auction-manual-qualification", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-english-initial-auction", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-english-initial-auction-manual", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-english-initial-qualification", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-english-initial-qualification-prod", name: "Комерційний продаж. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        
        {type: "commercialSell-dutch", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-dutch-fast", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-dutch-fast-manual", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-dutch-fast-auction-manual-qualification", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-dutch-initial-auction", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-dutch-initial-auction-manual", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-dutch-initial-qualification", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},
        {type: "commercialSell-dutch-initial-qualification-prod", name: "Комерційний продаж. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: true},

        {type: "bankRuptcy-english", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-english-fast", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-english-fast-manual", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-english-fast-auction-manual-qualification", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-english-initial-auction", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-english-initial-auction-manual", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-english-initial-qualification", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-english-initial-qualification-prod", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},

        {type: "bankRuptcy-dutch", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-dutch-fast", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-dutch-fast-manual", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-dutch-fast-auction-manual-qualification", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-dutch-initial-auction", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-dutch-initial-auction-manual", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-dutch-initial-qualification", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},
        {type: "bankRuptcy-dutch-initial-qualification-prod", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},

        {type: "bankRuptcy-withoutAuction", name: "Продаж майна боржників у справах про банкрутство без аукціону", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreateBankruptAuction: true},

        {type: "regulationsPropertyLease-english", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-ultra-fast", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-fast", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-fast-manual", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-fast-auction-manual-qualification", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-fast-auction-prod", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-initial-auction", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-initial-auction-manual", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-initial-qualification", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-english-initial-qualification-prod", name: "Оренда за регламентом (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-ultra-fast", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-fast", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-fast-manual", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-fast-auction-manual-qualification", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-fast-auction-prod", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-initial-auction", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-initial-auction-manual", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-initial-qualification", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "regulationsPropertyLease-dutch-initial-qualification-prod", name: "Оренда за регламентом (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},

        {type: "commercialPropertyLease-english", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-ultra-fast", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-fast", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-fast-manual", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-fast-auction-manual-qualification", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-fast-auction-prod", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-initial-auction", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-initial-auction-manual", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-initial-qualification", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-english-initial-qualification-prod", name: "Комерційна оренда (англійський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-ultra-fast", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-fast", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-fast-manual", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-fast-auction-manual-qualification", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-fast-auction-prod", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-initial-auction", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-initial-auction-manual", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-initial-qualification", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "commercialPropertyLease-dutch-initial-qualification-prod", name: "Комерційна оренда (голландський аукціон)", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},

        {type: "smallPrivatization-english", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-ultra-fast", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-fast", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-fast-manual", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-fast-auction-manual-qualification", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-fast-auction-prod", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-initial-auction", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-initial-auction-manual", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-initial-qualification", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-english-initial-qualification-prod", name: "Продаж об'єктів малої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},

        {type: "smallPrivatization-dutch", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-fast", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-ultra-fast", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-fast-manual", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-fast-auction-manual-qualification", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: true, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-fast-auction-prod", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-initial-auction", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-initial-auction-manual", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-initial-qualification", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "smallPrivatization-dutch-initial-qualification-prod", name: "Продаж об'єктів малої приватизації. Голландський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},



        {type: "nonperformingLoans-english", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-fast", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-fast-manual", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-fast-auction-manual-qualification", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-fast-auction-prod", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-initial-qualification", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-initial-qualification-prod", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-initial-auction", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-english-initial-auction-manual", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        
        {type: "nonperformingLoans-dutch", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-fast", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-fast-manual", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-fast-auction-manual-qualification", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-fast-auction-prod", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-initial-auction", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-initial-auction-manual", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-initial-qualification", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},
        {type: "nonperformingLoans-dutch-initial-qualification-prod", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", canCreateAuction: true, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false},


        {type: "landArrested-english", name: "Арештована земля. Англійський аукціон", master: true, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-ultra-fast", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-fast", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-fast-manual", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-fast-auction-manual-qualification", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-fast-auction-prod", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-initial-auction", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-initial-auction-manual", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-initial-qualification", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-english-initial-qualification-prod", name: "Арештована земля. Англійський аукціон", master: false, canCreateLandArrestedAuction: true},

        {type: "landArrested-withoutAuction", name: "Арештована земля. Викуп", master: true, canCreateLandArrestedAuction: true},
        
        {type: "landArrested-priorityEnglish", name: "Арештована земля з переважним правом", master: true, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-ultra-fast", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-fast", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-fast-manual", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-fast-auction-manual-qualification", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-fast-auction-prod", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-initial-auction", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-initial-auction-manual", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-initial-qualification", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},
        {type: "landArrested-priorityEnglish-initial-qualification-prod", name: "Арештована земля з переважним правом", master: false, canCreateLandArrestedAuction: true},

        {type: "largePrivatization-english", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: true, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-fast", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-ultra-fast", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-fast-manual", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-fast-auction-manual-qualification", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-fast-auction-prod", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-initial-auction", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-initial-auction-manual", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-initial-qualification", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},
        {type: "largePrivatization-english-initial-qualification-prod", name: "Продаж об'єктів великої приватизації. Англійський", canCreateAuction: false, canCreateLandAuction: false, canCreateLeaseAuction: false, master: false, canCreateAlienation: false, canCreateBasicSell: false, canCreateCommercialSellAuction: false, canCreatePrivatization: true},

        {type: "armaProperty-english", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: true},
        {type: "armaProperty-english-ultra-fast", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-fast", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-fast-manual", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-fast-auction-manual-qualification", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-fast-auction-prod", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-initial-auction", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-initial-auction-manual", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-initial-qualification", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-english-initial-qualification-prod", name: "Арештовані активи АРМА. Англійський аукціон", canCreateArma: true, master: false},

        {type: "armaProperty-dutch", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: true},
        {type: "armaProperty-dutch-ultra-fast", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-fast", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-fast-manual", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-fast-auction-manual-qualification", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-fast-auction-prod", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-initial-auction", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-initial-auction-manual", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-initial-qualification", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},
        {type: "armaProperty-dutch-initial-qualification-prod", name: "Арештовані активи АРМА. Голандський аукціон", canCreateArma: true, master: false},

        {type: "renewables-multiAwards", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: true},
        {type: "renewables-multiAwards-ultra-fast", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-fast", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-fast-manual", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-fast-auction-manual-qualification", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-fast-auction-prod", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-initial-auction", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-initial-auction-manual", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-initial-qualification", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},
        {type: "renewables-multiAwards-initial-qualification-prod", name: "Зелена енергетика: продаж квот", canCreateAuction: true, master: false},

    ]
}

function viewOption(props, master){
    let tmpCanCreateLeaseAuction = []
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod)
    ){
        if(master === true){
            tmpCanCreateLeaseAuction = arrayTypeAuction()
            .filter(
                (i) => {
                    return props.userProfiles.canCreateLandArrestedAuction !== true
                }
            )
            .filter(
                (i) => {
                    return props.userProfiles.canCreateLeaseAuction === true
                }
            )
            .filter(
                (i) => {
                    return i.canCreateLeaseAuction === true
                }
            )
            .filter(
                (i) => {
                    return i.master === true
                }
            )
            .map(
                (i) => {
                    return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
                }
            )
        }else{
            tmpCanCreateLeaseAuction = arrayTypeAuction()
            .filter(
                (i) => {
                    return props.userProfiles.canCreateLandArrestedAuction !== true
                }
            )
            .filter(
                (i) => {
                    return props.userProfiles.canCreateLeaseAuction === true
                }
            )
            .filter(
                (i) => {
                    return i.canCreateLeaseAuction === true
                }
            )
            .map(
                (i) => {
                    return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
                }
            )
        }
    }
    let tmpCanCreateLandAuction = []
    if(master === true){
        tmpCanCreateLandAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateLandAuction === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        tmpCanCreateLandAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateLandAuction === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }

    let tmpCanCreateAuction =[]
    if(master === true){
        tmpCanCreateAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateAuction === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        tmpCanCreateAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateAuction === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }

    let tmpCanCreateAlienation =[]
    if(master === true){
        tmpCanCreateAlienation = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateAlienation === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateAlienation === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        tmpCanCreateAlienation = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateAlienation === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateAlienation === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }


    let canCreateCommercialSellAuction =[]
    if(master === true){
        canCreateCommercialSellAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateCommercialSellAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateCommercialSellAuction === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        canCreateCommercialSellAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateCommercialSellAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateCommercialSellAuction === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }
    
    let tmpCanCreateBasicSell =[]
    if(master === true){
        tmpCanCreateBasicSell = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateBasicSell === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateBasicSell === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        tmpCanCreateBasicSell = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateBasicSell === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateBasicSell === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }

    let canCreateBankruptAuction =[]
    if(master === true){
        canCreateBankruptAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateBankruptAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateBankruptAuction === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        canCreateBankruptAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateBankruptAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateBankruptAuction === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }

    let canCreatePrivatization =[]
    if(master === true){
        canCreatePrivatization = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreatePrivatization === true
            }
        )
        .filter(
            (i) => {
                return i.canCreatePrivatization === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        canCreatePrivatization = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreatePrivatization === true
            }
        )
        .filter(
            (i) => {
                return i.canCreatePrivatization === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }

    let canCreateLandArrestedAuction =[]
    if(master === true){
        canCreateLandArrestedAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateLandArrestedAuction === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        canCreateLandArrestedAuction = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateLandArrestedAuction === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }
    let canCreateArma =[]
    if(master === true){
        canCreateArma = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateArma === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateArma === true
            }
        )
        .filter(
            (i) => {
                return i.master === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name}</option>
            }
        )
    }else{
        canCreateArma = arrayTypeAuction()
        .filter(
            (i) => {
                return props.userProfiles.canCreateLandArrestedAuction !== true
            }
        )
        .filter(
            (i) => {
                return props.userProfiles.canCreateArma === true
            }
        )
        .filter(
            (i) => {
                return i.canCreateArma === true
            }
        )
        .map(
            (i) => {
                return <option key={i.type} className={styles.hoverOptions} value={i.type}>{i.name + ' ' + i.type}</option>
            }
        )
    }


    let tmp = [
        ...tmpCanCreateAuction,
        ...tmpCanCreateLeaseAuction,
        ...tmpCanCreateLandAuction,
        ...tmpCanCreateAlienation,
        ...tmpCanCreateBasicSell,
        ...canCreateCommercialSellAuction,
        ...canCreateBankruptAuction,
        ...canCreatePrivatization,
        ...canCreateLandArrestedAuction,
        ...canCreateArma
    ]
    return tmp
}
 
function setStatusTaxValue(props, data){
    if(
        data === "legitimatePropertyLease-english" ||
        data === "legitimatePropertyLease-english-fast" ||
        data === "legitimatePropertyLease-english-fast-manual" ||
        data === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        data === "legitimatePropertyLease-english-initial-auction" ||
        data === "legitimatePropertyLease-english-initial-auction-manual" ||
        data === "legitimatePropertyLease-english-initial-qualification" ||
        data === "legitimatePropertyLease-english-initial-qualification-prod" ||
        data === "legitimatePropertyLease-priorityEnglish" ||
        data === "legitimatePropertyLease-priorityEnglish-fast" ||
        data === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        data === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        data === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        data === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        data === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        data === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        props.changeCreateAuctionValueTax(true)
    }
}


function auctionZU(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
    ){
        if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){}
    }
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy !== null){
        if(props.auctionsMy.status !== null){
            if(props.auctionsMy.status !== 'active_rectification'){
                tmpStatus = 'disabled'
            }
        }
        if(
            isLLE(props.auctionsMy.sellingMethod) ||
            isLLD(props.auctionsMy.sellingMethod) ||
            isLLP(props.auctionsMy.sellingMethod)
        ){
            //if(props.auctionsMy.status !== null){
                tmpStatus = 'disabled'
            //}
        }
    }
    return tmpStatus
}

function redBlock(props, amount){
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod)
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        bildStatus: state.start.bildStatus,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionType,
    setUniversalError,
    changeCreateAuctionValueTax,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionType)
