import React, {useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {PROCEDURE_NAME} from '../../../../../../redux/procedure_v3.35.2.uk';

import {isMongo, checkProp} from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmountMult';
import InputDate from '../../../../../elements/inputs/inputDate';

import {disabletElem} from '../../../../disabled';

import {createBody} from '../lib/createBody';
import {setTenderAttemptList} from '../lib/utils';

import {editAuc, createAuc} from '../../../createEditAndCloneAuction/edit/editTabMonga'

const AuctionRMA = ({data, ...props}) => {
    const auction = useSelector(state => state.start.auctionsMy)
    // const token = useSelector(state => state.start.token)


    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [bankGuaranteeDetails, setBankGuaranteeDetails] = useState(checkProp(data, 'bankGuaranteeDetails') ? isMongo(data.bankGuaranteeDetails) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : 1)
    // const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)

    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)

    let requestBody = {
        sellingMethod: data.sellingMethod,
        title: title !== null && title !== ""
            ? {
                uk_UA: title
            }
            : null,
        description: description !== null && description !== ""
            ? {
                uk_UA: description
            }
            : null,
        bankGuaranteeDetails: bankGuaranteeDetails !== null && bankGuaranteeDetails !== ""
            ? {
                uk_UA: bankGuaranteeDetails
            }
            : null,
        // accessDetails: accessDetails !== null && accessDetails !== ''
        //     ? {
        //         uk_UA: accessDetails
        //     }
        //     : null,

        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        x_additionalInformation: {
            uk_UA: x_additionalInformation
        },
        x_documentRequirements: {
            uk_UA: x_documentRequirements
        },
        value: value?.amount !== null && value?.amount > 0
            ? {
                currency: checkProp(value, 'currency') ? 'eurocent' : null,
                amount: checkProp(value, 'amount') ? +value.amount : null,
                valueAddedTaxIncluded: checkProp(value, 'addTax') ? value.addTax : null
            }
            : null,
        minimalStep: minimalStep?.amount !== null && minimalStep?.amount > 0
            ? {
                currency: checkProp(minimalStep, 'currency') ? 'eurocent' : null,
                amount: checkProp(minimalStep, 'amount') ? +minimalStep.amount : null,
            }
            : null,
        previousAuctionId: previousAuctionId,
    }

    return (
        <>
            <Input //Назва аукціону"
                label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.title.legalName}
                value={title}
                onChange={(e) => {
                    setTitle(e)
                }}
                required
                //disabled={disabletElem(auction, '01')}
            />
            <TextArea //"Опис аукціону"
                value={description}
                //disabled={disabletElem(auction, '01')}
                label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.description.legalName}
                required
                onChange={(e) => {
                    setDescription(e)
                }}
            />
            <Input //"Номер лота"
                label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.lotId.legalName}
                value={lotId}
                onChange={(e) => {
                    setLotId(e)
                }}
                //disabled={disabletElem(auction, '01')}
                //required
            />
            <TextArea //"Опис аукціону"
                value={bankGuaranteeDetails}
                //disabled={disabletElem(auction, '01')}
                label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.bankGuaranteeDetails.legalName}
                required
                onChange={(e) => {
                    setBankGuaranteeDetails(e)
                }}
            />


            <Select //'Лот виставляється',
                data={{
                    label: "Лот виставляється",
                    target: tenderAttempts,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabletElem(auction, '01')}
                onChange={(e) => {
                    setTenderAttempts(e)
                }
                }
                options={setTenderAttemptList()}
            />
            {/*<Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"*/}
            {/*    label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.accessDetails.legalName}*/}
            {/*    value={accessDetails}*/}
            {/*    onChange={(e) => {*/}
            {/*        setAccessDetails(e)*/}
            {/*    }}*/}
            {/*    //disabled={disabletElem(auction, '01')}*/}
            {/*/>*/}
            <Input
                label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.x_additionalInformation.legalName}
                value={x_additionalInformation}
                onChange={(e) => {
                    setX_additionalInformation(e)
                }}
                //disabled={disabletElem(auction, '01')}
            />
            <Input
                label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.x_documentRequirements.legalName}
                value={x_documentRequirements}
                onChange={(e) => {
                    setX_documentRequirements(e)
                }}
                //disabled={disabletElem(auction, '01')}
            />

            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>

                <InputAmount //"Стартова ціна лота"
                    title={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.value.legalName}
                    label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.value.legalName}
                    data={value === null || value === '' || value === "null" || (value.currency === 'null' && value.amount === 0.01)
                        ? {
                            amount: 0,
                            currency: 'eurocent',
                            addTax: false
                        }
                        : {
                            amount: value.amount,
                            currency: 'eurocent',
                            addTax: value.valueAddedTaxIncluded
                        }
                    }
                    onChange={(e) => {
                        setValue(e)
                        let tmp = {
                            amount: (e.amount / 100) * 1,
                            currency: 'eurocent',
                        }
                        setMinimalStep(tmp)
                    }}
                    required
                    //disabled={disabletElem(auction, '11')}
                    tax
                />
                <InputAmount //"Розмір кроку аукціону"
                    title={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.minimalStep.legalName}
                    label={PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.minimalStep.legalName}
                    data={{
                        amount: checkProp(minimalStep, 'amount') && minimalStep.amount !== '' ? minimalStep.amount : null,
                        currency: checkProp(minimalStep, 'currency') ? 'eurocent' : null,
                    }}
                    onChange={(e) => {
                        console.log(e)
                        setMinimalStep(e)
                    }}
                    //disabled={disabletElem(auction, '11')}
                />
            </div>


            <div className={styles.startDate}>
                <div
                    className={styles.costBlockTitle}>{`${PROCEDURE_NAME['renewables-multiAwards'].RenewablesMultiAwardsProcedure.auctionPeriod.startDate.legalName} аукціону`}</div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={e => setStartDate(e)}
                    //disabled={disabletElem(auction, '10')}
                />
            </div>

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () => {
                            let tmp = null
                            let bodyObj = {
                                base: requestBody,
                            }
                            tmp = createBody(bodyObj)
                            if (auction.id !== null && auction.id !== '') {
                                editAuc(props.updateAuc, tmp)
                            } else {
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionRMA