import {
    SET_NUMBER_OF_CURRENT_TENANTS,
    SET_NEW_CURRENT_TENANT,
    CREATE_AUCTION_MARTIAL_LAW_DISCLAIMER,
    CLEAR_DISCOUNT,
    SET_CONTRACT_X_PREPAYMENTDETAILS_DATE,
    SET_CONTRACT_X_PREPAYMENTDETAILS_PREPAYMENT_AMOUNT,
    CREATE_CVZU_DATA,
    CREATE_CVZU_ARR,
    CREATE_CVZU_VALUE,
    CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED,
    CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE,
    SERCH_ITEM_TYPE,
    SERCH_IS_PERISHABLE,
    SET_IS_PERISHABLE,
    SET_VALUE_ADDED_TAX_CHARGED_BASIC,
    CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION,
    SET_CLASSIFICATIONS_BSE_BSD,

    SET_CONTRACT_TAX,
    SET_CONTRACT_VALUE_CURRENCY,
    SET_CONTRACT_VALUE_AMOUNT,
    SET_CONTRACT_VALUE_TAX,

    SET_BID_NEEDED_SUMM,
    CHECKBOX_BID_ALL_1,
    CHECKBOX_BID_ALL_2,
    CHECKBOX_BID_ALL_3,

    CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID,
    CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME,
    CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION,
    CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY,
    CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS,
    CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE,

    SET_CADASTRAL_NUMBER,
    SET_LAND_AREA_TO,
    SET_LAND_AREA_FROM,

    PUSH_NEW_LINK,
    SET_TYPE_DOCUMENT_OF,
    SET_FULL_AUCTION,
    SET_ID_FULL_AUCTION,
    SET_LAND_CURRENT_TENANT_NAME,
    SET_LAND_CURRENT_TENANT_REPRESENTATIVE_INFO,
    SET_LAND_CURRENT_TENANT_VALUE_AMOUNT,
    SET_LAND_CURRENT_TENANT_VALUE_CURRENCY,
    SET_LAND_CURRENT_TENANT_IDENTIFIER_ID,
    SET_LAND_CURRENT_TENANT_IDENTIFIER_LEGAL_NAME,
    SET_LAND_CURRENT_TENANT_IDENTIFIER_SCHEME,
    SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_FROM,
    SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_TO,
    SET_LAND_CURRENT_TENANT_ADDRESS_COUNTRY_NAME,
    SET_LAND_CURRENT_TENANT_ADDRESS_POSTAL_CODE,
    SET_LAND_CURRENT_TENANT_ADDRESS_STREET_ADDRESS,
    SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY,
    SET_LAND_CURRENT_TENANT_ADDRESS_REGION,


    POPUP_CONTRACT_DOC_ID,
    SET_CONTRACT_POPUP_DEL,
    SET_EDIT_BID_VALUE,
    SET_TOGETHER_API_HELP_POPUP,
    SET_AUCTION_RESTRICTIONS,
    SET_AUCTION_CONDITIONS,
    SET_AUCTION_BUDGET_SPENT,
    SET_AUCTION_EXPERT_MONETARY_VALUATION,
    SET_AUCTION_NORMATIVE_MONETARY_VALUATION,
    AUCTION_lEASE_TYPE,
    AUCTION_SALE_TYPE,
    CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR,
    CREATE_ITEMS_LAND_RENTAL_ID,
    CREATE_ITEMS_LAND_RENTAL_DESCRIPTION,
    CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION,
    CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION,
    CREATE_ITEMS_LAND_RENTAL_ADDRESS,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE,
    CREATE_ITEMS_LAND_RENTAL_QUANTITY,
    CREATE_ITEMS_LAND_RENTAL_UNIT_CODE,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP,
    CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY,


    CLONE_AUCTIONS_STATUS_CHANGE,
    CREATE_ADDRESS_TYPE_ORG,
    SET_SEARCH_REGISTRY_ID,
    SET_MY_AUCTIONS_STATUS,

    CREATE_SEARCH_NUMBER_OF_CURRENT_TENANTS,


    SET_CABINET_PAGE_COUNTER,
    SET_BRIDGE_OPEN_POPUP,
    SET_BRIDGE_OPEN_BUTTON,

    SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE,
    SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE,
    SET_CURRENT_TENANT_GUARANTEE_FEE_VALUE,
    SET_SHEDULE_PERIOD_ID,
    SET_SCHEDULE_TIME_START,
    SET_SCHEDULE_TIME_END,

    SET_SHEDULE_PERIOD,
    SET_NEW_PERIOD_ARR,
    SET_NEW_FULL_PERIOD_ARR,

    SET_SCHEDULE_WEEKDAY,

    SET_CREATE_AUCTION_ZU_FLAG,
    SET_CREATE_AUCTION_ZU_DATA,
    SET_CREATE_AUCTION_ZU_OBJECT_ID,

    SET_OBJECT_REESTR,
    SET_SCHEDULE_FLAG,
    SET_ID_OBJECT_CLONE_AUCTIONS,
    SET_POP_UP_AUCTIONS_REASON,
    CREATE_BANK_ACCOUNT_ZU_PERCENT,
    CREATE_BANK_ACCOUNT_ZU_HOLDER_TYPE,
    CREATE_BANK_ACCOUNT_ZU_GROUPE,
    SET_POP_UP_BANK_ACCOUNT_CURRENCY,
    SET_POPUP_ORGANIZATIONS,
    SET_POP_UP_ADDRESS_ID,
    SET_POPUP_ADDRESSES,

    CHECKBOX_AGREEMENT,
    CHECKBOX_WAIT,

    SEARCH_LEASE_DURATION_FROM_YEAR,
    SEARCH_LEASE_DURATION_FROM_MONTH,
    SEARCH_LEASE_DURATION_FROM_DAY,
    SEARCH_LEASE_DURATION_TO_YEAR,
    SEARCH_LEASE_DURATION_TO_MONTH,
    SEARCH_LEASE_DURATION_TO_DAY,

    SEARCH_AREA_FROM,
    SEARCH_AREA_TO,

    SERCH_ADDITIONAL_CLASSIFICATION,
    SERCH_CLASSIFICATION,
    SET_CLASSIFICATIONS_ZU_ADD,
    CREATE_AUCTIONS_ZU_ATTEMP,

    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ID,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID,
    CREATE_ITEMS_VEHICLE_ITEM_ID,
    CREATE_ITEMS_REAL_ESTATEITEM_ID,

    CREATE_BANK_ACCOUNT_ZU_GROUP_TYPE,

    SET_SCHEDULE_HOURS_BY_VALUE,
    SET_SCHEDULE_HOURS_BY_BY,
    SET_SCHEDULE_DAYS_BY_VALUE,
    SET_SCHEDULE_DAYS_BY_BY,
    SET_SCHEDULE_PERIOD_START_DATE,
    SET_SCHEDULE_PERIOD_END_DATE,
    SET_SCHEDULE_OTHER,
    
    SET_AUCTION_LEASE_RULES,
    SET_AUCTION_RELATED_ORGANIZATIONS,
    CREATE_BANK_ACCOUNT_ZU_ID,
    CREATE_BANK_ACCOUNT_ZU_GROUP,
    CREATE_BANK_ACCOUNT_ZU_TYPE,
    SET_POP_UP_BANK_ACCOUNT_ZU,

    CREATE_ITEMS_REAL_ESTATEITEM_TITLE,
    CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION,
    CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION,
    CREATE_ITEMS_REAL_ESTATEITEM_ADDITIONAL_CLASSIFICATION,
    CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS,
    CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE,
    CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE,
    CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS,
    CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID,
    CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE,
    CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY,
    CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE,

    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX,
    CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE,


    CREATE_ITEMS_VEHICLE_ITEM_TITLE,
    CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION,
    CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION,
    CREATE_ITEMS_VEHICLE_ITEM_ADDITIONAL_CLASSIFICATION,
    CREATE_ITEMS_VEHICLE_ITEM_ADDRESS,
    CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE,
    CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE,
    CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS,
    CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID,
    CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION,
    CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE,
    CREATE_ITEMS_VEHICLE_ITEM_QUANTITY,
    CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE,

    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_TITLE,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_DESCRIPTION,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_CLASSIFICATION,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDITIONAL_CLASSIFICATION,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDRESS,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LATITUDE,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LONGITUDE,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY,
    CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE,
    

    CREATE_ITEMS_ZU_CHOUSEN_TYPE,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_TITLE,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_DESCRIPTION,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_CLASSIFICATION,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDITIONAL_CLASSIFICATION,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDRESS,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LATITUDE,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LONGITUDE,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_ELEVATION,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ITEM_TYPE,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_QUANTITY,
    CREATE_ITEMS_OTHER_PROPERTY_ITEMS_UNIT_CODE,

    SET_SCHEDULE_LENGTH_YEARS,
    SET_SCHEDULE_LENGTH_MONTHS,
    SET_SCHEDULE_LENGTH_DAYS,
    SET_SCHEDULE_MONDAY,
    SET_SCHEDULE_TUESDAY,
    SET_SCHEDULE_WEDNESDAY,
    SET_SCHEDULE_THURSDAY,
    SET_SCHEDULE_FRIDAY,
    SET_SCHEDULE_SATURDAY,
    SET_SCHEDULE_SUNDAY,
    SET_SCHEDULE_PLANNED_EVENTS,

    SET_AUCTION_PROPERTY_OWNER_ID,
    SET_AUCTION_SELLING_ENTITY_ID,
    SET_AUCTION_CURRENT_TENANT_ID,
    SET_AUCTION_OWNERSHIP_TYPE,
    GET_ADDRESS_NEW_BOOKS,
    CREATE_ADDRESS_NEW_OPEN_CLOSE,
    CREATE_ADDRESS_NEW_ID,
    CREATE_ADDRESS_NEW_NAME,
    CREATE_ADDRESS_NEW_IDENTIFIER_ID,
    CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER,
    CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME,
    CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME,
    CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO,
    CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID,
    CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER,
    CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME,
    CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME,
    CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID,
    CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER,
    CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME,
    CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME,
    CREATE_ADDRESS_NEW_ADDRESS_ID,
    CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS,
    CREATE_ADDRESS_NEW_ADDRESS_LOCALITY,
    CREATE_ADDRESS_NEW_ADDRESS_REGION,
    CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE,
    CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME,
    CREATE_ADDRESS_NEW_CONTACT_POINT_ID,
    CREATE_ADDRESS_NEW_CONTACT_POINT_NAME,
    CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL,
    CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE,
    CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER,
    CREATE_ADDRESS_NEW_CONTACT_POINT_URL,

    CREATE_LEASE_RULES_INTENDED_USE,
    CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_METHOD,
    CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_DESCRIPTION,
    CREATE_LEASE_RULES_ADDITIONAL_LEASE_CONDITIONS,
    CREATE_LEASE_RULES_SUBLEASE_ALLOWED,
    CREATE_VALUE_PERIOD,
    CREATE_REGISTRATION_FEE,
    CREATE_REGISTRATION_FEE_TAX,
    CREATE_REGISTRY_ID,
    CREATE_VALUE_ADDED_TAX_CHARGED,
    CREATE_CALC_ON_BOOK_VALUE,

    CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS,
    CREATE_ITEMS_DGF_VIRTUALDATAROOM,
    CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA,
    CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS,
    CREATE_ITEMS_DGF_X_DGFDECISION_ID,
    CREATE_ITEMS_DGF_X_DGFDECISION_DATE,

    SET_MY_CURRENT_PAGE,
    SET_MY_CABINET_PAGE,

    SET_DICTIONARIES_OWNERSHIPTYPE,
    SET_DICTIONARIES_ENCUMBRANCES,
    SET_DICTIONARIES_POWERSUPPLYCLASS,
    SET_DICTIONARIES_FLOORTYPE,
    SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY,
    SET_DICTIONARIES_FUELTYPE,
    SET_DICTIONARIES_TRANSMISSIONTYPE,
    SET_DICTIONARIES_ELIGIBILITYCRITERIA,

    CREATE_ITEMS_DGF_REALESTATEPROPS_ID,
    CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR,
    CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS,

    CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY,
    CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY,
    CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION,
    CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING,
    CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX,

    CREATE_ITEMS_DGF_VEHICLEPROPS_ID,
    CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND,
    CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL,
    CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR,
    CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY,
    CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE,
    CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION,
    CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR,
    CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE,
    CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER,
    CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION,
    CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION,
    CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE,
    CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION,

    CREATE_ITEMS_DGF_LANDPROPS_ID,
    CREATE_ITEMS_DGF_LANDPROPS_LANDAREA,
    CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER,
    CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE,
    CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES,
    CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP,
    CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY,
    CREATE_ITEMS_DGF_LANDPROPS_HASENCUMBRANCES,
    CREATE_ITEMS_DGF_LANDPROPS_HASJOINTOWNERSHIP,
    CREATE_ITEMS_DGF_LANDPROPS_HASUTILITIESAVAILABILITY,

    CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE,

    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION,
    CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS,

    CREATE_ITEMS_LOCATION_LATITUDE,
    CREATE_ITEMS_LOCATION_LONGITUDE,
    CREATE_ITEMS_LOCATION_ELEVATION,
    SET_DGF_MASK,
    SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD,

    OPEN_BANK_WINDOW,

    SET_ACCORDIONS_BIDS,
    SET_ACCORDIONS_AWARDS,
    SET_ACCORDIONS_CONTRACTS,
    SET_ACCORDIONS_CHANCEL,

    SAVE_HISTORY_DOCS_CONTRACT,

    SET_CONTRACT_TIME_ADDED_DATE_FROM,
    SET_CONTRACT_TIME_ADDED_DATE_TILL,


    SET_SINGL_PASSWORD,
    WORK_BANK_ACCOUNT_SAVE,
    WORK_BANK_ACCOUNT_ADD,
    WORK_BANK_ACCOUNT_DELETE,
    

    PAYMENT_POP_UP_SET_BANK,
    SET_NADRA_CLASSIFICATOR_ADD,
    SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE,
    PAYMENT_POP_UP_GET_DATA,
    PAYMENT_POP_UP,
    PAYMENT_POP_UP1,
    PAYMENT_POP_UP_CREATE_PDF,
    ALL_AUCTION_BIDS_ARR,
    ALL_AUCTION_BIDS_AUCTION_ID,

    POPUP_EDIT_APPROVE_DOC,
    POPUP_EDIT_APPROVE_DOC_TITLE,
    POPUP_EDIT_APPROVE_DOC_DISCRIP,
    POPUP_EDIT_APPROVE_DOC_FILES,
    CHECKBOX_BID_PUBLISH,

    TOGETHER_API_PAGE_AUCTION_END_PAY,
    WORK_MY_PAGE_CURRENT_AUCTION,
    WORK_MY__API_PAGE_NEXT_AUCTION,
    TOGETHER_API_PAGE_CURRENT_AUCTION,
    TOGETHER_API_PAGE_NEXT_AUCTION,

    EDIT_AUCTION_FROM_ID,
    POP_UP_ADDRESS_ID,
    SET_POP_UP_BANK_ACCOUNT_EDIT_ID,

    CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY,
    CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT,
    CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT,


    TOGETHER_API_TIMER_ROUND,
    TOGETHER_API_TIMER_TIME,
    TOGETHER_API_TIMER_TIMELEFT,

    DISCOUNT_ON_OFF,
    SET_END_POINT,
    HISTERY_AUCTIONS,
    HISTERY_AWARDS,
    HISTERY_CONTRACTS,
    HISTERY_BIDS,

    TOGETHER_API_PAGE_SWITCH,
    TOGETHER_API_BIDS_ARR,
    TOGETHER_API_BIDS_WORK_ARR,
    TOGETHER_API_POPUP_ONOFF,
    TOGETHER_API_DOC_TITLE,
    TOGETHER_API_DOC_DESCRIPTION,
    TOGETHER_API_DOC_TYPE,
    TOGETHER_API_DOC_LANG,
    TOGETHER_API_DOC_FILENAME,
    TOGETHER_API_DOC_FILENAME_ECP,
    TOGETHER_API_ECP,
    TOGETHER_API_DRAFT_BID_SUMM,
    TOGETHER_API_PULL_AUC_EI,
    TOGETHER_API_BASE_PULL_AUC_EI,
    TOGETHER_API_SAVE_DATA_LOAD,
    TOGETHER_API_SAVE_VALUE,

    SET_MIN_NUMBER_OF_QUALIFIED_BIDS,
    UNIVERSAL_ERROR,
    
    ECP_POPUP,
    ECP_LINK,

    AWARD_ALL_USER_POPUP,
    AWARD_ALL_USER_POPUP_USER,
    CHANGE_LOADER,
    STREET_ADDRESS,
    LOCALITY_ADDRESS,
    REGION_ADDRESS,
    POSTAL_CODE_ADDRESS,
    COUNTRY_NAME_ADDRESS,
    POP_UP_ADDRESS,
    SET_POP_UP_ADDRESS_ERROR,
    SET_POP_UP_ADDRESS_COMPLITE,

    SET_AWARD_REJECT_TITLE,
    SET_AWARD_REJECT_DESCRIPTION,
    AWARD_DOCUMENT_RESTRICT,
    SET_AWARD_REJECT_DOCUMENT_TYPE,

    AWARD_POPUP_VIEW,
    SET_POP_UP_SEND_AUCTION,
    SET_POP_UP_DEL_AUCTION,
    AUCTIONS_GET_MY_ID,
    SET_DELETE_AUCTION_DESCRIPTION,

    SET_KEY_INTERVAL,
    SET_CLASSIFICATIONS_BY_TYPE,
    SET_CLASSIFICATIONS_BY_TYPE_ENERGY,
    SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE,
    SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
    SET_CLASSIFICATIONS_BY_TIMBER_SPECIES,
    SET_CLASSIFICATIONS_BY_TIMBER_CLASS,
    SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
    SET_CLASSIFICATIONS_BY_TIMBER_LENGTH,
    SET_CLASSIFICATIONS_BY_TIMBER_STORAGE,
    SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
    SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR,
    CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER,
    CHANGE_UNIT_CODE,
    CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE,
    CREATE_AUCTION_RAILWAY_LOT_TYPE,

    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY,
    UNITS_GET_BY_TYPE_RAILWAY,
    UNITS_GET_BY_TYPE_SUBSOIL,
    UNITS_GET_BY_TYPE_ZU,

    SET_CLASSIFICATIONS_BY_WAGON_SPECIES,

    SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH,

    CREATE_AUCTION_LOT_ID,

    SET_POPUP_LOGIN,
    SET_SEARCH_TEXT_STRING,
    SET_SEARCH_TYPE_KEYWORD,
    SET_SEARCH_DATE_START,
    SET_SEARCH_DATE_END,
    SET_SEARCH_SORT,

    AUCTIONS_GET_SEND,
    SET_POP_UP_ERROR_AUCTION,

    SET_AUCTIONS_PATH,
    SET_CATEGORY_AUCTION,
    SET_PRICE_FROM,
    SET_PRICE_FROM_BAG,
    SET_PRICE_TO,
    SET_PRICE_TO_BAG,

    DOCUMENT_COUNNT_ARR_FILES,
    DOCUMENT_COUNNT_FILES,
    DOCUMENT_BID_BLOCK_STATUS,
    DOCUMENT_BID_PUBLIC_NAME,

    AUC_TEST,
    CHANGE_MEASURES,
    CHANGE_MENU,
    CHANGE_MENU_ON_OFF,
    CHANGE_WINDOW,
    CHANGE_LANG,
    CHANGE_ADVANCED_FIND,
    BID_AWARDS,
    BID_QUANTITY,
    CREATE_AUCTION_TAB,

    CREATE_AUCTION_TITLE,
    CREATE_AUCTION_DESCRIPTION,
    CREATE_AUCTION_TYPE,
    CREATE_AUCTION_TENDER_ATTEMPTS,
    CREATE_AUCTION_ACCESS_DETAILS,
    CREATE_AUCTION_XDOCUMENT_REQUIREMENTS,
    CREATE_AUCTION_XADDITIONAL_INFORMATION,
    CREATE_AUCTION_VALUE_CURRENCY,
    CREATE_AUCTION_VALUE_AMOUNT,
    CREATE_AUCTION_VALUE_TAX,
    CREATE_AUCTION_GUARANTEE_CURRENCY,
    CREATE_AUCTION_GUARANTEE_AMOUNT,
    CREATE_AUCTION_GUARANTEE_TAX,
    CREATE_AUCTION_MINIMAL_STEP_CURRENCY,
    CREATE_AUCTION_MINIMAL_STEP_AMOUNT,
    CREATE_AUCTION_MINIMAL_STEP_TAX,
    CREATE_AUCTION_DATE_START,
    CREATE_AUCTION_DATE_END,
    CREATE_AUCTION_ID,
    CREATE_CHANGET_AUCTION,


    CREATE_ITEMS_CLASSIFICATION_ID,
    CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS,
    CREATE_ITEMS_UNIT_VALUE_CURRENCY,
    CREATE_ITEMS_UNIT_VALUE_AMOUNT,
    CREATE_ITEMS_UNIT_VALUE_TAX,
    CREATE_ITEMS_DESCRIPTION,
    CREATE_ITEMS_QUANTITY,
    CREATE_ITEMS_IDS,
    CREATE_ITEMS_ADDRESS,

    CREATE_ITEMS_REGION,
    CREATE_ITEMS_TECH_PARAMS,
    CREATE_ITEMS_TIME_SLOTS,
    CREATE_ITEMS_LOAD_PROFILES,

    CREATE_DOCUMENT_FILE_NAME,
    

    NUMBER_PAGE_LOT,

    //Address
    ADDRESS_GET_MY,
    //ADDRESS_POST,
    //ADDRESS_PUT,
    //ADDRESS_DELETE,
    //Auctions
    AUCTIONS_GET,
    AUCTIONS_GET_FLAG,
    AUCTIONS_GET_ID,
    //AUCTIONS_POST,
    //AUCTIONS_PUT,
    //AUCTIONS_DELETE,
    AUCTIONS_SEARCH,
    AUCTIONS_SEARCH_OLD,
    AUCTIONS_SEARCH_MIN,
    AUCTIONS_SEARCH_COUNT,
    AUCTIONS_SEARCH_OLD_COUNT,
    AUCTIONS_GET_MY,
    AUCTIONS_SWITCH_WINDOW,
    AUCTIONS_ITEMS_SWITCH_WINDOW,
    AUCTIONS_CURENT_PAGE,
    AUCTIONS_STATUS,
    //Auction Items
    //AUCTION_ITEMS_GET,
    //AUCTION_ID_ITEMS_GET,
    //AUCTION_ITEMS_POST,
    //AUCTION_ITEMS_PUT,
    //AUCTION_ITEMS_DELETE,
    //Awards
    SET_AWARDS,
    SET_AWARD_ID,
    SET_POPUP_AWARD_REJECT,
    SET_POPUP_AWARD_ERROR,
    SET_POPUP_AWARD_APPROWE,
    //Auction Bid's
    //AUCTION_BID_GET,
    //AUCTION_ID_BID_GET,
    //AUCTION_BID_POST,
    //AUCTION_BID_PUT,
    //AUCTION_BID_DELETE,
    SET_AUCTION_ID_BID,
    SET_CREATE_BID_ERROR,
    SET_GET_BANK_ACCOUNT,
    //Classifications
    //CLASSIFICATION_GET,
    CLASSIFICATIONS_GET,
    //CLASSIFICATION_POST,
    //CLASSIFICATION_PUT,
    //CLASSIFICATION_DELETE,
    CLASSIFICATIONS_BY_SCHEME_GET,
    //Contacts
    //CONTACT_GET,
    //CONTACTS_GET,
    //CONTACT_POST,
    //CONTACT_PUT,
    //CONTACT_DELETE,
    //Contracts
    //CONTRACT_GET,
    CONTRACT_AUCTION_ID_GET,
    SET_POPUP_CONTRACT_APPROWE,
    SET_POPUP_CONTRACT_ERROR,

    SET_CONTRACT_TITLE,
    SET_CONTRACT_DESCRIPTION,
    SET_CONTRACT_OFF,
    SET_CONTRACT_CONTRACT_NUMBER,
    SET_CONTRACT_DATE_FROM,
    SET_CONTRACT_DATE_TILL,
    SET_CONTRACT_DATE_PAID,
    SET_CONTRACT_START_DATE,
    SET_CONTRACT_END_DATE,
    SET_CONTRACT_CURRENCY,
    SET_CONTRACT_AMOUNT,
    SET_CONTRACT_TOTAL_AMOUNT,
    SET_CONTRACT_DATE_SIGNED,
    //CONTRACT_POST,
    //CONTRACT_PUT,
    //CONTRACT_DELETE,
    //Documents
    //DOCUMENT_GET,
    //DOCUMENTS_GET,
    //DOCUMENT_POST,
    //DOCUMENT_PUT,
    //DOCUMENT_DELETE,
    CHANGE_DOCUMENT_ID,
    CHANGE_DOCUMENT_TITLE,
    CHANGE_DOCUMENT_INDEX,
    CHANGE_DOCUMENT_DESCRIPTION,
    CHANGE_DOCUMENT_LANG,
    CHANGE_DOCUMENT_TYPE,
    OLD_ARR_DOCUMENT_GET,
    //Identifier
    //IDENTIFIER_GET,
    //IDENTIFIERS_GET,
    //IDENTIFIER_POST,
    //IDENTIFIER_PUT,
    //IDENTIFIER_DELETE,
    //default
    //DOC_GET,
    //AUTHORIZE_GET,
    TOKEN_POST,
    TOKEN_CLEAR,
    //Old site users
    //OLD_USER_GET,
    //OLD_USERS_GET,
    //OLD_USER_POST,
    //OLD_USER_PUT,
    //OLD_USER_DELETE,
    USER_PROFILES_GET,
    //Organizations
    //ORGANIZATION_GET,
    //ORGANIZATIONS_GET,
    //ORGANIZATION_POST,
    //ORGANIZATION_PUT,
    //ORGANIZATION_DELETE,
    //Procuring Entity
    //PROCURING_ENTITY_GET,
    //PROCURING_ENTITIES_GET,
    //PROCURING_ENTITY_POST,
    //PROCURING_ENTITY_PUT,
    //PROCURING_ENTITY_DELETE,
    //Questions
    //QUESTION_GET,
    QUESTIONS_AUCTION_ID_GET,
    ANSWER_TITLE,
    ANSWER_DISCRIPTION,
    //QUESTION_POST,
    //QUESTION_PUT,
    //QUESTION_DELETE,
    //Item Units
    //UNIT_GET,
    UNITS_GET,
    //UNIT_POST,
    //UNIT_PUT,
    //UNIT_DELETE,
    UNITS_GET_BY_TYPE,
    UNITS_GET_BY_TYPE_ENERGY,
    SET_LOGO,

    SET_MY_BIDS,
    SET_CURENT_BID,
    SET_CURENT_BID_DOC_CVALIF,
    SET_PROFILE_NULL,

    SET_POP_UP_ANSWER_AUCTION,
    SET_POP_UP_EDIT_AUCTION,
    SET_POP_UP_BANK_ACCOUNT,
    SET_POP_UP_BANK_ACCOUNT_COMPLITE,
    SET_POP_UP_BANK_ACCOUNT_ERROR,
    SET_POP_UP_BANK_ACCOUNT_SHEME,
    SET_POP_UP_BANK_ACCOUNT_OPUS,
    SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK,
    SET_POP_UP_BANK_ACCOUNT_NAZVA,
    SET_POP_UP_BANK_ACCOUNT_IDENTIF,
    SET_POP_UP_BANK_ACCOUNT_SHEME_ID,
    SET_POP_UP_BANK_ACCOUNT_ID,
    SET_POP_UP_BANK_ACCOUNT_ID_ARR,

    REG_EXP_TIMBER_DIAMETR,
    BID_POPUP_EDIT,

    CHANGE_DISCOUNT_ID,
    CHANGE_DISCOUNT_STATUS,
    CHANGE_DISCOUNT_PERCENT,
    CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY,
    CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT,
    CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID,

    CHANGE_POP_UP_AUCTION_CREATE,
    POPUP_AUCTION_MONGA,

    CHANGE_GEOLOGIV_DATA_VALUE,
    CHANGE_DOCUMENTATION_VALUE,
    CHANGE_DATE_USES,
    SET_CLASSIFICATIONS_BY_CARRY_TYPE,

    SET_CARRY_TYPE,
    SET_WAGON_SPECIES,
    SET_LOADING_RESTRICTION,
    SET_WAGON_TYPES,
    SET_DATE_FROM_1,
    SET_DATE_TILL_1,
    SET_DATE_FROM_2,
    SET_DATE_TILL_2,
    SET_ROUTE_QUANTITY,
    SET_ROUTE_ACCOUNTING_ROUTE_NUMBER,
    SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD,
    SET_RAILWAY_CLASSIFICATOR_ADD,

    SET_CREATE_RESTRICTION_FLAG,
	SET_CREATE_RESTRICTION_LOAD_OBJECT,
	SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE,
	SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME,
	SET_CREATE_RESTRICTION_UNLOAD_OBJECT,
	SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE,
    SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME,
    
    SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE,
	SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE,
    SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT,
    SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE,

    SET_CREATE_ITEM_RESTRICT,
    CHANGE_TOGETHER_API,

    SET_TIMBER_SORTMENT,
    SET_TIMBER_SPECIES,
    SET_TIMBER_CLASS,
    SET_TIMBER_DIAMETER,
    SET_TIMBER_STORAGE,
    SET_TIMBER_PRODUCTION_YEAR,
    SET_TIMBER_PRODUCTION_QUARTER,
    SET_POPUP_BID_TOGETHER_API,
    SET_TIMBER_LENGTH1,
    SET_TIMBER_LENGTH2,

    AUCTIONS_SEARCH_ELEM_ARR,
    KOATUU_REGION,
    KOATUU_GOROD,
    BID_POPUP_HELP_PUBLISH,
    
    BID_POPUP_EDIT_DOC,
    BID_POPUP_EDIT_DOC_TITLE,
    BID_POPUP_EDIT_DOC_DESCRIPTION,
    BID_POPUP_EDIT_DOC_TYPE,

    POPUP_AUCTION_DELETE_TITLE,
    POPUP_DATE_DELETE_AUCTION,

    POPUP_CONTRACT_DOC_TITLE,
    POPUP_CONTRACT_DOC_TYPE,
    POPUP_CONTRACT_DOC_DESCRIPTION,
    ECP_API_PASS,
    TOGETHER_API_AUCTION_NULL,
    TOGETHER_API_LIST,
    TOGETHER_API_LIST_CA,

    PROXY_TIMBER_SPECIES,
    PROXY_TIMBER_SORTMENT,
    PROXY_TIMBER_STORAGE,
    PROXY_TIMBER_DIAMETER,
    PROXY_TIMBER_CLASS,
    AWARD_DOCUMENT_STATUS,

    SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP,
    SET_DELETE_BANK_GROOPS,
    SET_DELETE_CURENT_BANK,
} from './types';

const initialState = {
    deleteBankGroops: [
        {type: 'registrationFee', status: false},
        {type: 'guarantee', status: false},
        {type: 'securityDeposit', status: false},
        {type: 'improvementCompensation', status: false},
        {type: 'other', status: false},
        {type: 'advancePayment', status: false},
        {type: 'lease', status: false},
        {type: 'payment', status: false},
        {type: 'preparationPayment', status: false}
    ],
    deleteCurentBank: [],
    classificationCVZU: null,
    classificationCVZUArr: [],
    classificationCVZUValue: 'null',
    classificationsBseBsd: null,
    auctionFull: null,
    auctionFullId: null,
    togetherApiHelpPopUp: null,
    bridge: {
        openPopUp: false,
        openButtonPopUp: false,
    },
    shedullePeriod: {
        id: null,
        onOff: false,
        newPeriodArr: [],
        newFullPeriod: [],
    },

    createAuctionZU: {
        flag: false,
        data: null,
        objectId: '',
    },
    objectReestr: null,
    cloneObjectId: null,
    popupOrganizations: false,
    checkBox: {
        wait: false,
        agreement: false,
        waitZgoda: false,
        waitZgodaReglament: false,
        waitPersonalData: false,
    },
    auctionsZUAttemp: null,
    bankAccountZU: [],
    createBankAccountZUtimestamp: null,
    createBankAccountZU: {
        groupe: 'null',
        id: 'null',
        holderType: 'null',
        percent: '',
        group: 'null',
        type: 'null',
        groupType: 'default'
    },
    myPage: 0,
    myCabinetPage: 0,
    myCabinetPageCounter: 10,
    myStatus: 'all',

    setMask: '',
    dictionaries: {
        ownershipType: null,
        encumbrances: null,
        powerSupplyClass: null,
        floorType: null,
        constructionTechnology: null,
        fuelType: null,
        transmissionType: null,
        eligibilityCriteria: null,
        jointPropertyComplexItemJpcPropsLandPropsOwnershipType: null,
        jointPropertyComplexItemJpcPropsLandPropsEncumbrances: null,
    },

    setOpenBankWindow: false,
    setOpenAddressWindow: false,
    auctionAccordeons: {
        bidsArr: [],
        awardArr: [],
        contractArr: [],
        chancelArr: [],
    },
    historyDocsContracts: null,
    bankAccountWork: {
        saveBank: [],
        addNewBank: [],
        deleteBank: [],
    },
    awardDocument: {
        statusDoc: 0,
    },

    workMyAuction: {
        pageCurrentAuction: 0,
        pageNextAuction: 0,
    },

    togetherApiPage: {
        pageCurrentAuction: 0,
        pageNextAuction: 0,
        pageSwitch: 0,
        bidsDraftArr: null,
        bidsDraftSumm: '',
        bidsDraftWorkArr: null,
        popUpOnOff: 0,
        ecpOnOff: false,
        docData: {
            title: '',
            description: '',
            type: 'null',
            lang: 'null',
            fileName: '',
            fileNameECP: '',
        },
        list: null,
        listChoise: 'null',
        proxy: {
            timberSpecies: null,
            timberSortment: null,
            timberStorage: null,
            timberDiameter: null,
            timberClass: null
        },
        basePullAucEI: null,
        pullAucEI: null,
        //saveDataSoket: null,
        saveDataSoket: [],
        saveValue: null,
        timer: {
            round: 0,
            time: null,
            timeLeft: null,
        },
        arrayAuctionsEndPay: null
    },
    ecpData: {
        pass: '',
    },

    histery: {
        auctions: [],
        awards: [],
        contracts: [],
        bids: [],
    },

    deleteDateCancelAuction: null,
    universalError: null,
    
    ecp: false,
    ecpLink: null,
    schedule: {
        flag: false,
        hoursBy: {
            value: '',
            by: 'null'
        },
        daysBy: {
            value: '',
            by: 'null'
        },
        period: {
            startDate: '',
            endDate: '',
            weekday: 'null',
            startTime: '',
            endTime: ''
        },
        other: null,
        lengthYears: '',
        lengthMonths: '',
        lengthDays: '',
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: '',
        plannedEvents: ''
    },
    addressNew: {
        id: null,
        getAddress: null,
        openPopUp: false,
        name: '',
        identifier: {
            id: null,
            identifier: '',
            scheme: 'UA-EDR',
            legalName: ''
        },
        additionalIdentifiers: {
            id: null,
            identifier: '',
            scheme: 'UA-EDR',
            legalName: ''
        },
        address: {
            id: null,
            addressIdentifier: {
                id: null,
                scheme: 'UA-EDR',
                identifier: '',
                name: ''
            },
            streetAddress: '',
            locality: '',
            region: 'null',
            postalCode: '',
            countryName: 'Україна'
        },
        contactPoint: {
            id: null,
            name: '',
            email: '',
            telephone: '',
            faxNumber: '',
            url: ''
        },
        representativeInfo: '',
        currentContractTime: {
            startDate: '',
			endDate: ''
        }
    },
    address: {
        id: null,
        streetAddress: '',
        locality: '',
        //locality: '',
        region: 'null',
        postalCode: '',
        countryName: 'Україна',
        popUpAddress: false,
        popUpAddressError: null,
        popUpAddressComplite: null,
    },
    koatuu: {
        region: null,
        gorod: null,
    },
    allUserPopUp: null,
    allUserPopUpUser: null,
    addressGet: null,
    addressGetId: null,
    auctionAwardId: null,
    auctionAwards: null,
    auctionBidGet: null,
    auctionIdBidGet: null,
    auctionIdItemsGet: null,
    auctionItemsGet: null,
    auctionsGet: null,
    auctionsGetFlag: false,
    auctionsGetId: null,
    auctionsGetIdNotFound: false,
    auctionsGetMy: null,
    auctionsGetOld: null,
    auctionsItemsSwitchWindow: 0,
    auctionsMy: null,
    auctionsMyNew: {
        
        restrictions: '',
        conditions: '',
        normativeMonetaryValuation: {
            amount: ''
        },
        expertMonetaryValuation: {
            amount: ''
        },
        budgetSpent: {
            amount: ''
        },


        currentTenantGuaranteeFee: {
            amount: ''
        },
        accessDetails: '',
        auctionId: null,
        auctionPeriods: [],
        awards: [],
        bankAccounts: [],
        bankAccountsGroups: null,
        bids: [],
        calcOnBookValue: true,
        valueAddedTaxCharged: 'false',
        registryId: '',
        valuePeriod: 'null',
        martialLawDisclaimer: true,
        /*relatedOrganizations: {
            propertyOwnerId: 0,
            sellingEntityId: 0,
            currentTenantId: 0,
            ownershipType: ''
        },*/
        leaseType: 'landRental',
        saleType: 'null',
        leaseRules: {
            intendedUse: [],
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        },
        cancellations: [],
        contracts: [],
        createdAt: '',
        createdByCurrentUser: true,
        description: '',
        documents: [],
        dutchStep: {
            dutchStepQuantity: null,
            dutchStepPercent: 1,
            dutchStepValue: {
                currency: 'null',
                amount: null,
                valueAddedTaxIncluded: null,
            },
        },
        endDate: null,
        guarantee: {
            currency: 'null',
            amount: null,
            valueAddedTaxIncluded:  'null'
        },
        registrationFee: {
            currency: 'UAH',
            amount: null,
            valueAddedTaxIncluded: 'null',
        },
        relatedOrganizations: {
            propertyOwner: {
                id: 'null'
            },
            sellingEntity: {
                id: 'null'
            },
            currentTenant: {
                id: 'null'
            },
            ownershipType: 'null'
        },
        id: '',
        items: [],
        valueAddedTaxChargedBasic: 'false',
        isPerishable: 'false',
        minimalStep: {
            currency: 'null',
            amount: null,
            valueAddedTaxIncluded: 'null'
        },
        discount: {
            currency: 'null',
            amount: '',
            valueAddedTaxIncluded: 'null',
            procent: ''
        },
        procedureId: null,
        procurementEntity: {},
        sellingMethod: 'null',
        sellingMethodClear: 'null',
        questions: [],
        startDate: '',
        status: null,
        tenderAttempts: '',
        title: '',
        value: {
            currency: 'null',
            amount: null,
            valueAddedTaxIncluded:  true
        },
        xAdditionalInformation: '',
        xDocumentRequirements: '',
        geologicDataValue: {
            amount: null,
        },
        documentationValue: {
            amount: null,
        },
        leaseDuration: null,
        minNumberOfQualifiedBids: 'null',

        //minNumberOfQualifiedBids: '2',
        
        virtualDataRoom: '',
        eligibilityCriteria: 'null',
        decision: {
            decisionId: '',
            decisionDate: '',
        },
        xDgfPlatformLegalDetails: '',
        currentTenant: {
            id: null,
            name: '',
            identifier: {
                scheme: 'null',
                legalName: '',
                id: ''
            },
            address: {
                addressID: {
                    scheme: 'koatuu',
                    name: '',
                    identifier: '',
                },
                streetAddress: '',
                locality: '',
                region: '',
                postalCode: '',
                countryName: 'Україна',
            },
            representativeInfo: '',
            currentContractTime: {
                dateFrom: '',
                dateTill: '',
            },
            currentTenantValue: {
                currency: 'UAH',
                amount: '',
            },
        },
    },
    //aucyionsPath: '/public/search',
    aucyionsPath: '/search',
    auctionsSwitchWindow: 0,
    authorizeGet: null,
    awardReject: {
        title: null,
        description: null,
        type: null,
    },
    awardsGet: null,
    awardsIdGet: null,
    auctionsAllBids: {
        arr: null,
        auctionId: null,
    },
    bankAccount: null,
    bankAccountId: 'null',
    bankAccountEditId: 'null',
    bankAccountIdArr: null,
    bankAccountIdentif: null,
    bankAccountNazva: null,
    bankAccountСurrency: null,
    bankAccountNazvaBank: null,
    bankAccountOpus: null,
    bankAccountShemeId: null,


    bidAward: '',
    bidCvalification: null,
    bidPopUpEdit: null,
    bidQuantity: '',
    bidPopUpHelpPublish: null,
    carryType: null,
    classificationGet: null,
    classificationsEnergy: {
        classificationsByTypeMain: null,
        generationType: null,
        unitCode: null,
    },
    classificationsGet: null,
    classificationsNadraEnglish: {
        classificationsByTypeMain: null,
        classificationsByShemaSubsoilUsageType: null,
        unitCode: null,
    },
    classificationsRailway: {
        classificationsByTypeMain: null,
        railwayCargoAdditional: null,
        wagonSpec: null,
        unitCode: null,
        railwayCargoExclusionObjectType: null,
    },
    classificationsTimber: {
        classificationsByTypeMain: null,
        timberSortment: null,
        timberSpecies: null,
        timberClass: null,
        timberDiameter: null,
        timberLength1: null,
        timberLength2: null,
        timberStorage: null,
        timberProductionYear: null,
        timberProductionQuarter: null,
        unitCode: null,
    },

    classificationsDgf: {
        classificationsByTypeMain: null,
        classificationsAdd: null,
    },
    classificationsZU: {
        unitCode: null,
    },

    contactGet: null,
    contactsGet: null,
    contractAuctionIdGet: null,
    contractGet: null,
    createAuction: {
        restrictions: '',
        conditions: '',
        normativeMonetaryValuation: {
            amount: ''
        },
        expertMonetaryValuation: {
            amount: ''
        },
        budgetSpent: {
            amount: ''
        },


        id: null,
        lotIdentifier: null,
        documentIds: [{}],
        title: '',
        description: '',
        sellingMethod: 'null',
        accessDetails: '',
        xDocumentRequirements: '',
        xAdditionalInformation: '',
        martialLawDisclaimer: true,
        value: {
            currency: 'UAH',
            amount: '',
            valueAddedTaxIncluded: true
        },
        valueAddedTaxChargedBasic: 'false',
        isPerishable: 'false',
        guarantee: {
            currency: 'UAH',
            amount: '',
            valueAddedTaxIncluded: true
        },
        minimalStep: {
            currency: 'UAH',
            amount: '',
            valueAddedTaxIncluded: true
        },
        discount: {
            currency: 'UAH',
            amount: '',
            valueAddedTaxIncluded: true,
            procent: ''
        },
        tenderAttempts: '',
        startDate: '',
        currentTenant: {
            id: null,
            name: '',
            identifier: {
                scheme: 'null',
                legalName: '',
                id: ''
            },
            address: {
                addressID: {
                    scheme: 'koatuu',
                    name: '',
                    identifier: '',
                },
                streetAddress: '',
                locality: '',
                region: '',
                postalCode: '',
                countryName: 'Україна',
            },
            representativeInfo: '',
            currentContractTime: {
                dateFrom: '',
                dateTill: '',
            },
            currentTenantValue: {
                currency: 'UAH',
                amount: '',
            },
        },
    },
    createAuctionTab: 0,
    createDocuments: {
        id: null,
        title: '',                          //Заголовок
        index: '',
        description: '',                    //Описание
        documentOf: '',
        documentType: 'null',                   //Тип документа
        language: 'null',                     //Язык
        hash: null,                         //Хэш
        token: null,                        //Пишем jwt от файла
        fileName: null
    },
    createItems: {
        id: null,                           //Айди итема если редактирование
        auctionId: null,                    //Айди аукциона
        classificationId: 'null',              //Тип аукциона
        railwayLotType: 'null',
        additionalClassificationIds: {
            timberSortment: 'null',
            timberSpecies: 'null',
            timberClass: 'null',
            timberDiameter: 'null',
            timberLength1: 'null',
            timberLength2: 'null',
            timberStorage: 'null',
            timberProductionYear: 'null',
            timberProductionQuarter: 'null',
            generationType: 'null',
            railwayCargoAdditional: 'null',
            subsoilUsageTypeAdditional: 'null',
        },                                   //Доп классификатор, порода дерева
        locationId: 0,                      //New необязательное поле
        addressId: null,                      //Адрес
        addressesGetMy: 'null',
        description: '',                    //Описание
        unitCode: 'null',                    //Мера измерения
        unitValue: {
            currency: 'null',                 //Валюта
            amount: '',                     //Цена
            valueAddedTaxIncluded: true     //налог да/нет
        },
        quantity: '',                        //Колличество
        wagonSpec: null,
        loadingRestriction: '',
        wagonTypes: '',
        requestLifeTime: {
            dateFrom: '',
            dateTill: ''
        },
        loadLifeTime: {
            dateFrom: '',
            dateTill: ''
        },
        routeQuantity: '',
        routesAccountingRouteNumber: '',
        routesAccountingWagonsPerRouteQuantity: '',
        auctionRestriction: null,

        location: {
            latitude: '50.44395',
            longitude: '30.51589',
            elevation: '',
        },
        dgf: {
            realEstateProps: {
                id: '',
                totalBuildingArea: '',
                totalObjectArea: '',
                usableArea: '',
                constructionYear: '',
                constructionTechnology: 'null',
                livingArea: '',
                kitchenArea: '',
                landArea: '',
                locationInBuilding: 'null',
                floors: '',
                generalCondition: '',
                serviceElectricity: 'null',
                powerSupplyCapacity: '',
                powerSupplyClass: 'null',
                serviceWater: 'null',
                serviceSewerage: 'null',
                serviceGas: 'null',
                serviceCentralHeating: 'null',
                serviceAutonomousHeating: 'null',
                serviceHeatingCounter: 'null',
                serviceVentilation: 'null',
                serviceAirConditioning: 'null',
                servicePhone: 'null',
                serviceTV: 'null',
                serviceInternet: 'null',
                serviceElevator: 'null',
                serviceSecurityAlarm: 'null',
                serviceFireAlarm: 'null',
                servicesDescription: '',
                servicesAccounting: '',
                landTax: '',
            },
            vehicleProps: {
                id: '',
                brand: '',
                model: '',
                productionYear: '',
                engineCapacity: '',
                fuelType: 'null',
                transmission: 'null',
                color: '',
                kilometrage: '',
                identificationNumber: '',
                configuration: '',
                condition: '',
                damagePresence: 'null',
                damagedDescription: '',
            },
            jointPropertyComplexProps: {
                id: '',
                quantityAndNomenclature: '',
                workplacesQuantity: '',
                buildingsInformation: '',
                landPlotInformation: '',
                mainObligations: '',
            },
            landProps: {
                id: '',
                landArea: '',
                cadastralNumber: '',
                ownershipType: 'null',
                encumbrances: 'null',
                jointOwnership: '',
                utilitiesAvailability: '',
                intendedUse: '',
                hasEncumbrances: false,
                hasJointOwnership: false,
                hasUtilitiesAvailability: false,
            },
        },
        zu: {
            chousenType: 'null',
            baseClasif: 'null',
            otherPropertyItem: {
                id: null,
                title: '',
                description: '',
                classification: 'null',
                additionalClassifications: {
                    id: 'null',
                },
                address: 'null',
                location: {
                    latitude: '',
                    longitude: '',
                    elevation: '',
                },
                registrationDetails: {
                    registrationStatus: 'null',
                    registrationID: '',
                    registrationDate: '',
                },
                itemType: 'otherProperty',
                quantity: '',
                unitCode: 'null'
            },
            jointPropertyComplexItem:{
                id: null,
                title: '',
                description: '',
                classification: 'null',
                additionalClassifications: {
                    id: 'null',
                },
                address: 'null',
                location: {
                    latitude: '',
                    longitude: '',
                    elevation: '',
                },
                registrationDetails: {
                    registrationStatus: 'null',
                    registrationID: '',
                    registrationDate: '',
                },
                itemType: 'jointPropertyComplex',
                jpcProps: {
                    quantityAndNomenclature: '',
                    workplacesQuantity: '',
                    buildingsInformation: '',
                    landPlotInformation: '',
                    landProps: {
                        landArea: '',
                        cadastralNumber: '',
                        ownershipType: 'null',
                        encumbrances: 'null',
                        jointOwnership: '',
                        utilitiesAvailability: '',
                        landIntendedUse: ''

                    },
                    mainObligations: '',
                },
                quantity: '',
                unitCode: 'null'
            },
            vehicleItem: {
                id: null,
                title: '',
                description: '',
                classification: 'null',
                additionalClassifications: {
                    id: 'null',
                },
                address: 'null',
                location: {
                    latitude: '',
                    longitude: '',
                    elevation: '',
                },
                registrationDetails: {
                    registrationStatus: 'null',
                    registrationID: '',
                    registrationDate: '',
                },
                itemType: 'vehicle',
                vehicleProps: {
                    brand: '',
                    model: '',
                    productionYear: '',
                    engineCapacity: '',
                    fuelType: 'null',
                    transmission: 'null',
                    color: '',
                    kilometrage: '',
                    identificationNumber: '',
                    configuration: '',
                    condition: '',
                    damagedDescription: '',
                    damagePresence: false
                },
                quantity: '',
                unitCode: 'null'
            },
            realEstateItem: {
                id: null,
                title: '',
                description: '',
                classification: 'null',
                additionalClassifications: {
                    id: 'null',
                },
                address: 'null',
                location: {
                    latitude: '',
                    longitude: '',
                    elevation: '',
                },
                registrationDetails: {
                    registrationStatus: 'null',
                    registrationID: '',
                    registrationDate: '',
                },
                itemType: 'realEstate',
                reProps: {
                    totalBuildingArea: '',
                    totalObjectArea: '',
                    usableArea: '',
                    constructionYear: '',
                    constructionTechnology: 'null',
                    livingArea: '',
                    kitchenArea: '',
                    landArea: '',
                    locationInBuilding: 'null',
                    floors: '',
                    generalCondition: '',
                    serviceElectricity: 'null',
                    powerSupplyCapacity: '',
                    serviceWater: 'null',
                    serviceSewerage: 'null',
                    serviceGas: 'null',
                    serviceCentralHeating: 'null',
                    serviceAutonomousHeating: 'null',
                    serviceHeatingCounter: 'null',
                    serviceVentilation: 'null',
                    serviceAirConditioning: 'null',
                    servicePhone: 'null',
                    serviceTV: 'null',
                    serviceInternet: 'null',
                    serviceElevator: 'null',
                    serviceSecurityAlarm: 'null',
                    serviceFireAlarm: 'null',
                    servicesDescription: '',
                    servicesAccounting: '',
                    landTax: '',
                },
                quantity: '',
                unitCode: 'MTK'
            },
        },
        landRental: {
            additionalClassificationsArr: null,
            id: null,
            description: '',
            classification: 'null',
            additionalClassifications: {
                id: 'null',
            },
            address: 'null',
            location: {
                latitude: '50.44395',
                longitude: '30.51589',
                elevation: '',
            },
            addressItem:{
                id: null,
                postalCode: '',
                region: '',
                streetAddress: '',
                countryName: '',
                locality: '',
            },
            quantity: '',
            unitCode: 'HAR',
            itemProps: {
                landArea: '',
                cadastralNumber: '',
                ownershipType: 'null',
                encumbrances: '',
                jointOwnership:	'',
                utilitiesAvailability: '',
                landIntendedUse: '',
                hasEncumbrances: false,
                hasJointOwnership: false,
                hasUtilitiesAvailability: false,
            }
        }
    },
    createRestrictionFlag: null,
    createRestriction: {
        setArrRestrict: null,
        loadObject: "null",
        loadObjectCode: "",
        loadObjectName: "",
        unloadObject: "null",
        unloadObjectCode: "",
        unloadObjectName: ""
    },
    createRestrictionExclusionsFlag: null,
    createRestrictionExclusions: {
        exclusionObject: "null",
        exclusionObjectType: "",
        exclusionObjectCode: "",
        exclusionObjectName: ""
    },
    curentBidLot: null,
    curentBidDocuments: null,
    deleteAuctionDescription: '',
    deleteDateCancelAuctionReason: 'null',
    discount: {
        id: null,
        discount: '',
        discountPercent: '0',
        previousAuctionValue: {
            currency: 'null',
            amount: '',
        },
        previousAuctionId: '',
        discountOnOff: false,
    },
    docGet: null,
    documentCount: {
        arrFiles: [],
        countArrFiles: 0,
        documentBidStatus: false,
        documentPublicName: '',
        documenttype: '',
    },
    documentGet: null,
    documentsGet: null,
    paymentPopup: null,
    paymentPopup1: null,
    paymentPopupCreatePDF: false,
    paymentPopupSetBanks: 'null',
    paymentPopupGetData: null,
    checkBoxBidPublish: false,
    popUpEditAppruveDoc: null,
    find: false,
    getMyCurentBid: null,
    identifierGet: null,
    identifiersGet: null,
    itemAward: null,
    keySetIntervalRefresh: null,
    langFlag: 0,
    loader: false,
    logo: null,
    lots: null,
    maxCostTender: 0,
    menuOnOff: false,
    minCostTender: 0,
    mobileMenu: false,
    myBids: null,
    oldArrDocument: null,
    oldUserGet: null,
    oldUsersGet: null,
    organizationGet: null,
    organizationsGet: null,
    pageNumber: null,
    popUpAuctionCreate: null,
    popUpAuctionCreateMonga: null,
    popUpAuctionError: null,
    popUpAwardApprowe: null,
    popUpAwardError: null,
    popUpAwardReject: null,
    popUpBankAccount: false,
    popUpBankAccountComplite: false,
    popUpBankAccounErrort: null,
    popUpBankAccountSheme: null,
    popUpBidError: null,
    popUpContractApprowe: null,
    popUpContractError: null,
    popUpDelAuc: null,
    popUpSendAuc: null,
    popUpStatus: {
        login: false,
    },
    procuringEntitiesGet: null,
    procuringEntityGet: null,
    questionCreate: {
        authorId: {
            additionalProp1: {}
        },
        author: {},
        title: null,
        description: null,
        datePublished: null,
        dateAnswered: null,
        dateModified: null,
        answer: null,
        popUp: null,
        popUpEdit: null,
    },
    questionGet: null,
    questionsAuctionIdGet: null,
    regExpTimberDiametr: null,
    search: {
        numberOfCurrentTenants: null,

        itemType: 'null',
        isPerishable: 'null',
        isLegacy: null,
        cadastralNumber: null,
        landAreaTo: null,
        landAreaFrom: null,

        order: "asc",
        //orderBy: "string",
        orderBy: null, //по какому полю сортировать для верхнего поиска
        authorName: null,
        auctionId: null,
        status: 'active_tendering',
        //status: null,
        locality: 'null',
        region: 'null', //Регион
        //category: "string",
        category: null,//'timberEnglish', 
        classification: 'null',
        additionalClassifications: 'null',
        priceCurrency: null,
        //priceCurrency: null,
        //priceFrom: 0,
        priceFrom: null,
        priceFromBag: false,
        //priceTo: 0,
        priceTo: null,
        priceToBag: false,
        //measures: "string",
        measures: null, //одиници вимыру
        //measuresFrom: 0,
        measuresFrom: null,
        //measuresTo: 0
        measuresTo: null,
        textSting: null,
        selectTypeKeyword: '0',
        startDateFrom: null,
        startDateTo: null,
        registryId: null,

        areaFrom: '',
        areaTo: '',
        leaseDurationFrom: {
            year: '',
            month: '',
            day: ''
        },
        leaseDurationTo: {
            year: '',
            month: '',
            day: ''
        },



        /*"order": "asc",       сортировка
        "orderBy": "string",    любой критерий по которому ищем в базовый поиск
        "keyword": "string",    по словам по которому ищем в базовый поиск
        "authorName": "string", по создателю по которому ищем в базовый поиск
        "auctionId": "string",  по айди аукциона по которому ищем в базовый поиск
        "itemId": "string",     по айди итема по которому ищем в базовый поиск
        "region": "string",     по региону --- не пашет
        "status": "active_rectification",   по статусу
        "category": "string",   по категории
        "priceCurrency": "UAH", валюта
        "priceFrom": 0,         мин цена
        "priceTo": 0,           макс цена
        "measures": "string",   ед.измерения
        "measuresFrom": 0,      ед.измерения от
        "measuresTo": 0,        ед.измерения до
        "startDateFrom": "string",  дата начала
        "startDateTo": "string",    дата окончания
        "classification": "string",     классификатор
        "additionalClassifications": [  доп классификатор
            "string"
        ]*/
    },
    searchCount: {
        count: null,
        countOld: null,
        curentPage: 0,
    },
    searchElemOn: null,
    statusTender: 1,
    timberContractPut: {
        title: '',
        description: '',
        contractNumber: '',
        documentOf: '',
        contractTime: {
            dateFrom: '',
            dateTill: '',
            startDate: '',
            endDate: ''
        },
        datePaid: '',
        contractTimeAdded: {
            dateFrom: '',
            dateTill: ''
        },
        xValueUAH: {
            currency: "UAH",
            amount: null,
            valueAddedTaxIncluded: true
        },
        contractTotalValue: {
            currency: "UAH",
            amount: null,
            //valueAddedTaxIncluded: true
        },
        value: {
            currency: "UAH",
            amount: null,
            valueAddedTaxIncluded: true
        },
        dateSigned: '',
        x_prepaymentDetails: {
            date: '',
            prepayment: {
                currency: "UAH",
                amount: null,
                valueAddedTaxIncluded: true
            }
        }
    },
    togetherAPIelem:{
        classificationsByTypeMain: null,
        timberSortment: null,
        timberSpecies: null,
        timberClass: null,
        timberDiameter: null,
        timberLength1: null,
        timberLength2: null,
        timberStorage: null,
        timberProductionYear: null,
        timberProductionQuarter: null,
        popup: null,
    },
    token: null,
    singlPassword: null,
    unitChange: '',
    unitGet: null,
    unitsGet: null,
    userProfiles: null,
    windowSize: null,
    togetherApi: false,
    

    bidEdit: null,
    popUpAuctionDeleteTitle: null,
    popUpContractUniversal: {
        id: null,
        title: null,
        type: null,
        description: null,
        documentOf: null,
    },
    typeOrg: false,
    popUpContractDel: null,
    arrPrivatLink: [],
    bidSumm: null
}

export const startReducer = (state = initialState, action) => {
    switch (action.type){
        case CLEAR_DISCOUNT:
            return { ...state, discount: action.payload}
        case SET_DELETE_BANK_GROOPS:
            return { ...state, deleteBankGroops: action.payload}
        case SET_DELETE_CURENT_BANK:
            return { ...state, deleteCurentBank: action.payload}

        case CREATE_CVZU_DATA:
            return { ...state, classificationCVZU: action.payload}
        case CREATE_CVZU_ARR:
            return { ...state, classificationCVZUArr: action.payload}
        case CREATE_CVZU_VALUE:
            return { ...state, classificationCVZUValue: action.payload}
            
        case SET_CLASSIFICATIONS_BSE_BSD:
            return { ...state, classificationsBseBsd: action.payload}
        case SET_BID_NEEDED_SUMM:
            return { ...state, bidSumm: action.payload}
        case PUSH_NEW_LINK:
            return { ...state, arrPrivatLink: action.payload}
        case SET_FULL_AUCTION:
            return { ...state, auctionFull: action.payload}
        case SET_ID_FULL_AUCTION:
            return { ...state, auctionFullId: action.payload}
        case SET_NEW_CURRENT_TENANT:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: action.payload}}
        case SET_LAND_CURRENT_TENANT_NAME:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, name: action.payload}}}
        case SET_LAND_CURRENT_TENANT_REPRESENTATIVE_INFO:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, representativeInfo: action.payload}}}
        case SET_LAND_CURRENT_TENANT_VALUE_AMOUNT:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, currentTenantValue: { ...state.auctionsMy.currentTenantValue, amount: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_VALUE_CURRENCY:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, currentTenantValue: { ...state.auctionsMy.currentTenantValue, currency: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_IDENTIFIER_ID:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, identifier: { ...state.auctionsMy.currentTenant.identifier, identifier: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_IDENTIFIER_LEGAL_NAME:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, identifier: { ...state.auctionsMy.currentTenant.identifier, legalName: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_IDENTIFIER_SCHEME:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, identifier: { ...state.auctionsMy.currentTenant.identifier, scheme: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_FROM:
            if(state.auctionsMy.currentTenant === null){
                let data = {
                    dateFrom: action.payload,
                    dateTill: null
                }
                return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, currentContractTime: data}}}

            }else{
                return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, currentContractTime: { ...state.auctionsMy.currentTenant.currentContractTime, dateFrom: action.payload}}}}
            }
        case SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_TO:
            if(state.auctionsMy.currentTenant === null){
                let data = {
                    dateFrom: null,
                    dateTill: action.payload
                }
                return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, currentContractTime: data}}}

            }else{
                return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, currentContractTime: { ...state.auctionsMy.currentTenant.currentContractTime, dateTill: action.payload}}}}
            }
        case SET_LAND_CURRENT_TENANT_ADDRESS_COUNTRY_NAME:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, address: { ...state.auctionsMy.currentTenant.address, countryName: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_ADDRESS_POSTAL_CODE:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, address: { ...state.auctionsMy.currentTenant.address, postalCode: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_ADDRESS_STREET_ADDRESS:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, address: { ...state.auctionsMy.currentTenant.address, streetAddress: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, address: { ...state.auctionsMy.currentTenant.address, locality: action.payload}}}}
        case SET_LAND_CURRENT_TENANT_ADDRESS_REGION:
            return { ...state, auctionsMy: { ...state.auctionsMy, currentTenant: { ...state.auctionsMy.currentTenant, address: { ...state.auctionsMy.currentTenant.address, region: action.payload}}}}



        case SET_CONTRACT_POPUP_DEL:
            return { ...state, popUpContractDel: action.payload}
        case SET_EDIT_BID_VALUE:
            return { ...state, bidPopUpEdit: { ...state.bidPopUpEdit, value: { ...state.bidPopUpEdit.value, amount: action.payload}}}

        case SET_TOGETHER_API_HELP_POPUP:
            return { ...state, togetherApiHelpPopUp: action.payload}
        case CLONE_AUCTIONS_STATUS_CHANGE:
            return { ...state, auctionsMy: { ...state.auctionsMy, status: action.payload}}
        case CREATE_ADDRESS_TYPE_ORG:
            return { ...state, typeOrg: action.payload}
        case SET_MY_AUCTIONS_STATUS:
            return { ...state, myStatus: action.payload}
        case SET_MY_CABINET_PAGE:
            return { ...state, myCabinetPage: action.payload}
        case SET_MY_CURRENT_PAGE:
            return { ...state, myPage: action.payload}
        case SET_CABINET_PAGE_COUNTER:
            return { ...state,  myCabinetPageCounter: action.payload}

        case SET_BRIDGE_OPEN_POPUP:
            return { ...state,  bridge: {...state.bridge, openPopUp: action.payload}}
        case SET_BRIDGE_OPEN_BUTTON:
            return { ...state,  bridge: {...state.bridge, openButtonPopUp: action.payload}}


        case SET_SHEDULE_PERIOD_ID:
            return { ...state,  shedullePeriod: {...state.shedullePeriod, id: action.payload}}
        case SET_SHEDULE_PERIOD:
            return { ...state,  shedullePeriod: {...state.shedullePeriod, onOff: action.payload}}
        case SET_NEW_PERIOD_ARR:
            return { ...state,  shedullePeriod: {...state.shedullePeriod, newPeriodArr: action.payload}}
        case SET_NEW_FULL_PERIOD_ARR:
            return { ...state,  shedullePeriod: {...state.shedullePeriod, newFullPeriod: action.payload}}
            
        case SET_CREATE_AUCTION_ZU_FLAG:
            return { ...state,  createAuctionZU: {...state.createAuctionZU, flag: action.payload}}
        case SET_CREATE_AUCTION_ZU_DATA:
            return { ...state,  createAuctionZU: {...state.createAuctionZU, data: action.payload}}
        case SET_CREATE_AUCTION_ZU_OBJECT_ID:
            return { ...state,  createAuctionZU: {...state.createAuctionZU, objectId: action.payload}}


        case SET_OBJECT_REESTR:
            return { ...state,  objectReestr: action.payload}
        case SET_ID_OBJECT_CLONE_AUCTIONS:
            return { ...state,  cloneObjectId: action.payload}
        case CREATE_AUCTIONS_ZU_ATTEMP:
            return { ...state,  auctionsZUAttemp: action.payload}

        case SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP:
            return { ...state,  createBankAccountZUtimestamp: action.payload}

        case SET_POP_UP_AUCTIONS_REASON:
            return { ...state,  deleteDateCancelAuctionReason: action.payload}
            
        case CREATE_BANK_ACCOUNT_ZU_HOLDER_TYPE:
            return { ...state,  createBankAccountZU: {...state.createBankAccountZU, holderType: action.payload}}
        case CREATE_BANK_ACCOUNT_ZU_PERCENT:
            return { ...state,  createBankAccountZU: {...state.createBankAccountZU, percent: action.payload}}
        case CREATE_BANK_ACCOUNT_ZU_ID:
            return { ...state,  createBankAccountZU: {...state.createBankAccountZU, id: action.payload}}
        case CREATE_BANK_ACCOUNT_ZU_GROUP:
            return { ...state,  createBankAccountZU: {...state.createBankAccountZU, group: action.payload}}
        case CREATE_BANK_ACCOUNT_ZU_GROUPE:
            return { ...state,  createBankAccountZU: {...state.createBankAccountZU, groupe: action.payload}}
        case CREATE_BANK_ACCOUNT_ZU_TYPE:
            return { ...state,  createBankAccountZU: {...state.createBankAccountZU, type: action.payload}}
        case CREATE_BANK_ACCOUNT_ZU_GROUP_TYPE:
            return { ...state,  createBankAccountZU: {...state.createBankAccountZU, groupType: action.payload}}



        case CREATE_ITEMS_REAL_ESTATEITEM_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, id: action.payload}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_TITLE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, title: action.payload}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, description: action.payload}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, classification: action.payload}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_ADDITIONAL_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, additionalClassifications: {...state.createItems.zu.realEstateItem.additionalClassifications, id: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_ADDRESS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, address: action.payload}}}}

        case CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LATITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, location: {...state.createItems.zu.realEstateItem.location, latitude: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_LOCATION_LONGITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, location: {...state.createItems.zu.realEstateItem.location, longitude: action.payload}}}}}

        case CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, registrationDetails: {...state.createItems.zu.realEstateItem.registrationDetails, registrationStatus: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, registrationDetails: {...state.createItems.zu.realEstateItem.registrationDetails, registrationID: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_REGISTRATION_DETAILS_REGISTRATION_DATE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, registrationDetails: {...state.createItems.zu.realEstateItem.registrationDetails, registrationDate: action.payload}}}}}

        case CREATE_ITEMS_REAL_ESTATEITEM_QUANTITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, quantity: action.payload}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_UNIT_CODE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, unitCode: action.payload}}}}


        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_BUILDING_AREA:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, totalBuildingArea: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_TOTAL_OBJECT_AREA:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, totalObjectArea: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_USABLE_AREA:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, usableArea: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_YEAR:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, constructionYear: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_CONSTRUCTION_TECHNOLOGY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, constructionTechnology: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LIVING_AREA:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, livingArea: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_KITCHEN_AREA:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, kitchenArea: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_AREA:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, landArea: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LOCATION_IN_BUILDING:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, locationInBuilding: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_FLOORS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, floors: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_GENERAL_CONDITION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, generalCondition: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELECTRICITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceElectricity: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CAPACITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, powerSupplyCapacity: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SUPPLY_CLASS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, powerSupplyClass: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_WATER:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceWater: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SEWERAGE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceSewerage: action.payload}}}}}    
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_GAS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceGas: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_CENTRAL_HEATING:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceCentralHeating: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AUTONOMOUS_HEATING:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceAutonomousHeating: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_HEATING_COUNTER:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceHeatingCounter: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_VENTILATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceVentilation: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_AIR_CONDITIONING:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceAirConditioning: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_PHONE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, servicePhone: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_TV:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceTV: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_INTERNET:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceInternet: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_ELEVATOR:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceElevator: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_SECURITY_ALARM:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceSecurityAlarm: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_SERVICE_FIRE_ALARM:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, serviceFireAlarm: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, servicesDescription: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_POWER_SERVICES_ACCOUNTING:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, servicesAccounting: action.payload}}}}}
        case CREATE_ITEMS_REAL_ESTATEITEM_RE_PROPS_LAND_TAX:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, realEstateItem: {...state.createItems.zu.realEstateItem, reProps: {...state.createItems.zu.realEstateItem.reProps, landTax: action.payload}}}}}


        case CREATE_ITEMS_LAND_RENTAL_ID:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, id: action.payload}}}
        case CREATE_ITEMS_LAND_RENTAL_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, description: action.payload}}}
        case CREATE_ITEMS_LAND_RENTAL_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, classification: action.payload}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, additionalClassifications: {...state.createItems.landRental.additionalClassifications, id: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, additionalClassificationsArr: action.payload}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDRESS:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, address: action.payload}}}
        
        case CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, addressItem: {...state.createItems.landRental.addressItem, id: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, addressItem: {...state.createItems.landRental.addressItem, countryName: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, addressItem: {...state.createItems.landRental.addressItem, region: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, addressItem: {...state.createItems.landRental.addressItem, locality: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, addressItem: {...state.createItems.landRental.addressItem, streetAddress: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, addressItem: {...state.createItems.landRental.addressItem, postalCode: action.payload}}}}



        
        case CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, location: {...state.createItems.landRental.location, latitude: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, location: {...state.createItems.landRental.location, longitude: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_QUANTITY:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, quantity: action.payload}}}
        case CREATE_ITEMS_LAND_RENTAL_UNIT_CODE:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, unitCode: action.payload}}}

        case CREATE_ITEMS_LAND_RENTAL_LOCATION_LAND_AREA:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, itemProps: {...state.createItems.landRental.itemProps, landArea: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_LOCATION_CADASTRAL_NUMBER:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, itemProps: {...state.createItems.landRental.itemProps, cadastralNumber: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_LOCATION_OWNERSHIP_TYPE:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, itemProps: {...state.createItems.landRental.itemProps, ownershipType: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_LOCATION_ENCUMBRANCES:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, itemProps: {...state.createItems.landRental.itemProps, encumbrances: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_LOCATION_JOINT_OWNERSHIP:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, itemProps: {...state.createItems.landRental.itemProps, jointOwnership: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_LOCATION_UTILITIES_AVAILABILITY:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, itemProps: {...state.createItems.landRental.itemProps, utilitiesAvailability: action.payload}}}}
        case CREATE_ITEMS_LAND_RENTAL_LAND_INTENDED_USE:
            return { ...state,  createItems: {...state.createItems, landRental: {...state.createItems.landRental, itemProps: {...state.createItems.landRental.itemProps, landIntendedUse: action.payload}}}}





        //////////////////////////////////////////
        case CREATE_ITEMS_VEHICLE_ITEM_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, id: action.payload}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_TITLE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, title: action.payload}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, description: action.payload}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, classification: action.payload}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_ADDITIONAL_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, additionalClassifications: {...state.createItems.zu.vehicleItem.additionalClassifications, id: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_ADDRESS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, address: action.payload}}}}

        case CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LATITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, location: {...state.createItems.zu.vehicleItem.location, latitude: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_LOCATION_LONGITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, location: {...state.createItems.zu.vehicleItem.location, longitude: action.payload}}}}}

        case CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, registrationDetails: {...state.createItems.zu.vehicleItem.registrationDetails, registrationStatus: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, registrationDetails: {...state.createItems.zu.vehicleItem.registrationDetails, registrationID: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, registrationDetails: {...state.createItems.zu.vehicleItem.registrationDetails, registrationDate: action.payload}}}}}

        case CREATE_ITEMS_VEHICLE_ITEM_QUANTITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, quantity: action.payload}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_UNIT_CODE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, unitCode: action.payload}}}}

        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_BRAND:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, brand: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_MODEL:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, model: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_PRODUCTION_YEAR:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, productionYear: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_ENGINE_CAPACITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, engineCapacity: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_FUEL_TYPE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, fuelType: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_TRANSMISSION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, transmission: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_COLOR:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, color: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_KILOMETRAGE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, kilometrage: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_IDENTIFICATION_NUMBER:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, identificationNumber: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONFIGURATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, configuration: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_CONDITION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, condition: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGED_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, damagedDescription: action.payload}}}}}
        case CREATE_ITEMS_VEHICLE_ITEM_VEHICLE_PROPS_DAMAGE_PRESENCE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, vehicleItem: {...state.createItems.zu.vehicleItem, vehicleProps: {...state.createItems.zu.vehicleItem.vehicleProps, damagePresence: action.payload}}}}}


        //////////////////////////////////////////////////////
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, id: action.payload}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_TITLE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, title: action.payload}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, description: action.payload}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, classification: action.payload}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDITIONAL_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, additionalClassifications:{...state.createItems.zu.jointPropertyComplexItem.additionalClassifications, id: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_ADDRESS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, address: action.payload}}}}

        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LATITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, location: {...state.createItems.zu.jointPropertyComplexItem.location, latitude: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_LOCATION_LONGITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, location: {...state.createItems.zu.jointPropertyComplexItem.location, longitude: action.payload}}}}}

        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_STATUS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, registrationDetails: {...state.createItems.zu.jointPropertyComplexItem.registrationDetails, registrationStatus: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, registrationDetails: {...state.createItems.zu.jointPropertyComplexItem.registrationDetails, registrationID: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_REGISTRATION_DETAILS_REGISTRATION_DATE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, registrationDetails: {...state.createItems.zu.jointPropertyComplexItem.registrationDetails, registrationDate: action.payload}}}}}

        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_QUANTITY_AND_NOMENCLATURE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, quantityAndNomenclature: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_WORKPLACES_QUANTITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, workplacesQuantity: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_BUILDINGS_INFORMATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, buildingsInformation: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PLOT_INFORMATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landPlotInformation: action.payload}}}}}        
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_AREA:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps.landProps, landArea: action.payload}}}}}}        
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_CADASTRAL_NUMBER:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps.landProps, cadastralNumber: action.payload}}}}}}        
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_OWNERSHIP_TYPE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps.landProps, ownershipType: action.payload}}}}}}        
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_ENCUMBRANCES:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps.landProps, encumbrances: action.payload}}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_JOINT_OWNERSHIP:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps.landProps, jointOwnership: action.payload}}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_UTILITIES_AVAILABILITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps.landProps, utilitiesAvailability: action.payload}}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_LAND_PROPS_LAND_INTENDED_USE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, landProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps.landProps, landIntendedUse: action.payload}}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_JPC_PROPS_MAIN_OBLIGATIONS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, jpcProps: {...state.createItems.zu.jointPropertyComplexItem.jpcProps, mainObligations: action.payload}}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_QUANTITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, quantity: action.payload}}}}
        case CREATE_ITEMS_JOINT_PROPERTY_COMPLEX_ITEM_UNIT_CODE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, jointPropertyComplexItem: {...state.createItems.zu.jointPropertyComplexItem, unitCode: action.payload}}}}        



        case CREATE_ITEMS_ZU_CHOUSEN_TYPE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, chousenType: action.payload}}}
        case CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, baseClasif: action.payload}}}

        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, id: action.payload}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_TITLE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, title: action.payload}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, description: action.payload}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, classification: action.payload}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDITIONAL_CLASSIFICATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, additionalClassifications: {...state.createItems.zu.otherPropertyItem.additionalClassifications, id: action.payload}}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ADDRESS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, address: action.payload}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_ITEM_TYPE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, itemType: action.payload}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_QUANTITY:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, quantity: action.payload}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_UNIT_CODE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, unitCode: action.payload}}}}
        
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LATITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, location: {...state.createItems.zu.otherPropertyItem.location, latitude: action.payload}}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_LONGITUDE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, location: {...state.createItems.zu.otherPropertyItem.location, longitude: action.payload}}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_LOCATION_ELEVATION:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, location: {...state.createItems.zu.otherPropertyItem.location, elevation: action.payload}}}}}

        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_STATUS:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, registrationDetails: {...state.createItems.zu.otherPropertyItem.registrationDetails, registrationStatus: action.payload}}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_ID:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, registrationDetails: {...state.createItems.zu.otherPropertyItem.registrationDetails, registrationID: action.payload}}}}}
        case CREATE_ITEMS_OTHER_PROPERTY_ITEMS_REGISTRATION_DETAILS_REGISTRATION_DATE:
            return { ...state,  createItems: {...state.createItems, zu: {...state.createItems.zu, otherPropertyItem: {...state.createItems.zu.otherPropertyItem, registrationDetails: {...state.createItems.zu.otherPropertyItem.registrationDetails, registrationDate: action.payload}}}}}


        case SET_SCHEDULE_FLAG:
            return { ...state, schedule: {...state.schedule, flag: action.payload}}
        case SET_SCHEDULE_HOURS_BY_VALUE:
            return { ...state, schedule: {...state.schedule, hoursBy: {...state.schedule.hoursBy, value: action.payload}}}
        case SET_SCHEDULE_HOURS_BY_BY:
            return { ...state, schedule: {...state.schedule, hoursBy: {...state.schedule.hoursBy, by: action.payload}}}
        case SET_SCHEDULE_DAYS_BY_VALUE:
            return { ...state, schedule: {...state.schedule, daysBy: {...state.schedule.daysBy, value: action.payload}}}
        case SET_SCHEDULE_DAYS_BY_BY:
            return { ...state, schedule: {...state.schedule, daysBy: {...state.schedule.daysBy, by: action.payload}}}
        case SET_SCHEDULE_PERIOD_START_DATE:
            return { ...state, schedule: {...state.schedule, period: {...state.schedule.period, startDate: action.payload}}}
        case SET_SCHEDULE_PERIOD_END_DATE:
            return { ...state, schedule: {...state.schedule, period: {...state.schedule.period, endDate: action.payload}}}
        case SET_SCHEDULE_WEEKDAY:
            return { ...state, schedule: {...state.schedule, period: {...state.schedule.period, weekday: action.payload}}}

        case SET_SCHEDULE_TIME_START:
            return { ...state, schedule: {...state.schedule, period: {...state.schedule.period, startTime: action.payload}}}
        case SET_SCHEDULE_TIME_END:
            return { ...state, schedule: {...state.schedule, period: {...state.schedule.period, endTime: action.payload}}}
            
        case SET_SCHEDULE_OTHER:
            return { ...state, schedule: {...state.schedule, other: action.payload}}


        case SET_SCHEDULE_LENGTH_YEARS:
            return { ...state, schedule: {...state.schedule, lengthYears: action.payload}}
        case SET_SCHEDULE_LENGTH_MONTHS:
            return { ...state, schedule: {...state.schedule, lengthMonths: action.payload}}
        case SET_SCHEDULE_LENGTH_DAYS:
            return { ...state, schedule: {...state.schedule, lengthDays: action.payload}}
        case SET_SCHEDULE_MONDAY:
            return { ...state, schedule: {...state.schedule, monday: action.payload}}
        case SET_SCHEDULE_TUESDAY:
            return { ...state, schedule: {...state.schedule, tuesday: action.payload}}
        case SET_SCHEDULE_WEDNESDAY:
            return { ...state, schedule: {...state.schedule, wednesday: action.payload}}
        case SET_SCHEDULE_THURSDAY:
            return { ...state, schedule: {...state.schedule, thursday: action.payload}}
        case SET_SCHEDULE_FRIDAY:
            return { ...state, schedule: {...state.schedule, friday: action.payload}}
        case SET_SCHEDULE_SATURDAY:
            return { ...state, schedule: {...state.schedule, saturday: action.payload}}
        case SET_SCHEDULE_SUNDAY:
            return { ...state, schedule: {...state.schedule, sunday: action.payload}}
        case SET_SCHEDULE_PLANNED_EVENTS:
            return { ...state, schedule: {...state.schedule, plannedEvents: action.payload}}


        case SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE:
            return { ...state, addressNew: {...state.addressNew, currentContractTime: {...state.addressNew.currentContractTime, startDate: action.payload}}}
        case SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE:
            return { ...state, addressNew: {...state.addressNew, currentContractTime: {...state.addressNew.currentContractTime, endDate: action.payload}}}

        case CREATE_ADDRESS_NEW_ID:
            return { ...state, addressNew: {...state.addressNew, id: action.payload}}
        case CREATE_ADDRESS_NEW_NAME:
            return { ...state, addressNew: {...state.addressNew, name: action.payload}}


        case CREATE_ADDRESS_NEW_IDENTIFIER_ID:
            return { ...state, addressNew: {...state.addressNew, identifier: {...state.addressNew.identifier, id: action.payload}}}
        case CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER:
            return { ...state, addressNew: {...state.addressNew, identifier: {...state.addressNew.identifier, identifier: action.payload}}}
        case CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME:
            return { ...state, addressNew: {...state.addressNew, identifier: {...state.addressNew.identifier, scheme: action.payload}}}
        case CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME:
            return { ...state, addressNew: {...state.addressNew, identifier: {...state.addressNew.identifier, legalName: action.payload}}}

        case CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID:
            return { ...state, addressNew: {...state.addressNew, additionalIdentifiers: {...state.addressNew.additionalIdentifiers, id: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER:
            return { ...state, addressNew: {...state.addressNew, additionalIdentifiers: {...state.addressNew.additionalIdentifiers, identifier: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME:
            return { ...state, addressNew: {...state.addressNew, additionalIdentifiers: {...state.addressNew.additionalIdentifiers, scheme: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME:
            return { ...state, addressNew: {...state.addressNew, additionalIdentifiers: {...state.addressNew.additionalIdentifiers, legalName: action.payload}}}

        case CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, addressIdentifier: {...state.addressNew.address.addressIdentifier, id: action.payload}}}}
        case CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, addressIdentifier: {...state.addressNew.address.addressIdentifier, scheme: action.payload}}}}
        case CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, addressIdentifier: {...state.addressNew.address.addressIdentifier, identifier: action.payload}}}}
        case CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, addressIdentifier: {...state.addressNew.address.addressIdentifier, name: action.payload}}}}

        case CREATE_ADDRESS_NEW_ADDRESS_ID:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, id: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, streetAddress: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDRESS_LOCALITY:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, locality: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDRESS_REGION:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, region: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, postalCode: action.payload}}}
        case CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME:
            return { ...state, addressNew: {...state.addressNew, address: {...state.addressNew.address, countryName: action.payload}}}

        case CREATE_ADDRESS_NEW_CONTACT_POINT_ID:
            return { ...state, addressNew: {...state.addressNew, contactPoint: {...state.addressNew.contactPoint, id: action.payload}}}
        case CREATE_ADDRESS_NEW_CONTACT_POINT_NAME:
            return { ...state, addressNew: {...state.addressNew, contactPoint: {...state.addressNew.contactPoint, name: action.payload}}}
        case CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL:
            return { ...state, addressNew: {...state.addressNew, contactPoint: {...state.addressNew.contactPoint, email: action.payload}}}
        case CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE:
            return { ...state, addressNew: {...state.addressNew, contactPoint: {...state.addressNew.contactPoint, telephone: action.payload}}}
        case CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER:
            return { ...state, addressNew: {...state.addressNew, contactPoint: {...state.addressNew.contactPoint, faxNumber: action.payload}}}
        case CREATE_ADDRESS_NEW_CONTACT_POINT_URL:
            return { ...state, addressNew: {...state.addressNew, contactPoint: {...state.addressNew.contactPoint, url: action.payload}}}
        case CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO:
            return { ...state, addressNew: {...state.addressNew, representativeInfo: action.payload}}

        case CREATE_ADDRESS_NEW_OPEN_CLOSE:
            return { ...state, addressNew: {...state.addressNew, openPopUp: action.payload}}
        case GET_ADDRESS_NEW_BOOKS:
            return { ...state, addressNew: {...state.addressNew, getAddress: action.payload}}


        case AUCTIONS_GET_SEND:
            //return { ...state,  logo: action.payload}
            break

        
        case SET_DICTIONARIES_OWNERSHIPTYPE:
            return { ...state, dictionaries: {...state.dictionaries, ownershipType: action.payload}}
        case SET_DICTIONARIES_ENCUMBRANCES:
            return { ...state, dictionaries: {...state.dictionaries, encumbrances: action.payload}}
        case SET_DICTIONARIES_POWERSUPPLYCLASS:
            return { ...state, dictionaries: {...state.dictionaries, powerSupplyClass: action.payload}}
        case SET_DICTIONARIES_FLOORTYPE:
            return { ...state, dictionaries: {...state.dictionaries, floorType: action.payload}}
        case SET_DICTIONARIES_CONSTRUCTIONTECHNOLOGY:
            return { ...state, dictionaries: {...state.dictionaries, constructionTechnology: action.payload}}
        case SET_DICTIONARIES_FUELTYPE:
            return { ...state, dictionaries: {...state.dictionaries, fuelType: action.payload}}
        case SET_DICTIONARIES_TRANSMISSIONTYPE:
            return { ...state, dictionaries: {...state.dictionaries, transmissionType: action.payload}}
        case SET_DICTIONARIES_ELIGIBILITYCRITERIA:
            return { ...state, dictionaries: {...state.dictionaries, eligibilityCriteria: action.payload}}



        case SET_POPUP_ADDRESSES:
            return { ...state, setOpenAddressWindow: action.payload}
        case OPEN_BANK_WINDOW:
            return { ...state, setOpenBankWindow: action.payload}
        case PAYMENT_POP_UP:
            return { ...state, paymentPopup: action.payload}
        case PAYMENT_POP_UP1:
            return { ...state, paymentPopup1: action.payload}
        case PAYMENT_POP_UP_CREATE_PDF:
            return { ...state, paymentPopupCreatePDF: action.payload}
        case PAYMENT_POP_UP_GET_DATA:
            return { ...state, paymentPopupGetData: action.payload}
        case PAYMENT_POP_UP_SET_BANK:
            return { ...state, paymentPopupSetBanks: action.payload}
        case SAVE_HISTORY_DOCS_CONTRACT:
            return { ...state, historyDocsContracts: action.payload}
        case SET_END_POINT:
            return { ...state, endPoint: action.payload}
        case TOGETHER_API_AUCTION_NULL:
            return { ...state, auctionsGet: action.payload}
        case CHECKBOX_BID_PUBLISH:
            return { ...state, checkBoxBidPublish: action.payload}


        case CHECKBOX_BID_ALL_1:
            return { ...state, checkBox: { ...state.checkBox, waitPersonalData: action.payload}}
        case CHECKBOX_BID_ALL_2:
            return { ...state, checkBox: { ...state.checkBox, waitZgoda: action.payload}}
        case CHECKBOX_BID_ALL_3:
            return { ...state, checkBox: { ...state.checkBox, waitZgodaReglament: action.payload}}

        case CHECKBOX_WAIT:
            return { ...state, checkBox: { ...state.checkBox, wait: action.payload}}
        case CHECKBOX_AGREEMENT:
            return { ...state, checkBox: { ...state.checkBox, agreement: action.payload}}

        case POPUP_EDIT_APPROVE_DOC:
            return { ...state, popUpEditAppruveDoc: action.payload}
        case POPUP_EDIT_APPROVE_DOC_TITLE:
            return { ...state, popUpEditAppruveDoc: {...state.popUpEditAppruveDoc, document: {...state.popUpEditAppruveDoc.document, title: action.payload}}}
        case POPUP_EDIT_APPROVE_DOC_DISCRIP:
            return { ...state, popUpEditAppruveDoc: {...state.popUpEditAppruveDoc, document: {...state.popUpEditAppruveDoc.document, description: action.payload}}}
        case POPUP_EDIT_APPROVE_DOC_FILES:
            return { ...state, popUpEditAppruveDoc: {...state.popUpEditAppruveDoc, files: action.payload}}
        case SET_ACCORDIONS_BIDS:
            return { ...state, auctionAccordeons: {...state.auctionAccordeons, bidsArr: action.payload}}
        case SET_ACCORDIONS_AWARDS:
            return { ...state, auctionAccordeons: {...state.auctionAccordeons, awardArr: action.payload}}
        case SET_ACCORDIONS_CONTRACTS:
            return { ...state, auctionAccordeons: {...state.auctionAccordeons, contractArr: action.payload}}
        case SET_ACCORDIONS_CHANCEL:
            return { ...state, auctionAccordeons: {...state.auctionAccordeons, chancelArr: action.payload}}
        case WORK_BANK_ACCOUNT_SAVE:
            return { ...state, bankAccountWork: {...state.bankAccountWork, saveBank: action.payload}}
        case WORK_BANK_ACCOUNT_ADD:
            return { ...state, bankAccountWork: {...state.bankAccountWork, addNewBank: action.payload}}
        case WORK_BANK_ACCOUNT_DELETE:
            return { ...state, bankAccountWork: {...state.bankAccountWork, deleteBank: action.payload}}
        case ECP_POPUP:
            return { ...state, ecp: action.payload}
        case ECP_LINK:
            return { ...state, ecpLink: action.payload}
        case ECP_API_PASS:
            return { ...state, ecpData: {...state.ecpData, pass: action.payload}}
        case ALL_AUCTION_BIDS_ARR:
            return { ...state, auctionsAllBids: {...state.auctionsAllBids, arr: action.payload}}
        case ALL_AUCTION_BIDS_AUCTION_ID:
            return { ...state, auctionsAllBids: {...state.auctionsAllBids, auctionId: action.payload}}
        case AWARD_DOCUMENT_STATUS:
            return { ...state, awardDocument: {...state.awardDocument, statusDoc: action.payload}}
        case HISTERY_AUCTIONS:
            return { ...state, histery: {...state.histery, auctions: action.payload}}
        case HISTERY_AWARDS:
            return { ...state, histery: {...state.histery, awards: action.payload}}
        case HISTERY_CONTRACTS:
            return { ...state, histery: {...state.histery, contracts: action.payload}}
        case HISTERY_BIDS:
            return { ...state, histery: {...state.histery, bids: action.payload}}
        case POPUP_DATE_DELETE_AUCTION:
            return { ...state, deleteDateCancelAuction: action.payload}
        case WORK_MY_PAGE_CURRENT_AUCTION:
            return { ...state, workMyAuction: {...state.workMyAuction, pageCurrentAuction: action.payload}}
        case WORK_MY__API_PAGE_NEXT_AUCTION:
            return { ...state, workMyAuction: {...state.workMyAuction, pageNextAuction: action.payload}}

            
        case TOGETHER_API_PAGE_AUCTION_END_PAY:
            return { ...state, togetherApiPage: {...state.togetherApiPage, arrayAuctionsEndPay: action.payload}}
        case TOGETHER_API_PAGE_CURRENT_AUCTION:
            return { ...state, togetherApiPage: {...state.togetherApiPage, pageCurrentAuction: action.payload}}
        case TOGETHER_API_PAGE_NEXT_AUCTION:
            return { ...state, togetherApiPage: {...state.togetherApiPage, pageNextAuction: action.payload}}


        case SET_DGF_MASK:
            return { ...state, setMask: action.payload}

        case TOGETHER_API_PAGE_SWITCH:
            return { ...state, togetherApiPage: {...state.togetherApiPage, pageSwitch: action.payload}}
        case TOGETHER_API_BIDS_ARR:
            return { ...state, togetherApiPage: {...state.togetherApiPage, bidsDraftArr: action.payload}}
        case TOGETHER_API_BIDS_WORK_ARR:
            return { ...state, togetherApiPage: {...state.togetherApiPage, bidsDraftWorkArr: action.payload}}

            
            
        case TOGETHER_API_POPUP_ONOFF:
            return { ...state, togetherApiPage: {...state.togetherApiPage, popUpOnOff: action.payload}}
        case TOGETHER_API_ECP:
            return { ...state, togetherApiPage: {...state.togetherApiPage, ecpOnOff: action.payload}}
        case TOGETHER_API_LIST:
            return { ...state, togetherApiPage: {...state.togetherApiPage, list: action.payload}}
        case TOGETHER_API_LIST_CA:
            return { ...state, togetherApiPage: {...state.togetherApiPage, listChoise: action.payload}}

        case TOGETHER_API_DRAFT_BID_SUMM:
            return { ...state, togetherApiPage: {...state.togetherApiPage, bidsDraftSumm: action.payload}}
        case TOGETHER_API_PULL_AUC_EI:
            return { ...state, togetherApiPage: {...state.togetherApiPage, pullAucEI: action.payload}}
        case TOGETHER_API_BASE_PULL_AUC_EI:
            return { ...state, togetherApiPage: {...state.togetherApiPage, basePullAucEI: action.payload}}
        
                   
        case TOGETHER_API_SAVE_VALUE:
            return { ...state, togetherApiPage: {...state.togetherApiPage, saveValue: action.payload}}
            

        case TOGETHER_API_DOC_TITLE:
            return { ...state, togetherApiPage: {...state.togetherApiPage, docData: {...state.togetherApiPage.docData, title: action.payload}}}


        case TOGETHER_API_TIMER_ROUND:
            return { ...state, togetherApiPage: {...state.togetherApiPage, timer: {...state.togetherApiPage.timer, round: action.payload}}}
        case TOGETHER_API_TIMER_TIME:
            return { ...state, togetherApiPage: {...state.togetherApiPage, timer: {...state.togetherApiPage.timer, time: action.payload}}}
        case TOGETHER_API_TIMER_TIMELEFT:
            return { ...state, togetherApiPage: {...state.togetherApiPage, timer: {...state.togetherApiPage.timer, timeLeft: action.payload}}}


        case TOGETHER_API_DOC_DESCRIPTION:
            return { ...state, togetherApiPage: {...state.togetherApiPage, docData: {...state.togetherApiPage.docData, description: action.payload}}}
        case TOGETHER_API_DOC_TYPE:
            return { ...state, togetherApiPage: {...state.togetherApiPage, docData: {...state.togetherApiPage.docData, type: action.payload}}}
        case TOGETHER_API_DOC_LANG:
            return { ...state, togetherApiPage: {...state.togetherApiPage, docData: {...state.togetherApiPage.docData, lang: action.payload}}}
        case TOGETHER_API_DOC_FILENAME:
            return { ...state, togetherApiPage: {...state.togetherApiPage, docData: {...state.togetherApiPage.docData, fileName: action.payload}}}
        case TOGETHER_API_DOC_FILENAME_ECP:
            return { ...state, togetherApiPage: {...state.togetherApiPage, docData: {...state.togetherApiPage.docData, fileNameECP: action.payload}}}


        case PROXY_TIMBER_SPECIES:
            return { ...state, togetherApiPage: {...state.togetherApiPage, proxy: {...state.togetherApiPage.proxy, timberSpecies: action.payload}}}
        case PROXY_TIMBER_SORTMENT:
            return { ...state, togetherApiPage: {...state.togetherApiPage, proxy: {...state.togetherApiPage.proxy, timberSortment: action.payload}}}
        case PROXY_TIMBER_STORAGE:
            return { ...state, togetherApiPage: {...state.togetherApiPage, proxy: {...state.togetherApiPage.proxy, timberStorage: action.payload}}}
        case PROXY_TIMBER_DIAMETER:
            return { ...state, togetherApiPage: {...state.togetherApiPage, proxy: {...state.togetherApiPage.proxy, timberDiameter: action.payload}}}
        case PROXY_TIMBER_CLASS:
            return { ...state, togetherApiPage: {...state.togetherApiPage, proxy: {...state.togetherApiPage.proxy, timberClass: action.payload}}}


        case POPUP_AUCTION_DELETE_TITLE:
            return { ...state, popUpAuctionDeleteTitle: action.payload}

        case UNIVERSAL_ERROR:
            return { ...state, universalError: action.payload}

        case BID_POPUP_HELP_PUBLISH:
            return { ...state, bidPopUpHelpPublish: action.payload}
        case BID_POPUP_EDIT_DOC:
            return { ...state, bidEdit: action.payload}
        case BID_POPUP_EDIT_DOC_TITLE:
            return { ...state, bidEdit: {...state.bidEdit, title: action.payload}}
        case BID_POPUP_EDIT_DOC_DESCRIPTION:
            return { ...state, bidEdit: {...state.bidEdit, description: action.payload}}
        case BID_POPUP_EDIT_DOC_TYPE:
            return { ...state, bidEdit: {...state.bidEdit, documentType: action.payload}}
            

        case POPUP_CONTRACT_DOC_ID:
            return { ...state, popUpContractUniversal: {...state.popUpContractUniversal, id: action.payload}}
        case POPUP_CONTRACT_DOC_TITLE:
            return { ...state, popUpContractUniversal: {...state.popUpContractUniversal, title: action.payload}}
        case POPUP_CONTRACT_DOC_TYPE:
            return { ...state, popUpContractUniversal: {...state.popUpContractUniversal, type: action.payload}}
        case POPUP_CONTRACT_DOC_DESCRIPTION:
            return { ...state, popUpContractUniversal: {...state.popUpContractUniversal, description: action.payload}}
        case SET_TYPE_DOCUMENT_OF:
            return { ...state, popUpContractUniversal: {...state.popUpContractUniversal, documentOf: action.payload}}
            

        case AWARD_ALL_USER_POPUP:
            return { ...state, allUserPopUp: action.payload}
        case AWARD_ALL_USER_POPUP_USER:
            return { ...state, allUserPopUpUser: action.payload}
        case AUCTIONS_SEARCH_ELEM_ARR:
            return { ...state, searchElemOn: action.payload}

        

        case KOATUU_REGION:
            return { ...state, koatuu: {...state.koatuu, region: action.payload}}
        case KOATUU_GOROD:
            return { ...state, koatuu: {...state.koatuu, gorod: action.payload}}

        case SET_TIMBER_SORTMENT:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberSortment: action.payload}}
        case SET_TIMBER_SPECIES:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberSpecies: action.payload}}
        case SET_TIMBER_CLASS:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberClass: action.payload}}
        case SET_TIMBER_DIAMETER:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberDiameter: action.payload}}
        case SET_TIMBER_STORAGE:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberStorage: action.payload}}
        case SET_TIMBER_PRODUCTION_YEAR:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberProductionYear: action.payload}}
        case SET_TIMBER_PRODUCTION_QUARTER:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberProductionQuarter: action.payload}}
        case SET_POPUP_BID_TOGETHER_API:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, popup: action.payload}}

        case SET_TIMBER_LENGTH1:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberLength1: action.payload}}
        case SET_TIMBER_LENGTH2:
            return { ...state, togetherAPIelem: {...state.togetherAPIelem, timberLength2: action.payload}}        

                        

        case SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT:
            return { ...state, createRestriction: {...state.createRestriction, setArrRestrict: action.payload}}

        case SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_RESTRICT_TYPE:
            return { ...state, classificationsRailway: {...state.classificationsRailway, railwayCargoExclusionObjectType: action.payload}}
            
        
        case CHANGE_TOGETHER_API:
            if(state.togetherApi === false){
                return { ...state, togetherApi: true}
            }else{
                return { ...state, togetherApi: false}
            }
        
        case CHANGE_LOADER:
            return { ...state, loader: action.payload}

        case SET_CLASSIFICATIONS_BY_CARRY_TYPE:
            return { ...state, carryType: action.payload}

        case SET_POPUP_ORGANIZATIONS:
            return { ...state, popupOrganizations: action.payload}

        case SET_POP_UP_ADDRESS_ID:
            return { ...state, address: {...state.address, id: action.payload}}
        case STREET_ADDRESS:
            return { ...state, address: {...state.address, streetAddress: action.payload}}
        case LOCALITY_ADDRESS:
            return { ...state, address: {...state.address, locality: action.payload}}
        case REGION_ADDRESS:
            return { ...state, address: {...state.address, region: action.payload}}
        case POSTAL_CODE_ADDRESS:
            return { ...state, address: {...state.address, postalCode: action.payload}}
        case COUNTRY_NAME_ADDRESS:
            return { ...state, address: {...state.address, countryName: action.payload}}
        case POP_UP_ADDRESS:
            return { ...state, address: {...state.address, popUpAddress: action.payload}}
        case SET_POP_UP_ADDRESS_ERROR:
            return { ...state, address: {...state.address, popUpAddressError: action.payload}}
        case SET_POP_UP_ADDRESS_COMPLITE:
            return { ...state, address: {...state.address, popUpAddressComplite: action.payload}}
        
        case CHANGE_POP_UP_AUCTION_CREATE:
            return { ...state, popUpAuctionCreate: action.payload}
        case POPUP_AUCTION_MONGA:
            return { ...state, popUpAuctionCreateMonga: action.payload}

        case CHANGE_DISCOUNT_STATUS:
            return { ...state, discount: {...state.discount, discount: action.payload}}
        case CHANGE_DISCOUNT_ID:
            return { ...state, discount: {...state.discount, id: action.payload}}
        case CHANGE_DISCOUNT_PERCENT:
            return { ...state, discount: {...state.discount, discountPercent: action.payload}}
        case CHANGE_DISCOUNT_PERVIOUS_AUCTION_CURRENCY:
            return { ...state, discount: {...state.discount, previousAuctionValue: {...state.discount.previousAuctionValue, currency: action.payload}}}
        case CHANGE_DISCOUNT_PERVIOUS_AUCTION_AMOUNT:
            return { ...state, discount: {...state.discount, previousAuctionValue: {...state.discount.previousAuctionValue, amount: action.payload}}}
        case CHANGE_DISCOUNT_PERVIOUS_AUCTION_ID:
            return { ...state, discount: {...state.discount, previousAuctionId: action.payload}}
        case DISCOUNT_ON_OFF:
            return { ...state, discount: {...state.discount, discountOnOff: action.payload}}
            

        case OLD_ARR_DOCUMENT_GET:
            if(action.payload.hasOwnProperty('message') === true){
                return { ...state, oldArrDocument: null}
            }else{
                return { ...state, oldArrDocument: action.payload}
            }
        case AWARD_POPUP_VIEW:
            return { ...state, itemAward: action.payload}
        case BID_POPUP_EDIT:
            return { ...state, bidPopUpEdit: action.payload}
            
        case REG_EXP_TIMBER_DIAMETR:
            return { ...state, regExpTimberDiametr: action.payload}

        case SET_GET_BANK_ACCOUNT:
            return { ...state, bankAccount: action.payload}
        
        case SET_DELETE_AUCTION_DESCRIPTION:
            return { ...state, deleteAuctionDescription: action.payload}
        case SET_POP_UP_BANK_ACCOUNT:
            return { ...state, popUpBankAccount: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_COMPLITE:
            return { ...state, popUpBankAccountComplite: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_ERROR:
            return { ...state, popUpBankAccounErrort: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_SHEME:
            return { ...state, popUpBankAccountSheme: action.payload}

        case SET_POP_UP_BANK_ACCOUNT_CURRENCY:
            return { ...state, bankAccountСurrency: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_OPUS:
            return { ...state, bankAccountOpus: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_NAZVA_BANK:
            return { ...state, bankAccountNazvaBank: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_NAZVA:
            return { ...state, bankAccountNazva: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_IDENTIF:
            return { ...state, bankAccountIdentif: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_SHEME_ID:
            return { ...state, bankAccountShemeId: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_ID:
            return { ...state, bankAccountId: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_ID_ARR:
            return { ...state, bankAccountIdArr: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_ZU:
            return { ...state, bankAccountZU: action.payload}
        case SET_POP_UP_BANK_ACCOUNT_EDIT_ID:
            return { ...state, bankAccountEditId: action.payload}
            
            
        case SET_PROFILE_NULL:
            return { ...state, userProfiles: null}
        case SET_POP_UP_ERROR_AUCTION:
            return { ...state, popUpAuctionError: action.payload}

        case SET_POPUP_CONTRACT_APPROWE:
            return { ...state, popUpContractApprowe: action.payload}
        case SET_POPUP_CONTRACT_ERROR:
            return { ...state, popUpContractError: action.payload}


        case SET_CREATE_RESTRICTION_FLAG:
            return { ...state, createRestrictionFlag: action.payload}
        case SET_CREATE_RESTRICTION_LOAD_OBJECT:
            return { ...state, createRestriction: {...state.createRestriction, loadObject: action.payload}}
        case SET_CREATE_RESTRICTION_LOAD_OBJECT_CODE:
            return { ...state, createRestriction: {...state.createRestriction, loadObjectCode: action.payload}}
        case SET_CREATE_RESTRICTION_LOAD_OBJECT_NAME:
            return { ...state, createRestriction: {...state.createRestriction, loadObjectName: action.payload}}
        case SET_CREATE_RESTRICTION_UNLOAD_OBJECT:
            return { ...state, createRestriction: {...state.createRestriction, unloadObject: action.payload}}
        case SET_CREATE_RESTRICTION_UNLOAD_OBJECT_CODE:
            return { ...state, createRestriction: {...state.createRestriction, unloadObjectCode: action.payload}}
        case SET_CREATE_RESTRICTION_UNLOAD_OBJECT_NAME:
            return { ...state, createRestriction: {...state.createRestriction, unloadObjectName: action.payload}}
        
        case SET_CREATE_RESTRICTION_EXCLUSIONS_FLAF:
            return { ...state, createRestrictionExclusionsFlag: action.payload}
        case SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT:
            return { ...state, createRestrictionExclusions: {...state.createRestrictionExclusions, exclusionObject: action.payload}}
        case SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_TYPE:
            return { ...state, createRestrictionExclusions: {...state.createRestrictionExclusions, exclusionObjectType: action.payload}}
        case SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_CODE:
            return { ...state, createRestrictionExclusions: {...state.createRestrictionExclusions, exclusionObjectCode: action.payload}}
        case SET_CREATE_RESTRICTION_EXCLUSIONS_OBJECT_NAME:
            return { ...state, createRestrictionExclusions: {...state.createRestrictionExclusions, exclusionObjectName: action.payload}}

        case SET_AWARD_REJECT_TITLE:
            return { ...state, awardReject: {...state.awardReject, title: action.payload}}
        case SET_AWARD_REJECT_DESCRIPTION:
            return { ...state, awardReject: {...state.awardReject, description: action.payload}}
        case AWARD_DOCUMENT_RESTRICT:
            return { ...state, awardReject: {...state.awardReject, restrict: action.payload}}
            
        case SET_AWARD_REJECT_DOCUMENT_TYPE:
            return { ...state, awardReject: {...state.awardReject, type: action.payload}}

        case SET_CONTRACT_TITLE:
            return { ...state, timberContractPut: {...state.timberContractPut, title: action.payload}}
        case SET_CONTRACT_DESCRIPTION:
            return { ...state, timberContractPut: {...state.timberContractPut, description: action.payload}}
        case SET_CONTRACT_OFF:
            return { ...state, timberContractPut: {...state.timberContractPut, documentOf: action.payload}}

            

        case SET_CONTRACT_X_PREPAYMENTDETAILS_DATE:
            return { ...state, timberContractPut: {...state.timberContractPut, x_prepaymentDetails: {...state.timberContractPut.x_prepaymentDetails, date: action.payload}}}
        case SET_CONTRACT_X_PREPAYMENTDETAILS_PREPAYMENT_AMOUNT:
            return { ...state, timberContractPut: {...state.timberContractPut, x_prepaymentDetails: {...state.timberContractPut.x_prepaymentDetails, prepayment: {...state.timberContractPut.x_prepaymentDetails.prepayment, amount: action.payload}}}}

        case SET_CONTRACT_CONTRACT_NUMBER:
            return { ...state, timberContractPut: {...state.timberContractPut, contractNumber: action.payload}}
        case SET_CONTRACT_DATE_FROM:
            return { ...state, timberContractPut: {...state.timberContractPut, contractTime: {...state.timberContractPut.contractTime, dateFrom: action.payload}}}
        case SET_CONTRACT_DATE_TILL:
            return { ...state, timberContractPut: {...state.timberContractPut, contractTime: {...state.timberContractPut.contractTime, dateTill: action.payload}}}
        case SET_CONTRACT_DATE_PAID:
            return { ...state, timberContractPut: {...state.timberContractPut, datePaid: action.payload}}
        
        case SET_CONTRACT_TIME_ADDED_DATE_FROM:
            return { ...state, timberContractPut: {...state.timberContractPut, contractTimeAdded: {...state.timberContractPut.contractTimeAdded, dateFrom: action.payload}}}
        case SET_CONTRACT_TIME_ADDED_DATE_TILL:
            return { ...state, timberContractPut: {...state.timberContractPut, contractTimeAdded: {...state.timberContractPut.contractTimeAdded, dateTill: action.payload}}}


        case SET_CONTRACT_START_DATE:
            return { ...state, timberContractPut: {...state.timberContractPut, contractTime: {...state.timberContractPut.contractTime, dateFrom: action.payload}}}
        case SET_CONTRACT_END_DATE:
            return { ...state, timberContractPut: {...state.timberContractPut, contractTime: {...state.timberContractPut.contractTime, dateTill: action.payload}}}
        
        case SET_CONTRACT_CURRENCY:
            return { ...state, timberContractPut: {...state.timberContractPut, xValueUAH: {...state.timberContractPut.xValueUAH, currency: action.payload}}}
        case SET_CONTRACT_AMOUNT:
            return { ...state, timberContractPut: {...state.timberContractPut, xValueUAH: {...state.timberContractPut.xValueUAH, amount: action.payload}}}
        case "SET_CONTRACT_TOTAL_AMOUNT":
            return { ...state, timberContractPut: {...state.timberContractPut, contractTotalValue: {...state.timberContractPut.contractTotalValue, amount: action.payload}}}
            

        case SET_CONTRACT_TAX:
            return { ...state, timberContractPut: {...state.timberContractPut, xValueUAH: {...state.timberContractPut.xValueUAH, valueAddedTaxIncluded: action.payload}}}
        case SET_CONTRACT_VALUE_CURRENCY:
            return { ...state, timberContractPut: {...state.timberContractPut, value: {...state.timberContractPut.value, currency: action.payload}}}
        case SET_CONTRACT_VALUE_AMOUNT:
            return { ...state, timberContractPut: {...state.timberContractPut, value: {...state.timberContractPut.value, amount: action.payload}}}
        case SET_CONTRACT_VALUE_TAX:
            return { ...state, timberContractPut: {...state.timberContractPut, value: {...state.timberContractPut.value, valueAddedTaxIncluded: action.payload}}}
        
        case SET_CONTRACT_DATE_SIGNED:
            return { ...state, timberContractPut: {...state.timberContractPut, dateSigned: action.payload}}
        case SET_CONTRACT_DATE_PAID:
            return { ...state, timberContractPut: {...state.timberContractPut, datePaid: action.payload}}
            

        case SET_CREATE_BID_ERROR:
            return { ...state, popUpBidError: action.payload}

        case SET_POPUP_AWARD_ERROR:
            return { ...state, popUpAwardError: action.payload}
        case SET_POPUP_AWARD_REJECT:
            return { ...state, popUpAwardReject: action.payload}
        case SET_POPUP_AWARD_APPROWE:
            return { ...state, popUpAwardApprowe: action.payload}
        case SET_AUCTION_ID_BID:
            return { ...state, getMyCurentBid: action.payload}

        case SET_POP_UP_SEND_AUCTION:
            return { ...state, popUpSendAuc: action.payload}
        case SET_POP_UP_DEL_AUCTION:
            return { ...state, popUpDelAuc: action.payload}
        case SET_MY_BIDS:
            return { ...state, myBids: action.payload}
        case SET_CURENT_BID:
            return { ...state, curentBidLot: action.payload}
        case SET_CURENT_BID_DOC_CVALIF:
            return { ...state, bidCvalification: action.payload}
            
        case SET_AWARDS:
            return { ...state, auctionAwards: action.payload}
        case SET_AWARD_ID:
            return { ...state, auctionAwardId: action.payload}


        case SET_CLASSIFICATIONS_ZU_ADD:
            return { ...state, classificationsDgf: {...state.classificationsDgf, classificationsAdd: action.payload}}
        case SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD:
            return { ...state, classificationsDgf: {...state.classificationsDgf, classificationsByTypeMain: action.payload}}


        case SET_CLASSIFICATIONS_BY_TYPE:
            return { ...state, classificationsTimber: {...state.classificationsTimber, classificationsByTypeMain: action.payload}}
        case SET_CLASSIFICATIONS_BY_TYPE_ENERGY:
            return { ...state, classificationsEnergy: {...state.classificationsEnergy, classificationsByTypeMain: action.payload}}
        case SET_CLASSIFICATIONS_BY_TYPE_RAILWAY:
            return { ...state, classificationsRailway: {...state.classificationsRailway, classificationsByTypeMain: action.payload}}
        case SET_CLASSIFICATIONS_BY_WAGON_SPECIES:
            let tmpWagonSpecies = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('wagonSpecies', JSON.stringify(tmpWagonSpecies))
            return { ...state, classificationsRailway: {...state.classificationsRailway, wagonSpec: action.payload}}
        case SET_CLASSIFICATIONS_BY_TYPE_RAILWAY_CARGO_ADD:
            return { ...state, classificationsRailway: {...state.classificationsRailway, railwayCargoAdditional: action.payload}}
           

        case SET_CLASSIFICATIONS_BY_TYPE_NADRA_ENGLISH:
            let tmpByTypeNadraEnglish = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('byTypeNadraEnglish', JSON.stringify(tmpByTypeNadraEnglish))
            return { ...state, classificationsNadraEnglish: {...state.classificationsNadraEnglish, classificationsByTypeMain: action.payload}}
        case SET_CLASSIFICATIONS_BY_TYPE_SUBSOIL_USAGE_TYPE:
            return { ...state, classificationsNadraEnglish: {...state.classificationsNadraEnglish, classificationsByShemaSubsoilUsageType: action.payload}}
            

        case SET_CLASSIFICATIONS_BY_ENERGY_GENERATION_TYPE:
            return { ...state, classificationsEnergy: {...state.classificationsEnergy, generationType: action.payload}}
        
        case UNITS_GET_BY_TYPE_ENERGY:
            return { ...state, classificationsEnergy: {...state.classificationsEnergy, unitCode: action.payload}}
        case UNITS_GET_BY_TYPE_RAILWAY:
            return { ...state, classificationsRailway: {...state.classificationsRailway, unitCode: action.payload}}
        case UNITS_GET_BY_TYPE_SUBSOIL:
            return { ...state, classificationsNadraEnglish: {...state.classificationsNadraEnglish, unitCode: action.payload}}
        case UNITS_GET_BY_TYPE_ZU:
            return { ...state, classificationsZU: {...state.classificationsZU, unitCode: action.payload}}
            

            
        
            
            
        case SET_CLASSIFICATIONS_BY_TIMBER_SORTMENT:
            let tmpTimberSortment = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberSortment', JSON.stringify(tmpTimberSortment))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberSortment: action.payload}}
        case SET_CLASSIFICATIONS_BY_TIMBER_SPECIES:
            let tmpTimberSpecies = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberSpecies', JSON.stringify(tmpTimberSpecies))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberSpecies: action.payload}}
        case SET_CLASSIFICATIONS_BY_TIMBER_CLASS:
            let tmpTimberClass = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberClass', JSON.stringify(tmpTimberClass))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberClass: action.payload}}
        case SET_CLASSIFICATIONS_BY_TIMBER_DIAMETER:
            let tmpTimberDiameter = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberDiameter', JSON.stringify(tmpTimberDiameter))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberDiameter: action.payload}}
        case SET_CLASSIFICATIONS_BY_TIMBER_LENGTH:
            let tmpTimberLength = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberLength', JSON.stringify(tmpTimberLength))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberLength: action.payload}}
        case SET_CLASSIFICATIONS_BY_TIMBER_STORAGE:
            let tmpTimberStorage = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberStorage', JSON.stringify(tmpTimberStorage))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberStorage: action.payload}}
        case SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR:
            let tmpTimberYear = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberYear', JSON.stringify(tmpTimberYear))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberProductionYear: action.payload}}
        case SET_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER:
            let tmpTimberQuarter = {
                dictonary: action.payload,
                time: Date.now() + (((1000 * 60) * 60) * 24)
            }
            localStorage.setItem('timberQuarter', JSON.stringify(tmpTimberQuarter))
            return { ...state, classificationsTimber: {...state.classificationsTimber, timberProductionQuarter: action.payload}}
        case UNITS_GET_BY_TYPE:
            return { ...state, classificationsTimber: {...state.classificationsTimber, unitCode: action.payload}}
        case CHANGE_UNIT_CODE:
            return { ...state,  createItems: {...state.createItems, unitCode: action.payload}}
        case CHANGE_CLASSIFICATIONS_BY_GENERATION_TYPE:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, generationType: action.payload}}}
            
        case CREATE_ITEMS_LOCATION_LATITUDE:
            return { ...state,  createItems: {...state.createItems, location: {...state.createItems.location, latitude: action.payload}}}
        case CREATE_ITEMS_LOCATION_LONGITUDE:
            return { ...state,  createItems: {...state.createItems, location: {...state.createItems.location, longitude: action.payload}}}
        case CREATE_ITEMS_LOCATION_ELEVATION:
            return { ...state,  createItems: {...state.createItems, location: {...state.createItems.location, elevation: action.payload}}}
        

        case CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_ID:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, jointPropertyComplexProps: 
                        {...state.createItems.dgf.jointPropertyComplexProps, id: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_QUANTITYANDNOMENCLATURE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, jointPropertyComplexProps: 
                        {...state.createItems.dgf.jointPropertyComplexProps, quantityAndNomenclature: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_WORKPLACESQUANTITY:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, jointPropertyComplexProps: 
                        {...state.createItems.dgf.jointPropertyComplexProps, workplacesQuantity: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_BUILDINGSINFORMATION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, jointPropertyComplexProps: 
                        {...state.createItems.dgf.jointPropertyComplexProps, buildingsInformation: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_LANDPLOTINFORMATION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, jointPropertyComplexProps: 
                        {...state.createItems.dgf.jointPropertyComplexProps, landPlotInformation: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_JOINTPROPERTYCOMPLEXPROPS_MAINOBLIGATIONS:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, jointPropertyComplexProps: 
                        {...state.createItems.dgf.jointPropertyComplexProps, mainObligations: action.payload}
                    }
                }
            }

        case CREATE_ITEMS_DGF_LANDPROPS_ID:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, id: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_LANDAREA:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, landArea: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_CADASTRALNUMBER:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, cadastralNumber: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_OWNERSHIPTYPE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, ownershipType: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_HASENCUMBRANCES:
            return { ...state,  createItems: 
                {...state.createItems, landRental: 
                    {...state.createItems.landRental, itemProps: 
                        {...state.createItems.landRental.itemProps, hasEncumbrances: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_ENCUMBRANCES:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, encumbrances: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_HASJOINTOWNERSHIP:
            return { ...state,  createItems: 
                {...state.createItems, landRental: 
                    {...state.createItems.landRental, itemProps: 
                        {...state.createItems.landRental.itemProps, hasJointOwnership: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_JOINTOWNERSHIP:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, jointOwnership: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_HASUTILITIESAVAILABILITY:
            return { ...state,  createItems: 
                {...state.createItems, landRental: 
                    {...state.createItems.landRental, itemProps: 
                        {...state.createItems.landRental.itemProps, hasUtilitiesAvailability: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_UTILITIESAVAILABILITY:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, utilitiesAvailability: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_LANDPROPS_INTENDEDUSE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, landProps: 
                        {...state.createItems.dgf.landProps, intendedUse: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_ID:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, id: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_BRAND:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, brand: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_MODEL:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, model: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_PRODUCTIONYEAR:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, productionYear: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_ENGINECAPACITY:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, engineCapacity: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_FUELTYPE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, fuelType: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_TRANSMISSION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, transmission: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_COLOR:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, color: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_KILOMETRAGE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, kilometrage: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_IDENTIFICATIONNUMBER:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, identificationNumber: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_CONFIGURATION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, configuration: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_CONDITION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, condition: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEPRESENCE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, damagePresence: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_VEHICLEPROPS_DAMAGEDDESCRIPTION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, vehicleProps: 
                        {...state.createItems.dgf.vehicleProps, damagedDescription: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_ID:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, id: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALBUILDINGAREA:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, totalBuildingArea: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_TOTALOBJECTAREA:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, totalObjectArea: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_USABLEAREA:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, usableArea: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONYEAR:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, constructionYear: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_CONSTRUCTIONTECHNOLOGY:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, constructionTechnology: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_LIVINGAREA:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, livingArea: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_KITCHENAREA:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, kitchenArea: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_LANDAREA:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, landArea: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_LOCATIONINBUILDING:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, locationInBuilding: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_FLOORS:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, floors: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_GENERALCONDITION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, generalCondition: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELECTRICITY:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceElectricity: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCAPACITY:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, powerSupplyCapacity: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_POWERSUPPLYCLASS:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, powerSupplyClass: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEWATER:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceWater: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESEWERAGE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceSewerage: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEGAS:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceGas: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICECENTRALHEATING:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceCentralHeating: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAUTONOMOUSHEATING:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceAutonomousHeating: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEHEATINGCOUNTER:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceHeatingCounter: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEVENTILATION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceVentilation: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEAIRCONDITIONING:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceAirConditioning: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEPHONE:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, servicePhone: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICETV:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceTV: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEINTERNET:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceInternet: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEELEVATOR:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceElevator: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESECURITYALARM:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceSecurityAlarm: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICEFIREALARM:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, serviceFireAlarm: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESDESCRIPTION:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, servicesDescription: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_SERVICESACCOUNTING:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, servicesAccounting: action.payload}
                    }
                }
            }
        case CREATE_ITEMS_DGF_REALESTATEPROPS_LANDTAX:
            return { ...state,  createItems: 
                {...state.createItems, dgf: 
                    {...state.createItems.dgf, realEstateProps: 
                        {...state.createItems.dgf.realEstateProps, landTax: action.payload}
                    }
                }
            }

        case SET_CURRENT_TENANT_GUARANTEE_FEE_VALUE:
            return { ...state,  auctionsMy: {...state.auctionsMy, currentTenantGuaranteeFee: {...state.auctionsMy.currentTenantGuaranteeFee, amount: action.payload}}}

        //DGF
        case CREATE_ITEMS_DGF_MINNUMBEROFQUALIFIEDBIDS:
            return { ...state,  auctionsMy: {...state.auctionsMy, minNumberOfQualifiedBids: action.payload}}


        case CREATE_ITEMS_DGF_VIRTUALDATAROOM:
            return { ...state,  auctionsMy: {...state.auctionsMy, virtualDataRoom: action.payload}}
        case CREATE_ITEMS_DGF_ELIGIBILITYCRITERIA:
            return { ...state,  auctionsMy: {...state.auctionsMy, eligibilityCriteria: action.payload}}
        case CREATE_ITEMS_DGF_X_DGFPLATFORMLEGALDETAILS:
            return { ...state,  auctionsMy: {...state.auctionsMy, xDgfPlatformLegalDetails: action.payload}}
        case CREATE_ITEMS_DGF_X_DGFDECISION_ID:
            return { ...state,  auctionsMy: {...state.auctionsMy, decision: {...state.auctionsMy.decision, decisionId: action.payload}}}
        case CREATE_ITEMS_DGF_X_DGFDECISION_DATE:
            return { ...state,  auctionsMy: {...state.auctionsMy, decision: {...state.auctionsMy.decision, decisionDate: action.payload}}}



        case CREATE_AUCTION_RAILWAY_DUTCH_STEP_QUANTITY:
            return { ...state,  auctionsMy: {...state.auctionsMy, dutchStep: {...state.auctionsMy.dutchStep, dutchStepQuantity: action.payload}}}
        case CREATE_AUCTION_RAILWAY_DUTCH_STEP_PERCENT:
            return { ...state,  auctionsMy: {...state.auctionsMy, dutchStep: {...state.auctionsMy.dutchStep, dutchStepPercent: action.payload}}}
        case CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_AMOUNT:
            return { ...state,  auctionsMy: {...state.auctionsMy, dutchStep: {...state.auctionsMy.dutchStep, dutchStepValue: {...state.auctionsMy.dutchStep.dutchStepValue, amount:action.payload}}}}
        case CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED:
            return { ...state,  discount: {...state.discount, previousAuctionValue: {...state.discount.previousAuctionValue, valueAddedTaxIncluded:action.payload}}}
            


        case CHANGE_GEOLOGIV_DATA_VALUE:
            return { ...state,  auctionsMy: {...state.auctionsMy, geologicDataValue: {...state.auctionsMy.geologicDataValue, amount: action.payload}}}
        case CHANGE_DOCUMENTATION_VALUE:
            return { ...state,  auctionsMy: {...state.auctionsMy, documentationValue: {...state.auctionsMy.documentationValue, amount: action.payload}}}
        case CHANGE_DATE_USES:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseDuration: action.payload}}

        case SET_MIN_NUMBER_OF_QUALIFIED_BIDS:
            return { ...state,  auctionsMy: {...state.auctionsMy, minNumberOfQualifiedBids: action.payload}}
            

        case SET_CARRY_TYPE:
            return { ...state,  auctionsMy: {...state.auctionsMy, carryType: action.payload}}

        case CHANGE_CLASSIFICATIONS_BY_TIMBER_SORTMENT:
            return { ...state,  createItems: 
                {...state.createItems, additionalClassificationIds: 
                    {...state.createItems.additionalClassificationIds, timberSortment: action.payload}}}
        
                    
        case CREATE_AUCTION_RAILWAY_LOT_TYPE:
            return { ...state,  createItems: {...state.createItems, railwayLotType: action.payload}}

        case CHANGE_CLASSIFICATIONS_BY_TIMBER_SPECIES:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberSpecies: action.payload}}}
        case CHANGE_CLASSIFICATIONS_BY_TIMBER_CLASS:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberClass: action.payload}}}
        case CHANGE_CLASSIFICATIONS_BY_TIMBER_DIAMETER:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberDiameter: action.payload}}}
        case CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH1:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberLength1: action.payload}}}
        case CHANGE_CLASSIFICATIONS_BY_TIMBER_LENGTH2:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberLength2: action.payload}}}
        case CHANGE_CLASSIFICATIONS_BY_TIMBER_STORAGE:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberStorage: action.payload}}}
        case CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_YEAR:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberProductionYear: action.payload}}}
        case CHANGE_CLASSIFICATIONS_BY_TIMBER_PRODUCTION_QUARTER:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, timberProductionQuarter: action.payload}}}

        case SET_CREATE_ITEM_RESTRICT:
            return { ...state,  createItems: {...state.createItems, auctionRestriction: action.payload}}
            

        case SET_RAILWAY_CLASSIFICATOR_ADD:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, railwayCargoAdditional: action.payload}}}
        case SET_NADRA_CLASSIFICATOR_ADD:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: {...state.createItems.additionalClassificationIds, subsoilUsageTypeAdditional: action.payload}}}

        case SET_LOGO:
            return { ...state,  logo: action.payload}
        case SET_POPUP_LOGIN:
            return { ...state,  popUpStatus: {...state.popUpStatus, login: action.payload}}

        
        case SERCH_ITEM_TYPE:
            return { ...state,  search: {...state.search, itemType: action.payload}}
        case SERCH_IS_PERISHABLE:
            return { ...state,  search: {...state.search, isPerishable: action.payload}}

        case SERCH_CLASSIFICATION:
            return { ...state,  search: {...state.search, classification: action.payload}}    
        case SERCH_ADDITIONAL_CLASSIFICATION:
            return { ...state,  search: {...state.search, additionalClassifications: action.payload}}
        case SEARCH_AREA_FROM:
            return { ...state,  search: {...state.search, areaFrom: action.payload}}
        case SEARCH_AREA_TO:
            return { ...state,  search: {...state.search, areaTo: action.payload}}

        case SET_NUMBER_OF_CURRENT_TENANTS:
            return { ...state, auctionsMy: { ...state.auctionsMy, numberOfCurrentTenants: action.payload}}
        case CREATE_SEARCH_NUMBER_OF_CURRENT_TENANTS:
            return { ...state,  search: {...state.search, numberOfCurrentTenants: action.payload}}

        case SEARCH_LEASE_DURATION_FROM_YEAR:
            return { ...state,  search: {...state.search, leaseDurationFrom: {...state.search.leaseDurationFrom, year: action.payload}}}
        case SEARCH_LEASE_DURATION_FROM_MONTH:
            return { ...state,  search: {...state.search, leaseDurationFrom: {...state.search.leaseDurationFrom, month: action.payload}}}
        case SEARCH_LEASE_DURATION_FROM_DAY:
            return { ...state,  search: {...state.search, leaseDurationFrom: {...state.search.leaseDurationFrom, day: action.payload}}}

        case SEARCH_LEASE_DURATION_TO_YEAR:
            return { ...state,  search: {...state.search, leaseDurationTo: {...state.search.leaseDurationTo, year: action.payload}}}
        case SEARCH_LEASE_DURATION_TO_MONTH:
            return { ...state,  search: {...state.search, leaseDurationTo: {...state.search.leaseDurationTo, month: action.payload}}}
        case SEARCH_LEASE_DURATION_TO_DAY:
            return { ...state,  search: {...state.search, leaseDurationTo: {...state.search.leaseDurationTo, day: action.payload}}}


        case SET_SEARCH_SORT:
            return { ...state,  search: {...state.search, order: action.payload}}
        case SET_SEARCH_TEXT_STRING:
            return { ...state,  search: {...state.search, textSting: action.payload}}
        case SET_SEARCH_TYPE_KEYWORD:
            return { ...state,  search: {...state.search, selectTypeKeyword: action.payload}}

        case SET_SEARCH_DATE_START:
            return { ...state,  search: {...state.search, startDateFrom: action.payload}}
        case SET_SEARCH_DATE_END:
            return { ...state,  search: {...state.search, startDateTo: action.payload}}
        case SET_SEARCH_REGISTRY_ID:
            return { ...state,  search: {...state.search, registryId: action.payload}}
            
            

        case DOCUMENT_BID_PUBLIC_NAME:
            return { ...state,  documentCount: {...state.documentCount, documentPublicName: action.payload}}
        case DOCUMENT_BID_BLOCK_STATUS:
            return { ...state,  documentCount: {...state.documentCount, documentBidStatus: action.payload}}
        case DOCUMENT_COUNNT_ARR_FILES:
            return { ...state,  documentCount: {...state.documentCount, arrFiles: action.payload}}
        case DOCUMENT_COUNNT_FILES:
            return { ...state,  documentCount: {...state.documentCount, countArrFiles: action.payload}}
        case SET_CATEGORY_AUCTION:
            return { ...state,  search: {...state.search, category: action.payload}}
        case SET_PRICE_FROM:
            return { ...state,  search: {...state.search, priceFrom: action.payload}}
        case SET_CADASTRAL_NUMBER:
            return { ...state,  search: {...state.search, cadastralNumber: action.payload}}
        case SET_LAND_AREA_TO:
            return { ...state,  search: {...state.search, landAreaTo: action.payload}}
        case SET_LAND_AREA_FROM:
            return { ...state,  search: {...state.search, landAreaFrom: action.payload}}
            
            
        case SET_PRICE_FROM_BAG:
            return { ...state,  search: {...state.search, priceFromBag: action.payload}}
        case SET_PRICE_TO:
            return { ...state,  search: {...state.search, priceTo: action.payload}}
        case SET_PRICE_TO_BAG:
            return { ...state,  search: {...state.search, priceToBag: action.payload}}
        case SET_AUCTIONS_PATH:
            return { ...state,  aucyionsPath: action.payload}
        case CHANGE_MENU:
            return { ...state,  mobileMenu: action.payload}
        case CHANGE_MENU_ON_OFF:
            return { ...state,  menuOnOff: action.payload}
        case CHANGE_WINDOW:
            return { ...state,  windowSize: action.payload}
        case CHANGE_LANG:
            return { ...state,  langFlag: action.payload}
        case CHANGE_ADVANCED_FIND:
            return { ...state,  find: action.payload}
        case BID_AWARDS:
            return { ...state,  bidAward: action.payload}
        case BID_QUANTITY:
            return { ...state,  bidQuantity: action.payload}
            
        case CREATE_AUCTION_TAB:
            return { ...state,  createAuctionTab: action.payload}
        case CREATE_CHANGET_AUCTION:
            return {...state,  auctionsMy: action.payload}
        case EDIT_AUCTION_FROM_ID:
            return {...state,  auctionsMy: action.payload}
            
        case CREATE_AUCTION_LOT_ID:
            return { ...state,  auctionsMy: {...state.auctionsMy, lotIdentifier: action.payload}}
        case CREATE_AUCTION_TITLE:
            return { ...state,  auctionsMy: {...state.auctionsMy, title: action.payload}}
        case CREATE_CALC_ON_BOOK_VALUE:
            return { ...state,  auctionsMy: {...state.auctionsMy, calcOnBookValue: action.payload}}
        case CREATE_VALUE_ADDED_TAX_CHARGED:
            return { ...state,  auctionsMy: {...state.auctionsMy, valueAddedTaxCharged: action.payload}}
        case CREATE_REGISTRY_ID:
            return { ...state,  auctionsMy: {...state.auctionsMy, registryId: action.payload}}
        case CREATE_VALUE_PERIOD:
            return { ...state,  auctionsMy: {...state.auctionsMy, valuePeriod: action.payload}}


            

            
        case SET_AUCTION_RESTRICTIONS:
            return { ...state,  auctionsMy: {...state.auctionsMy, restrictions: action.payload}}    
        case SET_AUCTION_CONDITIONS:
            return { ...state,  auctionsMy: {...state.auctionsMy, conditions: action.payload}}
        case SET_AUCTION_BUDGET_SPENT:
            return { ...state,  auctionsMy: {...state.auctionsMy, budgetSpent: {...state.auctionsMy.budgetSpent, amount: action.payload}}}
        case SET_AUCTION_EXPERT_MONETARY_VALUATION:
            return { ...state,  auctionsMy: {...state.auctionsMy, expertMonetaryValuation: {...state.auctionsMy.expertMonetaryValuation, amount: action.payload}}}
        case SET_AUCTION_NORMATIVE_MONETARY_VALUATION:
            return { ...state,  auctionsMy: {...state.auctionsMy, normativeMonetaryValuation: {...state.auctionsMy.normativeMonetaryValuation, amount: action.payload}}}
        case AUCTION_lEASE_TYPE:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseType: action.payload}}
        case AUCTION_SALE_TYPE:
            return { ...state,  auctionsMy: {...state.auctionsMy, saleType: action.payload}}
            
        case SET_AUCTION_LEASE_RULES:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseRules: action.payload}}
        case CREATE_LEASE_RULES_INTENDED_USE:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseRules: {...state.auctionsMy.leaseRules, intendedUse: action.payload}}}
        case CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_METHOD:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseRules: {...state.auctionsMy.leaseRules, intendedUseRestrictionMethod: action.payload}}}
        case CREATE_LEASE_RULES_INTENDED_USE_RESTRICTION_DESCRIPTION:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseRules: {...state.auctionsMy.leaseRules, intendedUseRestrictionDescription: action.payload}}}
        case CREATE_LEASE_RULES_ADDITIONAL_LEASE_CONDITIONS:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseRules: {...state.auctionsMy.leaseRules, additionalLeaseConditions: action.payload}}}
        case CREATE_LEASE_RULES_SUBLEASE_ALLOWED:
            return { ...state,  auctionsMy: {...state.auctionsMy, leaseRules: {...state.auctionsMy.leaseRules, subleaseAllowed: action.payload}}}

        case SET_AUCTION_RELATED_ORGANIZATIONS:
            return { ...state,  auctionsMy: {...state.auctionsMy, relatedOrganizations: action.payload}}
        case SET_AUCTION_PROPERTY_OWNER_ID:
            return { ...state,  auctionsMy: {...state.auctionsMy, relatedOrganizations: {...state.auctionsMy.relatedOrganizations, propertyOwner: {...state.auctionsMy.relatedOrganizations.propertyOwner, id: action.payload}}}}
        case SET_AUCTION_SELLING_ENTITY_ID:
            return { ...state,  auctionsMy: {...state.auctionsMy, relatedOrganizations: {...state.auctionsMy.relatedOrganizations, sellingEntity: {...state.auctionsMy.relatedOrganizations.sellingEntity, id: action.payload}}}}
        case SET_AUCTION_CURRENT_TENANT_ID:
            return { ...state,  auctionsMy: {...state.auctionsMy, relatedOrganizations: {...state.auctionsMy.relatedOrganizations, currentTenant: {...state.auctionsMy.relatedOrganizations.currentTenant, id: action.payload}}}}
        case SET_AUCTION_OWNERSHIP_TYPE:
            return { ...state,  auctionsMy: {...state.auctionsMy, relatedOrganizations: {...state.auctionsMy.relatedOrganizations, ownershipType: action.payload}}}




            
        case CREATE_AUCTION_DESCRIPTION:
            return { ...state,  auctionsMy: {...state.auctionsMy, description: action.payload}}
        case CREATE_AUCTION_TYPE:
            return { ...state,  auctionsMy: {...state.auctionsMy, sellingMethod: action.payload}}
        case CREATE_AUCTION_TENDER_ATTEMPTS:
            return { ...state,  auctionsMy: {...state.auctionsMy, tenderAttempts: action.payload}}
        case CREATE_AUCTION_ACCESS_DETAILS:
            return { ...state,  auctionsMy: {...state.auctionsMy, accessDetails: action.payload}}
        case CREATE_AUCTION_XDOCUMENT_REQUIREMENTS:
            return { ...state,  auctionsMy: {...state.auctionsMy, xDocumentRequirements: action.payload}}
        case CREATE_AUCTION_MARTIAL_LAW_DISCLAIMER:
            return { ...state,  auctionsMy: {...state.auctionsMy, martialLawDisclaimer: action.payload === null ? true : action.payload}}
        case CREATE_AUCTION_XADDITIONAL_INFORMATION:
            return { ...state,  auctionsMy: {...state.auctionsMy, xAdditionalInformation: action.payload}}
        case CREATE_AUCTION_VALUE_CURRENCY:
            return { ...state,  auctionsMy: {...state.auctionsMy, value: {...state.auctionsMy.value, currency: action.payload}}}
        case CREATE_AUCTION_VALUE_AMOUNT:
            return { ...state,  auctionsMy: {...state.auctionsMy, value: {...state.auctionsMy.value, amount: action.payload}}}
        case CREATE_AUCTION_VALUE_TAX:
            return { ...state,  auctionsMy: {...state.auctionsMy, value: {...state.auctionsMy.value, valueAddedTaxIncluded: action.payload}}}

        case SET_VALUE_ADDED_TAX_CHARGED_BASIC:
            return { ...state,  auctionsMy: {...state.auctionsMy, valueAddedTaxCharged: action.payload}}
        case SET_IS_PERISHABLE:
            return { ...state,  auctionsMy: {...state.auctionsMy, isPerishable: action.payload}}
        case CREATE_AUCTION_GUARANTEE_CURRENCY:
            return { ...state,  auctionsMy: {...state.auctionsMy, guarantee: {...state.auctionsMy.guarantee, currency: action.payload}}}
        case CREATE_AUCTION_GUARANTEE_AMOUNT:
            return { ...state,  auctionsMy: {...state.auctionsMy, guarantee: {...state.auctionsMy.guarantee, amount: action.payload}}}
        case CREATE_AUCTION_GUARANTEE_TAX:
            return { ...state,  auctionsMy: {...state.auctionsMy, guarantee: {...state.auctionsMy.guarantee, valueAddedTaxIncluded: action.payload}}}
        case CREATE_REGISTRATION_FEE:
            return { ...state,  auctionsMy: {...state.auctionsMy, registrationFee: {...state.auctionsMy.registrationFee, amount: action.payload}}}
        case CREATE_REGISTRATION_FEE_TAX:
            return { ...state,  auctionsMy: {...state.auctionsMy, registrationFee: {...state.auctionsMy.registrationFee, valueAddedTaxIncluded: action.payload}}}
        case CREATE_AUCTION_MINIMAL_STEP_CURRENCY:
            return { ...state,  auctionsMy: {...state.auctionsMy, minimalStep: {...state.auctionsMy.minimalStep, currency: action.payload}}}
        case CREATE_AUCTION_MINIMAL_STEP_AMOUNT:
            return { ...state,  auctionsMy: {...state.auctionsMy, minimalStep: {...state.auctionsMy.minimalStep, amount: action.payload}}}
        case CREATE_AUCTION_MINIMAL_STEP_TAX:
            return { ...state,  auctionsMy: {...state.auctionsMy, minimalStep: {...state.auctionsMy.minimalStep, valueAddedTaxIncluded: action.payload}}}
        case CREATE_AUCTION_DATE_START:
            return { ...state,  auctionsMy: {...state.auctionsMy, startDate: action.payload}}
        case CREATE_AUCTION_DATE_END:
            return { ...state,  auctionsMy: {...state.auctionsMy, endDate: action.payload}}
        case CREATE_AUCTION_ID:
            return { ...state,  auctionsMy: {...state.auctionsMy, id: action.payload}}
        case CREATE_ITEMS_CLASSIFICATION_ID:
            return { ...state,  createItems: {...state.createItems, classificationId: action.payload}}
        case CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS:
            return { ...state,  createItems: {...state.createItems, additionalClassificationIds: [action.payload]}}
        case CREATE_ITEMS_UNIT_VALUE_CURRENCY:
            return { ...state,  createItems: {...state.createItems, unitValue: {...state.createItems.unitValue, currency: action.payload}}}
        case CREATE_ITEMS_UNIT_VALUE_AMOUNT:
            return { ...state,  createItems: {...state.createItems, unitValue: {...state.createItems.unitValue, amount: action.payload}}}
        case CREATE_ITEMS_UNIT_VALUE_TAX:
            return { ...state,  createItems: {...state.createItems, unitValue: {...state.createItems.unitValue, valueAddedTaxIncluded: action.payload}}}
        case CREATE_ITEMS_DESCRIPTION:
            return { ...state,  createItems: {...state.createItems, description: action.payload}}
        case CREATE_ITEMS_REGION:
            return { ...state,  createItems: {...state.createItems, region: action.payload}}
        case CREATE_ITEMS_TECH_PARAMS:
            return { ...state,  createItems: {...state.createItems, techParams: action.payload}}
        case CREATE_ITEMS_TIME_SLOTS:
            return { ...state,  createItems: {...state.createItems, timeSlots: action.payload}}
        case CREATE_ITEMS_LOAD_PROFILES:
            return { ...state,  createItems: {...state.createItems, loadProfiles: action.payload}}
        case CREATE_ITEMS_QUANTITY:
            return { ...state,  createItems: {...state.createItems, quantity: action.payload}}
        case CREATE_ITEMS_IDS:
            return { ...state,  createItems: {...state.createItems, id: action.payload}}
        case CREATE_ITEMS_ADDRESS:
            return { ...state,  createItems: {...state.createItems, addressesGetMy: action.payload}}
        case POP_UP_ADDRESS_ID:
            return { ...state,  createItems: {...state.createItems, addressId: action.payload}}
        case SET_WAGON_SPECIES:
            return { ...state,  createItems: {...state.createItems, wagonSpec: action.payload}}
        case SET_LOADING_RESTRICTION:
            return { ...state,  createItems: {...state.createItems, loadingRestriction: action.payload}}
        case SET_WAGON_TYPES:
            return { ...state,  createItems: {...state.createItems, wagonTypes: action.payload}}

        case SET_DATE_FROM_1:
            return { ...state,  createItems: {...state.createItems, requestLifeTime: {...state.createItems.requestLifeTime, dateFrom: action.payload}}}
        case SET_DATE_TILL_1:
            return { ...state,  createItems: {...state.createItems, requestLifeTime: {...state.createItems.requestLifeTime, dateTill: action.payload}}}
        case SET_DATE_FROM_2:
            return { ...state,  createItems: {...state.createItems, loadLifeTime: {...state.createItems.loadLifeTime, dateFrom: action.payload}}}
        case SET_DATE_TILL_2:
            return { ...state,  createItems: {...state.createItems, loadLifeTime: {...state.createItems.loadLifeTime, dateTill: action.payload}}}

        case SET_ROUTE_QUANTITY:
            return { ...state,  createItems: {...state.createItems, routeQuantity: action.payload}}
        case SET_ROUTE_ACCOUNTING_ROUTE_NUMBER:
            return { ...state,  createItems: {...state.createItems, routesAccountingRouteNumber: action.payload}}
        case SET_ROUTE_ACCOUNTING_WAGONS_PER_ROUTE_QUANTITY:
            return { ...state,  createItems: {...state.createItems, routesAccountingWagonsPerRouteQuantity: action.payload}}
            
        case NUMBER_PAGE_LOT:
            return { ...state,  pageNumber: action.payload}
        ///////////////////////////////
        //Address
        //case ADDRESS_GET:
        //    return { ...state, addressGet: action.payload}
        case ADDRESS_GET_MY:
            return { ...state, addressGetId: action.payload}
        //case ADDRESS_POST:
        //case ADDRESS_PUT:
        //case  ADDRESS_DELETE:
        //Auctions
        case AUCTIONS_GET:
            let tmp = null;
            if(action.payload !== null){
                if(action.payload.length > 0){
                    tmp = action.payload
                }
            }
            return { ...state, auctionsGet: tmp}
        case AUCTIONS_GET_FLAG:
            return { ...state, auctionsGetFlag: action.payload}
        case AUCTIONS_GET_ID:
            if(action.payload === null){
                return { ...state, auctionsGetIdNotFound: true}
            }else if(action.payload.hasOwnProperty('message')){
                return { ...state, auctionsGetIdNotFound: true}
            }else{
                return { ...state, auctionsGetId: action.payload}
            }
        //case AUCTIONS_POST:
        //case AUCTIONS_PUT:
        //case AUCTIONS_DELETE:
        case AUC_TEST:
            return { ...state, auctionsGet: action.payload}
        case AUCTIONS_SEARCH:
            if(action.payload.hasOwnProperty('message')){
                return { ...state, auctionsGet: null}
            }else{
                return { ...state, auctionsGet: action.payload}
            }

        case AUCTIONS_SEARCH_OLD:
            if(action.payload.hasOwnProperty('message')){
                return { ...state, auctionsGetOld: null}
            }else{
                return { ...state, auctionsGetOld: action.payload}
            }
        case AUCTIONS_SEARCH_MIN:
            return { ...state, auctionsGet: action.payload}
        case AUCTIONS_SEARCH_COUNT:
            if(action.payload.hasOwnProperty('message')){
                return { ...state, searchCount: {...state.searchCount, count: null}}
            }else{
                return { ...state, searchCount: {...state.searchCount, count: action.payload.count}}
            }
        case AUCTIONS_SEARCH_OLD_COUNT:
            if(action.payload.hasOwnProperty('message')){
                return { ...state, searchCount: {...state.searchCount, countOld: null}}
            }else{
                return { ...state, searchCount: {...state.searchCount, countOld: action.payload.count}}
            }
        case AUCTIONS_GET_MY:
            return { ...state, auctionsGetMy: action.payload}
        
        case AUCTIONS_GET_MY_ID:
            return { ...state, auctionsGetMy: action.payload}
            
        
        case AUCTIONS_SWITCH_WINDOW:
            return { ...state, auctionsSwitchWindow: action.payload}
        case AUCTIONS_ITEMS_SWITCH_WINDOW:
            return { ...state, auctionsItemsSwitchWindow: action.payload}
        case AUCTIONS_CURENT_PAGE:
            return { ...state, searchCount: {...state.searchCount, curentPage: action.payload}}
        case AUCTIONS_STATUS:
            let tmp1 = null;
            if(action.payload !== 'null'){
                tmp1 = action.payload
            }
            return { ...state, search: {...state.search, status: tmp1}}
        //Auction Items
        //case AUCTION_ITEMS_GET:
        //    return { ...state, auctionItemsGet: action.payload}
        //case AUCTION_ID_ITEMS_GET:
        //    return { ...state, auctionIdItemsGet: action.payload}
        //case AUCTION_ITEMS_POST:
        //case AUCTION_ITEMS_PUT:
        //case AUCTION_ITEMS_DELETE:
        //Awards
        //case AWARDS_GET:
        //    return { ...state, awardsGet: action.payload}
        //case AWARDS_ID_GET:
        //    return { ...state, awardsIdGet: action.payload}
        //case AWARDS_POST:
        //case AWARDS_PUT:
        //case AWARDS_DELETE:
        //Auction Bid's
        //case AUCTION_BID_GET:
        //    return { ...state, auctionBidGet: action.payload}
        //case AUCTION_ID_BID_GET:
        //    return { ...state, auctionIdBidGet: action.payload}
        //case AUCTION_BID_POST:
        //case AUCTION_BID_PUT:
        //case AUCTION_BID_DELETE:
        //Classifications
        //case CLASSIFICATION_GET:
        //    return { ...state, classificationGet: action.payload}
        case CLASSIFICATIONS_GET:
            return { ...state, classificationsGet: action.payload}
        //case CLASSIFICATION_POST:
        //case CLASSIFICATION_PUT:
        //case CLASSIFICATION_DELETE:
        case CLASSIFICATIONS_BY_SCHEME_GET:
            return { ...state, classificationsGet: action.payload}
        //Contacts
        //case CONTACT_GET:
        //    return { ...state, contactGet: action.payload}
        //case CONTACTS_GET:
        //    return { ...state, contactsGet: action.payload}
        //case CONTACT_POST:
        //case CONTACT_PUT:
        //case CONTACT_DELETE:
        //Contracts
        //case CONTRACT_GET:
        //    return { ...state, contractGet: action.payload}
        case CONTRACT_AUCTION_ID_GET:
            return { ...state, contractAuctionIdGet: action.payload}
        //case CONTRACT_POST:
        //case CONTRACT_PUT:
        //case CONTRACT_DELETE:
        //Documents
        //case DOCUMENT_GET:
        //    return { ...state, documentGet: action.payload}
        //case DOCUMENTS_GET:
        //    return { ...state, documentsGet: action.payload}
        //case DOCUMENT_POST:
        //case DOCUMENT_PUT:
        //case DOCUMENT_DELETE:
        case CHANGE_DOCUMENT_ID:
            return { ...state, createDocuments: {...state.createDocuments, id: action.payload}}
        case CHANGE_DOCUMENT_TITLE:
            return { ...state, createDocuments: {...state.createDocuments, title: action.payload}}
        case CHANGE_DOCUMENT_INDEX:
            return { ...state, createDocuments: {...state.createDocuments, index: action.payload}}
        case CHANGE_DOCUMENT_DESCRIPTION:
            return { ...state, createDocuments: {...state.createDocuments, description: action.payload}}
        case CHANGE_DOCUMENT_LANG:
            return { ...state, createDocuments: {...state.createDocuments, language: action.payload}}
        case CHANGE_DOCUMENT_TYPE:
            return { ...state, createDocuments: {...state.createDocuments, documentType: action.payload}}
            
        case CREATE_DOCUMENT_FILE_NAME:
            return { ...state, createDocuments: {...state.createDocuments, fileName: action.payload}}
        //Identifier
        //case IDENTIFIER_GET:
        //    return { ...state, identifierGet: action.payload}
        //case IDENTIFIERS_GET:
        //    return { ...state, identifiersGet: action.payload}
        //case IDENTIFIER_POST:
        //case IDENTIFIER_PUT:
        //case IDENTIFIER_DELETE:
        //default
        //case DOC_GET:
        //    return { ...state, docGet: action.payload}
        //case AUTHORIZE_GET:
        //    return { ...state, authorizeGet: action.payload}
        case SET_SINGL_PASSWORD:
            return { ...state, singlPassword: action.payload}

        case TOKEN_POST:
            //let tmp2 = action.payload
            //tmp2.timeStamp = Date.now()
            localStorage.setItem('token', JSON.stringify(action.payload))
            //localStorage.setItem('token', JSON.stringify(tmp2))
            return { ...state, token: action.payload, auctionsGetFlag: false }
        case TOKEN_CLEAR:
            let tmpClearToken = {
                access_token: "",
                expires_in: 3600,
                refresh_token: "",
                timeStamp: 0,
                token_type: "",
            }
            localStorage.setItem('token', JSON.stringify(tmpClearToken))
            return { ...state, token: action.payload}
        case SET_KEY_INTERVAL:
            return { ...state, keySetIntervalRefresh: action.payload}
        //Old site users
        //case OLD_USER_GET:
        //    return { ...state, oldUserGet: action.payload}
        //case OLD_USERS_GET:
        //    return { ...state, oldUsersGet: action.payload}
        //case OLD_USER_POST:
        //case OLD_USER_PUT:
        //case OLD_USER_DELETE:
        case USER_PROFILES_GET:
            return { ...state, userProfiles: action.payload}
        //Organizations
        //case ORGANIZATION_GET:
        //    return { ...state, organizationGet: action.payload}
        //case ORGANIZATIONS_GET:
        //    return { ...state, organizationsGet: action.payload}
        //case ORGANIZATION_POST:
        //case ORGANIZATION_PUT:
        //case ORGANIZATION_DELETE:
        //Procuring Entity
        //case PROCURING_ENTITY_GET:
        //    return { ...state, procuringEntityGet: action.payload}
        //case PROCURING_ENTITIES_GET:
        //    return { ...state, procuringEntitiesGet: action.payload}
        //case PROCURING_ENTITY_POST:
        //case PROCURING_ENTITY_PUT:
        //case PROCURING_ENTITY_DELETE:
        //Questions
        //case QUESTION_GET:
        //    return { ...state, questionGet: action.payload}
        case QUESTIONS_AUCTION_ID_GET:
            return { ...state, questionsAuctionIdGet: action.payload}

        case ANSWER_TITLE:
            return { ...state, questionCreate: {...state.questionCreate, title: action.payload}}
        case ANSWER_DISCRIPTION:
            return { ...state, questionCreate: {...state.questionCreate, description: action.payload}}
        case SET_POP_UP_ANSWER_AUCTION:
            return { ...state, questionCreate: {...state.questionCreate, popUp: action.payload}}
        case SET_POP_UP_EDIT_AUCTION:
            return { ...state, questionCreate: {...state.questionCreate, popUpEdit: action.payload}}

        //case QUESTION_POST:
        //case QUESTION_PUT:
        //case QUESTION_DELETE:
        //Item Units
        //case UNIT_GET:
        //    return { ...state, unitGet: action.payload}
        case UNITS_GET:
            return { ...state, unitsGet: action.payload}
        //case UNIT_POST:
        //case UNIT_PUT:
        //case UNIT_DELETE:
        case CHANGE_MEASURES:
            return { ...state, search: {...state.search, measures: action.payload}}
        case TOGETHER_API_SAVE_DATA_LOAD:
            if(action.payload !== null){
                return { ...state, togetherApiPage: {...state.togetherApiPage, saveDataSoket: createArrSocketData(action.payload, state.togetherApiPage.saveDataSoket)}}
            }else{
                return { ...state, togetherApiPage: {...state.togetherApiPage, saveDataSoket: []}}
            }
        default: return state
    }
}

function createArrSocketData(dataPayLoad, dataState){
    let tmpArr = null
    if(dataState.length > 0){
        tmpArr = dataState
    }else{
        tmpArr = []
    }
    let tmpIdText = null
    tmpIdText = Object.keys(dataPayLoad)

    let tmpTY = {
        aucion: dataPayLoad,
        id: tmpIdText[0]
    }
    if(tmpArr.length > 0){
        let tmpCountNull = null
        for(let aucI = 0; aucI < tmpArr.length; aucI++){
            if(tmpArr[aucI].aucion[`${tmpArr[aucI].id}`] === tmpTY.id) tmpCountNull = aucI
            //if(tmpArr[aucI].id === tmpTY.id) tmpCountNull = aucI
        }
        if(tmpCountNull !== null){
            tmpArr[tmpCountNull] = tmpTY
        }else{
            tmpArr.push(tmpTY)
        }
    }else{
        tmpArr.push(tmpTY)
    }
    return tmpArr
}
