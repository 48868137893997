import React, {useState} from 'react';
import styles from './renewablesItemProps.module.css';

import {connect} from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {setDataAddress} from '../../../../../redux/createAddress';

import {
    getMyAuction,
    changeLoader,
} from '../../../../../redux/actions.js';

import {disabletElem} from '../../../disabled'
import {checkProp, isMongo} from "../../../../../redux/check";
import {clearDataLot} from "../../BRE/clearDataItem";
import MultiSelectSimple from "../../../../elements/inputs/multiSelect/multiSelectSimple";


//@todo DUPLICATES

const regionsList = ['Севастополь', 'Київ', 'Чернігівська область', 'Чернівецька область', 'Черкаська область',
    'Хмельницька область', 'Херсонська область', 'Харківська область', 'Тернопільська область', 'Сумська область',
    'Рівненська область', 'Полтавська область', 'Одеська область', 'Миколаївська область', 'Львівська область',
    'Луганська область', 'Кіровоградська область', 'Київська область', 'Івано-Франківська область', 'Запорізька область',
    'Закарпатська область', 'Житомирська область', 'Донецька область', 'Дніпропетровська область',
    'Волинська область', 'Вінницька область', 'Автономна Республіка Крим',
]


const RenewablesItemProps = ({data, classification, ...props}) => {

    // console.log(props.createItems)
    const [itemId, setItemId] = useState(checkProp(data, 'id') ? data.id : null)
    // const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    // const [region, setRegion] = useState(checkProp(data, 'region') ? data.region : null)
    // const [unit, setUnit] = useState(checkProp(data, 'unit') ? data.unit : null)

    return (
        <>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled="disabled"
                        id="outlined-select-currency-native"
                        select
                        label="Основний класифікатор"
                        value="09300000-2"
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Основний класифікатор"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">09300000-2 - Електрична, теплова, сонячна та
                            атомна
                            енергія
                        </option>
                        {/*{classificationIdOption(props)}*/}
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled
                        id="outlined-select-currency-native"
                        required
                        select
                        label="Додатковий класифікатор об'єкта"
                        value={data?.additionalClassifications.id}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Додатковий класифікатор об'єкта"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">Автогенерується під час публікації процедури на
                            ЦБД
                        </option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Опис об'єкта"
                        variant="outlined"
                        multiline
                        rows={10}
                        value={props.createItems.description}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_DESCRIPTION')
                        }}
                    />
                </div>
            </div>


            <div>Специфічні поля для об'єкта електроенергетики</div>
            <div className={styles.inPutWrap}>
                <MultiSelectSimple
                    label="Області, в яких розподіляється обсяг лота"
                    value={props.createItems?.region}
                    options={regionsList}
                    dictionary={true}
                    setUpLevel={(value) => {
                        props.setDataAddress(value, 'CREATE_ITEMS_REGION')
                    }}
                />
            </div>

            <div className={styles.blokInputs}>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            //disabled={blockedInput(props, '11')}
                            className={styles.textAreaWidth}
                            id="outlined-multiline-static"
                            label="Технічні параметри установок зберігання енергії, які можуть бути встановлені на об’єкті"
                            variant="outlined"
                            value={props.createItems?.techParams}
                            onChange={(event) => {
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_TECH_PARAMS')
                            }}
                        />
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            //disabled={blockedInput(props, '11')}
                            className={styles.textAreaWidth}
                            id="outlined-multiline-static"
                            label="Денні часові інтервали, протягом яких учасник може набути право на підтримку"
                            variant="outlined"
                            value={props.createItems?.timeSlots}
                            onChange={(event) => {
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_TIME_SLOTS')
                            }}
                        />
                    </div>
                </div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            //disabled={blockedInput(props, '11')}
                            className={styles.textAreaWidth}
                            id="outlined-multiline-static"
                            label="Профілі навантаження об’єкта електроенергетики"
                            variant="outlined"
                            value={props.createItems?.loadProfiles}
                            onChange={(event) => {
                                props.setDataAddress(event.target.value, 'CREATE_ITEMS_LOAD_PROFILES')
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error={redBlock(props, props.createItems.quantity)}
                        // disabled
                        className={styles.textAreaWidth}
                        id="outlined-multiline-static"
                        label="Розмір частки річної квоти"
                        variant="outlined"
                        value={props.createItems.quantity}
                        onChange={(event) => {
                            props.setDataAddress(event.target.value, 'CREATE_ITEMS_QUANTITY')
                        }}
                    />
                </div>
            </div>
            <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled="disabled"
                        id="outlined-select-currency-native"
                        select
                        label="Одиниці виміру"
                        value="кВт"
                        SelectProps={{
                            native: true,
                        }}
                        // helperText="Одиниці виміру"
                        variant="outlined"
                    >
                        <option key='0' selected disabled value="null">кВт</option>
                    </TextField>
                </div>
            </div>
            {viewButtons(props, itemId)}
        </>
    )
}

async function saveItem(props, itemId) {
    props.changeLoader(true)
    let route = 'auction'
    let method = itemId ? 'PUT' : 'POST'
    if (props.auctionsMy.status === null) route = 'auction-draft'

    let url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}/item`;
    if (itemId) {
        url += '/' + itemId
    }

    let body = {
        itemProps: {}
    }

    if (checkProp(props.createItems, 'description')) {
        body.description = {'uk_UA': props.createItems.description}
    }
    if (checkProp(props.createItems, 'techParams')) {
        body.itemProps.techParams = props.createItems.techParams
    }
    if (checkProp(props.createItems, 'timeSlots')) {
        body.itemProps.timeSlots = props.createItems.timeSlots
    }
    if (checkProp(props.createItems, 'loadProfiles')) {
        body.itemProps.loadProfiles = props.createItems.loadProfiles
    }
    if (checkProp(props.createItems, 'region')) {
        body.itemProps.regions = props.createItems.region
    }
    body.quantity = checkProp(props.createItems, 'quantity') ? props.createItems.quantity : null;

    console.log('updating item:', props.createItems, body);

    const response = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(error => {
        props.changeLoader(false)
        console.error('Api request error:', error);
    })
    if (response === undefined || response === 'undefined') {
        props.changeLoader(false)
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true) {
            props.changeLoader(false)
            let tmp = ''
            if (JSON.stringify(json) === '{"message":{"documents":"Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first"}}') {
                tmp = JSON.stringify({
                    title: 'Помилка при збереженні доданих даних',
                    message: 'Для редагування аукціону спочатку необхідно додати документ "Погодження змін до опису лоту, опис причин редагування"'
                })
            } else {
                tmp = JSON.stringify({title: 'Помилка при збереженні доданих даних', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        } else {
            clearDataLot(props)
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/${route}/${props.auctionsMy.id}`;
            const response = await fetch(endPoint, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(error => {
                props.changeLoader(false)
                console.error('Error during service worker registration:', error);
            })
            if (response === undefined || response === 'undefined') {
            } else {
                const json = await response.json()
                if (json.hasOwnProperty('message') === true) {
                    let tmp = JSON.stringify({
                        title: 'Помилка при завантаженні аукціонів',
                        message: JSON.stringify(json)
                    })
                    props.setUniversalError(tmp)
                } else {
                    props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
                }
            }
            props.changeLoader(false)
        }
    }
}


function blockedInput(props, type) {
    if (props.createItems.id !== null) {
        return disabletElem(props.auctionsMy, type)
    } else if (
        props.auctionsMy.items === null ||
        props.auctionsMy.items.length === 0
    ) {
        return disabletElem(props.auctionsMy, type)
    } else {
        return 'disabled'
    }
}

function classificationIdOption(props) {
    if (props.classificationsDgf.classificationsByTypeMain !== null) {
        return props.classificationsDgf.classificationsByTypeMain.map(
            (i, count) => {
                return <option key={count}
                               value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}

function redBlock(amount) {
    if (amount === null || amount === '' || amount === 'null') {
        return 'error'
    }
    return null
}

function viewButtons(props, itemId) {
    // console.log(props.createItems)

    if (
        props.createItems.description !== ''
        // props.createItems.additionalClassifications.id !== 'null' &&
        //props.createItems.address !== 'null' &&
        // props.createItems.itemProps.landArea !== '' &&
        // props.createItems.itemProps.cadastralNumber !== ''
    ) {
        return (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                saveItem(props, itemId)
                            }
                        }
                    >
                        Зберегти зміни
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.setDataAddress(null, 'CREATE_ITEMS_CLASSIFICATION')
                                props.setDataAddress(null, 'CREATE_ITEMS_CLASSIFICATION_ID')
                                props.setDataAddress(null, 'CREATE_ITEMS_ADDITIONAL_CLASSIFICATION_IDS')
                                props.setDataAddress(null, 'CREATE_ITEMS_DESCRIPTION')
                                props.setDataAddress(null, 'CREATE_ITEMS_QUANTITY')
                                props.setDataAddress(null, 'CREATE_ITEMS_IDS')
                                props.setDataAddress(null, 'CREATE_ITEMS_REGION')
                                props.setDataAddress(null, 'CREATE_ITEMS_TECH_PARAMS')
                                props.setDataAddress(null, 'CREATE_ITEMS_TIME_SLOTS')
                                props.setDataAddress(null, 'CREATE_ITEMS_LOAD_PROFILES')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )

    }

}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        auctionsMy: state.start.auctionsMy,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        classificationsNadraEnglish: state.start.classificationsNadraEnglish,
        userProfiles: state.start.userProfiles,
        classificationsDgf: state.start.classificationsDgf,
        classificationsZU: state.start.classificationsZU,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        classificationCVZU: state.start.classificationCVZU,
        classificationCVZUArr: state.start.classificationCVZUArr,
        classificationCVZUValue: state.start.classificationCVZUValue,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewablesItemProps)