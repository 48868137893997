import React from 'react';
import styles from './warehouseComposition.module.css';

import {connect} from 'react-redux';

import TextHalf from './textHalf'
import TextFull from './textFull'
import {checkProp} from '../../../../redux/check';

import OverdueDurationString from '../../lotInformation/auctionElements/overdueDuration'

const StructureLot = (props) => {
    let tmp = null;
    if (props.auction !== null) {
        if (props.auction.items.length === 0) {
            tmp = <div className={styles.documentElem + ' ' + styles.scladLotuElem}>Додані лоти відсутні</div>
        } else {
            tmp = props.auction.items
                .map(
                    (i, count) => {
                        let item = null
                        return (
                            <>
                                {viewTypeAuction(i, count)}
                                {item}
                            </>
                        )
                    }
                )
        }
    }

    return (
        <div className={styles.infoBlock}>
            <h3>СКЛАД ЛОТУ</h3>
            {tmp}
        </div>
    )
}

function viewTypeAuction(data, count) {
    let tmpClassificationScheme = null
    let tmpClassificationId = null
    let tmpClassificationDescription = null
    if (checkProp(data, 'classification')) {
        tmpClassificationScheme = data.classification.scheme
        tmpClassificationId = data.classification.id
        tmpClassificationDescription = checkProp(data.classification, 'description') &&
        checkProp(data.classification.description, 'uk_UA') ? data.classification.description.uk_UA : null
    }
    let tmpClassificationNameStr = `Класифікатор об'єкта ${tmpClassificationScheme}`
    let tmpClassificationSchemeStr = `${tmpClassificationId} - ${tmpClassificationDescription}`
    return (
        <>
            <TextFull
                title="Опис об'єкта"
                data={data.description.uk_UA}
            />
            <TextFull
                title="Області, в яких розподіляється обсяг лота"
                data={data.itemProps?.regions.length === 0 ? '-' : data.itemProps?.regions.join(', ')}
            />
            <TextFull
                title="Технічні параметри установок зберігання енергії, які можуть бути встановлені на об’єкті"
                data={data.itemProps.techParams}
            />
            <TextFull
                title="Денні часові інтервали, протягом яких учасник може набути право на підтримку"
                data={data.itemProps.timeSlots}
            />
            <TextFull
                title="Профілі навантаження об’єкта електроенергетики"
                data={data.itemProps.loadProfiles}
            />
            <TextFull
                title={tmpClassificationNameStr}
                data={tmpClassificationSchemeStr}
            />
            <OverdueDurationString obj={data}/>
            {unitName(data)}
            {quantityName(data)}
        </>
    )
}

function unitName(data) {
    if (data?.unit?.name !== null) {
        return <TextHalf
            title="Одиниці виміру об'єкта"
            data={data.unit.name.uk_UA}
        />
    }
    return null
}

function quantityName(data) {
    if (data?.quantity !== null) {
        return <TextHalf
            title="Розмір частки річної квоти"
            data={data.quantity}
        />
    }
    return null
}

function viewTrueOrFalse(data) {
    if (data === undefined || data === 'undefined') return 'Невідомо'
    if (data) return 'Так'
    return 'Ні'
}

const mapStateToProps = state => {
    return {
        //auction: state.start.auctionsGetId,
        auction: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(StructureLot)
