import React from 'react';
import styles from './createBid.module.css';

import {connect} from 'react-redux';
import {changeBidAward, changeBidQuantity} from '../../../../redux/actions.js';
import {auctionsBidsGetId, changeCreateBidDocType, changeBidErrorPopUpStatus} from '../../../../redux/actions/auctionBid/auctionBid.js';

import {setUniversalError} from '../../../../redux/actions.js';

import {changeDocumentArrFiles, changeDocumentCountArrFiles, changeDocumentBidBlockStatus, changeDocumentBidPublicName} from '../../../../redux/actions/documents/documents.js';

const CreateBid = ( props ) => {
    let tmpBaseUrl = null;
    let tmpId = null;
    if(window.location.pathname.indexOf('/tenders/') !== -1){
        tmpBaseUrl = window.location.pathname.split('/tenders/');
        tmpId = tmpBaseUrl[1].split('/')
        tmpId = tmpId[0]
    }
    let minCoast = null;
    let quantity = null;
    if(props.auctionId !== null){
        minCoast = props.auctionId.value.amount;
        quantity = props.auctionId.quantity;
    }
    return (
        <div>
            <div className={styles.blockBidLot}>
                <div className={styles.blockBidLot_title}>Ваша пропозиція</div>
                <div className={styles.bidLotCost}>
                    <div className={styles.bidLotCost_title}>Цінова пропозиція</div>
                    <div className={styles.bidLotCost_input + ' ' + styles.inpMargin}>
                        <input 
                            className={styles.inputNumber}
                            type='number'
                            value={props.bidAward}
                            onChange={(e) => {
                                let tmp = e.target.value.replace(/,/gi, '.')
                                props.changeBidAward(tmp)
                            }}
                        />
                        <div>
                            Початкова ціна продажу лоту: {minCoast} грн.
                        </div>
                        <input 
                            className={styles.inputNumber}
                            type='number'
                            value={props.bidQuantity}
                            onChange={(e) => {
                                if(props.bidQuantity < quantity){
                                    props.changeBidQuantity(e.target.value)
                                }
                            }}
                        />
                        <div>
                            Кількість
                        </div>
                        
                    </div>
                </div>
                {createDraftBid(props, tmpId, minCoast)}
            </div>
        </div>
    )
}

function createDraftBid(props, tmpId, minCoast){
    let tmp = null;
    if(props.bidAward > 0 && props.bidAward <= minCoast && props.bidQuantity !== ''){
        tmp = <div
            className={styles.button}
            onClick={
                ()=>{
                    saveBidDocument(props, tmpId)
                }
            }
        >
            Створити чернетку
        </div>
    }else{
        tmp = <div
            className={styles.button + ' ' + styles.button_disabled}
        >
            Створити чернетку
        </div>
    }
    return tmp
}

async function saveBidDocument(props, tmpId){
    let tmpCurrency = null;
    switch (true) {
        case props.auctionId.sellingMethod.startsWith("timber-english"):
            tmpCurrency = 'UAH'
            break;
        case props.auctionId.sellingMethod.startsWith("renewables"):
            tmpCurrency = 'eurocent'
            break;
        case props.auctionId.sellingMethod.startsWith("subsoil-english"):
        case props.auctionId.sellingMethod.startsWith("subsoil-dutch"):
            tmpCurrency = 'UAH'
            break;
        case props.auctionId.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionId.sellingMethod.startsWith("railwayCargo-dutch"):
            tmpCurrency = 'UAH'
            break;
        default:
            break;
    }


    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${tmpId}/bid`;
    const response = await fetch(endPoint, {
        method: "post",
        body: JSON.stringify({
            classificationId: props.auctionId.items[0].classification.id,
            unitCode: props.auctionId.items[0].unit.code,
            documentIds: [],
            qualified: true,
            value: {
                currency: tmpCurrency,
                amount: Number(props.bidAward)
            },
            quantity: Number(props.bidQuantity)
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні чернетки', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            
            props.changeBidAward('')
            props.changeBidQuantity('')
        }else{
            let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag,
                params: '?limit=20'
            }
            props.auctionsBidsGetId(data, props.token.access_token, tmpId)
            props.changeBidAward('')
            props.changeBidQuantity('')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionsGetId,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        bidAward: state.start.bidAward,
        bidQuantity: state.start.bidQuantity,
        documentCount: state.start.documentCount,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    changeBidQuantity,
    changeDocumentBidBlockStatus,
    changeDocumentBidPublicName,
    auctionsBidsGetId,
    changeCreateBidDocType,
    changeBidErrorPopUpStatus,

    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBid)