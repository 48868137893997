import React from 'react';
import styles from './tabMenu.module.css';

import {connect} from 'react-redux';
import {changeCreateAuctionTab, setEditAuctionFromId,} from '../../../redux/actions.js';
import {changeAuctionsItemsSwitchWindow} from '../../../redux/actions/auctions/auctions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../redux/actions/awards/awards.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';
import {setUniversalError} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId
} from '../../../redux/actions/discount/discount.js';

import {PROCEDURE_NAME} from '../../../redux/procedure_v3.35.2.uk';

import {clearDataLot} from '../windowItems/basicSell/clearDataItem'
import {
    setDataAddress,
} from '../../../redux/createAddress';

const TabMenu = ( props ) => {
    let tmp = null;
    if(props.state === true){
        tmp = <div
            className={styles.tab__item + ' ' + addActiveStyle(props)}
            onClick={
                () => {
                    props.changeCreateAuctionTab(props.count);
                    props.changeAuctionsItemsSwitchWindow(0);
                    clearDataLot(props)
                    let data = {
                        domen: process.env.REACT_APP_END_POINT_BD,
                        version: 'v1',
                        local: props.langFlag
                    }
                    let headers = {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                    if(props.count === 1){
                        getAucionFromId(props, props.auctionsMy.id)
                    }else if(props.count === 4){
                        //props.auctionsGetAwards(data, headers, props.auctionsMy.id)
                    }else if(props.count === 5){
                        //props.contractsAuctionIdGet(data, headers, props.auctionsMy.id)
                    }
                }
            }
        >
            {viewTabText(props)}
        </div>
    }else{
        if(props.count === 0){
            tmp = <div
                className={styles.tab__item + ' ' + addActiveStyle(props) }
                
            >
                {viewTabText(props)}
            </div>
        }else{
            tmp = <div
                className={styles.tab__item + ' ' + addActiveStyle(props) + ' ' + styles.off}
                
            >
                {viewTabText(props)}
            </div>
        }
    }
    return tmp
}

function viewTabText(props){
    let tmp = null;
    if(props.auctionsMy !== null){
        switch(props.count){
            case 0:
                tmp = 'Аукціон'
                break;
            case 1:
                tmp = PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.items.legalName
                break;
            /*case 2:
                tmp = 'Сервіс обслуговування аукціону (запитання/відповідь)'
                break;*/
            case 2:
                tmp = PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.documents.legalName
                break;
            case 3:
                //tmp = PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.questions.legalName
                tmp = null
                break;
            case 4:
                if(
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
                ){
                    tmp = null
                }else{
                    //tmp = PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.awards.legalName
                    tmp = null
                }
                break;
            case 5:
                if(
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
                ){
                    tmp = null
                }else{
                    //tmp = PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.contracts.legalName
                    tmp = null
                }
                break;
            default:
                tmp = ''
                break;
        }
    }
    return tmp
}

function addActiveStyle(props){
    let tmp = null;
    if(props.count === props.createAuctionTab){
        tmp = styles.active;
    }
    return tmp
}

////////////////////////////
async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(json.previousAuctionId !== null){
                props.changeDiscountPreviousAuctionId(json.previousAuctionId)
                if(json.discount !== null){
                    props.changeDiscountPreviousAuctionAmount(json.discount.previousAuctionValue.amount)
                    props.changeDiscountPercent(json.discount.discountPercent)
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        langFlag: state.start.langFlag,
        token: state.start.token,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsMy: state.start.auctionsMy
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,
    changeAuctionsItemsSwitchWindow,
    auctionsGetAwards,
    contractsAuctionIdGet,

    setUniversalError,
    setEditAuctionFromId,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabMenu)
