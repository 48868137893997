import React, { useEffect } from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {createBodyOld} from '../createBodyPostAndPatch/createBodyAuction'
import {createBody} from '../../createAuction/procedure/lib/createBody'

import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,
    minNumberOfQualifiedBids,
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
} from '../../../../../redux/actions.js';

import {
    changeLoader,
} from '../../../../../redux/actions.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
} from '../../../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../../../../redux/actions.js';

/////////////////////////////////////
import SnoozeIcon from "@material-ui/icons/Snooze";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { IconButton, InputAdornment } from "@material-ui/core";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import WindowAuctionSelfNumber from '../../windowAuctionSelfNumber.js';
import WindowAuctionTitle from '../../windowAuctionTitle.js';
import WindowAuctionDescription from '../../windowAuctionDescription.js';
import WindowAuctionTenderAttempt from '../../windowAuctionTenderAttempt.js';
import MartialLawDisclaimer from '../../martialLawDisclaimer.js'
import WindowAuctionAccessDetails from '../../windowAuctionAccessDetails.js';
import WindowAuctionDocumentRequirements from '../../windowAuctionDocumentRequirements.js';
import WindowAuctionAdditionalInformation from '../../windowAuctionAdditionalInformation.js';
import WindowAuctionType from '../../auctionsCommon/windowAuctionType/windowAuctionType.js';
import WindowAuctionCost from '../../windowAuctionCost.js';

import ValueAddedTaxCharged from '../../valueAddedTaxCharged.js';
import IsPerishable  from '../../isPerishable.js';

import WindowAuctionGaranty from '../../windowAuctionGaranty.js';
import WindowAuctionMinimalStep from '../../windowAuctionMinimalStep.js';
import WindowAuctionDiscount from '../../windowAuctionDiscount.js';

import WindowAuctionDutchSteps from '../../windowAuctionDutchSteps.js';
import MinNumberOfQualifiedBids from '../../minNumberOfQualifiedBids'

import WindowAuctionDgf from '../../dgf.js';
import LandRental from '../../landRental'
import WindowAuctionZU from '../../zu.js';
import WindowAuctionZUBanlAccounts from '../../zuBankAccounts'
import WindowAuctionLandRentalBanlAccounts from '../../WindowAuctionLandRentalBanlAccounts'
import WindowAuctionBaseSellBankAccounts from '../../WindowAuctionBaseSellBankAccounts'

import ReturnButton from '../../../returnButton'
import ViewAddBankAccont from '../../viewAddBankAccont.js';

import CreateAuctionLoader from '../../../createAuctionLoader.js';

import LegitimatePropertyLeaseLeaseDuration from '../legitimatePropertyLeaseLeaseDuration'
import RailWayCargoDutchMinNumberOfQualifiedBids from '../railWayCargoDutchMinNumberOfQualifiedBids'

import RegistrationFee from '../../windowAuctionRegistrationFee'
import LandRentalLeaseType from '../../landRental_leaseType'

import {disabletElem, onOffCurrentTenat} from '../../../disabled'

import {checkingFilledFields} from '../checkingFilledFields'
import {
    isRLE, isRLD, isCLE, isCLD, isBRD, isBRE, isBRW, isAAE, isALE, isBSD, isBSE, isCSD, isCSE,
    isLLD, isLLE, isLLP, isLRE, isLSE, isLSP, isRCD, isRCE, isSUD, isSUE, isTIE, isSPE, isSPD,
    isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD} from '../../../../../redux/aucTypes';
import { bankAccountGet } from '../../../../../redux/actions/bankAccount/bankAccount.js';
import Sidebar from '../../../auctionEditNew/components/sidebar/sidebar';
import InputDate from '../../../../elements/inputs/inputDate';

import { getRegion } from '../../../../../api/dictonaries/region';
const WindowAuction = ( props ) => {

    useEffect(() => {
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let dataBank = {}
        dataBank.domen = process.env.REACT_APP_END_POINT_BD
        dataBank.version = 'v1'
        dataBank.local = props.langFlag
        dataBank.params = `?offset=0`
        props.bankAccountGet(dataBank, header)
    }, [])

    let tmp = null;
    let tmpBank = null
    let tmpBankSelect = null
    let tmpRegistrationFee = null
    let tmpLandRentalLeaseType = null
    if(props.auctionsMy !== null){
        switch (true) {
            case isLSE(props.auctionsMy.sellingMethod):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case isLRE(props.auctionsMy.sellingMethod):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case isLSP(props.auctionsMy.sellingMethod):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break
            case isBSD(props.auctionsMy.sellingMethod) :
            case isBSE(props.auctionsMy.sellingMethod) :
            case isALE(props.auctionsMy.sellingMethod) :
            case isCSE(props.auctionsMy.sellingMethod) :
            case isCSD(props.auctionsMy.sellingMethod) :
            case isBRE(props.auctionsMy.sellingMethod) :
            case isBRD(props.auctionsMy.sellingMethod) :
            case isNLE(props.auctionsMy.sellingMethod) :
            case isNLD(props.auctionsMy.sellingMethod) :
            case isLAE(props.auctionsMy.sellingMethod) :
            case isLAW(props.auctionsMy.sellingMethod) :
            case isLAP(props.auctionsMy.sellingMethod) :
            case props.auctionsMy.sellingMethod.includes('railwayCargo-english'):
            case props.auctionsMy.sellingMethod.includes('railwayCargo-dutch'):
                tmpRegistrationFee = <RegistrationFee/>
                break;
        }
    }
    if(props.auctionsMy !== null){
        if(
            isLLE(props.auctionsMy.sellingMethod) ||
            isLLD(props.auctionsMy.sellingMethod) ||
            isLLP(props.auctionsMy.sellingMethod)
        ){
            if(props.auctionsSwitchWindow !== 2){
                if(props.auctionsMy.hasOwnProperty('leaseRules') === true){
                    if(props.auctionsMy.leaseRules !== null){
                        if(props.auctionsMy.leaseRules.hasOwnProperty('schedule') === true){
                            if(props.auctionsMy.leaseRules.schedule !== null){
                                if(props.schedule.flag === false){
                                    props.setDataAddress(true, 'SET_SCHEDULE_FLAG')
                                    if(props.auctionsMy.leaseRules.schedule.hasOwnProperty('hoursBy') === true){
                                        if(props.auctionsMy.leaseRules.schedule.hoursBy !== null){
                                            if(props.auctionsMy.leaseRules.schedule.hoursBy.value !== null && props.auctionsMy.leaseRules.schedule.hoursBy.value !== ''){
                                                if(props.schedule.hoursBy.value !== props.auctionsMy.leaseRules.schedule.hoursBy.value){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.hoursBy.value, 'SET_SCHEDULE_HOURS_BY_VALUE')
                                                }
                                            }
                                            if(props.auctionsMy.leaseRules.schedule.hoursBy.by !== null && props.auctionsMy.leaseRules.schedule.hoursBy.by !== 'null'){
                                                if(props.schedule.hoursBy.by !== props.auctionsMy.leaseRules.schedule.hoursBy.by){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.hoursBy.by, 'SET_SCHEDULE_HOURS_BY_BY')
                                                }
                                            }
                                        }
                                    }
                                    if(props.auctionsMy.leaseRules.schedule.hasOwnProperty('daysBy') === true){
                                        if(props.auctionsMy.leaseRules.schedule.daysBy !== null){
                                            if(props.auctionsMy.leaseRules.schedule.daysBy.value !== null && props.auctionsMy.leaseRules.schedule.daysBy.value !== ''){
                                                if(props.schedule.daysBy.value !== props.auctionsMy.leaseRules.schedule.daysBy.value){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.daysBy.value, 'SET_SCHEDULE_DAYS_BY_VALUE')
                                                }
                                            }
                                            if(props.auctionsMy.leaseRules.schedule.daysBy.by !== null && props.auctionsMy.leaseRules.schedule.daysBy.by !== 'null'){
                                                if(props.schedule.daysBy.by !== props.auctionsMy.leaseRules.schedule.daysBy.by){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.daysBy.by, 'SET_SCHEDULE_DAYS_BY_BY')
                                                }
                                            }
                                        }
                                    }
                                }
                                //props.schedule.period.startDate
                                //props.setDataAddress(e.target.value, 'SET_SCHEDULE_PERIOD_START_DATE')
                                //props.schedule.period.endDate
                                //props.setDataAddress(e.target.value, 'SET_SCHEDULE_PERIOD_END_DATE')
                                //if(props.schedule.other !== props.auctionsMy.leaseRules.schedule.other){
                                if(props.schedule.other === null){
                                    if(props.auctionsMy.leaseRules.schedule.other !== null && props.auctionsMy.leaseRules.schedule.other !== ''){
                                        props.setDataAddress(props.auctionsMy.leaseRules.schedule.other, 'SET_SCHEDULE_OTHER')
                                    }
                                }
                            }
                        }
                    }
                }
            }
            tmpBank = <WindowAuctionZUBanlAccounts />
            tmpBankSelect = null
        }else if(
            isLRE(props.auctionsMy.sellingMethod) ||
            isLSE(props.auctionsMy.sellingMethod) ||
            isLSP(props.auctionsMy.sellingMethod)
        ){
            tmpBank = <WindowAuctionLandRentalBanlAccounts />
            tmpBankSelect = null
        }else if(
            isBSD(props.auctionsMy.sellingMethod) || isBSE(props.auctionsMy.sellingMethod) ||
            isALE(props.auctionsMy.sellingMethod) ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
            isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
            isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod)
        ){
            tmpBank = <WindowAuctionBaseSellBankAccounts />
        }else if(
            !isSUE(props.auctionsMy.sellingMethod) &&
            !isSUD(props.auctionsMy.sellingMethod) &&
            !props.auctionsMy.sellingMethod.startsWith('renewables-multiAwards')
        ){
            tmpBank = <ViewAddBankAccont />
            tmpBankSelect = <TextField
                className={styles.inputMaxBank}
                disabled={disabletElem(props.auctionsMy, '01')}
                id="outlined-select-currency-native"
                select
                label="Банківський рахунок"
                value={props.bankAccountId}
                onChange={(event)=>{
                    let tmpBankAdd = props.bankAccountWork.addNewBank
                    tmpBankAdd.push(event.target.value)
                    props.setBankAccountAdd(tmpBankAdd)
                    props.setPopBankAccountId(event.target.value)
                    addArrayBankAccounts(props, event.target.value)
                }}
                SelectProps={{
                    native: true,
                }}
                helperText="Обрати банківський рахунок"
                variant="outlined"
            >
                <option key={0} selected disabled value='null'>Обрати</option>
                {selectBankAuction(props)}
            </TextField>
        }
    }

    if(props.auctionsMy !== null && props.auctionsSwitchWindow === 1){
        if(
            isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
            isALE(props.auctionsMy.sellingMethod) ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||

            isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod) ||
            isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod) ||
            isBRW(props.auctionsMy.sellingMethod) ||
            isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod) ||
            isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
            isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod) ||
            isLPE(props.auctionsMy.sellingMethod)
            || isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)
            || isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod)
            || isLRE(props.auctionsMy.sellingMethod) || isLSE(props.auctionsMy.sellingMethod) || isLSP(props.auctionsMy.sellingMethod)
        ){
            tmp = <div>
                <div className={styles.mainWrap}>
                    <Sidebar />
                    <div className={styles.wrapBlock} >
                        <div className={styles.group}>
                            <WindowAuctionType typeElem={1} />
                        </div>
                        <div className={styles.bottonBlock}>
                            {createNew(props)}
                        </div>
                        <div className={styles.bottonBlock}>
                            <ReturnButton />
                        </div>
                        <div className={styles.helper}>* Поля обов'язкові для заповнення</div>
                    </div>
                </div>
            </div>
        }
        else{
            let minStep = null;
            let minStepAmo = null;
            if(props.auctionsMy.hasOwnProperty('minimalStep') === true){
                if(props.auctionsMy.minimalStep !== null){
                    if(props.auctionsMy.minimalStep.hasOwnProperty("currency") === true){
                        if(props.auctionsMy.minimalStep.currency !== null){
                            minStep = props.auctionsMy.minimalStep.currency
                        }
                    }
                    if(props.auctionsMy.minimalStep.hasOwnProperty("amount") === true){
                        if(props.auctionsMy.minimalStep.amount !== null){
                            minStepAmo = props.auctionsMy.minimalStep.amount
                        }
                    }
                }
            }

            if(props.auctionsMy.hasOwnProperty('bankAccounts') === true){
                if(props.auctionsMy.bankAccounts.length !== null){
                    if(props.auctionsMy.bankAccounts.length > 0){
                        props.setPopBankAccountId(props.auctionsMy.bankAccounts[0].id)
                    }
                }
            }

            tmp = <div>
                {/*texpRegistBlok*/}
                <div className={styles.mainWrap}>
                    <Sidebar />
                    <div className={styles.wrapBlock} >
                        {viewIdElements(props)}
                        <div className={styles.group}>
                            <WindowAuctionType typeElem={1} />
                            <WindowAuctionSelfNumber typeElem={1} />
                            <WindowAuctionTitle typeElem={1} />
                            <WindowAuctionDescription typeElem={1} />
                            <div className={styles.groupElem}>
                                <WindowAuctionTenderAttempt typeElem={1} />
                            </div>
                            <div className={styles.groupElem}>
                                <MartialLawDisclaimer />
                            </div>
                            <div className={styles.groupElem}>
                                <WindowAuctionAccessDetails typeElem={1} />
                            </div>
                            <div className={styles.groupElem}>
                                <WindowAuctionDocumentRequirements typeElem={1} />
                            </div>
                            <div className={styles.groupElem}>
                                <WindowAuctionAdditionalInformation typeElem={1} />
                            </div>
                            <div>
                                {viewMinNumberOfQualifiedBids(props, 1)}
                            </div>
                            {viewRailWayCargoDutch(props, 1)}
                            <IsPerishable />
                        </div>

                        {/*viewMinNumberOfQualifiedBids(props)*/}
                        <WindowAuctionCost typeElem={1} />
                        <ValueAddedTaxCharged />
                        <WindowAuctionGaranty typeElem={1} />
                        <WindowAuctionMinimalStep typeElem={1} />
                        {tmpRegistrationFee}
                        {tmpLandRentalLeaseType}
                        <WindowAuctionDiscount typeElem={1} />
                        <div className={styles.group}>
                            {closeClock(props)}
                    {viewSubsoil(props)}
                    {viewLegitimatePropertyLease(props)}
                    {viewWagonSelected(props)}
                    {bankAccountView(props, tmpBank, tmpBankSelect)}
                </div>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    {createOrEdit(props, minStep, minStepAmo)}
                </div>
                <div className={styles.bottonBlock}>
                    <ReturnButton />
                </div>
                <div className={styles.helper}>* Поля обов'язкові для заповнення</div>
            </div>
        }
    }else if(props.auctionsMy !== null && props.auctionsSwitchWindow === 3){
        if(
            isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
            isALE(props.auctionsMy.sellingMethod) ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||

            isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod) ||
            isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod) ||
            isBRW(props.auctionsMy.sellingMethod) ||
            isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod) ||
            isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
            isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod) ||
            isLPE(props.auctionsMy.sellingMethod)
            || isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)
            || isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod)
        ){
            tmp = <div>
                <div className={styles.mainWrap}>
                    <Sidebar />
                    <div className={styles.wrapBlock} >
                        <div className={styles.group}>
                            <WindowAuctionType typeElem={1} />
                        </div>
                        <div className={styles.bottonBlock}>
                            {createNew(props)}
                        </div>
                        <div className={styles.bottonBlock}>
                            <ReturnButton />
                        </div>
                        <div className={styles.helper}>* Поля обов'язкові для заповнення</div>
                    </div>
                </div>
            </div>
        } else {
            let minStep = null;
            let minStepAmo = null;
            if(props.auctionsMy.minimalStep !== null){
                if(props.auctionsMy.minimalStep.currency !== null){
                    minStep = props.auctionsMy.minimalStep.currency
                }
                if(props.auctionsMy.minimalStep.amount !== null){
                    minStepAmo = props.auctionsMy.minimalStep.amount
                }
            }

            if(props.auctionsMy.bankAccounts.length > 0){
                props.setPopBankAccountId(props.auctionsMy.bankAccounts[0].id)
            }
            tmp = <div>
                {/*texpRegistBlok*/}
                <div className={styles.mainWrap}>
                    <Sidebar />
                    <div className={styles.wrapBlock} >
                        {viewIdElements(props)}
                        <div className={styles.group}>
                            <WindowAuctionTitle typeElem={2}/>
                            <WindowAuctionSelfNumber typeElem={2} />
                            <WindowAuctionDescription typeElem={2} />
                            <div className={styles.groupElem}>
                                <WindowAuctionTenderAttempt typeElem={2} />
                            </div>
                            <div className={styles.groupElem}>
                                <MartialLawDisclaimer />
                            </div>
                            <div className={styles.groupElem}>
                                <WindowAuctionAccessDetails typeElem={2} />
                            </div>
                            <div className={styles.groupElem}>
                                <WindowAuctionDocumentRequirements typeElem={2} />
                            </div>
                            <div className={styles.groupElem}>
                                <WindowAuctionAdditionalInformation typeElem={2} />
                            </div>
                            <div className={styles.groupElem}>
                                <WindowAuctionType typeElem={2} />
                            </div>
                            <div>
                                {viewMinNumberOfQualifiedBids(props, 2)}
                            </div>
                            {viewRailWayCargoDutch(props, 2)}
                            <IsPerishable />
                        </div>
                        {/*viewMinNumberOfQualifiedBids(props)*/}
                        <WindowAuctionCost typeElem={2} />
                        <ValueAddedTaxCharged />
                        <WindowAuctionGaranty typeElem={2} />
                        <WindowAuctionMinimalStep typeElem={2} />
                        {tmpRegistrationFee}
                        {tmpLandRentalLeaseType}
                        <WindowAuctionDiscount typeElem={2} />
                        <div className={styles.group}>
                            {closeClock(props)}
                        {viewSubsoil(props)}
                        {viewLegitimatePropertyLease(props)}
                        {viewWagonSelected(props)}
                        {bankAccountView(props, tmpBank, tmpBankSelect)}
                    </div>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    {createOrEdit(props, minStep, minStepAmo)}
                </div>
                <div className={styles.bottonBlock}>
                    <Link
                        to="/create_auctions"
                        className={styles.buttons}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsSwitchWindow(0)
                                props.changeCreateAuctionLotIdentifier('')
                                props.changeCreateAuctionTitle('')
                                props.changeCreateAuctionDescription('')
                                props.changeCreateAuctionTenderAttempts('')
                                props.changeCreateAuctionAccessDetails('')
                                props.changeCreateAuctionXDocumentRequirements('')
                                props.changeCreateAuctionXAdditionalInformation('')
                                props.changeCreateAuctionType('null')
                                props.changeCreateAuctionValueCurrency('null')
                                props.changeCreateAuctionValueAmount('')
                                props.changeCreateAuctionGuaranteeCurrency('null')
                                props.changeCreateAuctionGuaranteeAmount('')
                                props.changeCreateAuctionMinimalStepCurrency('null')
                                props.changeCreateAuctionMinimalStepAmount('')
                                props.changeCreateAuctionDateStart('')
                                props.setPopBankAccountId('null')
                                props.setPopBankAccountIdArr(null)
                                props.getMyAuction(null)
                            }
                        }
                    >
                        Повернутися до списку аукціонів
                    </Link>
                </div>
                <div>* Поля обов'язкові для заповнення</div>
            </div>
        }
    }else{
        if(
            isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
            isALE(props.auctionsMy.sellingMethod) ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||

            isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod) ||
            isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod) ||
            isBRW(props.auctionsMy.sellingMethod) ||
            isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod) ||
            isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
            isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod) ||
            isLPE(props.auctionsMy.sellingMethod)
            || isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)
            || isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod)
            || isLRE(props.auctionsMy.sellingMethod) || isLSE(props.auctionsMy.sellingMethod) || isLSP(props.auctionsMy.sellingMethod)
            || props.auctionsMy.sellingMethod.startsWith("renewables-multiAwards")
        ){
            tmp = <div>
                <div className={styles.mainWrap}>
                    <Sidebar />
                    <div className={styles.wrapBlock} >
                        <div className={styles.group}>
                            <WindowAuctionType typeElem={1} />
                        </div>
                        <div className={styles.bottonBlock}>
                            {createNew(props)}
                        </div>
                        <div className={styles.bottonBlock}>
                            <ReturnButton />
                        </div>
                        <div className={styles.helper}>* Поля обов'язкові для заповнення</div>
                    </div>
                </div>
            </div>
        }else{
            let minStep = null;
            let minStepAmo = null;
            if(props.auctionsMy !== null){
                if(props.auctionsMy.minimalStep !== null){
                    if(props.auctionsMy.minimalStep.currency !== null){
                        minStep = props.auctionsMy.minimalStep.currency
                    }
                    if(props.auctionsMy.minimalStep.amount !== null){
                        minStepAmo = props.auctionsMy.minimalStep.amount
                    }
                }
                tmp = <div>
                    {/*texpRegistBlok*/}
                    <div className={styles.mainWrap}>
                    <Sidebar />
                    <div className={styles.wrapBlock} >
                        <WindowAuctionType typeElem={1} />
                        <WindowAuctionSelfNumber typeElem={1} />
                        <WindowAuctionTitle typeElem={1} />
                        <WindowAuctionDescription typeElem={1} />
                        <WindowAuctionTenderAttempt typeElem={1} />
                        <MartialLawDisclaimer />
                        <WindowAuctionAccessDetails typeElem={1} />
                        <WindowAuctionDocumentRequirements typeElem={1} />
                        <WindowAuctionAdditionalInformation typeElem={1} />
                        {viewMinNumberOfQualifiedBids(props, 1)}
                        {viewRailWayCargoDutch(props, 1)}
                        <IsPerishable />
                        {/*viewMinNumberOfQualifiedBids(props)*/}
                        <div className={styles.costBlock}>
                            <WindowAuctionCost typeElem={1} />
                            <ValueAddedTaxCharged />
                            <WindowAuctionGaranty typeElem={1} />
                            <WindowAuctionMinimalStep typeElem={1} />
                            {tmpRegistrationFee}
                            {tmpLandRentalLeaseType}
                            <WindowAuctionDiscount typeElem={1} />
                        </div>
                        <div className={styles.startDate}>
                                {closeClock(props)}
                        </div>
                        {viewSubsoil(props)}
                        {viewLegitimatePropertyLease(props)}
                        {viewWagonSelected(props)}
                        {bankAccountView(props, tmpBank, tmpBankSelect)}
                        </div>
                    </div>
                    <div className={styles.bottonBlock}>
                        {createOrEdit(props, minStep, minStepAmo)}
                    </div>
                    <div className={styles.bottonBlock}>
                        <Link
                            to="/create_auctions"
                            className={styles.buttons}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    props.changeAuctionsSwitchWindow(0)
                                    props.changeCreateAuctionLotIdentifier('')
                                    props.changeCreateAuctionTitle('')
                                    props.changeCreateAuctionDescription('')
                                    props.changeCreateAuctionTenderAttempts('')
                                    props.changeCreateAuctionAccessDetails('')
                                    props.changeCreateAuctionXDocumentRequirements('')
                                    props.changeCreateAuctionXAdditionalInformation('')
                                    props.changeCreateAuctionType('null')
                                    props.changeCreateAuctionValueCurrency('null')
                                    props.changeCreateAuctionValueAmount('')
                                    props.changeCreateAuctionGuaranteeCurrency('null')
                                    props.changeCreateAuctionGuaranteeAmount('')
                                    props.changeCreateAuctionMinimalStepCurrency('null')
                                    props.changeCreateAuctionMinimalStepAmount('')
                                    props.changeCreateAuctionDateStart('')
                                    props.setPopBankAccountId('null')
                                    props.setPopBankAccountIdArr(null)
                                    props.getMyAuction(null)
                                }
                            }
                        >
                            Повернутися до списку аукціонів
                        </Link>
                    </div>
                    <div>* Поля обов'язкові для заповнення</div>
                </div>
            }else{
                tmp = <CreateAuctionLoader />
            }
        }
    }
    return tmp
}

function closeClock(props){
    if(props.auctionsMy.isPerishable === true || props.auctionsMy.isPerishable === 'true'){
        return (
            <>
            <div className={styles.costBlockTitle}>Дата аукціону</div>
            {dateStartAuction(props, 'disabled')}
            </>
        )
    }
    return (
        <>
        <div className={styles.costBlockTitle}>Дата аукціону</div>
        {dateStartAuction(props, null)}
        </>
    )
}

function bankAccountView(props, tmpBank, tmpBankSelect){
    if(
        isBSD(props.auctionsMy.sellingMethod) || isBSE(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
        isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
        isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod)
    ){
        return <WindowAuctionBaseSellBankAccounts />
    }else if(
        props.auctionsMy.sellingMethod !== "subsoil-english" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
    ){
        return (
            <>
                <div className={styles.groupElem + ' ' + styles.bankAccountElements}>
                    <div className={styles.bankAccountTitle}>Банківські реквізити</div>
                    {tmpBank}
                </div>
                <div className={styles.groupElem}>
                    <div>
                        {tmpBankSelect}
                        <div className={styles.bottonBlock}>
                            <Button
                                disabled={elementDisabledCreate(props)}
                                className={styles.buttonExitDocument}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.openBankWindows(true)
                                    }
                                }
                            >
                                Створити та редагувати банківський рахунок
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return null
}

function zuDutchStep(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-auction-prod" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-qualification" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-qualification-prod"
    ){
        return <WindowAuctionDutchSteps />
    }
}

function viewDgf(props, type){
    switch(props.auctionsMy.sellingMethod){
    }
}

function viewMinNumberOfQualifiedBids(props, type){
    switch(props.auctionsMy.sellingMethod){
        case props.auctionsMy.sellingMethod.startsWith("dgf-english"):
        case props.auctionsMy.sellingMethod.startsWith("dgf-dutch"):
            return <WindowAuctionDgf typeElem={type} />
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
            return <>
                {zuDutchStep(props)}
                <WindowAuctionZU typeElem={type} />
                </>
        case props.auctionsMy.sellingMethod.startsWith("landRental-english"):
        case props.auctionsMy.sellingMethod.startsWith("landSell-english"):
            return null
        case props.auctionsMy.sellingMethod.startsWith("landSell-priorityEnglish"):
            return <LandRental typeElem={type} />

        case props.auctionsMy.sellingMethod.startsWith("basicSell-english"):
        case props.auctionsMy.sellingMethod.startsWith("alienation-english"):
        case props.auctionsMy.sellingMethod.startsWith("commercialSell-english"):
        case props.auctionsMy.sellingMethod.startsWith("bankRuptcy-english"):
        case props.auctionsMy.sellingMethod.startsWith("nonperformingLoans-english"):
            return <MinNumberOfQualifiedBids typeElem={type} />
        case props.auctionsMy.sellingMethod.startsWith("basicSell-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("commercialSell-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("bankRuptcy-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("nonperformingLoans-dutch"):
            return  zuDutchStep(props)
        case props.auctionsMy.sellingMethod.startsWith("arrestedAssets-english"):
            return <>
                <MinNumberOfQualifiedBids typeElem={type} />
                {zuDutchStep(props)}
            </>

        default:
            return null
    }
}

function viewRailWayCargoDutch(props){
    return <RailWayCargoDutchMinNumberOfQualifiedBids />
}

function viewSubsoil(props){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            tmp = <>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory + " " + styles.groupElemSue}>
                    <div className={styles.sue}>
                        <TextField
                            disabled={disabletElem(props.auctionsMy, '01')}
                            label="Вартість геологічної інформації"
                            value={viewGeologivDataValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemGeologivDataValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            //helperText="Вартість геологічної інформації"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory + " " + styles.groupElemSue}>
                    <div className={styles.sue}>
                        <TextField
                            disabled={disabletElem(props.auctionsMy, '01')}
                            label="Вартість пакета аукціонної документації, що сплачується переможцем"
                            value={viewDocumentationValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemDocumentationValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            //helperText="Вартість пакета аукціонної документації, що сплачується переможцем"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    Строк, на який надається дозвіл
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={disabletElem(props.auctionsMy, '00')}
                            label="Років"
                            type="number"
                            value={handleViewDate(props, 1)}
                            onChange={(event) => {
                                handleDate(props, event.target.value, 1)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Років"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            disabled={disabletElem(props.auctionsMy, '00')}
                            label="Місяців"
                            type="number"
                            value={handleViewDate(props, 2)}
                            onChange={(event) => {
                                if(Number(event.target.value) < 13){
                                    handleDate(props, event.target.value, 2)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Місяців"
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
            break;
        default:
            break;
    }
    return tmp
}

function viewLegitimatePropertyLease(props){
    return <LegitimatePropertyLeaseLeaseDuration />
}

function handleViewDate(props, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(tmpArr1[0] !== '0'){
                tmp = tmpArr1[0]
            }else{
                tmp = ''
            }
        }else if(type === 2){
            if(tmpArr2[0] !== '0'){
                tmp = tmpArr2[0]
            }else{
                tmp = ''
            }
        }else if(type === 3){
            if(tmpArr3[0] !== '0'){
                tmp = tmpArr3[0]
            }else{
                tmp = ''
            }
        }
    }else{
        if(type === 1){
            tmp = ``
        }else if(type === 2){
            tmp = ``
        }else if(type === 3){
            tmp = ``
        }
    }
    return tmp
}

function handleDate(props, date, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(date === ''){
                tmp = `P0Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }else{
                tmp = `P${date}Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }
        }else if(type === 2){
            tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y0M${tmpArr3[0]}D`
            }else{
                tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            }
        }
        else if(type === 3){
            tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M0D`
            }else{
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            }
        }
    }else{
        if(type === 1){
            tmp = `P${date}Y0M0D`
        }else if(type === 2){
            tmp = `P0Y${date}M0D`
        }else if(type === 3){
            tmp = `P0Y0M${date}D`
        }

    }
    props.changeCreateItemDateUse(tmp)
}

function viewGeologivDataValue(props){
    let tmp = null;
    if(props.auctionsMy.geologicDataValue !== null){
        tmp = props.auctionsMy.geologicDataValue.amount
    }
    return tmp
}

function viewDocumentationValue(props){
    let tmp = null;
    if(props.auctionsMy.documentationValue !== null){
        tmp = props.auctionsMy.documentationValue.amount
    }
    return tmp
}

function dateStartAuction1(props){
    let tmp = null;
    if(props.auctionsMy.hasOwnProperty('dateUses') !== false){
        if(props.auctionsMy.dateUses !== null){
            tmp = props.auctionsMy.dateUses.slice(0, 16)
        }
    }
    return tmp
}

function viewIdElements(props){
    let tmp = null;
    let tmpIdAuction = null;
    let tmpIdProcedure = null;
    if(props.auctionsMy){
        if(props.auctionsMy.auctionId !== null){
            tmpIdAuction = <div>ID аукціону Prozorro: <span>{props.auctionsMy.auctionId}</span></div>
        }
        if(props.auctionsMy.procedureId !== null){
            tmpIdProcedure = <div>ID процедури: <span>{props.auctionsMy.procedureId}</span></div>
        }
        tmp = <div>
            {tmpIdAuction}
            {tmpIdProcedure}
        </div>
    }
    return tmp
}

function viewWagonSelected(props){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            tmp = <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabletElem(props.auctionsMy, '01')}
                            id="outlined-select-currency-native"
                            select
                            label="Тип аукціону"
                            value={props.auctionsMy.carryType}
                            onChange={(event)=>{
                                props.setCarryType(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати тип аукціону"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            {wagonTypeOption(props)}
                        </TextField>
                    </div>
                </div>
            break;
        default:
            break;
    }
    return tmp
}

function wagonTypeOption(props){
    let tmp = null;
    let tmpArr = null;
    if(props.carryType !== null){
        tmpArr = props.carryType.map(
            (i) => {
                //return <option value={i.classificationId}>{i.description}</option>
                return <option value={i.name}>{i.description.uk_UA}</option>
            }
        )
    }
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            tmp = tmpArr
            break;
        default:
            break;
    }
    return tmp
}

function addArrayBankAccounts(props, elem){
    let tmp = props.bankAccountIdArr;
    if(tmp !== null){
        if(tmp.length > 0){
            if(tmp.includes(Number(elem)) !== true){
                tmp.push(Number(elem))
                props.setPopBankAccountIdArr(tmp)
            }
        }else{
            tmp.push(Number(elem))
            props.setPopBankAccountIdArr(tmp)
        }
    }else{
        tmp = []
        tmp.push(Number(elem))
        props.setPopBankAccountIdArr(tmp)
    }
}

function removeArrayBankAccounts(props, elem){
    let tmp = props.bankAccountIdArr;
    let index = tmp.indexOf(Number(elem));
    if(tmp.length === 1){
        props.setPopBankAccountIdArr(null)
    }else{
        if(index > -1){
            tmp.splice(index, 1);
            props.setPopBankAccountIdArr(tmp)
        }
    }
}

/*function allBanckAccountProduct(props){
    let tmp = null;
    if(props.bankAccount !== null && props.bankAccountIdArr !== null){
        tmp = props.bankAccount
        .filter(
            (i) => {
                return props.bankAccountIdArr.includes(Number(i.id)) === true
            }
        )
        .map(
            (i) => {
                return (
                    <div className={styles.bankAccountElement}>
                        <div>
                            <div>Назва банку: {i.bankName}</div>
                            <div>Опис банку: {i.description}</div>
                        </div>
                        <div>
                            <div>Опис банківського аккаунту: {i.accountIdentifications[0].description}</div>
                            <div>Ідінтіфікатор банківського аккаунту: {i.accountIdentifications[0].identifier}</div>
                            <div>Схема банківського аккаунту: {i.accountIdentifications[0].scheme}</div>
                        </div>
                        <div
                            onClick={
                                ()=>{
                                    //editBankAccounts(props, i)
                                }
                            }
                        >Редагувати</div>
                        <div
                            onClick={
                                ()=>{
                                    removeArrayBankAccounts(props, i.id)
                                }
                            }
                        >Вилучити</div>
                    </div>
                )
            }
        )
    }else{
        tmp = <div>Підключені банки відсутні</div>
    }
    return tmp
}*/

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function selectBankAuction(props){
    let tmp = null;
    if(props.bankAccount !== null){
        tmp = props.bankAccount
        .map(
            (i, count) => {
            return <option key={count+1} value={i.id}>{i.bankName}</option>
            }
        )
    }
    return tmp
}

function dateStartAuction(props, onOff){
    let tmp = null;
    if(onOff === null){
        if(props.auctionsMy.procedureId === null){
            tmp = <InputDate
                    data={{
                        target: props.auctionsMy.startDate,
                        time: true
                    }}
                    onChange={(e) => {
                                    props.changeCreateAuctionDateStart(e)
                    }}
                    />
        //     <KeyboardDateTimePicker
        //     error = {redBlock(props, props.auctionsMy.startDate)}
        //     disabled={disabletElem(props.auctionsMy, '10')}
        //     className={styles.dateSearch}
        //     value={props.auctionsMy.startDate}
        //     onChange={
        //         (e) => {
        //             props.changeCreateAuctionDateStart(e)
        //         }
        //     }
        //     onError={console.log}
        //     minDate={new Date("1900-01-01T00:00")}
        //     ampm={false}
        //     format="yyyy/MM/dd HH:mm"
        //     helperText="Формат дати: гггг/мм/дд чч:мм"
        // />
        }else{
            tmp = <InputDate
            data={{
                target: props.auctionsMy.startDate,
                time: true
            }}
            onChange={(e) => {
                            props.changeCreateAuctionDateStart(e)
            }}
            />
        //     <KeyboardDateTimePicker
        //     error = {redBlock(props, props.auctionsMy.startDate)}
        //     disabled={disabletElem(props.auctionsMy, '10')}
        //     className={styles.dateSearch}
        //     value={props.auctionsMy.startDate}
        //     onChange={
        //         (e) => {
        //             props.changeCreateAuctionDateStart(e)
        //         }
        //     }
        //     onError={console.log}
        //     minDate={new Date("1900-01-01T00:00")}
        //     ampm={false}
        //     format="yyyy/MM/dd HH:mm"
        // />
        }

        if(props.auctionsSwitchWindow === 3){
            tmp = <InputDate
            data={{
                target: props.auctionsMy.startDate,
                time: true
            }}
            onChange={(e) => {
                            props.changeCreateAuctionDateStart(e)
            }}
            />
        //     <KeyboardDateTimePicker
        //     error = {redBlock(props, props.auctionsMy.startDate)}
        //     disabled={disabletElem(props.auctionsMy, '10')}
        //     className={styles.dateSearch}
        //     value={props.auctionsMy.startDate}
        //     onChange={
        //         (e) => {
        //             props.changeCreateAuctionDateStart(e)
        //         }
        //     }
        //     onError={console.log}
        //     minDate={new Date("1900-01-01T00:00")}
        //     ampm={false}
        //     format="yyyy/MM/dd HH:mm"
        // />
        }
    }else{
        tmp = <>
                <InputDate
                    data={{
                        target: props.auctionsMy.startDate,
                        time: true
                    }}
                    onChange={(e) => {
                                    props.changeCreateAuctionDateStart(e)
                    }}
                    disabled='disabled'
                />
                {/* <KeyboardDateTimePicker
                    error = {redBlock(props, props.auctionsMy.startDate)}
                    disabled='disabled'
                    className={styles.dateSearch}
                    value={props.auctionsMy.startDate}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionDateStart(e)
                        }
                    }
                    onError={console.log}
                    minDate={new Date("1900-01-01T00:00")}
                    ampm={false}
                    format="yyyy/MM/dd HH:mm"
                /> */}
            <div>Дата аукціону буде запланована автоматично: через 1 повний робочий день з моменту публікації</div>
        </>
    }
    return tmp
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
    }
    return null
}

function createNew(props){
    let tmp = null;
    if(props.auctionsMy.sellingMethod !== null && props.auctionsMy.sellingMethod !== 'null'){
        tmp = (
            <Button
                disabled={checkingFilledFields(props, onOffCurrentTenat)}
                variant="contained"
                color="primary"
                onClick={() => {createAucNew1(props)}}
            >
                Створити аукціон
            </Button>
        )
    }
    return tmp
}

function createOrEdit(props, minStep, minStepAmo){
    let tmp = null;
    if(props.auctionsSwitchWindow === 2 && (props.auctionsMy.sellingMethod !== null && props.auctionsMy.sellingMethod !== 'null')){
        tmp = (
            <Button
                disabled={checkingFilledFields(props, onOffCurrentTenat)}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        if(

                            props.auctionsMy.sellingMethod === 'alienation-english' ||
                            props.auctionsMy.sellingMethod === 'alienation-english-fast' ||
                            props.auctionsMy.sellingMethod === 'alienation-english-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'alienation-english-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'alienation-english-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification-prod' ||

                            props.auctionsMy.sellingMethod === 'commercialSell-english' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-english-fast' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-english-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-english-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-qualification-prod' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||

                            props.auctionsMy.sellingMethod === 'bankRuptcy-english' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-english-fast' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-english-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-english-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-qualification-prod' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod' ||
                            props.auctionsMy.sellingMethod === 'bankRuptcy-withoutAuction' ||


                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-ultra-fast' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-fast' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-fast-auction-prod' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-english-initial-qualification-prod' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-ultra-fast' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-fast' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-fast-auction-prod' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'regulationsPropertyLease-dutch-initial-qualification-prod' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-ultra-fast' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-fast' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-fast-auction-prod' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-english-initial-qualification-prod' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-ultra-fast' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-fast' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-fast-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-fast-auction-manual-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-fast-auction-prod' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-initial-auction' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-initial-auction-manual' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-initial-qualification' ||
                            props.auctionsMy.sellingMethod === 'commercialPropertyLease-dutch-initial-qualification-prod'
                        ){
                            createAucNew(props)
                        }else{
                            createAuc(props)
                        }
                    }
                }
            >
                Зберегти аукціон
            </Button>
        )
    }else if(props.auctionsSwitchWindow === 3){
        tmp = (
            <Button
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        cloneAuc(props)
                    }
                }
            >
                Копіювання аукціону
            </Button>
        )
    }else if(props.auctionsSwitchWindow === 1 && (props.auctionsMy.sellingMethod !== null && props.auctionsMy.sellingMethod !== 'null')){
        tmp = (
            <Button
                disabled={checkingFilledFields(props, onOffCurrentTenat)}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        editAuc(props)
                    }
                }
            >
                Зберегти зміни
            </Button>
        )
    }
    return tmp
}

async function createDiscountForAuction(props, auctionId){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    let tmpDiscount = null;
    if(props.auctionsMy.tenderAttempts > 1){
        if(
            props.auctionsMy.sellingMethod === 'basicSell-english' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-fast' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'alienation-english' ||
            props.auctionsMy.sellingMethod === 'alienation-english-fast' ||
            props.auctionsMy.sellingMethod === 'alienation-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english-fast' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'commercialSell-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english-fast' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-fast" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-fast-auction-prod" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-auction-prod" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-qualification" ||
            props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-qualification-prod"
        ){
            if(props.discount.previousAuctionId !== '' && (props.discount.discountPercent !== '' && props.discount.discountPercent !== null && props.discount.discountPercent !== 0 && props.discount.discountPercent !== '0')){
                let body = {}
                body.discount = true
                if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null){
                    body.discountPercent = props.discount.discountPercent
                }else{
                    body.discountPercent = '0'
                }
                if(props.discount.previousAuctionValue.amount !== '' && props.discount.previousAuctionValue.amount !== null){
                    body.previousAuctionValue = {}
                    body.previousAuctionValue.currency = 'UAH'
                    body.previousAuctionValue.amount = props.discount.previousAuctionValue.amount
                    if(props.discount.previousAuctionValue.valueAddedTaxIncluded !== '' && props.discount.previousAuctionValue.valueAddedTaxIncluded !== null){
                        if(props.discount.previousAuctionValue.valueAddedTaxIncluded === true || props.discount.previousAuctionValue.valueAddedTaxIncluded === 'true'){
                            body.previousAuctionValue.valueAddedTaxIncluded = true
                        }else{
                            body.previousAuctionValue.valueAddedTaxIncluded = false
                        }
                    }else{
                        body.previousAuctionValue.valueAddedTaxIncluded = true
                    }
                }
                const response = await fetch(endPoint, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response === undefined || response === 'undefined'){
                }else{
                    const json = await response.json()
                    if(json.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                        props.setUniversalError(tmp)
                    }else{
                        props.changeDiscountPreviousAuctionCurrency('null')
                        props.changeDiscountPreviousAuctionAmount('')
                        props.changeDiscountPercent('')
                        props.changeDiscountPreviousAuctionId('')
                        //props.discountAuc(false)
                    }
                }
            }
        }else if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            //if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
                //let tmpZUd = props.auctionsZUAttemp.value.amount / 2;
                let body = {}
                body.discount = true
                //body.discountPercent = '50'
                body.discountPercent = props.discount.discountPercent
                let tmpDisAmount = null
                if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                body.previousAuctionValue = {
                    currency: 'UAH',
                    //amount: tmpZUd,
                    amount: tmpDisAmount
                }
                const response = await fetch(endPoint, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response === undefined || response === 'undefined'){
                }else{
                    const json = await response.json()
                    if(json.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                        props.setUniversalError(tmp)
                    }else{
                        props.changeDiscountPreviousAuctionCurrency('null')
                        props.changeDiscountPreviousAuctionAmount('')
                        props.changeDiscountPercent('')
                        props.changeDiscountPreviousAuctionId('')
                        //props.discountAuc(false)
                    }
                }
            //}
        }else{
            if(props.discount.previousAuctionId !== ''){
                if(props.discount.discountPercent !== ''){
                    let body = null
                    if(props.discount.discountOnOff === false){
                        body = {
                            discount: false,
                        }
                    }else{
                        if(props.discount.discountPercent !== ''){
                            let tmpDisAmount = null
                            if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                            body = {
                                discount: true,
                                discountPercent: props.discount.discountPercent,
                                previousAuctionValue: {
                                    currency: 'UAH',
                                    amount: tmpDisAmount,
                                }
                            }
                        }else{
                            let tmpDisAmount = null
                            if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                            body = {
                                discount: true,
                                discountPercent: '0',
                                previousAuctionValue: {
                                    currency: 'UAH',
                                    amount: tmpDisAmount,
                                }
                            }
                        }
                    }
                    const response = await fetch(endPoint, {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response === undefined || response === 'undefined'){
                    }else{
                        const json = await response.json()
                        if(json.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                            props.setUniversalError(tmp)
                        }else{
                            props.changeDiscountPreviousAuctionCurrency('null')
                            props.changeDiscountPreviousAuctionAmount('')
                            props.changeDiscountPercent('')
                            props.changeDiscountPreviousAuctionId('')
                            //props.discountAuc(false)
                        }
                    }
                }
            }
        }
    }
}

async function editDiscountForAuction(props, auctionId){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    let tmpDiscount = null;
    if(props.auctionsMy.tenderAttempts > 1){
        if(props.discount.previousAuctionId !== ''){
            if(props.discount.discountPercent !== ''){
                let body = null
                if(props.discount.discountOnOff === false){
                    body = {
                        discount: false,
                    }
                }else{
                    if(props.discount.discountPercent !== ''){
                        let tmpDisAmount = null
                        if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                        body = {
                            discount: true,
                            discountPercent: props.discount.discountPercent,
                            previousAuctionValue: {
                                currency: 'UAH',
                                amount: tmpDisAmount,
                            }
                        }
                    }else{
                        let tmpDisAmount = null
                        if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                        body = {
                            discount: true,
                            discountPercent: '0',
                            previousAuctionValue: {
                                currency: 'UAH',
                                amount: tmpDisAmount,
                            }
                        }
                    }
                }
                const response = await fetch(endPoint, {
                    method: "PUT",
                    body: JSON.stringify(body),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response === undefined || response === 'undefined'){
                }else{
                    const json = await response.json()
                    if(json.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
	                    props.setUniversalError(tmp)
                    }else{
                        props.changeDiscountPreviousAuctionCurrency('null')
                        props.changeDiscountPreviousAuctionAmount('')
                        props.changeDiscountPercent('')
                        props.changeDiscountPreviousAuctionId('')
                        //props.discountAuc(false)
                    }
                }
            }
        }
    }
}

async function createAucNew1(props){
    let tmpBody = {}
    tmpBody = {
        sellingMethod: props.auctionsMy.sellingMethod
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)

    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            props.setDataAddress(json, 'POPUP_AUCTION_MONGA')
        }
    }
}


async function createAucNew(props){
    let tmpBody = {}
    let bodyObj = {
        base: props.auctionsMy,
        bank: props.bankAccountWork,
        bankList: props.bankAccount,
        bankAccountZU: props.bankAccountZU,
        deleteBankGroops: props.deleteBankGroops,
        deleteCurentBank: props.deleteCurentBank,
        discount: props.discount,
    }
    tmpBody = createBody(bodyObj)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)

    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
            props.setDataAddress(json, 'POPUP_AUCTION_MONGA')
        }
    }
}


async function createAuc(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction`;
    let tmpPreviousAuctionId = null;
    if(props.auctionsMy.tenderAttempts !== 1){
        if(props.discount.previousAuctionId !== ''){
            tmpPreviousAuctionId = props.discount.previousAuctionId
        }
    }
    let tmpDocumentationValue = null;
    let tmpGeologivDataValue = null;
    let tmpGuarantee = null;
    let tmpMinStep = null;
    let tmpValue = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            tmpGuarantee = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'eurocent',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            tmpDocumentationValue = {currency: 'UAH', amount: props.auctionsMy.documentationValue.amount}
            tmpGeologivDataValue = {currency: 'UAH', amount: Number(props.auctionsMy.geologicDataValue.amount)}
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
                //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
                //valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded,
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
        case 'landRental-english':
        case 'landRental-english-fast':
        case 'landRental-english-fast-manual':
        case 'landRental-english-fast-auction-manual-qualification':
        case 'landRental-english-initial-auction':
        case 'landRental-english-initial-auction-manual':
        case 'landRental-english-initial-qualification':
        case 'landRental-english-initial-qualification-prod':
        case 'landSell-english':
        case 'landSell-english-fast':
        case 'landSell-english-fast-manual':
        case 'landSell-english-fast-auction-manual-qualification':
        case 'landSell-english-initial-auction':
        case 'landSell-english-initial-auction-manual':
        case 'landSell-english-initial-qualification':
        case 'landSell-english-initial-qualification-prod':
        case 'landSell-priorityEnglish':
        case 'landSell-priorityEnglish-fast':
        case 'landSell-priorityEnglish-fast-manual':
        case 'landSell-priorityEnglish-fast-auction-manual-qualification':
        case 'landSell-priorityEnglish-initial-auction':
        case 'landSell-priorityEnglish-initial-auction-manual':
        case 'landSell-priorityEnglish-initial-qualification':
        case 'landSell-priorityEnglish-initial-qualification-prod':
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
                //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
            }
            let tmpValueAddedTaxIncluded = null
            if(props.auctionsMy.value.valueAddedTaxIncluded === 'false'){
                tmpValueAddedTaxIncluded = false
            }else{
                tmpValueAddedTaxIncluded = true
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: tmpValueAddedTaxIncluded,
            }
            break
        default:
            tmpGuarantee = {
                currency: props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
    }

    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){

    }

    let tmpBank = null;
    if(props.bankAccountIdArr === null){
        tmpBank = []
    }else{
        tmpBank = props.bankAccountIdArr
    }
    let tmpDateDay = Date.parse(props.auctionsMy.startDate);
    let tmpMonth = null;
    if(new Date(tmpDateDay).getMonth() < 10){
        tmpMonth = '0' + (new Date(tmpDateDay).getMonth() + 1)
    }else{
        tmpMonth = new Date(tmpDateDay).getMonth() + 1
    }
    let tmpDay = null;
    if(new Date(tmpDateDay).getDate() < 10){
        tmpDay = '0' + new Date(tmpDateDay).getDate()
    }else{
        tmpDay = new Date(tmpDateDay).getDate()
    }

    let tmpDateString = new Date(tmpDateDay).getFullYear() + '-' + tmpMonth + '-' + tmpDay
    let minNumberOfQualifiedBids = returnDataRailway(props)
    if(minNumberOfQualifiedBids === 'null') minNumberOfQualifiedBids = null

    let tmpBody = {}

    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.auctionsMy.hasOwnProperty('currentTenantGuaranteeFee') === true){
            if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
                tmpBody.currentTenantGuaranteeFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
                }
            }
        }
        if(tmpBody.hasOwnProperty('currentTenantGuaranteeFee') !== true) tmpBody.currentTenantGuaranteeFee = null
    }

    tmpBody.documentationValue = tmpDocumentationValue
    tmpBody.geologicDataValue = tmpGeologivDataValue
    tmpBody.leaseDuration = props.auctionsMy.leaseDuration
    if(
        props.auctionsMy.sellingMethod !== 'landRental-english' &&
        props.auctionsMy.sellingMethod !== 'landRental-english-fast' &&
        props.auctionsMy.sellingMethod !== 'landRental-english-fast-manual' &&
        props.auctionsMy.sellingMethod !== 'landRental-english-fast-auction-manual-qualification' &&
        props.auctionsMy.sellingMethod !== 'landRental-english-initial-auction' &&
        props.auctionsMy.sellingMethod !== 'landRental-english-initial-auction-manual' &&
        props.auctionsMy.sellingMethod !== 'landRental-english-initial-qualification' &&
        props.auctionsMy.sellingMethod !== 'landRental-english-initial-qualification-prod' &&
        props.auctionsMy.sellingMethod !== 'landSell-english' &&
        props.auctionsMy.sellingMethod !== 'landSell-english-fast' &&
        props.auctionsMy.sellingMethod !== 'landSell-english-fast-manual' &&
        props.auctionsMy.sellingMethod !== 'landSell-english-fast-auction-manual-qualification' &&
        props.auctionsMy.sellingMethod !== 'landSell-english-initial-auction' &&
        props.auctionsMy.sellingMethod !== 'landSell-english-initial-auction-manual' &&
        props.auctionsMy.sellingMethod !== 'landSell-english-initial-qualification' &&
        props.auctionsMy.sellingMethod !== 'landSell-english-initial-qualification-prod' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish-fast' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish-fast-manual' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish-fast-auction-manual-qualification' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish-initial-auction' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish-initial-auction-manual' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish-initial-qualification' &&
        props.auctionsMy.sellingMethod !== 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        tmpBody.carryTypeId = props.auctionsMy.carryType
    }
    //tmpBody.carryType = props.auctionsMy.carryType.name

    tmpBody.previousAuctionId = tmpPreviousAuctionId
    tmpBody.title = props.auctionsMy.title
    tmpBody.description = props.auctionsMy.description
    if(
        props.auctionsMy.sellingMethod !== "subsoil-english" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
    ){
        tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
    }
    tmpBody.sellingMethod = props.auctionsMy.sellingMethod
    tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
    if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
        tmpBody.accessDetails = props.auctionsMy.accessDetails
    }
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
        //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
    }else{
        tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
        if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
            tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
        }
    }

    if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
        tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
    }
    tmpBody.value = tmpValue
    tmpBody.guarantee = tmpGuarantee
    tmpBody.minimalStep = tmpMinStep
    tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
    let tmpOldAuctionStartDate = props.auctionsMy.startDate
    if(
        tmpOldAuctionStartDate !== null &&
        tmpOldAuctionStartDate !== ''
    ){
        tmpOldAuctionStartDate = new Date(tmpOldAuctionStartDate)
        if(Date.now() < tmpOldAuctionStartDate.getTime()){
            tmpBody.startDate = props.auctionsMy.startDate
        }
    }
    tmpBody.documents = props.auctionsMy.documents

    switch(true){
        case props.auctionsMy.sellingMethod.includes('dgf-english'):
        case props.auctionsMy.sellingMethod.includes('dgf-dutch'):
            tmpBody.minNumberOfQualifiedBids = props.auctionsMy.minNumberOfQualifiedBids
            tmpBody.virtualDataRoom = props.auctionsMy.virtualDataRoom
            tmpBody.eligibilityCriteria = props.auctionsMy.eligibilityCriteria
            tmpBody.decision = {
                decisionId: props.auctionsMy.decision.decisionId,
                decisionDate: props.auctionsMy.decision.decisionDate
            }
            tmpBody.x_dgfPlatformLegalDetails = props.auctionsMy.x_dgfPlatformLegalDetails
            break;
        case props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-english'):
        case props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-dutch'):
        case props.auctionsMy.sellingMethod.includes('priorityEnglish-dutch'):
            if(Number(props.auctionsMy.minNumberOfQualifiedBids) === 1 || Number(props.auctionsMy.minNumberOfQualifiedBids) === 2){
                tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            }else{
                tmpBody.minNumberOfQualifiedBids = 1
            }
            tmpBody.calcOnBookValue = props.auctionsMy.calcOnBookValue
            tmpBody.valueAddedTaxCharged = props.auctionsMy.valueAddedTaxCharged
            tmpBody.registryId = props.auctionsMy.registryId
            /*if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    //valueAddedTaxIncluded: true
                }
            }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    //valueAddedTaxIncluded: false
                }
            }else{*/
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                }
            //}
            tmpBody.valuePeriod = props.auctionsMy.valuePeriod
            //tmpBody.xDocumentRequirements = ' ' //Єту строку после фикса от Сереги отключить
            break;
        case props.auctionsMy.sellingMethod.includes('landRental-english'):
        case props.auctionsMy.sellingMethod.includes('landSell-english'):
        case props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish'):
            if(props.auctionsMy.xDgfPlatformLegalDetails !== null && props.auctionsMy.xDgfPlatformLegalDetails !== '') tmpBody.xDgfPlatformLegalDetails = props.auctionsMy.xDgfPlatformLegalDetails
            if(props.auctionsMy.registrationFee !== null){
                if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                    if(props.auctionsMy.registrationFee.amount !== null && props.auctionsMy.registrationFee.amount !== ''){
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                            valueAddedTaxIncluded: true
                        }
                    }else{
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: 0.01,
                            valueAddedTaxIncluded: true
                        }
                    }
                }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                    if(props.auctionsMy.registrationFee.amount !== null && props.auctionsMy.registrationFee.amount !== ''){
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                            valueAddedTaxIncluded: false
                        }
                    }else{
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: 0.01,
                            valueAddedTaxIncluded: false
                        }
                    }
                }else{
                    if(props.auctionsMy.registrationFee.amount !== null && props.auctionsMy.registrationFee.amount !== ''){
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                        }
                    }else{
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: 0.01,
                            valueAddedTaxIncluded: false
                        }
                    }
                }
            }
            if(
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish'
                || props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast'
                || props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual'
                || props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification'
                || props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction'
                || props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual'
                || props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification'
                || props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
            ){

                if(props.auctionsMy.hasOwnProperty("numberOfCurrentTenants") === true && props.auctionsMy.numberOfCurrentTenants !== 'null' && props.auctionsMy.numberOfCurrentTenants !== null){

                    tmpBody.numberOfCurrentTenants = props.auctionsMy.numberOfCurrentTenants
                    //tmpBody.currentTenants = props.auctionsMy.currentTenant
                    if(props.auctionsMy.hasOwnProperty("currentTenant") === true  && props.auctionsMy.currentTenant !== 0){
                        tmpBody.currentTenants = props.auctionsMy.currentTenant.currentTenants
                    }else{
                        tmpBody.currentTenants = null
                    }
                }else{
                    tmpBody.numberOfCurrentTenants = 1
                    ///tmpBody.currentTenants = props.auctionsMy.currentTenant
                    if(props.auctionsMy.hasOwnProperty("currentTenant") === true && props.auctionsMy.currentTenant !== 0){
                        tmpBody.currentTenants = props.auctionsMy.currentTenant.currentTenants
                    }else{
                        tmpBody.currentTenants = null
                    }
                }
            }
            if(props.auctionsMy.leaseType !== 'null' && props.auctionsMy.leaseType !== null){
                if(props.auctionsMy.leaseType === 'saleOrLeaseType'){
                    tmpBody.leaseType = 'landRental'
                }else{
                    if(
                        props.auctionsMy.sellingMethod === 'landRental-english' ||
                        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
                        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
                        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
                        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
                        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
                        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod'
                    ){
                        tmpBody.leaseType = 'landRental'
                    }
                }
            }
            if(props.auctionsMy.saleType !== 'null' && props.auctionsMy.saleType !== null){
                tmpBody.saleType = props.auctionsMy.saleType
            }
            if(props.auctionsMy.hasOwnProperty('normativeMonetaryValuation') === true){
                if(props.auctionsMy.normativeMonetaryValuation !== null){
                    if(props.auctionsMy.normativeMonetaryValuation.amount !== null && props.auctionsMy.normativeMonetaryValuation.amount !== ''){
                        tmpBody.normativeMonetaryValuation = {
                            current: 'UAH',
                            amount: Number(props.auctionsMy.normativeMonetaryValuation.amount)
                        }
                    }
                }
            }
            if(tmpBody.hasOwnProperty('normativeMonetaryValuation') !== true) tmpBody.normativeMonetaryValuation = null

            if(props.auctionsMy.hasOwnProperty('expertMonetaryValuation') === true){
                if(props.auctionsMy.expertMonetaryValuation !== null){
                    if(props.auctionsMy.expertMonetaryValuation.amount !== null && props.auctionsMy.expertMonetaryValuation.amount !== ''){
                        tmpBody.expertMonetaryValuation = {
                            current: 'UAH',
                            amount: Number(props.auctionsMy.expertMonetaryValuation.amount)
                        }
                    }
                }
            }
            if(tmpBody.hasOwnProperty('expertMonetaryValuation') !== true) tmpBody.expertMonetaryValuation = null

            if(props.auctionsMy.hasOwnProperty('budgetSpent') === true){
                if(props.auctionsMy.budgetSpent !== null){
                    if(props.auctionsMy.budgetSpent.amount !== null && props.auctionsMy.budgetSpent.amount !== ''){
                        tmpBody.budgetSpent = {
                            current: 'UAH',
                            amount: Number(props.auctionsMy.budgetSpent.amount)
                        }
                    }
                }
            }
            if(tmpBody.hasOwnProperty('budgetSpent') !== true) tmpBody.budgetSpent = null

            if(props.auctionsMy.conditions !== 'null' && props.auctionsMy.conditions !== null && props.auctionsMy.conditions !== ''){
                tmpBody.conditions = props.auctionsMy.conditions
            }
            if(props.auctionsMy.restrictions !== 'null' && props.auctionsMy.restrictions !== null && props.auctionsMy.restrictions !== ''){
                tmpBody.restrictions = props.auctionsMy.restrictions
            }
            break;
        default:
            break;
    }

    if(isSUE(props.auctionsMy.sellingMethod) || isSUD(props.auctionsMy.sellingMethod)) delete tmpBody.xAdditionalInformation
    //if(isSUE(props.auctionsMy.sellingMethod) || isSUD(props.auctionsMy.sellingMethod)) delete tmpBody.xDocumentRequirements

    //TODO: Подключаю новую сборку боди
    let tmpBody2 = {}
    if(
        isTIE(props.auctionsMy.sellingMethod) ||
        isSUE(props.auctionsMy.sellingMethod) ||
        isSUD(props.auctionsMy.sellingMethod) ||
        isRCE(props.auctionsMy.sellingMethod) ||
        isRCD(props.auctionsMy.sellingMethod) ||
        isLRE(props.auctionsMy.sellingMethod) ||
        isLSE(props.auctionsMy.sellingMethod) ||
        isLSP(props.auctionsMy.sellingMethod) ||

        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) || isBRW(props.auctionsMy.sellingMethod) ||
        isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod) ||
        isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
        isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod) ||
        isLPE(props.auctionsMy.sellingMethod)
        || isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)
        || isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod)
    ){
        let bodyObj = {
            base: props.auctionsMy,
            bank: props.bankAccountWork,
            bankList: props.bankAccount,
            bankAccountZU: props.bankAccountZU,
            deleteBankGroops: props.deleteBankGroops,
            deleteCurentBank: props.deleteCurentBank,
            discount: props.discount,
        }
        // console.log(tmpBody)
        tmpBody2 = createBodyOld(bodyObj)
    }else{
        tmpBody2 = tmpBody
    }
    if (props.auctionsMy.sellingMethod.includes('railwayCargo-english') ||
        props.auctionsMy.sellingMethod.includes('railwayCargo-dutch')
    ) {
        tmpBody2.registrationFee = {
            currency: 'UAH',
            amount: Number(props.auctionsMy.registrationFee.amount),
        }
    }

    const response = await fetch(endPoint, {
        method: "POST",
        //timberEnglish
        body: JSON.stringify(tmpBody2),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)

        }else{
            if(
                props.auctionsMy.sellingMethod.startsWith('landRental-english') ||
                props.auctionsMy.sellingMethod.startsWith('landSell-english') ||
                props.auctionsMy.sellingMethod.startsWith('landSell-priorityEnglish') ||
                props.auctionsMy.sellingMethod.startsWith('basicSell-english') ||
                props.auctionsMy.sellingMethod.startsWith('basicSell-dutch') ||
                props.auctionsMy.sellingMethod.startsWith('alienation-english') ||
                props.auctionsMy.sellingMethod.startsWith('commercialSell-english') ||
                props.auctionsMy.sellingMethod.startsWith('commercialSell-dutch') ||
                props.auctionsMy.sellingMethod.startsWith('bankRuptcy-english') ||
                props.auctionsMy.sellingMethod.startsWith('bankRuptcy-dutch') ||
                props.auctionsMy.sellingMethod.startsWith('nonperformingLoans-english') ||
                props.auctionsMy.sellingMethod.startsWith('nonperformingLoans-dutch')
            ){
                createArrBanksLR(props, 2, json.id)
                if (props.auctionsMy.sellingMethod.includes('-dutch')) {
                    createDutch(props, json.id)
                }
            }
            if(
                props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english" )||
                props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch" )||
                props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish" )
            ){
                createAuctionLeaseRules(props, json.id)
            }
            if (props.auctionsMy.sellingMethod.startsWith("railwayCargo-dutch")) {
                if(
                    dutchData(props) !== false &&
                    !props.auctionsMy.sellingMethod .startsWith("legitimatePropertyLease-english") &&
                    !props.auctionsMy.sellingMethod .startsWith("legitimatePropertyLease-priorityEnglish")
                ){
                    let dutchStep = {}
                    dutchStep.dutchStepValue = {}
                    if(props.auctionsMy.dutchStep.dutchStepQuantity === 'null'){
                        dutchStep.dutchStepQuantity = 1
                    }else{
                        dutchStep.dutchStepQuantity = props.auctionsMy.dutchStep.dutchStepQuantity
                    }
                    dutchStep.dutchStepValue.currency = 'UAH'
                    if (props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch")) {
                        dutchStep.dutchStepPercent = 1
                        dutchStep.dutchStepValue.amount = props.auctionsMy.value.amount/100
                    }else{
                        dutchStep.dutchStepPercent = props.auctionsMy.dutchStep.dutchStepPercent
                        dutchStep.dutchStepValue.amount = props.auctionsMy.dutchStep.dutchStepValue.amount
                    }
                    const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${json.id}/dutch_step`
                    const response3 = await fetch(endPoint3, {
                        method: "POST",
                        body: JSON.stringify(dutchStep),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response3 === undefined || response3 === 'undefined'){
                    }else{
                        const json3 = await response3.json()
                        if(json3.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при створенні даних голандського аукціону', message: JSON.stringify(json3)})
                            props.setUniversalError(tmp)
                            //props.setPopUpAuctionCreate(1)
                        }else{
                            let tmpAucData = json
                            tmpAucData.dutchStep = json3
                            //props.setPopUpAuctionCreate(json)
                            props.setPopUpAuctionCreate(tmpAucData)
                            createDiscountForAuction(props, json.id)
                            props.changeDiscountPreviousAuctionCurrency('null')
                            props.changeDiscountPreviousAuctionAmount('')
                            props.changeDiscountPercent('')
                            props.changeDiscountPreviousAuctionId('')
                            props.discountAuc(false)
                        }
                    }
                }else{
                    props.setPopUpAuctionCreate(json)
                    createDiscountForAuction(props, json.id)
                    props.changeDiscountPreviousAuctionCurrency('null')
                    props.changeDiscountPreviousAuctionAmount('')
                    props.changeDiscountPercent('')
                    props.changeDiscountPreviousAuctionId('')
                    props.discountAuc(false)
                }
            }else{
                props.setPopUpAuctionCreate(json)
                createDiscountForAuction(props, json.id)
                props.changeDiscountPreviousAuctionCurrency('null')
                props.changeDiscountPreviousAuctionAmount('')
                props.changeDiscountPercent('')
                props.changeDiscountPreviousAuctionId('')
                props.discountAuc(false)
            }
        }
    }
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
}

async function createDutch(props, aucId){
    if(
        props.auctionsMy.sellingMethod.startsWith("basicSell-dutch") ||
        props.auctionsMy.sellingMethod.startsWith("commercialSell-dutch") ||
        props.auctionsMy.sellingMethod.startsWith("bankRuptcy-dutch") ||
        props.auctionsMy.sellingMethod.startsWith("nonperformingLoans-dutch")
    ){
        let dutchStep = {}
        dutchStep.dutchStepValue = {}
        if(props.auctionsMy.dutchStep !== null){
            if(props.auctionsMy.dutchStep.dutchStepQuantity === null || props.auctionsMy.dutchStep.dutchStepQuantity === 'null'){
                dutchStep.dutchStepQuantity = 1
            }else{
                dutchStep.dutchStepQuantity = props.auctionsMy.dutchStep.dutchStepQuantity
            }
        }
        dutchStep.dutchStepValue.currency = 'UAH'
        if(props.auctionsMy.dutchStep !== null && props.auctionsMy.dutchStep !== ''){
            dutchStep.dutchStepPercent = props.auctionsMy.dutchStep.dutchStepPercent
            if(props.auctionsMy.dutchStep.dutchStepValue !== null){
                dutchStep.dutchStepValue.amount = props.auctionsMy.dutchStep.dutchStepValue.amount
            }
        }
        const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/dutch_step`
        const response3 = await fetch(endPoint3, {
            method: "POST",
            body: JSON.stringify(dutchStep),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response3 === undefined || response3 === 'undefined'){
        }else{
            const json3 = await response3.json()
            if(json3.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json3)})
                props.setUniversalError(tmp)
            }else{

                //let tmpAucData = json
                //tmpAucData.dutchStep = json3
                //props.setPopUpAuctionCreate(tmpAucData)
            }
        }
    }
}

///////////////////////////////////////////////
///////////////////////////////////////////////
function createArrBanksLR(props, type, aucId){
    if(
        props.auctionsMy.sellingMethod.startsWith('landRental-english') ||
        props.auctionsMy.sellingMethod.startsWith('english-priorityEnglish')
    ){
        if(type === 2){
            createZUBanksLR(props, aucId)
        }
    }else if(
        props.auctionsMy.sellingMethod.startsWith('basicSell-english') ||
        props.auctionsMy.sellingMethod.startsWith('basicSell-dutch') ||
        props.auctionsMy.sellingMethod.startsWith('alienation-english') ||
        props.auctionsMy.sellingMethod.startsWith('commercialSell-english') ||
        props.auctionsMy.sellingMethod.startsWith('commercialSell-dutch') ||
        props.auctionsMy.sellingMethod.startsWith('bankRuptcy-english') ||
        props.auctionsMy.sellingMethod.startsWith('bankRuptcy-dutch') ||
        props.auctionsMy.sellingMethod.startsWith('nonperformingLoans-english') ||
        props.auctionsMy.sellingMethod.startsWith('nonperformingLoans-dutch')
    ){
        createZUBanks1(props, aucId)
    }
}

async function createZUBanksLR(props, aucId){
    let tmpRregistrationFee = [],
        tmpGuarantee = [],
        tmpSecurityDeposit = [],
        tmpImprovementCompensation = [],
        tmpOther = [],
        tmpAdvancePayment = [],
        tmpLease = [],
        tmpPayment = [],
        tmpPreparationPayment = []

    for(let i=0; i < props.bankAccountZU.length; i++){
        if(props.bankAccountZU[i].groupe === 'registrationFee'){
            tmpRregistrationFee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'guarantee'){
            tmpGuarantee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'securityDeposit'){
            tmpSecurityDeposit.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'improvementCompensation'){
            tmpImprovementCompensation.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'other'){
            tmpOther.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'advancePayment'){
            tmpAdvancePayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'lease'){
            tmpLease.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'payment'){
            tmpPayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'preparationPayment'){
            tmpPreparationPayment.push(props.bankAccountZU[i])
        }
    }
    if(tmpPayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpPayment.length; i++){
            tmp[i] = Number(tmpPayment[i].id)
        }
        data.id = tmp
        data.groupe = "payment"
        createZUBanksPOSTLR(props, data, aucId)
    }
    if(tmpPreparationPayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpPreparationPayment.length; i++){
            tmp[i] = Number(tmpPreparationPayment[i].id)
        }
        data.id = tmp
        data.groupe = "preparationPayment"
        createZUBanksPOSTLR(props, data, aucId)
    }

    if(tmpRregistrationFee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpRregistrationFee.length; i++){
            tmp[i] = Number(tmpRregistrationFee[i].id)
        }
        data.id = tmp
        data.groupe = "registrationFee"
        createZUBanksPOSTLR(props, data, aucId)
    }
    if(tmpGuarantee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpGuarantee.length; i++){
            tmp[i] = Number(tmpGuarantee[i].id)
        }
        data.id = tmp
        data.groupe = "guarantee"
        createZUBanksPOSTLR(props, data, aucId)
    }
    if(tmpSecurityDeposit.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpSecurityDeposit.length; i++){
            tmp[i] = Number(tmpSecurityDeposit[i].id)
        }
        data.id = tmp
        data.groupe = "securityDeposit"
        createZUBanksPOSTLR(props, data, aucId)
    }
    if(tmpImprovementCompensation.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpImprovementCompensation.length; i++){
            tmp[i] = Number(tmpImprovementCompensation[i].id)
        }
        data.id = tmp
        data.groupe = "improvementCompensation"
        createZUBanksPOSTLR(props, data, aucId)
    }
    if(tmpOther.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpOther.length; i++){
            tmp[i] = Number(tmpOther[i].id)
        }
        data.id = tmp
        data.groupe = "other"
        createZUBanksPOSTLR(props, data, aucId)
    }
    if(tmpAdvancePayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpAdvancePayment.length; i++){
            //tmp[i] = Number(tmpAdvancePayment[i].id)
            data.id = Number(tmpAdvancePayment[i].id)
            data.groupe = "advancePayment"
            if(tmpAdvancePayment[i].percent !== ''){
                data.percent = tmpAdvancePayment[i].percent
            }
            if(tmpAdvancePayment[i].holderType !== 'null'){
                data.holderType = tmpAdvancePayment[i].holderType
            }
            createZUBanksPOSTLR(props, data, aucId)
        }
    }
    if(tmpLease.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpLease.length; i++){
            //tmp[i] = Number(tmpLease[i].id)
            data.id = Number(tmpLease[i].id)
            data.groupe = "lease"
            if(tmpLease[i].percent !== ''){
                data.percent = tmpLease[i].percent
            }
            if(tmpLease[i].holderType !== 'null'){
                data.holderType = tmpLease[i].holderType
            }
            createZUBanksPOSTLR(props, data, aucId)
        }
    }
}

async function createZUBanks1(props, aucId){
    let tmpRregistrationFee = [],
        tmpGuarantee = [],
        tmpSecurityDeposit = [],
        tmpImprovementCompensation = [],
        tmpOther = [],
        tmpAdvancePayment = [],
        tmpLease = [],
        tmpPayment = [],
        tmpPreparationPayment = []

    for(let i=0; i < props.bankAccountZU.length; i++){
        if(props.bankAccountZU[i].groupe === 'registrationFee'){
            tmpRregistrationFee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'guarantee'){
            tmpGuarantee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'securityDeposit'){
            tmpSecurityDeposit.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'improvementCompensation'){
            tmpImprovementCompensation.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'other'){
            tmpOther.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'advancePayment'){
            tmpAdvancePayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'lease'){
            tmpLease.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'payment'){
            tmpPayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'preparationPayment'){
            tmpPreparationPayment.push(props.bankAccountZU[i])
        }
    }
    if(tmpPayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpPayment.length; i++){
            //tmp[i] = Number(tmpPayment[i].id)
            data.id = Number(tmpPayment[i].id)
            data.groupe = "payment"
            await createZUBanksPOST1(props, data, aucId)
        }
    }
    if(tmpPreparationPayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpPreparationPayment.length; i++){
            //tmp[i] = Number(tmpPreparationPayment[i].id)
            data.id = Number(tmpPreparationPayment[i].id)
            data.groupe = "preparationPayment"
            await createZUBanksPOST1(props, data, aucId)
        }
    }

    if(tmpRregistrationFee.length > 0){
        let data = {}
        for(let i=0; i < tmpRregistrationFee.length; i++){
            data.id = Number(tmpRregistrationFee[i].id)
            data.groupe = "registrationFee"
            await createZUBanksPOST1(props, data, aucId)
        }
    }
    if(tmpGuarantee.length > 0){
        let data = {}
        for(let i=0; i < tmpGuarantee.length; i++){
            data.id = Number(tmpGuarantee[i].id)
            data.groupe = "guarantee"
            await createZUBanksPOST1(props, data, aucId)
        }
    }
    if(tmpSecurityDeposit.length > 0){
        let data = {}
        for(let i=0; i < tmpSecurityDeposit.length; i++){
            data.id = Number(tmpSecurityDeposit[i].id)
            data.groupe = "securityDeposit"
            createZUBanksPOST1(props, data, aucId)
        }
    }
    if(tmpImprovementCompensation.length > 0){
        let data = {}
        for(let i=0; i < tmpImprovementCompensation.length; i++){
            data.id = Number(tmpImprovementCompensation[i].id)
            data.groupe = "improvementCompensation"
            await createZUBanksPOST1(props, data, aucId)
        }
    }
    if(tmpOther.length > 0){
        let data = {}
        for(let i=0; i < tmpOther.length; i++){
            data.id = Number(tmpOther[i].id)
            data.groupe = "other"
            await createZUBanksPOST1(props, data, aucId)
        }
    }
    if(tmpAdvancePayment.length > 0){
        let data = {}
        for(let i=0; i < tmpAdvancePayment.length; i++){
            data.id = Number(tmpAdvancePayment[i].id)
            data.groupe = "advancePayment"
            if(tmpAdvancePayment[i].percent !== ''){
                data.percent = tmpAdvancePayment[i].percent
            }
            if(tmpAdvancePayment[i].holderType !== 'null'){
                data.holderType = tmpAdvancePayment[i].holderType
            }
            await createZUBanksPOST1(props, data, aucId)
        }
    }
    if(tmpLease.length > 0){
        let data = {}
        let tmp = []
        let tmpId = null
        for(let i=0; i < tmpLease.length; i++){
            data.id = Number(tmpLease[i].id)
            data.groupe = "lease"
            if(tmpLease[i].percent !== ''){
                data.percent = tmpLease[i].percent
            }
            if(tmpLease[i].holderType !== 'null'){
                data.holderType = tmpLease[i].holderType
            }
            await createZUBanksPOST1(props, data, aucId, i, tmpId)
        }
    }
    await clearBanks(props)
}

async function createZUBanksPOST1(props, data, aucId){
    let body = {}
    for(let i = 0; i < props.bankAccount.length; i++){
        if(props.bankAccount[i].id === Number(data.id)){
            body.bankAccounts = [
                {
                    id: props.bankAccount[i].id,
                    bankName: props.bankAccount[i].bankName,
                    currency: props.bankAccount[i].currency,
                    accountIdentifications: [
                        {
                            id: props.bankAccount[i].accountIdentifications[0].id,
                            description: props.bankAccount[i].accountIdentifications[0].description,
                            identifier: props.bankAccount[i].accountIdentifications[0].identifier,
                            scheme: props.bankAccount[i].accountIdentifications[0].scheme
                        }
                    ],
                    accountHolder: props.bankAccount[i].accountHolder
                }
            ]
        }
    }

    if(
        data.groupe === 'advancePayment' ||
        data.groupe === 'lease'
    ){
        if(data.holderType !== 'null') body.holderType = data.holderType
        if(data.percent !== '') body.percent = data.percent
    }
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/bank_account_groups/${data.groupe}`
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
    return null
}

async function createZUBanksPOSTLR(props, data, aucId){
    let body = {}
    body.bankAccountIds = data.id
    if(
        data.groupe === 'advancePayment' ||
        data.groupe === 'lease'
    ){
        if(data.holderType !== 'null') body.holderType = data.holderType
        if(data.percent !== '') body.percent = data.percent
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/bank_account_groups/${data.groupe}`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            await addArrayBankAccountsClear(props)
            await addBankAccountZUtimestamp(props)
        }
    }
}


///////////////////////////////////////////////
///////////////////////////////////////////////


function dutchData(props) {
    switch (true) {
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("subsoil-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("dgf-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
            return true
        default:
            return false
    }
}

async function cloneAuc(props){
    let tmpCarryType = null;
    if(props.auctionsMy.carryType !== null) {tmpCarryType = props.auctionsMy.carryType}
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction`;

    let tmpDateDay = Date.parse(props.auctionsMy.startDate);
    let tmpMonth = null;
    if(new Date(tmpDateDay).getMonth() < 10){
        tmpMonth = '0' + (new Date(tmpDateDay).getMonth() + 1)
    }else{
        tmpMonth = new Date(tmpDateDay).getMonth() + 1
    }
    let tmpDay = null;
    if(new Date(tmpDateDay).getDate() < 10){
        tmpDay = '0' + new Date(tmpDateDay).getDate()
    }else{
        tmpDay = new Date(tmpDateDay).getDate()
    }
    let minNumberOfQualifiedBids = returnDataRailway(props)
    if(minNumberOfQualifiedBids === 'null') minNumberOfQualifiedBids = null
    let tmpBody = {}
    if (props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")) {
        if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
            tmpBody.currentTenantGuaranteeFee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
            }
        }
    }
    tmpBody.documentationValue = props.auctionsMy.documentationValue
    tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
    tmpBody.leaseDuration = props.auctionsMy.leaseDuration
    if(tmpCarryType !== null){
        let texttmpCarryType = typeof tmpCarryType
        if(texttmpCarryType === 'string'){
            for(let tmI = 0; tmI < props.carryType.length; tmI++){
                if(tmpCarryType === props.carryType[tmI].name){
                    tmpBody.carryType = tmpCarryType
                }
            }
        }else if(texttmpCarryType === 'object'){
            tmpBody.carryType = tmpCarryType.name
        }
    }
    tmpBody.title = props.auctionsMy.title
    tmpBody.description = props.auctionsMy.description
    if(
        !props.auctionsMy.sellingMethod.startsWith("subsoil-english") &&
        props.auctionsMy.sellingMethod.startsWith("subsoil-dutch")
    ){
        tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
    }
    tmpBody.sellingMethod = props.auctionsMy.sellingMethod
    tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
    if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
        tmpBody.accessDetails = props.auctionsMy.accessDetails
    }
    if (
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english") ||
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ) {
        tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
    }else{
        tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
        if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
            tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
        }
    }
    if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
        tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
    }
    if (
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english") ||
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ) {
        if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
            //let tmpZU = props.auctionsZUAttemp.value.amount / 2
            let tmpZU = props.auctionsZUAttemp.value.amount
            //let tmpGarantyZU = (tmpZU / 100) * 5
            let tmpGarantyZU = props.auctionsZUAttemp.guarantee.amount
            //let tmpMinStepZU = (tmpZU / 100) * 1
            let tmpMinStepZU = props.auctionsZUAttemp.minimalStep.amount
            tmpBody.value = {
                currency: props.auctionsMy.value.currency,
                amount: Number(tmpZU),
                valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded
            }
            tmpBody.guarantee = {
                currency: props.auctionsMy.guarantee.currency,
                amount: Number(tmpGarantyZU),
            }
            tmpBody.minimalStep = {
                currency: props.auctionsMy.minimalStep.currency,
                amount: Number(tmpMinStepZU),
            }
        }else{
            tmpBody.value = {
                currency: props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded
            }
            tmpBody.guarantee = {
                currency: props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpBody.minimalStep = {
                currency: props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
        }
    }else{
        tmpBody.value = {
            currency: props.auctionsMy.value.currency,
            amount: Number(props.auctionsMy.value.amount),
            valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded
        }
        tmpBody.guarantee = {
            currency: props.auctionsMy.guarantee.currency,
            amount: Number(props.auctionsMy.guarantee.amount),
        }
        tmpBody.minimalStep = {
            currency: props.auctionsMy.minimalStep.currency,
            amount: Number(props.auctionsMy.minimalStep.amount),
        }
    }
    tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
    tmpBody.startDate = props.auctionsMy.startDate
    tmpBody.documents = props.auctionsMy.documents
    if(Number(props.auctionsMy.tenderAttempts) !== 1){
        tmpBody.previousAuctionId = props.discount.previousAuctionId
    }

    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("dgf-english"):
        case props.auctionsMy.sellingMethod.startsWith("dgf-dutch"):
        case "dgf-dutch-fast":
            tmpBody.minNumberOfQualifiedBids = props.auctionsMy.minNumberOfQualifiedBids
            tmpBody.virtualDataRoom = props.auctionsMy.virtualDataRoom
            tmpBody.eligibilityCriteria = props.auctionsMy.eligibilityCriteria
            tmpBody.decision = {
                decisionId: props.auctionsMy.decision.decisionId,
                decisionDate: props.auctionsMy.decision.decisionDate
            }
            tmpBody.x_dgfPlatformLegalDetails = props.auctionsMy.x_dgfPlatformLegalDetails
            break;
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
        case props.auctionsMy.sellingMethod.startsWith( "legitimatePropertyLease-priorityEnglish"):
            if(Number(props.auctionsMy.minNumberOfQualifiedBids) === 1 || Number(props.auctionsMy.minNumberOfQualifiedBids) === 2){
                tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            }else{
                tmpBody.minNumberOfQualifiedBids = 1
            }
            tmpBody.calcOnBookValue = props.auctionsMy.calcOnBookValue
            tmpBody.valueAddedTaxCharged = props.auctionsMy.valueAddedTaxCharged
            tmpBody.registryId = props.auctionsMy.registryId
            if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: true
                }
            }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: false
                }
            }else{
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                }
            }
            tmpBody.valuePeriod = props.auctionsMy.valuePeriod
            break;
        default:
            break;
    }

    const response = await fetch(endPoint, {
        method: "POST",

        //timberEnglish
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeAuctionsSwitchWindow(0)
        props.changeCreateAuctionTab(0);
        getData(props)
    }else{
        //props.setPopUpAuctionMyNull()
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні копії аукціону', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeAuctionsSwitchWindow(0)
            props.changeCreateAuctionTab(0);
            getData(props)
        }else{
            if (
                json.sellingMethod.startsWith("legitimatePropertyLease-english") ||
                json.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
                json.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
            ) {
                createAuctionLeaseRules(props, json.id)
                let data = {}
                if(props.auctionsZUAttemp.bankAccountsGroups !== null){
                    if(props.auctionsZUAttemp.bankAccountsGroups.length !== null){
                        for(let i = 0; i < props.auctionsZUAttemp.bankAccountsGroups.length; i++){
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "registrationFeeAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "guaranteeAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "advancePaymentAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "securityDepositAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "improvementsCompensationAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "otherAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                        }
                    }
                }
                if(props.auctionsMy.items !== null){
                    if(props.auctionsMy.items.length > 0){
                        for(let i = 0; i < props.auctionsMy.items.length; i++){
                            await createItemZU(props, props.auctionsMy.items[i], json.id)
                        }
                    }
                }
            }else{
                if(props.auctionsMy.items !== null){
                    if(props.auctionsMy.items.length > 0){
                        for(let i = 0; i < props.auctionsMy.items.length; i++){
                            await createItemEnergy(props, props.auctionsMy.items[i], json.id)
                        }
                    }
                }
            }
            //if(props.discount.discountOnOff === true){
            await createDiscountForAuction(props, json.id)
            //}
            if(dutchData(props) !== false){
                let dutchStep = {
                    dutchStepQuantity: props.auctionsMy.dutchStep.dutchStepQuantity,
                    dutchStepPercent: props.auctionsMy.dutchStep.dutchStepPercent,
                    dutchStepValue: {
                        currency: 'UAH',
                        amount: props.auctionsMy.dutchStep.dutchStepValue.amount,
                        //valueAddedTaxIncluded: null,
                    },
                }
                const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${json.id}/dutch_step`
                const response3 = await fetch(endPoint3, {
                    method: "POST",
                    body: JSON.stringify(dutchStep),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response3 === undefined || response3 === 'undefined'){
                }else{
                    const json3 = await response3.json()
                    if(json3.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при створенні аукціону', message: JSON.stringify(json3)})
                        props.setUniversalError(tmp)
                        //props.setPopUpAuctionCreate(1)
                        props.changeAuctionsSwitchWindow(0)
                        props.changeCreateAuctionTab(0);
                        getData(props)
                    }else{
                        let tmpAucData = json
                        tmpAucData.dutchStep = json3
                        //props.setPopUpAuctionCreate(json)
                        props.setPopUpAuctionCreate(tmpAucData)
                        createDiscountForAuction(props, json.id)
                        props.changeDiscountPreviousAuctionCurrency('null')
                        props.changeDiscountPreviousAuctionAmount('')
                        props.changeDiscountPercent('')
                        props.changeDiscountPreviousAuctionId('')
                        props.discountAuc(false)
                    }
                }
            }else{
                props.changeDiscountPreviousAuctionCurrency('null')
                props.changeDiscountPreviousAuctionAmount('')
                props.changeDiscountPercent('')
                props.changeDiscountPreviousAuctionId('')
                props.discountAuc(false)
                //props.setPopUpAuctionCreate(json)
                props.changeAuctionsSwitchWindow(0)
                props.changeCreateAuctionTab(0);
                getData(props)
            }
        }
    }

    /*let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=25'
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    props.auctionsGetMy(data, header)*/

    props.changeCreateAuctionLotIdentifier('')
    props.changeCreateAuctionTitle('')
    props.changeCreateAuctionDescription('')
    props.changeCreateAuctionTenderAttempts('')
    props.changeCreateAuctionAccessDetails('')
    props.changeCreateAuctionXDocumentRequirements('')
    props.changeCreateAuctionXAdditionalInformation('')
    props.changeCreateAuctionType('null')
    props.changeCreateAuctionValueCurrency('null')
    props.changeCreateAuctionValueAmount('')
    props.changeCreateAuctionGuaranteeCurrency('null')
    props.changeCreateAuctionGuaranteeAmount('')
    props.changeCreateAuctionMinimalStepCurrency('null')
    props.changeCreateAuctionMinimalStepAmount('')
    props.changeCreateAuctionDateStart('')
    props.setPopBankAccountId('null')
    props.setPopBankAccountIdArr(null)
    //const json = await response.json()
    props.changeDiscountPreviousAuctionCurrency('null')
    props.changeDiscountPreviousAuctionAmount('')
    props.changeDiscountPercent('')
    props.changeDiscountPreviousAuctionId('')
    props.discountAuc(false)
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeCreateAuctionMinNumberOfQualifiedBids('2')
    props.changeCreateAuctionDgfVirtualDataRoom('')
    props.changeCreateAuctionDgfEligibilityCriteria('null')
    props.changeCreateAuctionDgfX_dgfPlatformLegalDetails('')
    props.changeCreateAuctionDgfDecisionId('')
    props.changeCreateAuctionDgfDecisionDate('')

    await props.changeAuctionsSwitchWindow(0)
    await props.changeCreateAuctionTab(0);
    await getData(props)

}

async function getData(props){
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    //props.auctionsBidsGetMy(data, header)
    //props.bankAccountGet(data, header)
    props.auctionsGetMy(data, header)
    /*await props.workMyPageCurrentAuction(0)
    if(props.auctionsMy !== null){
        if(props.auctionsMy.length === 10) {
            await props.workMyPageNextAuction(1)
        }else{
            await props.workMyPageNextAuction(null)
        }
    }*/
}

function returnDataRailway(props){
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
            return props.auctionsMy.minNumberOfQualifiedBids
        default:
            return 1
    }
}

///////////////////////////////////////////////////////
//КЛОНИРОВАНИЕ ИТЕМОВ
//////////////////////////////////////////////////////
async function createItemZU(props, item, auctionId){
    if(item.itemType === 'otherProperty'){
        createItemZUOtherPropertyItem(props, item, auctionId)
    }else if(item.itemType === 'jointPropertyComplex'){
        createItemZUJointPropertyComplexItem(props, item, auctionId)
    }else if(item.itemType === 'vehicle'){
        createItemZUVehicleItem(props, item, auctionId)
    }else if(item.itemType === 'realEstate'){
        createItemZURealEstateItem(props, item, auctionId)
    }
}
async function createItemZUOtherPropertyItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json1 = await response.json()
                if(json1.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                }else{
                    if(item.registrationDetails !== null){
                        await createRegDetal(props, auctionId, json1.id, item)
                    }
                }
            }
        }
    }
}

async function createItemZUVehicleItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.vehicleProps !== null){
                    await saveItemZuVehicleProps(props, item.vehicleProps, auctionId, json.id)
                }
            }
        }
    }
}
async function saveItemZuVehicleProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        brand: item.brand,
        model: item.model,
        productionYear: item.productionYear,
        engineCapacity: item.engineCapacity,
        fuelType: item.fuelType,
        transmission: item.transmission,
        color: item.color,
        kilometrage: item.kilometrage,
        identificationNumber: item.identificationNumber,
        configuration: item.configuration,
        condition: item.condition,
        damagedDescription: item.damagedDescription,
        damagePresence: item.damagePresence,
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/VehicleProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)

}

async function createItemZURealEstateItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.realEstateProps !== null){
                    await saveItemZuReProps(props, item.realEstateProps, auctionId, json.id)
                }
            }
        }
    }
}
async function saveItemZuReProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        totalBuildingArea: item.totalBuildingArea,
        totalObjectArea: item.totalObjectArea,
        usableArea: item.usableArea,
        constructionYear: item.constructionYear,
        constructionTechnology: item.constructionTechnology,
        livingArea: item.livingArea,
        kitchenArea: item.kitchenArea,
        landArea: item.landArea,
        locationInBuilding: item.locationInBuilding,
        floors: item.floors,
        generalCondition: item.generalCondition,
        serviceElectricity: item.serviceElectricity,
        powerSupplyCapacity: item.powerSupplyCapacity,
        powerSupplyClass: item.powerSupplyClass,
        serviceWater: item.serviceWater,
        serviceSewerage: item.serviceSewerage,
        serviceGas: item.serviceGas,
        serviceCentralHeating: item.serviceCentralHeating,
        serviceAutonomousHeating: item.serviceAutonomousHeating,
        serviceHeatingCounter: item.serviceHeatingCounter,
        serviceVentilation: item.serviceVentilation,
        serviceAirConditioning: item.serviceAirConditioning,
        servicePhone: item.servicePhone,
        serviceTV: item.serviceTV,
        serviceInternet: item.serviceInternet,
        serviceElevator: item.serviceElevator,
        serviceSecurityAlarm: item.serviceSecurityAlarm,
        serviceFireAlarm: item.serviceFireAlarm,
        servicesDescription: item.servicesDescription,
        servicesAccounting: item.servicesAccounting,
        landTax: item.landTax,
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/RealEstateProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)

}

async function createItemZUJointPropertyComplexItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.jointPropertyComplexProps !== null){
                    await saveItemZuJointPropertyComplexProps(props, item.jointPropertyComplexProps, auctionId, json.id)
                }
                //await saveItemZuJointPropertyComplexPropsLand(props, json.id, 'POST')
            }
        }
    }
}
async function saveItemZuJointPropertyComplexProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        quantityAndNomenclature: item.quantityAndNomenclature,
        workplacesQuantity: item.workplacesQuantity,
        buildingsInformation: item.buildingsInformation,
        landPlotInformation: item.landPlotInformation,
        mainObligations: item.mainObligations
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/JointPropertyComplexProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }
        if(item.landProps !== null){
            await saveItemZuJointPropertyComplexPropsLand(props, item.landProps, auctionId, itemId)
        }
    }
}
async function saveItemZuJointPropertyComplexPropsLand(props, item, auctionId, itemId){
    let body = {}
    body = {
        landArea: item.landArea,
        cadastralNumber: item.cadastralNumber,
        ownershipType: item.ownershipType,
        encumbrances: item.encumbrances,
        jointOwnership: item.jointOwnership,
        utilitiesAvailability: item.utilitiesAvailability,
        landIntendedUse: item.landIntendedUse
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/JointPropertyComplexProps/land_props`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)

}

async function createRegDetal(props, auctionId, jsonId, item){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${jsonId}/registration_details`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    registrationStatus: item.registrationDetails.registrationStatus,
                    registrationID: item.registrationDetails.registrationID,
                    registrationDate: item.registrationDetails.registrationDate,
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)

        }
    }
}
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
async function createItemEnergy(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const arrAddClass = [];
            let body = null;
            switch(props.auctionsMy.sellingMethod){
                case "timber-english":
                case "timber-english-fast":
                case "timber-english-fast-manual":
                case "timber-english-initial-auction":
                case "timber-english-initial-qualification":
                case "timber-english-initial-qualification-fast":
                    if(item.additionalClassifications !== null){
                        if(item.additionalClassifications.length > 0){
                            for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                                arrAddClass[tt] = {scheme: item.additionalClassifications[tt].scheme, classificationId: item.additionalClassifications[tt].classificationId}
                            }
                        }
                    }
                    body = JSON.stringify({
                        classificationId: Number(item.classification.id),
                        additionalClassifications: arrAddClass,
                        addressId: props.userProfiles.organization.address.id,
                        description: item.description,
                        unitCode: item.unit.code,
                        quantity: `${item.quantity}`,
                        unitValue: {
                            currency: 'UAH',                                 //+            //Валюта
                            amount: item.unitValue.amount,      //+                               //Цена
                        },
                    })
                    break;
                case "renewables":
                case "renewables-fast":
                case "renewables-fast-manual":
                case "renewables-initial-auction":
                case "renewables-initial-qualification":
                case "renewables-initial-qualification-fast":
                case "subsoil-english":
                case "subsoil-english-fast":
                case "subsoil-english-fast-manual":
                case "subsoil-english-initial-auction":
                case "subsoil-english-initial-qualification":
                case "subsoil-dutch":
                case "subsoil-dutch-fast":
                case "subsoil-dutch-fast-manual":
                case "subsoil-dutch-initial-auction":
                case "subsoil-dutch-initial-qualification":
                case "subsoil-dutch-initial-auction-manual":
                case "subsoil-english-fast-auction-manual-qualification":
                case "railwayCargo-english":
                case "railwayCargo-english-fast":
                case "railwayCargo-english-fast-manual":
                case "railwayCargo-english-initial-auction":
                case "railwayCargo-english-initial-qualification":
                case "railwayCargo-dutch":
                case "railwayCargo-dutch-fast":
                case "railwayCargo-dutch-fast-manual":
                case "railwayCargo-dutch-initial-auction":
                case "railwayCargo-dutch-initial-qualification":
                case "railwayCargo-dutch-fast-auction-manual-qualification":
                case "railwayCargo-dutch-initial-auction-manual":
                case "dgf-english":
                case "dgf-english-fast":
                case "dgf-english-fast-manual":
                case "dgf-english-fast-auction-manual-qualification":
                case "dgf-english-initial-auction":
                case "dgf-english-initial-auction-manual":
                case "dgf-english-initial-qualification":
                case "dgf-dutch":
                case "dgf-dutch-fast":
                case "dgf-dutch-fast-manual":
                case "dgf-dutch-fast-auction-manual-qualification":
                case "dgf-dutch-initial-auction":
                case "dgf-dutch-initial-qualification":
                case "dgf-dutch-initial-auction-manual":
                case "legitimatePropertyLease-english":
                case "legitimatePropertyLease-english-fast":
                case "legitimatePropertyLease-english-fast-manual":
                case "legitimatePropertyLease-english-fast-auction-manual-qualification":
                case "legitimatePropertyLease-english-initial-auction":
                case "legitimatePropertyLease-english-initial-auction-manual":
                case "legitimatePropertyLease-english-initial-qualification":
                case "legitimatePropertyLease-english-initial-qualification-prod":
                case "legitimatePropertyLease-dutch":
                case "legitimatePropertyLease-dutch-fast":
                case "legitimatePropertyLease-dutch-fast-manual":
                case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
                case "legitimatePropertyLease-dutch-initial-auction":
                case "legitimatePropertyLease-dutch-initial-auction-manual":
                case "legitimatePropertyLease-dutch-initial-qualification-prod":
                case "legitimatePropertyLease-priorityEnglish":
                case "legitimatePropertyLease-priorityEnglish-fast":
                case "legitimatePropertyLease-priorityEnglish-fast-manual":
                case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-auction":
                case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                    if(item.additionalClassifications !== null){
                        if(item.additionalClassifications.length > 0){
                            for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                                arrAddClass[tt] = Number(item.additionalClassifications[tt].id)
                            }
                        }
                    }

                    let tmpRequestLifeTimeStartDate = null
                    if(item.hasOwnProperty('requestLifeTime') === true){
                        if(item.requestLifeTime !== null){
                            if(item.requestLifeTime.hasOwnProperty('startDate') === true){
                                if(item.requestLifeTime.startDate !== null){
                                    tmpRequestLifeTimeStartDate = item.requestLifeTime.startDate
                                }
                            }
                        }
                    }
                    let tmpRequestLifeTimeEndDate = null
                    if(item.hasOwnProperty('requestLifeTime') === true){
                        if(item.requestLifeTime !== null){
                            if(item.requestLifeTime.hasOwnProperty('endDate') === true){
                                if(item.requestLifeTime.endDate !== null){
                                    tmpRequestLifeTimeEndDate = item.requestLifeTime.endDate
                                }
                            }
                        }
                    }
                    let tmpLoadLifeTimeStartDate = null
                    if(item.hasOwnProperty('loadLifeTime') === true){
                        if(item.loadLifeTime !== null){
                            if(item.loadLifeTime.hasOwnProperty('startDate') === true){
                                if(item.loadLifeTime.startDate !== null){
                                    tmpLoadLifeTimeStartDate = item.loadLifeTime.startDate
                                }
                            }
                        }
                    }
                    let tmpLoadLifeTimeEndDate = null
                    if(item.hasOwnProperty('loadLifeTime') === true){
                        if(item.loadLifeTime !== null){
                            if(item.loadLifeTime.hasOwnProperty('endDate') === true){
                                if(item.loadLifeTime.endDate !== null){
                                    tmpLoadLifeTimeEndDate = item.loadLifeTime.endDate
                                }
                            }
                        }
                    }


                    body = JSON.stringify({
                        classificationId: Number(item.classification.id),
                        additionalClassificationIds: arrAddClass,
                        addressId: props.userProfiles.organization.address.id,
                        description: item.description,
                        unitCode: item.unit.code,
                        quantity: `${item.quantity}`,
                        requestLifeTime: {
                            startDate: tmpRequestLifeTimeStartDate,
                            endDate: tmpRequestLifeTimeEndDate
                        },
                        loadLifeTime: {
                            startDate: tmpLoadLifeTimeStartDate,
                            endDate: tmpLoadLifeTimeEndDate
                        },
                        routeQuantity: item.routeQuantity,
                        routesAccountingRouteNumber: item.routesAccountingRouteNumber,
                        routesAccountingWagonsPerRouteQuantity: item.routesAccountingWagonsPerRouteQuantity,
                        wagonTypes: item.wagonTypes,
                        wagonSpecies: item.wagonSpecies,
                        loadingRestriction: item.loadingRestriction,

                    })
                    break;
                default:
                    break;
            }

            const response = await fetch(endPoint, {
                method: "post",
                body: body,
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            })
            .catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при копіюванні лоту аукціону ', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)

                }else{
                    if(item.auctionRestriction !== null){
                        if(item.auctionRestriction.length > 0){
                            for(let mi = 0; mi < item.auctionRestriction.length; mi++){
                                createRestrictLoadUnload(props, auctionId, json.id, item.auctionRestriction[mi])
                            }
                        }
                    }
                }
            }
        }
    }
}

async function createRestrictLoadUnload(props, auctionId, itemId, item){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/restriction`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            loadObject: item.loadObject,
            loadObjectCode: item.loadObjectCode,
            loadObjectName: item.loadObjectName,
            unloadObject: item.unloadObject,
            unloadObjectCode: item.unloadObjectCode,
            unloadObjectName: item.unloadObjectName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
}

async function editAuc(props, aucId=null){
    let endPoint = null;
    if(aucId === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`
    }
    let tmpDocumentationValue = null;
    let tmpGeologivDataValue = null;
    let tmpGuarantee = null;
    let tmpMinStep = null;
    let tmpValue = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            tmpGuarantee = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'eurocent',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            tmpDocumentationValue = {currency: 'UAH', amount: props.auctionsMy.documentationValue.amount}
            tmpGeologivDataValue = {currency: 'UAH', amount: Number(props.auctionsMy.geologicDataValue.amount)}
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
                //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
            }
            let tmpValueAddedTaxIncluded = null
            if(props.auctionsMy.value.valueAddedTaxIncluded === 'false'){
                tmpValueAddedTaxIncluded = false
            }else{
                tmpValueAddedTaxIncluded = true
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded,
            }
            break
        default:
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
    }

    let tmpCarryType = null;
    if(
        props.auctionsMy.sellingMethod === "railwayCargo-english" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-fast" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
    ){
        if(props.auctionsMy.carryType !== null) {tmpCarryType = props.auctionsMy.carryType}
    }
    let tmpBank = null;
    if(props.bankAccountIdArr === null){
        tmpBank = []
    }else{
        tmpBank = props.bankAccountIdArr
    }
    let tmpDateDay = Date.parse(props.auctionsMy.startDate);
    let tmpMonth = null;
    if(new Date(tmpDateDay).getMonth() < 10){
        tmpMonth = '0' + (new Date(tmpDateDay).getMonth() + 1)
    }else{
        tmpMonth = new Date(tmpDateDay).getMonth() + 1
    }
    let tmpDay = null;
    if(new Date(tmpDateDay).getDate() < 10){
        tmpDay = '0' + new Date(tmpDateDay).getDate()
    }else{
        tmpDay = new Date(tmpDateDay).getDate()
    }

    let minNumberOfQualifiedBids = returnDataRailway(props)
    if(minNumberOfQualifiedBids === 'null') minNumberOfQualifiedBids = null
    let tmpDateString = new Date(tmpDateDay).getFullYear() + '-' + tmpMonth + '-' + tmpDay

    /////////////////////////////////////////////
    /////////////////////////////////////////////
    let tmpBody = null
    let tmpValueAddedTaxIncluded1 = null
    if(props.auctionsMy.value.valueAddedTaxIncluded === 'false'){
        tmpValueAddedTaxIncluded1 = false
    }else{
        tmpValueAddedTaxIncluded1 = true
    }
    if(Number(props.auctionsMy.tenderAttempts) !== 1){
        tmpBody = {}
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
                tmpBody.currentTenantGuaranteeFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
                }
            }
        }
        if(aucId === null){
            tmpBody.documentationValue = props.auctionsMy.documentationValue
            tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
        }
        tmpBody.leaseDuration = props.auctionsMy.leaseDuration
        //tmpBody.carryTypeId = tmpCarryType
        let texttmpCarryType = typeof tmpCarryType
        if(texttmpCarryType === 'string'){
            for(let tmI = 0; tmI < props.carryType.length; tmI++){
                if(tmpCarryType === props.carryType[tmI].name){
                    tmpBody.carryType = tmpCarryType
                }
            }
        }else if(texttmpCarryType === 'object'){
            tmpBody.carryType = tmpCarryType.name
        }
        tmpBody.previousAuctionId = props.discount.previousAuctionId
        tmpBody.title = props.auctionsMy.title
        tmpBody.description = props.auctionsMy.description
        if(
            props.auctionsMy.sellingMethod !== "subsoil-english" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
        ){
            tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
        }
        tmpBody.sellingMethod = props.auctionsMy.sellingMethod
        tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
        if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
            tmpBody.accessDetails = props.auctionsMy.accessDetails
        }
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
            //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
        }else{
            tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
            if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
                tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
            }
        }
        if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
            tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
        }
        tmpBody.value = {
            currency: 'UAH',//props.auctionsMy.value.currency,
            amount: Number(props.auctionsMy.value.amount),
            valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
        }
        tmpBody.guarantee = {
                currency: 'UAH',//props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
        }
        tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
        tmpBody.minimalStep = {
            currency: 'UAH',//props.auctionsMy.minimalStep.currency,
            amount: Number(props.auctionsMy.minimalStep.amount),
            //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
        }
        tmpBody.startDate = props.auctionsMy.startDate
        tmpBody.documents = props.auctionsMy.documents

    }else{
        if(props.auctionsMy.status !== null){
            tmpBody = {}
            if(aucId === null){
                tmpBody.documentationValue = props.auctionsMy.documentationValue
                tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
            }
            tmpBody.leaseDuration = props.auctionsMy.leaseDuration
            //tmpBody.carryTypeId = tmpCarryType
            let texttmpCarryType = typeof tmpCarryType
            if(texttmpCarryType === 'string'){
                for(let tmI = 0; tmI < props.carryType.length; tmI++){
                    if(tmpCarryType === props.carryType[tmI].name){
                        tmpBody.carryType = tmpCarryType
                    }
                }
            }else if(texttmpCarryType === 'object'){
                tmpBody.carryType = tmpCarryType.name
            }
            tmpBody.title = props.auctionsMy.title
            tmpBody.description = props.auctionsMy.description
            if(
                props.auctionsMy.sellingMethod !== "subsoil-english" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
            ){
                tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
            }
            tmpBody.sellingMethod = props.auctionsMy.sellingMethod
            tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
            if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
                tmpBody.accessDetails = props.auctionsMy.accessDetails
            }
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
            }else{
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
                    tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
                }
            }
            if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
                tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
            }
            tmpBody.value = {
                currency: 'UAH',//props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
            }
            tmpBody.guarantee = {
                currency: 'UAH',//props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
            tmpBody.minimalStep = {
                currency: 'UAH',//props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpBody.startDate = props.auctionsMy.startDate
            tmpBody.documents = props.auctionsMy.documents
        }else{
            tmpBody = {}
            if(aucId === null){
                tmpBody.documentationValue = props.auctionsMy.documentationValue
                tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
            }
            tmpBody.leaseDuration = props.auctionsMy.leaseDuration
            //tmpBody.carryTypeId = tmpCarryType
            let texttmpCarryType = typeof tmpCarryType
            if(texttmpCarryType === 'string'){
                for(let tmI = 0; tmI < props.carryType.length; tmI++){
                    if(tmpCarryType === props.carryType[tmI].name){
                        tmpBody.carryType = tmpCarryType
                    }
                }
            }else if(texttmpCarryType === 'object'){
                tmpBody.carryType = tmpCarryType.name
            }
            tmpBody.previousAuctionId = null
            tmpBody.title = props.auctionsMy.title
            tmpBody.description = props.auctionsMy.description
            if(
                props.auctionsMy.sellingMethod !== "subsoil-english" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
            ){
                tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
            }
            tmpBody.sellingMethod = props.auctionsMy.sellingMethod
            tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
            if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
                tmpBody.accessDetails = props.auctionsMy.accessDetails
            }
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
            }else{
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
                    tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
                }
            }
            if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
                tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
            }
            tmpBody.value = {
                currency: 'UAH',//props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
            }
            tmpBody.guarantee = {
                currency: 'UAH',//props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
            tmpBody.minimalStep = {
                currency: 'UAH',//props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpBody.startDate = props.auctionsMy.startDate
            tmpBody.documents = props.auctionsMy.documents
        }
    }
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    if(aucId === null){
        if(Number(props.auctionsMy.tenderAttempts) === 1){
            if(props.auctionsMy.discount !== null){
                await deleteDiscount(props, props.auctionsMy.id)
            }
        }
    }

    switch(props.auctionsMy.sellingMethod){
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
            tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            tmpBody.virtualDataRoom = props.auctionsMy.virtualDataRoom
            tmpBody.eligibilityCriteria = props.auctionsMy.eligibilityCriteria
            tmpBody.decision = {
                decisionId: props.auctionsMy.decision.decisionId,
                decisionDate: props.auctionsMy.decision.decisionDate
            }
            tmpBody.x_dgfPlatformLegalDetails = props.auctionsMy.x_dgfPlatformLegalDetails
            break;
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            if(Number(props.auctionsMy.minNumberOfQualifiedBids) === 1 || Number(props.auctionsMy.minNumberOfQualifiedBids) === 2){
                tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            }else{
                tmpBody.minNumberOfQualifiedBids = 1
            }
            tmpBody.calcOnBookValue = props.auctionsMy.calcOnBookValue
            tmpBody.valueAddedTaxCharged = props.auctionsMy.valueAddedTaxCharged
            tmpBody.registryId = props.auctionsMy.registryId
            if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: true
                }
            }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: false
                }
            }else{
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                }
            }
            tmpBody.valuePeriod = props.auctionsMy.valuePeriod
            break;
        default:
            break;
    }

    if (props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")) {
        if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
            tmpBody.currentTenantGuaranteeFee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
            }
        }
    }


    if (props.auctionsMy.sellingMethod.startsWith('landSell-priorityEnglish')) {
        if(props.auctionsMy.numberOfCurrentTenants !== 'null' && props.auctionsMy.numberOfCurrentTenants !== null){
            tmpBody.numberOfCurrentTenants = props.auctionsMy.numberOfCurrentTenants
            if(props.auctionsMy.currentTenants.identifier.scheme === 'null'){
                tmpBody.currentTenants = null
            }else{
                tmpBody.currentTenants = props.auctionsMy.currentTenants
            }
        }else{
            tmpBody.numberOfCurrentTenants = 1
            if(props.auctionsMy.currentTenants.identifier.scheme === 'null'){
                tmpBody.currentTenants = null
            }else{
                tmpBody.currentTenants = props.auctionsMy.currentTenants
            }
        }
    }

    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            if(
                props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english") ||
                props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
                props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
            ){
                if(aucId === null){
                    createArrBanks(props, 2, props.auctionsMy.id)
                    createAuctionLeaseRules(props, json.id)
                }else{
                    createArrBanks(props, 2, aucId)
                    createAuctionLeaseRules(props, json.id)
                }
            }
            if(aucId === null){
                if(props.discount.discountOnOff === true){
                    if(props.discount.id === null){
                        createDiscountForAuction(props, props.auctionsMy.id)
                    }else{
                        editDiscountForAuction(props, props.auctionsMy.id)
                    }
                }
            }else{
                if(props.discount.discountOnOff === true){
                    if(props.discount.id === null){
                        createDiscountForAuction(props, aucId)
                    }else{
                        editDiscountForAuction(props, aucId)
                    }
                }
            }
            if(dutchData(props) !== false){
                let dutchStep = {
                    dutchStepQuantity: props.auctionsMy.dutchStep.dutchStepQuantity,
                    dutchStepPercent: props.auctionsMy.dutchStep.dutchStepPercent,
                    dutchStepValue: {
                        currency: 'UAH',
                        amount: props.auctionsMy.dutchStep.dutchStepValue.amount,
                        //valueAddedTaxIncluded: null,
                    },
                }
                const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${json.id}/dutch_step`
                const response3 = await fetch(endPoint3, {
                    method: "PUT",
                    body: JSON.stringify(dutchStep),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response3 === undefined || response3 === 'undefined'){
                }else{
                    const json3 = await response3.json()
                    if(json3.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json3)})
                        props.setUniversalError(tmp)
                        props.setPopUpAuctionCreate(1)
                    }else{
                        let tmpAucData = json
                        tmpAucData.dutchStep = json3
                        props.setPopUpAuctionCreate(tmpAucData)
                    }
                }
            }else{
                //props.setPopUpAuctionCreate(2)

                props.setPopUpAuctionCreate(json)
            }
        }
    }
    //props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeLoader(false)
}

async function deleteDiscount(props, auctionId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при видаленні знижки', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
        }
    }
}

function createArrBanks(props, type, aucId){
    if(
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english") ||
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
        props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ){
        if(type === 2){
            createZUBanks(props, aucId)
        }
    }else{
        if(
            props.auctionsMy.sellingMethod.startsWith("subsoil-english") ||
            props.auctionsMy.sellingMethod.startsWith("subsoil-dutch")
        ){
            let tmp = []
            for(let i = 0; i < props.auctionsMy.bankAccounts.length; i++){
                if(props.bankAccountWork.deleteBank.includes(String(props.auctionsMy.bankAccounts[i].id)) === false) tmp.push(props.auctionsMy.bankAccounts[i].id)
            }
            for(let i = 0; i < props.bankAccountWork.addNewBank.length; i++){
                tmp.push(Number(props.bankAccountWork.addNewBank[i]))
            }
            return tmp
        }
    }
}

//
async function createAuctionLeaseRules(props, aucId){
    let body = {}
    body.intendedUseRestrictionMethod = props.auctionsMy.leaseRules.intendedUseRestrictionMethod
    body.intendedUseRestrictionDescription = props.auctionsMy.leaseRules.intendedUseRestrictionDescription
    body.additionalLeaseConditions = props.auctionsMy.leaseRules.additionalLeaseConditions
    body.subleaseAllowed = props.auctionsMy.leaseRules.subleaseAllowed
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionMethod === 'exceptDescribed'){
                body.intendedUse = props.auctionsMy.leaseRules.intendedUse
            }
        }
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            await createAuctionLeaseRulesShedule(props, aucId)
            //if(props.schedule.daysBy.value === '' || props.schedule.daysBy.value === null){
                //if(props.schedule.hoursBy.value === '' || props.schedule.hoursBy.value === null){
                    if(props.shedullePeriod.newFullPeriod.length > 0){
                        for(let it=0; it < props.shedullePeriod.newFullPeriod.length; it++){
                            await createAuctionLeaseRulesShedulePeriod(props, aucId, props.shedullePeriod.newFullPeriod[it])
                        }
                    }
                //}
            //}
            await createAuctionRelatedOrganizations(props, aucId)
        }
    }
}

async function createAuctionLeaseRulesShedule(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule`
    let body = {}
    if(
        props.schedule.hoursBy.by !== 'null' &&
        props.schedule.hoursBy.by !== null &&
        props.schedule.hoursBy.value !== '' &&
        props.schedule.hoursBy.value !== null
    ){
        body.hoursBy = {
            value: props.schedule.hoursBy.value,
            by: props.schedule.hoursBy.by
        }
    }else{
        body.hoursBy = {}
    }
    if(
        props.schedule.daysBy.by !== 'null' &&
        props.schedule.daysBy.by !== null &&
        props.schedule.daysBy.value !== '' &&
        props.schedule.daysBy.value !== null
    ){
        body.daysBy = {
            value: props.schedule.daysBy.value,
            by: props.schedule.daysBy.by
        }
    }else{
        body.daysBy = {}
    }
    if(props.schedule.daysBy.value === '' || props.schedule.daysBy.value === null){
        if(props.schedule.hoursBy.value === '' || props.schedule.hoursBy.value === null){
            body.other = props.schedule.other
        }
    }
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні розкладу", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
}

//Периоды
async function createAuctionLeaseRulesShedulePeriod(props, aucId, data){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule/period`
    let body = {}
    if(data.timeStart !== ''){
        let tmp = data.timeStart.split('/');
        body.startDate = tmp[2] + '-' + tmp[1] + '-' + tmp[0] + 'T00:00:00+00:00'
    }
    if(data.timeEnd !== ''){
        let tmp = data.timeEnd.split('/');
        body.endDate = tmp[2] + '-' + tmp[1] + '-' + tmp[0] + 'T00:00:00+00:00'
    }
    if(data.weekdays.length > 0){
        body.weekdays = data.weekdays
    }
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні періодов", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
    await props.setDataAddress([], 'SET_NEW_FULL_PERIOD_ARR')
}

async function createAuctionRelatedOrganizations(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/related_organizations`
    let body = {}
    if(props.auctionsMy.relatedOrganizations.propertyOwner !== null){
        if(props.auctionsMy.relatedOrganizations.propertyOwner.id !== 'null') body.propertyOwnerId = Number(props.auctionsMy.relatedOrganizations.propertyOwner.id)
    }
    if(props.auctionsMy.relatedOrganizations.sellingEntity !== null){
        if(props.auctionsMy.relatedOrganizations.sellingEntity.id !== 'null') body.sellingEntityId = Number(props.auctionsMy.relatedOrganizations.sellingEntity.id)
    }
    if(props.auctionsMy.relatedOrganizations.currentTenant !== null){
        if(props.auctionsMy.relatedOrganizations.currentTenant.id !== 'null') body.currentTenantId = Number(props.auctionsMy.relatedOrganizations.currentTenant.id)
    }
    if(props.auctionsMy.relatedOrganizations.ownershipType !== '') body.ownershipType = props.auctionsMy.relatedOrganizations.ownershipType
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні relatedOrganizations", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
}

async function createZUBanks(props, aucId){
    let tmpRregistrationFee = [],
        tmpGuarantee = [],
        tmpSecurityDeposit = [],
        tmpImprovementCompensation = [],
        tmpOther = [],
        tmpAdvancePayment = [],
        tmpLease = []

    for(let i=0; i < props.bankAccountZU.length; i++){
        if(props.bankAccountZU[i].groupe === 'registrationFee'){
            tmpRregistrationFee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'guarantee'){
            tmpGuarantee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'securityDeposit'){
            tmpSecurityDeposit.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'improvementCompensation'){
            tmpImprovementCompensation.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'other'){
            tmpOther.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'advancePayment'){
            tmpAdvancePayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'lease'){
            tmpLease.push(props.bankAccountZU[i])
        }
    }
    if(tmpRregistrationFee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpRregistrationFee.length; i++){
            //tmp[i] = Number(tmpRregistrationFee[i].id)
            data.id = Number(tmpRregistrationFee[i].id)
            data.groupe = "registrationFee"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "registrationFee"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpGuarantee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpGuarantee.length; i++){
            //tmp[i] = Number(tmpGuarantee[i].id)
            data.id = Number(tmpGuarantee[i].id)
            data.groupe = "guarantee"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "guarantee"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpSecurityDeposit.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpSecurityDeposit.length; i++){
            //tmp[i] = Number(tmpSecurityDeposit[i].id)
            data.id = Number(tmpSecurityDeposit[i].id)
            data.groupe = "securityDeposit"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "securityDeposit"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpImprovementCompensation.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpImprovementCompensation.length; i++){
            //tmp[i] = Number(tmpImprovementCompensation[i].id)
            data.id = Number(tmpImprovementCompensation[i].id)
            data.groupe = "improvementCompensation"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "improvementCompensation"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpOther.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpOther.length; i++){
            //tmp[i] = Number(tmpOther[i].id)
            data.id = Number(tmpOther[i].id)
            data.groupe = "other"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "other"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpAdvancePayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpAdvancePayment.length; i++){
            //tmp[i] = Number(tmpAdvancePayment[i].id)
            data.id = Number(tmpAdvancePayment[i].id)
            data.groupe = "advancePayment"
            if(tmpAdvancePayment[i].percent !== ''){
                data.percent = tmpAdvancePayment[i].percent
            }
            if(tmpAdvancePayment[i].holderType !== 'null'){
                data.holderType = tmpAdvancePayment[i].holderType
            }
            await createZUBanksPOST(props, data, aucId)
        }
        /*data.id = tmp
        data.groupe = "advancePayment"
        if(tmpAdvancePayment[0].percent !== ''){
            data.percent = tmpAdvancePayment[0].percent
        }
        if(tmpAdvancePayment[0].holderType !== 'null'){
            data.holderType = tmpAdvancePayment[0].holderType
        }
        createZUBanksPOST(props, data, aucId)*/
    }
    if(tmpLease.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpLease.length; i++){
            //tmp[i] = Number(tmpLease[i].id)
            data.id = Number(tmpLease[i].id)
            data.groupe = "lease"
            if(tmpLease[i].percent !== ''){
                data.percent = tmpLease[i].percent
            }
            if(tmpLease[i].holderType !== 'null'){
                data.holderType = tmpLease[i].holderType
            }
            await createZUBanksPOST(props, data, aucId)
        }
        /*data.id = tmp
        data.groupe = "lease"
        if(tmpLease[0].percent !== ''){
            data.percent = tmpLease[0].percent
        }
        if(tmpLease[0].holderType !== 'null'){
            data.holderType = tmpLease[0].holderType
        }
        createZUBanksPOST(props, data, aucId)*/
    }
    await clearBanks(props)
}

async function clearBanks(props){
    await addArrayBankAccountsClear(props)
    await addBankAccountZUtimestamp(props)
}

async function createZUBanksPOST(props, data, aucId){
    let body = {}
    body.bankAccountIds = data.id
    if(
        data.groupe === 'advancePayment' ||
        data.groupe === 'lease'
    ){
        if(data.holderType !== 'null') body.holderType = data.holderType
        if(data.percent !== '') body.percent = data.percent
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/bank_account_groups/${data.groupe}`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            await addArrayBankAccountsClear(props)
            await addBankAccountZUtimestamp(props)
        }
    }
}

async function createZUBanksPOSTOld(props, data, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/bank_account_groups/${data.group}/${data.gropType}/${data.type}`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            bankAccountIds: data.id
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            await addArrayBankAccountsClear(props)
            await addBankAccountZUtimestamp(props)
        }
    }
}

async function addArrayBankAccountsClear(props){
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
}
async function addBankAccountZUtimestamp(props){
    let tmp = new Date()
    props.setDataAddress(String(tmp), 'SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP')
}

async function createObject(props, objId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/auction/bridge/${objId}`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            //Тут подключаю файл редактирования аука
            //getAucionFromId(props, json.id)
            await editAuc(props, json.id)
        }
    }
}

async function createCurrentTenant(props, aucId){
    props.changeLoader(true)
    let body = {}
    if(props.auctionsMy.currentTenant !== null){
        if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
            if(props.auctionsMy.currentTenant.address !== null){
                let address = {}
                let addressIdentifier = {}
                if(props.auctionsMy.currentTenant.address.streetAddress !== '' && props.auctionsMy.currentTenant.address.streetAddress !== null) address.streetAddress = props.auctionsMy.currentTenant.address.streetAddress
                if(props.auctionsMy.currentTenant.address.locality !== '' && props.auctionsMy.currentTenant.address.locality !== null) address.locality = props.auctionsMy.currentTenant.address.locality
                if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                    let tmpRegion = null
                    if(props.auctionsMy.currentTenant.address.region.indexOf('+:+') !== -1){
                        let r = props.auctionsMy.currentTenant.address.region.split('+:+')
                        tmpRegion = getRegion(r[1])
                        addressIdentifier.scheme = 'koatuu'
                        //addressIdentifier.identifier = r[0]
                        //addressIdentifier.name = tmpRegion
                        //address.addressIdentifier = addressIdentifier
                    }else{
                        tmpRegion = props.auctionsMy.currentTenant.address.region
                        addressIdentifier.scheme = 'koatuu'
                        addressIdentifier.identifier = getAddressRegionName(props)
                        addressIdentifier.name = getAddressRegionCode(props)
                        address.addressIdentifier = addressIdentifier
                    }
                    address.region = tmpRegion
                }
                if(props.auctionsMy.currentTenant.address.postalCode !== '' && props.auctionsMy.currentTenant.address.postalCode !== null) address.postalCode = props.auctionsMy.currentTenant.address.postalCode
                address.countryName = 'Україна'
                body.address = address
            }
        }
        if(props.auctionsMy.currentTenant.hasOwnProperty('identifier') === true){
            if(props.auctionsMy.currentTenant.identifier !== null){
                let identifier = {}
                let count = false
                if(props.auctionsMy.currentTenant.identifier.identifier !== null && props.auctionsMy.currentTenant.identifier.identifier !== ''){
                    identifier.identifier = props.auctionsMy.currentTenant.identifier.identifier
                    count = true
                }
                if(props.auctionsMy.currentTenant.identifier.scheme !== null && props.auctionsMy.currentTenant.identifier.scheme !== 'null'){
                    identifier.scheme = props.auctionsMy.currentTenant.identifier.scheme
                    count = true
                }
                if(props.auctionsMy.currentTenant.identifier.legalName !== null && props.auctionsMy.currentTenant.identifier.legalName !== ''){
                    identifier.legalName = props.auctionsMy.currentTenant.identifier.legalName
                    count = true
                }
                if(count === true) body.identifier = identifier
            }
        }
        //if(props.auctionsMy.currentTenant.name !== '' && props.auctionsMy.currentTenant.name !== null) body.name = props.auctionsMy.currentTenant.name
        if(props.auctionsMy.currentTenant.representativeInfo !== '' && props.auctionsMy.currentTenant.representativeInfo !== null) body.representativeInfo = props.auctionsMy.currentTenant.representativeInfo
        if(props.auctionsMy.currentTenant.hasOwnProperty('currentTenantValue') === true){
            let currentTenantValue = {}
            let count = false
            if(props.auctionsMy.currentTenant.currentTenantValue !== null){
                //if(props.auctionsMy.currentTenant.currentTenantValue.currency !== '' && props.auctionsMy.currentTenant.currentTenantValue.currency !== null){
                    //currentTenantValue.currency = props.auctionsMy.currentTenant.currentTenantValue.currency
                    currentTenantValue.currency = 'UAH'
                //}
                if(props.auctionsMy.currentTenant.currentTenantValue.amount !== '' && props.auctionsMy.currentTenant.currentTenantValue.amount !== null) {
                    currentTenantValue.amount = props.auctionsMy.currentTenant.currentTenantValue.amount
                    count = true
                }
            }
            if(count === true) body.currentTenantValue = currentTenantValue
        }
        if(props.auctionsMy.currentTenant.hasOwnProperty('currentContractTime') === true){
            if(props.auctionsMy.currentTenant.currentContractTime !== null){
                let currentContractTime = {}
                let count = false
                if(props.auctionsMy.currentTenant.currentContractTime.hasOwnProperty('dateFrom') === true){
                    if(props.auctionsMy.currentTenant.currentContractTime.dateFrom !== '' && props.auctionsMy.currentTenant.currentContractTime.dateFrom !== null){
                        let tmpStart = props.auctionsMy.currentTenant.currentContractTime.dateFrom.split('.')
                        currentContractTime.dateFrom = `${tmpStart[0]}+00:00`
                        count = true
                    }
                }
                if(props.auctionsMy.currentTenant.currentContractTime.hasOwnProperty('dateTill') === true){
                    if(props.auctionsMy.currentTenant.currentContractTime.dateTill !== '' && props.auctionsMy.currentTenant.currentContractTime.dateTill !== null){
                        let tmpEnd = props.auctionsMy.currentTenant.currentContractTime.dateTill.split('.')
                        currentContractTime.dateTill = `${tmpEnd[0]}+00:00`
                        count = true
                    }
                }
                if(count === true) body.currentContractTime = currentContractTime
            }
        }


        let method = 'POST'
        if(props.auctionsMy.currentTenant.id !== null) method = 'PATCH'
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/currentTenant`
        const response = await fetch(endPoint, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
                //props.setUniversalError(tmp)
            }else{
                //Тут подключаю файл редактирования аука
                //getAucionFromId(props, json.id)
            }
        }
    }
    props.changeLoader(false)
}

async function createCurrentTenantLSP(props, aucId){
    props.changeLoader(true)
    if(props.auctionsMy.currentTenant !== null){
        let method = 'POST'
        //if(props.auctionsMy.currentTenant.id !== null) method = 'PATCH'
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/currentTenant`
        const response = await fetch(endPoint, {
            method: method,
            body: JSON.stringify(props.auctionsMy.currentTenant),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
                //props.setUniversalError(tmp)
            }else{
                //Тут подключаю файл редактирования аука
                //getAucionFromId(props, json.id)
            }
        }
    }
    props.changeLoader(false)
}

function getAddressRegionName(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                            return createRegionCode(props.auctionsMy.currentTenant.address.region)
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getAddressRegionCode(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                            return getRegion(props.auctionsMy.currentTenant.address.region)
                        }
                    }
                }
            }
        }
    }
    return ''
}

function createRegionCode(data){
    if(data === 'Автономна Республіка Крим' || data === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ" || data === "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
        return '0100000000'
    }else if(data === 'Вінницька область' || data === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ" || data === "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
        return '0500000000'
    }else if(data === 'Волинська область' || data === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК" || data === "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
        return '0700000000'
    }else if(data === 'Дніпропетровська область' || data === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО" || data === "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
        return '1200000000'
    }else if(data === 'Донецька область' || data === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК" || data === "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
        return '1400000000'
    }else if(data === 'Житомирська область' || data === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР" || data === "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
        return '1800000000'
    }else if(data === 'Закарпатська область' || data === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД" || data === "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
        return '2100000000'
    }else if(data === 'Запорізька область' || data === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ" || data === "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
        return '2300000000'
    }else if(data === 'Івано-Франківська область' || data === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК" || data === "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
        return '2600000000'
    }else if(data === 'Київська область' || data === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ" || data === "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
        return '3200000000'
    }else if(data === 'Кіровоградська область' || data === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ" || data === "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
        return '3500000000'
    }else if(data === 'Луганська область' || data === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК" || data === "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
        return '4400000000'
    }else if(data === 'Львівська область' || data === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ" || data === "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
        return '4600000000'
    }else if(data === 'Миколаївська область' || data === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ" || data === "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
        return '4800000000'
    }else if(data === 'Одеська область' || data === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА" || data === "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
        return '5100000000'
    }else if(data === 'Полтавська область' || data === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА" || data === "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
        return '5300000000'
    }else if(data === 'Рівненська область' || data === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ" || data === "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
        return '5600000000'
    }else if(data === 'Сумська область' || data === "СУМСЬКА ОБЛАСТЬ/М.СУМИ" || data === "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
        return '5900000000'
    }else if(data === 'Тернопільська область' || data === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ" || data === "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
        return '6100000000'
    }else if(data === 'Харківська область' || data === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ" || data === "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
        return '6300000000'
    }else if(data === 'Херсонська область' || data === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН" || data === "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
        return '6500000000'
    }else if(data === 'Хмельницька область' || data === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ" || data === "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
        return '6800000000'
    }else if(data === 'Черкаська область' || data === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ" || data === "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
        return '7100000000'
    }else if(data === 'Чернівецька область' || data === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ" || data === "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
        return '7300000000'
    }else if(data === 'Чернігівська область' || data === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ" || data === "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
        return '7400000000'
    }else if(data === 'Київ' || data === "М.КИЇВ" || data === "8000000000+:+М.КИЇВ"){
        return '8000000000'
    }else if(data === 'Севастополь' || data === "М.СЕВАСТОПОЛЬ" || data === "8500000000+:+М.СЕВАСТОПОЛЬ"){
        return '8500000000'
    }else{
        return ''
    }
}
const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        shedullePeriod: state.start.shedullePeriod,
        deleteBankGroops: state.start.deleteBankGroops,
        deleteCurentBank: state.start.deleteCurentBank
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    minNumberOfQualifiedBids,
    setUniversalError,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    setDataAddress,

    changeLoader,
    openBankWindows,
    bankAccountGet
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)