import React from 'react';
import style from './error.module.css';

import {connect} from 'react-redux';

import {setUniversalError} from '../../../redux/actions.js';
import { isBRE, isBRD, isBRW, isAPE, isAPD } from '../../../redux/aucTypes.js';

const ContractApproweMain = ( props ) => {
    let tmp = JSON.parse(props.universalError)
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popup_closeX}
                    onClick={() => {props.setUniversalError(null)}}
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={style.popup_closeSvg} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                    </svg>
                </div>
                <div>{tmp.title}</div>
                <div>
                    {handleError(tmp.title, tmp.message, tmp.sellingMethod)}
                </div>
                <div className={style.buttonClose}
                    onClick={() => {props.setUniversalError(null)}}
                >Закрити</div>
            </div>
        </div>
    )
}

//@todo refactor
function handleError(title, data1, sellingMethod = null){
    let tmpText = []
    if(typeof data1 === "string" && data1 !== "Дані збережено" &&  data1 !== "Пропозиція відправлена на модерацію"){
        let data = null;
        try {
            data = JSON.parse(data1)
        } catch (error) {
            console.warn(error)
            data = data1
        }
        if(data.hasOwnProperty('message')){
            if(title === 'Помилка при активації аукціону'){
                if(JSON.stringify(data.message).indexOf('auctionPeriod') !== -1){
                    tmpText.push(<p>Вкажіть вірну дату та час початку аукціону</p>)
                }
                if(data.message.hasOwnProperty("accessDetails")){
                    if(data.message.accessDetails === "String value is too short."){
                        tmpText.push(<p>Необхідно заповнити поле "Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"</p>)
                    }
                    if(data.message.accessDetails.hasOwnProperty("uk_UA")){
                        for(let tt = 0; tt < data.message.accessDetails.uk_UA.length; tt++){
                            if(data.message.accessDetails.uk_UA[tt] === "String value is too short.") tmpText.push(<p>У полі "Порядок ознайомлення з майном, час і місце проведення огляду об'єкта" введено занадто короткий текст.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty("tenderAttempts")){
                    for(let tt = 0; tt < data.message.tenderAttempts.length; tt++){
                        if(data.message.tenderAttempts[tt] === "Int value should be less than or equal to 2."){
                            tmpText.push(<p>Значення поля "Лот виставляється" повинно бути менше або дорівнювати 2</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty("x_additionalInformation")){
                    if(data.message.x_additionalInformation === "String value is too short."){
                        tmpText.push(<p>Необхідно заповнити поле "Додаткові відомості"</p>)
                    }
                    if(data.message.x_additionalInformation.hasOwnProperty("uk_UA")){
                        for(let tt = 0; tt < data.message.x_additionalInformation.uk_UA.length; tt++){
                            if(data.message.x_additionalInformation.uk_UA[tt] === "String value is too short.") tmpText.push(<p>У полі "Додаткові відомості" введено занадто короткий текст.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty("x_documentRequirements")){
                    if(data.message.x_documentRequirements === "String value is too short."){
                        tmpText.push(<p>Необхідно заповнити поле "Перелік та вимоги до оформлення документів"</p>)
                    }
                    if(data.message.x_documentRequirements.hasOwnProperty("uk_UA")){
                        for(let tt = 0; tt < data.message.x_documentRequirements.uk_UA.length; tt++){
                            if(data.message.x_documentRequirements.uk_UA[tt] === "String value is too short.") tmpText.push(<p>У полі "Перелік та вимоги до оформлення документів" введено занадто короткий текст.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty('bankAccounts')){
                    if(Array.isArray(data.message.bankAccounts)){
                        for(let tt = 0; tt < data.message.bankAccounts.length; tt++){
                            if(data.message.bankAccounts[tt] === "Please provide at least 1 item."){
                                tmpText.push(<p>Необхідно додати хоча б один банківський рахунок</p>)
                            }
                            if(data.message.bankAccounts[tt] === "bank account with accountType='guarantee' is required"){
                                tmpText.push(<p>Відсутній рахунок для сплати гарантійного внеску</p>)
                            }
                            if(data.message.bankAccounts[tt] === "bank account with accountType='registrationFee' is required"){
                                tmpText.push(<p>Відсутній рахунок для сплати реєстраційного внеску</p>)
                            }
                            if(data.message.bankAccounts[tt].hasOwnProperty("accounts")){
                                for(let zz = 0; zz < data.message.bankAccounts[tt].accounts.length; zz++){
                                    if(data.message.bankAccounts[tt].accounts[zz].hasOwnProperty("accountIdentifications")){
                                        if(data.message.bankAccounts[tt].accounts[zz].accountIdentifications.hasOwnProperty("id")){
                                            if(data.message.bankAccounts[tt].accounts[zz].accountIdentifications.id === "Id required pattern ^[a-zA-Z]{2}[0-9]{27}$ with scheme value UA-IBAN"){
                                                tmpText.push(<p>Невірно внесені банківські реквізити. Номер рахунку має містити 27 цифр та бути записаним форматі "UAХХХ....ХХ"</p>)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }else{
                        if(data.message.bankAccounts.hasOwnProperty("2")){
                            if(data.message.bankAccounts["2"].hasOwnProperty("percent")){
                                if(data.message.bankAccounts["2"].percent === "Both holderType and percent fields must be filled or empty."){
                                    tmpText.push(<p>Поле "Тип власника об'єкта" і "Відсоток" повинні бути заповнені або залишитися порожніми.</p>)
                                }
                                for(let tt = 0; tt < data.message.bankAccounts["2"].percent.length; tt++){
                                    if(data.message.bankAccounts["2"].percent[tt] === "Int value should be less than or equal to 100."){
                                        tmpText.push(<p>Значення відсотку повинно бути менше або дорівнювати 100.</p>)
                                    }
                                }
                            }
                            if(data.message.bankAccounts["2"].hasOwnProperty("holderType")){
                                if(data.message.bankAccounts["2"].holderType === "Both holderType and percent fields must be filled or empty."){
                                    tmpText.push(<p>Поле "Тип власника об'єкта" і "Відсоток" повинні бути заповнені або залишитися порожніми.</p>)
                                }
                            }
                        }
                        if(data.message.bankAccounts.hasOwnProperty("3")){
                            if(data.message.bankAccounts["3"].hasOwnProperty("accounts")){
                                for(let tt = 0; tt < data.message.bankAccounts["3"].accounts.length; tt++){
                                    if(data.message.bankAccounts["3"].accounts[tt] === "This account type requires account with UAH currency."){
                                        tmpText.push(<p>Для цього типу рахунку потрібен рахунок у гривні.</p>)
                                    }
                                }
                            }
                        }
                    }
                }
                if(data.message.hasOwnProperty("previousAuctionValue")){
                    if(data.message.previousAuctionValue === "String value did not match validation regex"){
                        tmpText.push(<p>Стартова вартість попереднього аукціону не відповідає регулярному виразу перевірки</p>)
                    }
                    if(data.message.previousAuctionValue === "should be > than procedure"){
                        tmpText.push(<p>Стартова вартість попереднього аукціону має бути більшою за вартість поточної процедури</p>)
                    }
                }
                if(data.message.hasOwnProperty("items")){
                    if(Array.isArray(data.message.items)){
                        for(let tt = 0; tt < data.message.items.length; tt++){
                            if(data.message.items[tt] === "Please provide at least 1 items") tmpText.push(<p>Необхідно додати хоча б один лот.</p>)
                            if(data.message.items[tt].hasOwnProperty("description")){
                                if(data.message.items[tt].description === "Input must be a mapping or 'commercialSellEnglishProcedureItemsDescription' instance"){
                                    tmpText.push(<p>Необхідно заповнити обовʼязкове поле "Опис об'єкта".</p>)
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("unit")){
                                if(data.message.items[tt].unit === "This field is required"){
                                    tmpText.push(<p>В Складі лоту необхідно вказати "Одиниці виміру".</p>)
                                }
                                if(data.message.items[tt].unit === "Input must be a mapping or 'commercialSellEnglishProcedureItemsUnit' instance"){
                                    tmpText.push(<p>Необхідно вказати кількість одиниць та обрати одиницю виміру з випадаючого списку.</p>)
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("quantity")){
                                if(data.message.items[tt].quantity === "This field is required"){
                                    tmpText.push(<p>В Складі лоту необхідно вказати "Кількість одиниць".</p>)
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("address")){
                                if(data.message.items[tt].address.hasOwnProperty("addressID")){
                                    tmpText.push(<p>Необхідно заповнити адресу місцезнаходження майна.</p>)
                                }
                                if(data.message.items[tt].address.hasOwnProperty("locality")){
                                    tmpText.push(<p>Необхідно вказати географічні координати місцезнаходження майна.</p>)
                                }
                                if(data.message.items[tt].address.hasOwnProperty("region")){
                                    tmpText.push(<p>Необхідно вказати регіон (область) місцезнаходження майна.</p>)
                                }
                                if(data.message.items[tt].address.hasOwnProperty("streetAddress")){
                                    tmpText.push(<p>Необхідно вказати назву вулиці місцезнаходження майна.</p>)
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("registrationDetails")){
                                if(Array.isArray(data.message.items[tt].registrationDetails)){
                                    for(let zz = 0; zz < data.message.items[tt].registrationDetails.length; zz++){
                                        if(data.message.items[tt].registrationDetails[zz] === "Input must be a mapping or 'OtherPropertyItemRegistrationdetails' instance"){
                                            tmpText.push(<p>В Складі лоту необхідно вказати "Інформацію щодо реєстрації"</p>)
                                        }
                                        if(data.message.items[tt].registrationDetails[zz] === "Input must be a mapping or 'RealEstateItemRegistrationdetails' instance"){
                                            tmpText.push(<p>Заповніть поле "Стан державної реєстрації об''єкту"</p>)
                                        }
                                    }
                                }else{
                                    if(data.message.items[tt].registrationDetails === "This field is required"){
                                        tmpText.push(<p>В Складі лоту необхідно вказати Інформацію щодо реєстрації</p>)
                                    }
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("encumbrances")){
                                if(data.message.items[tt].encumbrances === "This field is required"){
                                    tmpText.push(<p>Необхідно внести інформацію про про наявні обтяження.</p>)
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("jointOwnership")){
                                if(data.message.items[tt].jointOwnership === "This field is required"){
                                    tmpText.push(<p>Необхідно внести інформацію про наявних співвласників.</p>)
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("utilitiesAvailability")){
                                if(data.message.items[tt].utilitiesAvailability === "This field is required"){
                                    tmpText.push(<p>Необхідно внести інформацію про наявні інженерні мережі.</p>)
                                }
                            }
                            if(data.message.items[tt].hasOwnProperty("itemProps")){
                                if(data.message.items[tt].itemProps.utilitiesAvailability === "This field is required"){
                                    if(isBRE(sellingMethod) || isBRD(sellingMethod) || isBRW(sellingMethod)){
                                        tmpText.push(<p>Поле "Наявність на земельній ділянці комунікацій" обов'язкове для заповнення.</p>)
                                    }else{
                                        tmpText.push(<p>Необхідно внести інформацію про наявні інженерні мережі.</p>)
                                    }
                                }
                            }
                        }
                    }else{
                        if(data.message.items.hasOwnProperty("1")){
                            if(data.message.items["1"].hasOwnProperty("unit")){
                                if(data.message.items["1"].unit === "This field is required"){
                                    tmpText.push(<p>В Складі лоту необхідно вказати "Одиниці виміру".</p>)
                                }
                                if(data.message.items["1"].unit === "Input must be a mapping or 'commercialSellEnglishProcedureItemsUnit' instance"){
                                    tmpText.push(<p>Необхідно вказати кількість одиниць та обрати одиницю виміру з випадаючого списку.</p>)
                                }
                            }
                            if(data.message.items["1"].hasOwnProperty("quantity")){
                                if(data.message.items["1"].quantity === "This field is required"){
                                    tmpText.push(<p>В Складі лоту необхідно вказати "Кількість одиниць".</p>)
                                }
                            }
                            if(data.message.items["1"].hasOwnProperty("registrationDetails")){
                                if(Array.isArray(data.message.items["1"].registrationDetails)){
                                    for(let zz = 0; zz < data.message.items["1"].registrationDetails.length; zz++){
                                        if(data.message.items["1"].registrationDetails[zz] === "Input must be a mapping or 'OtherPropertyItemRegistrationdetails' instance"){
                                            tmpText.push(<p>В Складі лоту необхідно вказати "Інформацію щодо реєстрації"</p>)
                                        }
                                        if(data.message.items["1"].registrationDetails[zz] === "Input must be a mapping or 'RealEstateItemRegistrationdetails' instance"){
                                            tmpText.push(<p>Заповніть поле "Стан державної реєстрації об''єкту"</p>)
                                        }
                                    }
                                }else{
                                    if(data.message.items["1"].registrationDetails === "This field is required"){
                                        tmpText.push(<p>В Складі лоту необхідно вказати Інформацію щодо реєстрації</p>)
                                    }
                                }
                            }
                        }
                    }
                }
                if(data.message.hasOwnProperty('arrests')){
                    if(data.message.arrests === 'Please provide at least 1 item.'){
                        tmpText.push(<p>Необхідно додати інформацію про арешти.</p>)
                    }
                }
                if(data.message.hasOwnProperty('currentTenants')){
                    if(data.message.currentTenants.hasOwnProperty('address')){
                        if(data.message.currentTenants.address.hasOwnProperty('locality')){
                            tmpText.push(<p>Необхідно вкзати координати місцезнаходження учасника з переважним правом.</p>)
                        }
                        if(data.message.currentTenants.address.hasOwnProperty('region')){
                            tmpText.push(<p>Необхідно вкзати адресу місцезнаходження учасника з переважним правом.</p>)
                        }
                        if(data.message.currentTenants.address.hasOwnProperty('streetAddress')){
                            tmpText.push(<p>Необхідно вкзати вулицю місцезнаходження учасника з переважним правом.</p>)
                        }
                    }
                    if(data.message.currentTenants.hasOwnProperty('priority')){
                        tmpText.push(<p>Необхідно вкзати ступінь пріоритетності учасника з переважним правом.</p>)
                    }
                    if(data.message.currentTenants.hasOwnProperty('identifier')){
                        if(data.message.currentTenants.identifier === 'currentTenants.identifier.id must have different values'){
                            tmpText.push(<p>Код ЄДРОПУ (ІПН або паспорт) Учасника з переважним правом та Учасника з переважним правом другої черги не може бути однаковим.</p>)
                        }
                        if(data.message.currentTenants.identifier.hasOwnProperty('legalName')){
                            tmpText.push(<p>Необхідно вкзати повну юридичну назву організації (ПІБ) учасника з переважним правом.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty('organizationEntity')){
                    if(data.message.organizationEntity.hasOwnProperty('address')){
                        if(data.message.organizationEntity.address.hasOwnProperty('locality')){
                            tmpText.push(<p>Необхідно вкзати координати місцезнаходження організатора аукціону.</p>)
                        }
                        if(data.message.organizationEntity.address.hasOwnProperty('region')){
                            tmpText.push(<p>Необхідно вкзати адресу місцезнаходження організатора аукціону.</p>)
                        }
                        if(data.message.organizationEntity.address.hasOwnProperty('streetAddress')){
                            tmpText.push(<p>Необхідно вкзати вулицю місцезнаходження організатора аукціону.</p>)
                        }
                    }
                    if(data.message.organizationEntity.hasOwnProperty('identifier')){
                        if(data.message.organizationEntity.identifier.hasOwnProperty('id')){
                            if(data.message.organizationEntity.identifier.id === 'Id required pattern ^[0-9]{8}$ with scheme value UA-EDR'){
                                tmpText.push(<p>Контактні дані організатора аукціону повинні містити вірні дані щодо Коду ЄДРПОУ або ІПН або паспорту .</p>)    
                            }
                        }
                        if(data.message.organizationEntity.identifier === 'This field is required'){
                            tmpText.push(<p>Необхідно вказати код ЄДРПОУ організатора аукціону.</p>)
                        }
                    }
                    if(data.message.organizationEntity.hasOwnProperty('contactPoint')){
                        if(data.message.organizationEntity.contactPoint.hasOwnProperty('telephone')){
                            if(data.message.organizationEntity.contactPoint.telephone === 'String value did not match validation regex.'){
                                tmpText.push(<p>Особа, відповідальна за забезпечення огляду, повинна містити вірні дані щодо номера телефону  у форматі +380ХХХХХХХХХ.</p>)
                            }
                        }
                        if(data.message.organizationEntity.contactPoint === 'This field is required'){
                            tmpText.push(<p>Необхідно вказати контактні дані організатора аукціону.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty('guarantee')){
                    if(data.message.guarantee === 'This field is required') tmpText.push(<p>Вкажіть "Гарантійний внесок" це поле обов'язкове</p>)
                    if(data.message.guarantee.hasOwnProperty('amount')){
                        tmpText.push(<p>Необхідно заповнити суму гарантійного внеску.</p>)
                    }
                }
                if(data.message.hasOwnProperty('minimalStep')){
                    if(data.message.minimalStep === 'This field is required'){
                        tmpText.push(<p>Необхідно вказати розмір мінімального кроку аукціону.</p>)
                    }
                    if(JSON.stringify(data.message.minimalStep).indexOf('Wrong minimalStep value') !== -1 && JSON.stringify(data.message.minimalStep).indexOf('Should be from') !== -1){
                        tmpText.push(<p>Невірно розраховано мінімальний крок аукціону. Розмір мінімальноко кроку складає 1% від стартової вартості.</p>)
                    }
                    if(data.message.minimalStep.hasOwnProperty('amount')){
                        if(data.message.minimalStep.amount === 'Decimal value should be greater than or equal to 0.01.'){
                            tmpText.push(<p>Decimal value should be greater than or equal to 0.01.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty('registrationFee')){
                    if(data.message.registrationFee === 'This field is required'){
                        tmpText.push(<p>Необхідно вказати реєстраційний внесок.</p>)
                    }
                }
                if(data.message.hasOwnProperty('value')){
                    if(data.message.value === 'This field is required') tmpText.push(<p>Вкажіть "Стартову ціну лота" це поле обов'язкове</p>)
                    if(data.message.value.hasOwnProperty('amount')){
                        for(let tt = 0; tt < data.message.value.amount.length; tt++){
                            if(data.message.value.amount[tt] === 'Decimal value should be greater than 0.0.'){
                                tmpText.push(<p>Стартова ціна повинна бути більше 0.0.</p>)
                            }
                        }
                    }
                }
                if(data.message.hasOwnProperty('documents')){
                    for(let tt = 0; tt < data.message.documents.length; tt++){
                        if(data.message.documents[tt] === "document with documentType=illustration is required"){
                            if(sellingMethod !== null && (isAPE(sellingMethod) || isAPD(sellingMethod))){
                                tmpText.push(<p>Необхідно додати документ "Ілюстрації"</p>)
                            }else{
                                tmpText.push(<p>Необхідно додати документ з типом "Фотографічні зображення земельної ділянки та ілюстрації"</p>)
                            }                           
                        }
                        if(data.message.documents[tt] === "document with documentType=technicalSpecifications is required") tmpText.push(<p>Необхідно додати документ з типом "Копії документів та матеріалів на лот"</p>)
                        if(data.message.documents[tt] === "document with documentType=contractProforma is required") tmpText.push(<p>Необхідно додати документ з типом "Проект договору оренди"</p>)
                    }
                }
                if(data.message.hasOwnProperty('contactPoint')){
                    if(data.message.contactPoint === 'This field is required'){
                        tmpText.push(<p>Необхідно вказати дані Особи відповідальної за забезпечення огляду.</p>)
                    }
                    if(data.message.contactPoint.indexOf('telephone')){
                        if(data.message.contactPoint.telephone === 'String value did not match validation regex.'){
                            tmpText.push(<p>Контактні дані організатора аукціону повинні містити вірні дані щодо  номеру телефону у форматі +380ХХХХХХХХХ.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty('dutchStep')){
                    if(data.message.dutchStep.hasOwnProperty('dutchStepQuantity')){
                        if(JSON.stringify(data.message.dutchStep.dutchStepQuantity).indexOf('Int value should be greater than or equal to 1.') !== -1){
                            tmpText.push(<p>Необхідно вказати Кількість кроків.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty('leaseDuration')){
                    if(Array.isArray(data.message.leaseDuration)){
                        for(let tt = 0; tt < data.message.leaseDuration.length; tt++){
                            if(data.message.leaseDuration[tt] === "Can not parse P as ISO8061 duration type"){
                                tmpText.push(<p>Неможливо розпізнати інформацію дати «Строку оренди»</p>)
                            }
                        }
                    }else{
                        if(data.message.leaseDuration === 'This field is required'){
                            tmpText.push(<p>Необхідно вказати "Строк оренди"</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty('valuePeriod')){
                    if(data.message.valuePeriod === 'This field is required'){
                        tmpText.push(<p>Необхідно вказати "Період розрахунку стартової вартості"</p>)
                    }
                }
                if(data.message.hasOwnProperty("restrictions")){
                    if(data.message.restrictions.hasOwnProperty("uk_UA")){
                        for(let tt = 0; tt < data.message.restrictions.uk_UA.length; tt++){
                            if(data.message.restrictions.uk_UA[tt] === "String value is too short.") tmpText.push(<p>У полі "Обмеження" введено занадто короткий текс.</p>)
                        }
                    }
                }
                if(data.message.hasOwnProperty("procedure")){
                    if(data.message.procedure.indexOf("System already has auctions in active status with registry object:") !== -1){
                        tmpText.push(<p>Система вже має аукціони в активному статусі з  цим об'єктом реєстру</p>)
                    }
                }
            }
            if(title === 'Помилка при редагуванні аукціону'){
                if(data.message === "Access Denied") tmpText.push(<p>Доступ заборонений</p>)
                
                if(data.message.hasOwnProperty('bankAccounts')){
                    if(Array.isArray(data.message.bankAccounts)){
                        for(let tt = 0; tt < data.message.bankAccounts.length; tt++){
                            if(data.message.bankAccounts[tt] === "bank account with accountType='registrationFee' is required") tmpText.push(<p>Неможливо видалити банківський рахунок з типом "Реєстраційний внесок", оскільки являється обов'язковим.</p>)
                            if(data.message.bankAccounts[tt] === "bank account with accountType='guarantee' is required") tmpText.push(<p>Неможливо видалити банківський рахунок з типом "Гарантійний внесок", оскільки являється обов'язковим.</p>)
                            if(data.message.bankAccounts[tt].hasOwnProperty('accounts')){
                                for(let zz = 0; zz < data.message.bankAccounts[tt].accounts.length; zz++){
                                    if(data.message.bankAccounts[tt].accounts[zz] === "This account type requires account with UAH currency.") tmpText.push(<p>Для цього типу рахунку потрібен рахунок в UAH (українські гривні).</p>)
                                }
                            }
                        }
                    }else{
                        if(data.message.bankAccounts.hasOwnProperty("2")){
                            if(data.message.bankAccounts["2"].hasOwnProperty("percent")){
                                if(data.message.bankAccounts["2"].percent === "Both holderType and percent fields must be filled or empty."){
                                    tmpText.push(<p>Поле "Тип власника об'єкта" і "Відсоток" повинні бути заповнені або залишитися порожніми.</p>)
                                }
                                for(let tt = 0; tt < data.message.bankAccounts["2"].percent.length; tt++){
                                    if(data.message.bankAccounts["2"].percent[tt] === "Int value should be less than or equal to 100."){
                                        tmpText.push(<p>Значення відсотку повинно бути менше або дорівнювати 100.</p>)
                                    }
                                }
                            }
                            if(data.message.bankAccounts["2"].hasOwnProperty("holderType")){
                                if(data.message.bankAccounts["2"].holderType === "Both holderType and percent fields must be filled or empty."){
                                    tmpText.push(<p>Поле "Тип власника об'єкта" і "Відсоток" повинні бути заповнені або залишитися порожніми.</p>)
                                }
                            }
                        }
                        if(data.message.bankAccounts.hasOwnProperty("3")){
                            if(data.message.bankAccounts["3"].hasOwnProperty("accounts")){
                                for(let tt = 0; tt < data.message.bankAccounts["3"].accounts.length; tt++){
                                    if(data.message.bankAccounts["3"].accounts[tt] === "This account type requires account with UAH currency."){
                                        tmpText.push(<p>Для цього типу рахунку потрібен рахунок у гривні.</p>)
                                    }
                                }
                            }
                        }
                    }
                }

                if(Array.isArray(data.message)){
                    for(let tt = 0; tt < data.message.length; tt++){
                        if(data.message[tt] === "Forbidden state - active_tendering. Cannot update procedure in current state") tmpText.push(<p>Forbidden state - active_tendering. Cannot update procedure in current state.</p>)
                    }
                }
            }
            if(title === 'Помилка при скасуванні аукціону'){
                if(data.message.hasOwnProperty('reason')){
                    tmpText.push(<p>Будь ласка вкажіть причину скасування аукціону</p>)
                }
                if(data.message.hasOwnProperty('datePublished')){
                    tmpText.push(<p>Будь ласка вкажіть дату скасування аукціону</p>)
                }
                if(data.message.hasOwnProperty('documents')){
                    if(data.message.documents.hasOwnProperty('description')){
                        tmpText.push(<p>Будь ласка додайте опис документа</p>)
                    }
                }
                if(data.message.hasOwnProperty('date')){
                    tmpText.push(<p>Дата скасування має бути між датою публікації аукціону та поточною датою</p>)
                }
            }
            if(title === 'Помилка оновлення данних контракту'){
                if(data.message.hasOwnProperty('contractTotalValue')){
                    if(data.message.contractTotalValue === 'Decimal value should be less than or equal to'){
                        tmpText.push(<p>Значення має бути менше або дорівнювати 9999999999999.99</p>)
                    }
                }
                if(data.message.hasOwnProperty('reason')){
                    if(data.message.reason === 'This field is required'){
                        tmpText.push(<p>Поле "Причина рішення згідно якого проводиться пролонгація" є обов'язковим</p>)
                    }
                }
            }
            if(title === 'Помилка при відхиленні аварду'){
                if(data.message.indexOf('Forbidden status. Cannot discqualify award that comes next to active award object with status pending_waiting') !== -1) tmpText.push(<p>Заборонений статус. Неможливо скасувати учасника, який йде після "Переможця" - учасника  зі статусом "Очікує рішення"</p>)
            }
            if(title === 'Помилка при затвердженні аварду'){
                if(data.message.hasOwnProperty('documents')){
                    for(let tt = 0; tt < data.message.documents.length; tt++){
                        if(data.message.documents[tt] === 'document with documentType=assetNotice is required') tmpText.push(<p>Необхідно додати документ "Рішення про реалізацію".</p>)
                    }
                }
            }
            if(title === 'Помилка при збереженні документу'){
                for(let tt = 0; tt < data.message.length; tt++){
                    if(data.message[tt] === 'Forbidden. Rectification period is over. ') tmpText.push(<p>Заборонено. Період редагування завершено.</p>)
                }
            }
        }
    }
    // console.log(data1)
    if (Array.isArray(data1)) {
        tmpText = data1.map((item) => typeof item === "string" ? <p>- {item}</p> : null)
    }
    if (tmpText.length > 5) {
        tmpText = tmpText.slice(0, 5);
    }
    if(tmpText.length > 0){
        return tmpText
    }
    return data1
}

const mapStateToProps = state => {
    return {
        universalError: state.start.universalError,
    }
}

const mapDispatchToProps = {
    setUniversalError
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractApproweMain)