import React, {useState} from 'react';
import styles from './createBidNew.module.css';

import {connect} from 'react-redux';
import {changeBidAward, changeBidQuantity} from '../../../../redux/actions.js';
import {auctionsBidsGetId, changeCreateBidDocType, changeBidErrorPopUpStatus} from '../../../../redux/actions/auctionBid/auctionBid.js';

import {changeLoader, setUniversalError} from '../../../../redux/actions.js';
import {changeDocumentArrFiles, changeDocumentCountArrFiles, changeDocumentBidBlockStatus, changeDocumentBidPublicName} from '../../../../redux/actions/documents/documents.js';
import InstructionBlock from '../../../elements/viewData/instructionBlock/instructionBlock';
import TextFull from '../../../elements/viewData/textFull';

import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD,
    isLLP, isLRE, isLSE, isLSP, isRMA, isBSE, isBSD, isALE, isCSE, isCSD,
    isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
    isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD } from '../../../../redux/aucTypes';

import {
    setDataAddress,
} from '../../../../redux/createAddress';
import {
    saveArrBids,
    saveAuctionId
} from '../../../../redux/allAuctionBids.js';

const CreateBid = (props) => {
    const [bidAwardValue, setBidAwardValue] = useState(null)

    let tmpBaseUrl = null;
    let tmpId = null;
    if(window.location.pathname.indexOf('/auctions/') !== -1){
        tmpBaseUrl = window.location.pathname.split('/auctions/');
        tmpId = tmpBaseUrl[1].split('/')
        tmpId = tmpId[0]
    }
    let minCoast = null;
    if(props.auctionId !== null){
        minCoast = props.auctionId.value.amount;
    }
    return viewIsFinAuth(props, tmpId, minCoast, bidAwardValue, setBidAwardValue)
}

function viewIsFinAuth(props, tmpId, minCoast, bidAwardValue, setBidAwardValue){
    let tmpSumm = ''
    if(props.userProfiles.isFinAuth){
        if(props.bidSumm !== null){
            if(props.bidSumm.summ !== null){
                tmpSumm = props.bidSumm.summ
            }
        }
        if(isLAP(props.auctionId.sellingMethod)){
            tmpSumm = 0
            if(props.auctionId.guarantee.amount !== null) tmpSumm += props.auctionId.guarantee.amount
            if(props.auctionId.registrationFee.amount !== null) tmpSumm += props.auctionId.registrationFee.amount
        }
        return (
            <div>
                <div className={styles.blockBidLot}>
                    <div className={styles.blockBidLot_title}>Ваша пропозиція</div>
                    <InstructionBlock
                        title='Важлива інформація'
                        content={
                            <>
                                <p>Для участі в даному аукціоні на Вашому балансі має бути сума у розмірі: {startCostAuction(tmpSumm)} грн.</p>
                                <p>Реєстраційний внесок є платою за участь в торгах та не підлягає поверненню.</p>
                                <p>У випадку вашої перемоги в аукціоні гарантійний внесок залишається заблокованим до завершення процедури торгів. У разі дискваліфікації учасник втрачає гарантійний внесок. В такому випадку, згідно регламенту гарантійний внесок в повному обсязі перераховується на організатора торгів.</p>

                                <p>{`Детальніше в `}
                                <a className={styles.instrLink} href='https://www.dto.com.ua/info/rules' target='blank'>Регламенті роботи ЕТС Prozorro:Продажі</a>
                                {` або в іншому нормативно-правовому акті, який регулює проведення даного аукціону.`}</p>
                            </>
                        }
                    />
                    {createInputValue(props, minCoast, bidAwardValue, setBidAwardValue)}
                    {checkBoxView(props)}
                    {createDraftBid(props, tmpId, minCoast, bidAwardValue)} 
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className={styles.blockBidLot}>
                <div className={styles.blockBidLot_title}>Ваша пропозиція</div>
                <InstructionBlock
                    title='Важлива інформація'
                    content={
                        <>
                            <p>Дані про організацію-користувача наразі не підтверджено необхідно пройти ідентифікацію користувача</p>
                        </>
                    }
                />
            </div>
        </div>
    )
}

function checkBoxView(props){
    if (isLLE(props.auctionId.sellingMethod) ||
        isLLP(props.auctionId.sellingMethod) ||
        isLLD(props.auctionId.sellingMethod) ||
        isLRE(props.auctionId.sellingMethod) ||
        isLSE(props.auctionId.sellingMethod) ||
        isLSP(props.auctionId.sellingMethod)
    ){
        return checkBox(props)
    }else{
        return checkBoxAll(props)
    }
}

function checkBoxAll(props){
    //@todo refactor
    return viewCheckBoxAll(props, 0)
}

function viewCheckBoxAll(props, flag){
    let tmpStylesPersonalData = null
    if(props.checkBox.waitPersonalData === false){
        tmpStylesPersonalData = styles.pseudoCheckboxOn
    }else{
        tmpStylesPersonalData = styles.pseudoCheckboxOff
    }
    let tmpStylesZgoda = null
    if(props.checkBox.waitZgoda === false){
        tmpStylesZgoda = styles.pseudoCheckboxOn
    }else{
        tmpStylesZgoda = styles.pseudoCheckboxOff
    }
    let tmpStylesZgodaReglament = null
    if(props.checkBox.waitZgodaReglament === false){
        tmpStylesZgodaReglament = styles.pseudoCheckboxOn
    }else{
        tmpStylesZgodaReglament = styles.pseudoCheckboxOff
    }
    if(flag === 0){
        return (
            <>
                <div className={styles.chekbox}>
                    <div
                        className={tmpStylesPersonalData}
                        onClick={
                            () => {
                                if(props.checkBox.waitPersonalData === false){
                                    props.setDataAddress(true, 'CHECKBOX_BID_ALL_1')
                                }else{
                                    props.setDataAddress(false, 'CHECKBOX_BID_ALL_1')
                                }
                            }
                        }
                    ></div>
                    <div>Підтверджую згоду на обробку моїх персональних даних</div>
                </div>
                <div className={styles.chekbox}>
                    <div
                        className={tmpStylesZgoda}
                        onClick={
                            () => {
                                if(props.checkBox.waitZgoda === false){
                                    props.setDataAddress(true, 'CHECKBOX_BID_ALL_2')
                                }else{
                                    props.setDataAddress(false, 'CHECKBOX_BID_ALL_2')
                                }
                            }
                        }
                    ></div>
                    <div>Підтверджую згоду з умовами проведення аукціону</div>
                </div>
                <div className={styles.chekbox}>
                    <div
                        className={tmpStylesZgodaReglament}
                        onClick={
                            () => {
                                if(props.checkBox.waitZgodaReglament === false){
                                    props.setDataAddress(true, 'CHECKBOX_BID_ALL_3')
                                }else{
                                    props.setDataAddress(false, 'CHECKBOX_BID_ALL_3')
                                }
                            }
                        }
                    ></div>
                    <div>Підтверджую згоду з умовами проведення аукціону/з Регламентом та тарифами ознайомлений</div>
                </div>
            </>
        )
    }
    return null
}

function checkBox(props){
    let tmpStyles = null
    if(props.checkBox.wait === false){
        tmpStyles = styles.pseudoCheckboxOn
    }else{
        tmpStyles = styles.pseudoCheckboxOff
    }
    let tmpCheckBox = null
    if(props.checkBox.agreement === false){
        tmpCheckBox = styles.pseudoCheckboxOn
    }else{
        tmpCheckBox = styles.pseudoCheckboxOff
    }
    return (
        <>
            <div className={styles.chekbox}>
                <div
                    className={tmpStyles}
                    onClick={
                        () => {
                            if(props.checkBox.wait === false){
                                props.setDataAddress(true, 'CHECKBOX_WAIT')
                            }else{
                                props.setDataAddress(false, 'CHECKBOX_WAIT')
                            }
                        }
                    }
                ></div>
                <div>Попередня згода на очікування</div>
            </div>
            <div className={styles.chekbox}>
                <div
                    className={tmpCheckBox}
                    onClick={
                        () => {
                            if(props.checkBox.agreement === false){
                                props.setDataAddress(true, 'CHECKBOX_AGREEMENT')
                            }else{
                                props.setDataAddress(false, 'CHECKBOX_AGREEMENT')
                            }
                        }
                    }
                ></div>
                <div>Згода на обробку персональних даних</div>
            </div>
        </>
    )
}

function createInputValue(props, minCoast, bidAwardValue, setBidAwardValue){
    let tmp = null;
    let reestracGrn = null
    if(props.auctionId !== null){
        if(props.auctionId.hasOwnProperty('dutchStep') === true){
            if(props.auctionId.dutchStep !== null){
                if(props.auctionId.dutchStep.hasOwnProperty('dutchStepValue') === true){
                    if(props.auctionId.dutchStep.dutchStepValue !== null){
                        if(props.auctionId.dutchStep.dutchStepValue.hasOwnProperty('amount') === true){
                            if(props.auctionId.dutchStep.dutchStepValue.amount !== null){
                                reestracGrn = props.auctionId.dutchStep.dutchStepValue.amount
                            }
                        }
                    }
                }
            }
        }
        if (
            isRCD(props.auctionId.sellingMethod) ||
            isSUD(props.auctionId.sellingMethod) ||
            isGFD(props.auctionId.sellingMethod) ||
            isLLD(props.auctionId.sellingMethod) ||
            isBSD(props.auctionId.sellingMethod) ||
            isCSD(props.auctionId.sellingMethod) ||
            isBRD(props.auctionId.sellingMethod) ||
            //isRLE(props.auctionId.sellingMethod) ||
            isRLD(props.auctionId.sellingMethod) ||
            //isCLE(props.auctionId.sellingMethod) ||
            isCLD(props.auctionId.sellingMethod) ||
            isSPD(props.auctionId.sellingMethod) ||
            isNLD(props.auctionId.sellingMethod)
            || isAPD(props.auctionId.sellingMethod)
        ){
            {/*tmp = <div>Реєстраційний внесок: {reestracGrn} грн.</div>*/}
        }else{
            tmp = <>
                    <div className={styles.bidLotCost}>
                    <TextFull
                        title='Початкова ціна продажу лоту:'
                        data={`${startCostAuction(minCoast)} грн.`}
                    />
                    </div>
                    <div className={styles.bidLotCost_input}>
                        
                            <input 
                                className={styles.inputNumber}
                                type='number'
                                value={bidAwardValue}
                                // value={props.bidAward}
                                onChange={(e) => {
                                    // let tmp = e.target.value.replace(/,/gi, '.')
                                    setBidAwardValue(e.target.value.replace(/,/gi, '.'))
                                    // props.changeBidAward(tmp)
                                }}
                            />
                        <div className={styles.bidLotCost_title}>Цінова пропозиція</div>
                    </div>
                    <div className={styles.bidLotCost_descr}>{`Ваша пропозиція має бути не менш ніж ${startCostAuction(minCoast)} грн.`}</div>
                    <div className={styles.bidLotBtn}></div>
                </>
        }
    }
    return tmp
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(data);
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}

function createDraftBid(props, tmpId, minCoast, bidAwardValue){
    switch (true) {
        case isRCD(props.auctionId.sellingMethod):
        case isSUD(props.auctionId.sellingMethod):
            if (
                props.checkBox.waitPersonalData &&
                props.checkBox.waitZgoda &&
                props.checkBox.waitZgodaReglament
            ) {
                return enableButtons(props, tmpId)
            }
            return disableButtons()

        case isGFD(props.auctionId.sellingMethod):
        case isBSD(props.auctionId.sellingMethod):
        case isCSD(props.auctionId.sellingMethod):
        case isBRD(props.auctionId.sellingMethod):
        case isRLD(props.auctionId.sellingMethod):
        case isCLD(props.auctionId.sellingMethod):
        case isSPD(props.auctionId.sellingMethod):
        case isNLD(props.auctionId.sellingMethod):
        case isAPD(props.auctionId.sellingMethod):
            if (
                props.checkBox.waitPersonalData &&
                props.checkBox.waitZgoda &&
                props.checkBox.waitZgodaReglament
            ) {
                return enableButtons(props, tmpId)
            }
            return disableButtons()

        case isLLE(props.auctionId.sellingMethod):
        case isLLP(props.auctionId.sellingMethod):
        case isLRE(props.auctionId.sellingMethod):
        case isLSE(props.auctionId.sellingMethod):
        case isLSP(props.auctionId.sellingMethod):
            if (props.checkBox.agreement === true && props.checkBox.wait === true) {
                if (bidAwardValue >= minCoast) {
                    return enableButtons(props, tmpId, bidAwardValue)
                }
                return disableButtons()
            }
            return disableButtons()

        case isLLD(props.auctionId.sellingMethod):
            if (props.checkBox.agreement === true && props.checkBox.wait === true) {
                return enableButtons(props, tmpId)
            }
            return disableButtons()

        default:
            if (bidAwardValue >= minCoast) {
                if (
                    props.checkBox.waitPersonalData &&
                    props.checkBox.waitZgoda &&
                    props.checkBox.waitZgodaReglament
                ) {
                    return enableButtons(props, tmpId, bidAwardValue)
                }

            }
            return disableButtons()
    }
}

function enableButtons(props, tmpId, bidAwardValue){
    return (
        <div
            className={styles.button}
            onClick={
                ()=>{
                    saveBidDocument(props, tmpId, bidAwardValue)
                }
            }
        >
            Створити чернетку
        </div>
    )
}

function disableButtons(){
    return (
        <div
            className={styles.button_disabled}
        >
            Створити чернетку
        </div>
    )
}

async function saveBidDocument(props, tmpId, bidAwardValue){
    await props.changeLoader(true)
    let tmpData = null;

    switch(true){
        case isTIE(props.auctionId.sellingMethod):
        case isGFE(props.auctionId.sellingMethod):
        case isLLE(props.auctionId.sellingMethod):
        case isLLP(props.auctionId.sellingMethod):
            tmpData = {
                classificationId: props.auctionId.items[0].classification.id,
                unitCode: props.auctionId.items[0].unit.code,
                documentIds: [],
                qualified: true,
                value: {
                    currency: 'UAH',
                    amount: Number(bidAwardValue)
                },
                quantity: props.auctionId.items[0].quantity
            }
            break;
        case isRMA(props.auctionId.sellingMethod):
            tmpData = {
                classificationId: props.auctionId.items[0].classification.id,
                unitCode: props.auctionId.items[0].unit.code,
                documentIds: [],
                qualified: true,
                value: {
                    currency: 'eurocent',
                    amount: Number(bidAwardValue)
                },
                quantity: props.auctionId.items[0].quantity
            }
            break;
        case isSUE(props.auctionId.sellingMethod):
            tmpData = {
                classificationId: props.auctionId.items[0].classification.id,
                unitCode: props.auctionId.items[0].unit.code,
                documentIds: [],
                qualified: true,
                value: {
                    currency: 'UAH',
                    amount: Number(bidAwardValue)
                },
                quantity: props.auctionId.items[0].quantity
            }
            break;
        case isRCE(props.auctionId.sellingMethod):
            tmpData = {
                classificationId: props.auctionId.items[0].classification.id,
                unitCode: props.auctionId.items[0].unit.code,
                documentIds: [],
                qualified: true,
                value: {
                    currency: 'UAH',
                    amount: Number(bidAwardValue)
                },
                quantity: props.auctionId.items[0].quantity
            }
            break;
        case isRCD(props.auctionId.sellingMethod):
        case isSUD(props.auctionId.sellingMethod):
        case isGFD(props.auctionId.sellingMethod):
        case isLLD(props.auctionId.sellingMethod):
        case isBSD(props.auctionId.sellingMethod):
        case isCSD(props.auctionId.sellingMethod):
        case isBRD(props.auctionId.sellingMethod):
        //case isRLE(props.auctionId.sellingMethod):
        case isRLD(props.auctionId.sellingMethod):
        //case isCLE(props.auctionId.sellingMethod):
        case isCLD(props.auctionId.sellingMethod):
        case isSPD(props.auctionId.sellingMethod):
        case isNLD(props.auctionId.sellingMethod):
        case isAPD(props.auctionId.sellingMethod):
            tmpData = {
                classificationId: props.auctionId.items[0].classification.id,
                unitCode: props.auctionId.items[0].unit.code,
                documentIds: [],
                qualified: true,
                quantity: props.auctionId.items[0].quantity
            }
            break;
        default:
            tmpData = {
                classificationId: props.auctionId.items[0].classification.id,
                unitCode: props.auctionId.items[0].unit.code,
                documentIds: [],
                qualified: true,
                value: {
                    currency: 'UAH',
                    amount: Number(bidAwardValue)
                },
                quantity: props.auctionId.items[0].quantity
            }
            break;
    }


    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/bid`;
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(tmpData),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = null
            if(json.message === 'Forbidden status. Cannot create bid object with status active_auction'){
                tmp = JSON.stringify({title: 'Помилка при створенні чернетки', message: 'Аукціон розпочався, створення ставки заборонено'})
            }else{
                tmp = JSON.stringify({title: 'Помилка при створенні чернетки', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
            props.changeBidAward('')
            props.changeBidQuantity('')
        }else{
            /*let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag,
                params: '?limit=20'
            }*/
            //await props.auctionsBidsGetId(data, props.token.access_token, tmpId)
            getPublicApiAuction(props, props.auctionId.id)
            getDocumentsAuctionBidsAll(props, json.id)

            props.changeBidAward('')
            props.changeBidQuantity('')
        }
    }
    await props.setDataAddress(false, 'CHECKBOX_BID_ALL_1')
    await props.setDataAddress(false, 'CHECKBOX_BID_ALL_2')
    await props.setDataAddress(false, 'CHECKBOX_BID_ALL_3')
    await props.changeLoader(false)
}

async function getDocumentsAuctionBidsAll(props, id){
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${id}`

    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
        }else{
            await props.saveArrBids(json)
        }
    }
}

async function getPublicApiAuction(props, aucId){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/auction/${aucId}`
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        // bidAward: state.start.bidAward,
        documentCount: state.start.documentCount,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
        checkBox: state.start.checkBox,
        userProfiles: state.start.userProfiles,
        bidSumm: state.start.bidSumm,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    changeDocumentArrFiles,
    changeDocumentCountArrFiles,
    changeDocumentBidBlockStatus,
    changeDocumentBidPublicName,
    auctionsBidsGetId,
    changeCreateBidDocType,
    changeBidErrorPopUpStatus,
    changeBidQuantity,

    setUniversalError,
    changeLoader,
    setDataAddress,

    saveArrBids,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBid)