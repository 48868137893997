import React from 'react';
import styles from './relatedOrganizations.module.css';

import {connect} from 'react-redux';

import {PROCEDURE_NAME} from '../../../../redux/procedure_v3.35.2.uk';

import {
    setEditAuctionFromId,

    getMyAuction,
    changeLoader,
} from '../../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId
} from '../../../../redux/actions/discount/discount.js';

import {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionСalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
} from '../../../../redux/actions.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {modifiDateString} from '../../../../redux/modifiDate'

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import RegistrationFee from '../windowAuctionRegistrationFee'
import Organization from '../zuAddressOrganization'
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import RelatedOrganizationsOwnershipType from '../auctionsCommon/zuRelatedOrganizationsOwnershipType'

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import {disabletElem} from '../../disabled'

const WindowAuctionDgf = ( props ) => {
    return (
        <>
            <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.legalName}</div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        id="filled-required"
                        type='number'
                        className={styles.inputText}
                        label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.hoursBy.value.legalName}
                        value={props.schedule.hoursBy.value}
                        variant="outlined"
                        onChange={
                            (e) => {
                                if(props.schedule.hoursBy.by === 'day'){
                                    if(Number(e.target.value) <= 24){
                                        props.setDataAddress(Math.trunc(Number(e.target.value)), 'SET_SCHEDULE_HOURS_BY_VALUE')
                                    }
                                }else{
                                    props.setDataAddress(e.target.value, 'SET_SCHEDULE_HOURS_BY_VALUE')
                                }
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label="На"
                        defaultValue={props.schedule.hoursBy.by}
                        onChange={(e)=>{
                            props.setDataAddress(e.target.value, 'SET_SCHEDULE_HOURS_BY_BY')
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='day'>день</option>
                        <option key={2} className={styles.hoverOptions} value='week'>тиждень</option>
                        <option key={3} className={styles.hoverOptions} value='month'>місяць</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        id="filled-required"
                        type='number'
                        className={styles.inputText}
                        label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.daysBy.value.legalName}
                        value={props.schedule.daysBy.value}
                        variant="outlined"
                        onChange={
                            (e) => {
                                if(props.schedule.daysBy.by === 'week'){
                                    if(Number(e.target.value) <= 7){
                                        props.setDataAddress(Math.trunc(Number(e.target.value)), 'SET_SCHEDULE_DAYS_BY_VALUE')
                                    }
                                }else if(props.schedule.daysBy.by === 'month'){
                                    if(Number(e.target.value) <= 31){
                                        props.setDataAddress(Math.trunc(Number(e.target.value)), 'SET_SCHEDULE_DAYS_BY_VALUE')
                                    }
                                }
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label="На"
                        defaultValue={props.schedule.daysBy.by}
                        onChange={(e)=>{
                            props.setDataAddress(e.target.value, 'SET_SCHEDULE_DAYS_BY_BY')
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={2} className={styles.hoverOptions} value='week'>тиждень</option>
                        <option key={3} className={styles.hoverOptions} value='month'>місяць</option>
                    </TextField>
                </div>
            </div>
            {disableShedule(props)}
        </>
    )
}

function disableShedule(props){
    let tmp = null
    if(
        props.schedule.hoursBy.length === 0 &&
        props.schedule.daysBy.length === 0 
    ){
        if(
            props.schedule.hoursBy.value !== '' &&
            props.schedule.hoursBy.by !== 'null' &&
            props.schedule.hoursBy.by !== null &&
            props.schedule.daysBy.value !== '' &&
            props.schedule.daysBy.by !== 'null' &&
            props.schedule.daysBy.by !== null
        ){
            tmp = null
        }else{
            tmp =<>
                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.period.legalName}</div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabletElem(props.auctionsMy, '00')}
                            id="filled-required"
                            className={styles.inputText}
                            label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.other.legalName}
                            value={props.schedule.other}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'SET_SCHEDULE_OTHER')
                                }
                            }
                            helperText={textHelp(props)}
                        />
                    </div>
                </div>
            </>
        }
    }else{
        tmp =<>
            <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.period.legalName}</div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        id="filled-required"
                        className={styles.inputText}
                        label={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.other.legalName}
                        value={props.schedule.other}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.setDataAddress(e.target.value, 'SET_SCHEDULE_OTHER')
                            }
                        }
                        helperText={textHelp(props)}
                    />
                </div>
            </div>
        </>
    }
    let tmpSaveArr = null
    //if(props.schedule.other !== '' && props.schedule.other !== null){
        tmpSaveArr = <>
            <div>{viewPeriodArr(props)}</div>
            <div>{viewNewPeriodArr(props)}</div>
            <div className={styles.bottonBlock}>
                <Button
                    disabled={disabletElem(props.auctionsMy, '00')}
                    className={styles.buttonExitDocument}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.setDataAddress(true, 'SET_SHEDULE_PERIOD')
                        }
                    }
                >
                    Додати період
                </Button>
            </div>
        </>
    //}
    return ( 
        <>
            {tmp}
            {tmpSaveArr}
        </>
    )
}

function viewNewPeriodWeekdaysArr(data){
    if(data.length > 0){
        return data.map(
            (e) => {
                return (
                    <div className={styles.weekend_elem}>
                        <div className={styles.weekend_data}>День тижня: {e.weekday}</div>
                        <div className={styles.weekend_data}>Початок: {e.startTime}</div>
                        <div className={styles.weekend_data}>Кінець: {e.endTime}</div>
                    </div>
                )
            }
        )
    }
}

function viewPeriodArr(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.leaseRules !== null){
            if(props.auctionsMy.leaseRules.hasOwnProperty('schedule') === true){
                if(props.auctionsMy.leaseRules.schedule !== null){
                    if(props.auctionsMy.leaseRules.schedule.hasOwnProperty('periods') === true){
                        if(props.auctionsMy.leaseRules.schedule.periods !== null){
                            if(props.auctionsMy.leaseRules.schedule.periods.length > 0){
                                return (
                                    <>
                                        <div>Збережені періоди</div>
                                        <div>{periodArrElem(props)}</div>
                                    </>
                                )
                            }
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewNewPeriodArr(props){
    if(props.shedullePeriod.newFullPeriod.length > 0){
        return (
            <>
                <div>Не збережені періоди</div>
                <div>{newPeriodArrElem(props)}</div>
            </>
        )
    }
    return null
}

function newPeriodArrElem(props){
    if(props.shedullePeriod.newFullPeriod.length > 0){
        return props.shedullePeriod.newFullPeriod.map(
            (e, count) => {
                let tmpWeekday = null
                if(Array.isArray(e.weekdays) === true){
                    if(e.weekdays.length > 0) tmpWeekday = viewNewPeriodWeekdaysArr(e.weekdays)
                }
                let tmpStart = null
                if(e.timeStart !== '') tmpStart = modifiDateString(e.timeStart, 1, true)
                let tmpEnd = null
                if(e.timeEnd !== '') tmpEnd = modifiDateString(e.timeEnd, 1, true)
                return (
                    <div className={styles.weekend_wrap}>
                        <div className={styles.weekend}>
                            <div className={styles.weekend_data}>Дата початку: {tmpStart}</div>
                            <div className={styles.weekend_data}>Дата завершення: {tmpEnd}</div>
                            <IconButton
                                disabled={disabletElem(props.auctionsMy, '01')}
                                aria-label="edit"
                                onClick={
                                    () => {
                                        let tmp = []
                                        let tmpCount = 0
                                        for(let i = 0; i < props.shedullePeriod.newFullPeriod.length; i++){
                                            if(i !== count){
                                                tmp[tmpCount] = props.shedullePeriod.newFullPeriod[i]
                                                tmpCount++
                                            }
                                        }
                                        props.setDataAddress(tmp, 'SET_NEW_FULL_PERIOD_ARR')
                                    }
                                }
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                        <div>{tmpWeekday}</div>
                    </div>
                )
            }
        )
    }
}

function viewPeriodWeekdaysArr(data){
    if(data.length > 0){
        return data
        .filter(
            (e) => {
                return e !== null
            }
        )
        .map(
            (e) => {
                return (
                    <div className={styles.weekend_elem}>
                        <div className={styles.weekend_data}>День тижня: {e.weekday}</div>
                        <div className={styles.weekend_data}>Початок: {e.startTime}</div>
                        <div className={styles.weekend_data}>Кінець: {e.endTime}</div>
                    </div>
                )
            }
        )
    }
}

function periodArrElem(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.leaseRules !== null){
            if(props.auctionsMy.leaseRules.hasOwnProperty('schedule') === true){
                if(props.auctionsMy.leaseRules.schedule !== null){
                    if(props.auctionsMy.leaseRules.schedule.hasOwnProperty('periods') === true){
                        if(props.auctionsMy.leaseRules.schedule.periods !== null){
                            if(props.auctionsMy.leaseRules.schedule.periods.length > 0){
                                return props.auctionsMy.leaseRules.schedule.periods.map(
                                    (e) => {
                                        let tmpWeekday = null
                                        if(e.weekdays.length > 0) tmpWeekday = viewPeriodWeekdaysArr(e.weekdays)
                                        return (
                                            <div className={styles.weekend_wrap}>
                                                <div className={styles.weekend}>
                                                    <div className={styles.weekend_data}>Дата початку: {modifiDateString(e.startDate, 1)}</div>
                                                    <div className={styles.weekend_data}>Дата завершення: {modifiDateString(e.endDate, 1)}</div>
                                                    <IconButton
                                                        disabled={disabletElem(props.auctionsMy, '00')}
                                                        aria-label="edit"
                                                        onClick={
                                                            () => {
                                                                props.setDataAddress(true, 'SET_SHEDULE_PERIOD')
                                                                props.setDataAddress(e.id, 'SET_SHEDULE_PERIOD_ID')
                                                                props.setDataAddress(e.weekdays, 'SET_NEW_PERIOD_ARR')
                                                                props.setDataAddress(e.startDate, 'SET_SCHEDULE_PERIOD_START_DATE')
                                                                props.setDataAddress(e.endDate, 'SET_SCHEDULE_PERIOD_END_DATE')
                                                                props.setDataAddress('null', 'SET_SCHEDULE_WEEKDAY')
                                                                props.setDataAddress('', 'SET_SCHEDULE_TIME_START')
                                                                props.setDataAddress('', 'SET_SCHEDULE_TIME_END')
                                                            }
                                                        }
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={disabletElem(props.auctionsMy, '00')}
                                                        aria-label="edit"
                                                        onClick={
                                                            () => {
                                                                deleteWeek(props, props.auctionsMy.id, e.id)
                                                            }
                                                        }
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </div>
                                                <div>{tmpWeekday}</div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

async function deleteWeek(props, aucId, periodId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule/period/${periodId}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json2 = await response.json()
        if(json2.hasOwnProperty('message') === true){

        }else{
            await props.getMyAuction(null)
            await getAucionFromId(props, aucId)
            await props.changeLoader(false)
        }
    }
}

function textHelp(props){
    if(
        props.schedule.hoursBy.value === '' ||
        props.schedule.hoursBy.by === 'null' ||
        props.schedule.daysBy.value === '' ||
        props.schedule.daysBy.by === 'null'
    ){
        if(props.schedule.other === ''){
            return 'Обов\'зкове поле'
        }
    }
}

async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(json.previousAuctionId !== null){
                props.changeDiscountPreviousAuctionId(json.previousAuctionId)
                if(json.discount !== null){
                    props.changeDiscountPreviousAuctionAmount(json.discount.previousAuctionValue.amount)
                    props.changeDiscountPercent(json.discount.discountPercent)
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        objectReestr: state.start.objectReestr,
        shedullePeriod: state.start.shedullePeriod,
        koatuu: state.start.koatuu,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionСalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
    setDataAddress,

    setEditAuctionFromId,
    changeDiscountPreviousAuctionId,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPercent,

    getMyAuction,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDgf)
