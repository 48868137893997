import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionGuaranteeCurrency, changeCreateAuctionGuaranteeAmount, changeCreateAuctionGuaranteeTax} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import {PROCEDURE_NAME} from '../../../redux/procedure_v3.35.2.uk';

import InputAmount from '../../elements/inputs/inputAmount';
import { isLLD, isLLE, isLLP, isRCE, isRCD, isSUE, isSUD } from '../../../redux/aucTypes';

import {disabletElem} from '../disabled'

const WindowAuctionGaranty = ( props ) => {
    let tmp = null
    if(props.auctionsMy !== null){
        tmp = summZU(props)    
    }
    if(
        isRCE(props.auctionsMy.sellingMethod)
        || isRCD(props.auctionsMy.sellingMethod)
        || props.auctionsMy.sellingMethod.startsWith('renewables-multiAwards')
        //isSUE(props.auctionsMy.sellingMethod) ||
        //isSUD(props.auctionsMy.sellingMethod)
    ){
        return null
    }
    return tmp
}

function viewOptionCurrentCost(props, position){
    let tmp = null;
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("timber-english"):
        case props.auctionsMy.sellingMethod.startsWith("dgf-english"):
        case props.auctionsMy.sellingMethod.startsWith("subsoil-english"):
        case props.auctionsMy.sellingMethod.startsWith("subsoil-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-dutch"):
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
            </>
            break;
        default:
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
            </>
            break;
    }
    return tmp
}

function summZU(props){
    let tmpValue = 'null'
    let tmpValueAmount = ''
    if(props.auctionsMy !== null){
        if(props.auctionsMy.guarantee !== null){
            if(props.auctionsMy.guarantee.currency !== null){
                tmpValue = props.auctionsMy.guarantee.currency
            }
            if(props.auctionsMy.guarantee.amount !== null){
                tmpValueAmount = props.auctionsMy.guarantee.amount
            }
        }
    }
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod) 
    ){
        if(props.auctionsZUAttemp !== null){
            if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
                return (
                    <div className={styles.group}>
                        <div className={styles.titleValue}>
                            {PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.guarantee.legalName}
                            {requiredText(props)}
                        </div>
                        <div className={styles.groupElem}>
                            <div className={styles.valuta}>
                                <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.guarantee.currency.legalName}</div>
                                <TextField
                                    disabled
                                    className={styles.valutaInput}
                                    id="outlined-select-currency-native"
                                    select
                                    value={tmpValue}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    helperText="Тип валюти"
                                    variant="outlined"
                                >
                                    {viewOptionCurrentCost(props, 2)}
                                </TextField>
                            </div>
                            <div>
                            <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.guarantee.amount.legalName}</div>
                                <TextField
                                    error = {redBlock(props, tmpValueAmount)}
                                    disabled={disabletElem(props.auctionsMy, '00')}
                                    value={tmpValueAmount}
                                    onChange={(event) => {
                                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                        if(reg.test(event.target.value) !== true) props.changeCreateAuctionGuaranteeAmount(convertStrToNumber(event.target.value))
                                    }}
                                    variant="outlined"
                                    helperText=" "
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.group}>
                <InputAmount
                    title={PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.guarantee.legalName}
                    data={
                        {amount: tmpValueAmount,
                        currency: tmpValue}
                    }
                    onChange={(event) => {
                        props.changeCreateAuctionGuaranteeAmount(event.amount)
                    }}
                    disabled={disabletElem(props.auctionsMy, '00')}
                    error={redBlock(props, tmpValueAmount)}
                />
                    {viewText(props)}
                </div>
        )
    }else{
        return (
            <div className={styles.group}>
                <div className={styles.titleValue}>
                    {PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.guarantee.legalName}
                    {requiredText(props)}
                </div>
                <div className={styles.groupElem}>
                    <div className={styles.valuta}>
                        <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.guarantee.currency.legalName}</div>
                        <TextField
                            className={styles.valutaInput}
                            disabled
                            id="outlined-select-currency-native"
                            select
                            value={tmpValue}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Тип валюти"
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <div>{PROCEDURE_NAME['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.guarantee.amount.legalName}</div>
                        <TextField
                            error = {redBlock(props, tmpValueAmount)}
                            disabled={disabletElem(props.auctionsMy, '00')}
                            value={tmpValueAmount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.changeCreateAuctionGuaranteeAmount(convertStrToNumber(event.target.value))
                            }}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                    {viewText(props)}
                </div>
            </div>
        )
    }
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function convertStrToNumber(data){
    let str = ''
    if(data.indexOf(',') !== -1){
        str = data.replace(',', '.')
    }else{
        str = data
    }
    let strArr = null
    if(str.indexOf('.') !== -1){
        //if(Number(str) < 0.01) return 0.01
        strArr = str.split('.')
        if(strArr.length < 3){
            if(strArr[1].length < 3){
                return str
            }else{
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }
        }else{
            if(strArr[1].length === 0){
                return strArr[0] + '.'
            }else if(strArr[1].length === 1){
                return strArr[0] + '.' + strArr[1].slice(0, 1)
            }else if(strArr[1].length === 2){
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }    
        }
    }
    //if(Number(str) === 0) str = 0.01
    //if(Number(str) === 0.0) str = 0.01
    return str
}

function viewText(props){
    if(
        !props.auctionsMy.sellingMethod.startsWith('legitimatePropertyLease-english')  &&
        !props.auctionsMy.sellingMethod.startsWith('legitimatePropertyLease-dutch') &&
        !props.auctionsMy.sellingMethod.startsWith('legitimatePropertyLease-priorityEnglish')  &&
        !props.auctionsMy.sellingMethod.startsWith('landRental-english')  &&
        !props.auctionsMy.sellingMethod.startsWith('landSell-english')  &&
        !props.auctionsMy.sellingMethod.startsWith('landSell-priorityEnglish')  &&
        !props.auctionsMy.sellingMethod.startsWith('basicSell-english')  &&
        !props.auctionsMy.sellingMethod.startsWith('basicSell-dutch')  &&
        !props.auctionsMy.sellingMethod.startsWith('alienation-english')  &&
        !props.auctionsMy.sellingMethod.startsWith('commercialSell-english')  &&
        !props.auctionsMy.sellingMethod.startsWith('commercialSell-dutch')  &&
        !props.auctionsMy.sellingMethod.startsWith('bankRuptcy-dutch')
    ){
        return <div className={styles.helperText}>5% від стартової ціни лоту, розраховується автоматично</div>
    }
}

function requiredText(props){
    switch (true) {
        case props.auctionsMy.sellingMethod.startsWith('landRental'):
        case props.auctionsMy.sellingMethod.startsWith('landSell'):
             return '*';
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionGaranty)