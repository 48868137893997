import React from 'react';

import {connect} from 'react-redux';

import {
    isRLE, isRLD, isCLE, isCLD, isBRD, isBRE, isBRW, isAAE, isALE, isBSD, isBSE, isCSD, isCSE,
    isLLD, isLLE, isLLP, isLRE, isLSE, isLSP, isRCD, isRCE, isSUD, isSUE, isTIE, isSPE, isSPD,
    isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isGFE, isGFD, isRMA, isAPE, isAPD
} from '../../../redux/aucTypes';


import Timber from './timber/timber.js';
import Energy from './energy/energy.js';
import Railway from './railway/railway.js';
import Subsoil from './subsoil/subsoil.js';
import Dgf from './dgf/dgf.js';
import LegitimatePropertyLease from './legitimatePropertyLease/legitimatePropertyLease'
//import LegitimatePropertyLease from './LLELLDLLP/commSell'
import LandRental from './landRental/landRental'
//import BasicSell from './basicSell/basicSell'
import BasicSell from './bsebsd/bsebsd'
//import Alienation from './alienation/alienation'
import Alienation from './ale/ale'
import CommSell from './commSell/commSell';
import BRESell from './BRE/BRE.js';
import BRWSell from './BRW/BRW.js';
import RLERLD from './RLERLD/RLERLD.js';

import NLE from './nlenld/nlenld'
import LAE from './lae/lae'
import LAW from './law/law'
import LAP from './lap/lap'
import LPE from './lpe/bsebsd'
import CLE from './CLE/bsebsd'
import RLE from './RLE/bsebsd'
import APE from './ape/bsebsd'
import LLE from './lle/bsebsd'
import LRE from './lre/bsebsd'
import RenewablesItem from "./renewablesMultiAwards/renewables";

const WindowItems = (props) => {
    switch (true) {
        case props.auctionsMy === null:
            return null
        case isTIE(props.auctionsMy.sellingMethod):
            return <Timber/>
        case isRMA(props.auctionsMy.sellingMethod):
            return <Energy/>
        case isSUD(props.auctionsMy.sellingMethod):
        case isSUE(props.auctionsMy.sellingMethod):
            return <Subsoil/>
        case isRCD(props.auctionsMy.sellingMethod):
        case isRCE(props.auctionsMy.sellingMethod):
            return <Railway/>
        case isGFE(props.auctionsMy.sellingMethod):
        case isGFD(props.auctionsMy.sellingMethod):
            return <Dgf/>

        case isLLE(props.auctionsMy.sellingMethod):
        case isLLD(props.auctionsMy.sellingMethod):
        case isLLP(props.auctionsMy.sellingMethod):
            return <LLE/>

        case isLRE(props.auctionsMy.sellingMethod):
        case isLSE(props.auctionsMy.sellingMethod):
        case isLSP(props.auctionsMy.sellingMethod):
            return <LRE/>

        case isBSD(props.auctionsMy.sellingMethod):
        case isBSE(props.auctionsMy.sellingMethod):
            return <BasicSell/>
        case isALE(props.auctionsMy.sellingMethod):
            return <Alienation/>
        case isCSD(props.auctionsMy.sellingMethod):
        case isCSE(props.auctionsMy.sellingMethod):
            return <CommSell/>
        // if(isAAE(props.auctionsMy.sellingMethod))
        case isBRD(props.auctionsMy.sellingMethod):
        case isBRE(props.auctionsMy.sellingMethod):
            return <BRESell/>
        case isBRW(props.auctionsMy.sellingMethod):
            return <BRWSell/>
        case isRLE(props.auctionsMy.sellingMethod):
        case isRLD(props.auctionsMy.sellingMethod):
            return <RLE/>
        case isCLE(props.auctionsMy.sellingMethod):
        case isCLD(props.auctionsMy.sellingMethod):
            return <CLE/>
        case isSPE(props.auctionsMy.sellingMethod):
        case isSPD(props.auctionsMy.sellingMethod):
            return <LPE/>
        case isNLE(props.auctionsMy.sellingMethod):
        case isNLD(props.auctionsMy.sellingMethod):
            return <NLE/>
        case isLAE(props.auctionsMy.sellingMethod):
            return <LAE/>
        case isLAW(props.auctionsMy.sellingMethod):
            return <LAW/>
        case isLAP(props.auctionsMy.sellingMethod):
            return <LAP/>
        case isLPE(props.auctionsMy.sellingMethod):
            return <LPE/>

        case isAPE(props.auctionsMy.sellingMethod):
        case isAPD(props.auctionsMy.sellingMethod):
            return <APE/>

        case props.auctionsMy.sellingMethod.startsWith('renewables'):
        case props.auctionsMy.sellingMethod.startsWith('renewables-multiAwards'):
            return <RenewablesItem/>
        default :
            return null
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
    }
}

export default connect(mapStateToProps, null)(WindowItems)
