import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionXDocumentRequirements} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import InputText from '../../elements/inputs/input';

import {disabletElem} from '../disabled'

const WindowAuctionDocumentRequirements = ( props ) => {
    if(props.auctionsMy !== null){
        return viewNameInput(props)
    }
    return null
}

function viewNameInput(props){
    let tmpDocumentRequirements = ''
    if(props.auctionsMy.hasOwnProperty('xDocumentRequirements') && props.auctionsMy.xDocumentRequirements !== null){
        tmpDocumentRequirements = props.auctionsMy.xDocumentRequirements
    }else if(props.auctionsMy.hasOwnProperty('x_documentRequirements') && props.auctionsMy.x_documentRequirements !== null){
        tmpDocumentRequirements = props.auctionsMy.x_documentRequirements.uk_UA
    }

    switch (true) {
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
            return null;
        case props.auctionsMy.sellingMethod.startsWith('timber-english'):
            if (props.typeElem === 1) {
                return <InputText
                    disabled={disabletElem(props.auctionsMy, '00')}
                    label={'Вимоги до оформлення документів'}
                    value={props.auctionsMy.xDocumentRequirements}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionXDocumentRequirements(e)
                        }
                    }
                    required
                />
            } else {
                return <div className={styles.inputMax}>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label="Вимоги до оформлення документів"
                        defaultValue={tmpDocumentRequirements}
                        variant="outlined"
                        onChange={(event) => {
                            props.changeCreateAuctionXDocumentRequirements(event.target.value)
                        }}
                        required
                    />
                </div>
            }
        default:
            if (props.typeElem === 1) {
                return <InputText
                    disabled={disabletElem(props.auctionsMy, '00')}
                    label={'Вимоги до оформлення документів'}
                    value={props.auctionsMy.xDocumentRequirements}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionXDocumentRequirements(e)
                        }
                    }
                />
            } else {
                return <div className={styles.inputMax}>
                    <TextField
                        disabled={disabletElem(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label="Вимоги до оформлення документів"
                        defaultValue={tmpDocumentRequirements}
                        variant="outlined"
                        onChange={(event) => {
                            props.changeCreateAuctionXDocumentRequirements(event.target.value)
                        }}
                    />
                </div>
            }
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionXDocumentRequirements,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDocumentRequirements)
