import React, {useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {PROCEDURE_NAME} from '../../../../../../redux/procedure_v3.35.2.uk';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmount';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';

import BankAccount from '../../bankAccount/bankAccount';

import { disabletElem } from '../../../../disabled';

//import { setTenderAttemptList, createBodyCSE } from './utils'; 
import { createBody, createBodyCT } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc, editAucCurrentTenant, editAucArest} from '../../../createEditAndCloneAuction/edit/editTabMonga'

import Arrests from '../../arrests/arrests'
import LocationOrg from '../../../../elems/localization/locationOrg';

const AuctionLAP = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)

    const [uaIdentifiers, setIdentifiers] = useState(null)
    const [worldIdentifiers, setWorldIdentifiers] = useState(null)
    const [optionsId, setOptionsId] = useState([
        {value: 'UA-EDR', name: 'ЄДРПОУ'},
        {value: 'UA-IPN', name: 'ІПН/РНОКПП'},
        {value: 'UA-PASSPORT', name: 'Паспорт'},
        {value: 'UA-ID-CARD', name: 'ID-карта'},
        {value: 'UA-IPN-FOP', name: 'ІПН/РНОКПП (ФОП)'},
        {value: 'AE-ACCI', name: 'Ajman Chamber of Commerce and Industry'},
        {value: 'AE-ADCD', name: 'Abu Dhabi Commercial Directory'},
        {value: 'AE-AFZ', name: 'Ajman Free Zone Authority'},
        {value: 'AE-DCCI', name: 'Dubai Chamber of Commerce and Industry'},
        {value: 'AE-DFSA', name: 'Dubai Financial Services Authority'},
        {value: 'AE-DIFC', name: 'Dubai International Financial Centre'},
        {value: 'AE-DMCC', name: 'Dubai Multi Commodities Centre'},
        {value: 'AE-FCCI', name: 'Fujairah Chamber of Commerce and Industry'},
        {value: 'AE-FFZ', name: 'Fujairah Free Zone Company Listing'},
        {value: 'AE-HFZA', name: 'Hamriyah Free Zone Authority'},
        {value: 'AE-RAKIA', name: 'Ras al-Khaimah Investment Authority'},
        {value: 'AE-RK_CR', name: 'Ras al-Khaimah Chamber of Commerce and Industry'},
        {value: 'AE-SAIF', name: 'Sharjah Airport International Free Zone'},
        {value: 'AE-SCCI', name: 'Sharjah Chamber of Commerce and Industry'},
        {value: 'AE-UQCCI', name: 'Umm al-Quwain Chamber of Commerce and Industry'},
        {value: 'AF-CBR', name: 'Afghanistan Central Business Registry'},
        {value: 'AF-MOE', name: 'Ministry of Economy NGO Department'},
        {value: 'AR-CENOC', name: 'The National Centre of Community Organisations (Argentina)'},
        {value: 'AR-CUIT', name: 'Unique Tax Identification Code (Argentina)'},
        {value: 'AT-FB', name: 'Austria Company Register'},
        {value: 'AT-UID', name: 'VAT number (Austria Company Register)'},
        {value: 'AU-ABN', name: 'Australian Business Register'},
        {value: 'AU-ACNC', name: 'Australian Charities and Not-for-profits Commission'},
        {value: 'AZ-IVI', name: 'State Register of Commercial Entities (Ministry of Taxes of Azerbaijan Republic)'},
        {value: 'BD-NAB', name: 'Bangladesh NGO Affairs Bureau'},
        {value: 'BE-BCE_KBO', name: 'Crossroads Bank for Enterprises'},
        {value: 'BE-GTCF', name: 'Au Greffe du Tribunal de Commerce Francophone de Bruxelles'},
        {value: 'BG-EIK', name: 'Commercial Register'},
        {value: 'BJ-IFU', name: 'Unique Tax Identifier'},
        {value: 'BW-CIPA', name: 'Companies and Intellectual Property Authority (Botswana)'},
        {value: 'BY-ADR', name: 'Unified State Register of Legal Entities and Indiv… (Ministry of Justice of the Republic of Belarus)'},
        {value: 'CA-CC', name: 'Corporations Canada'},
        {value: 'CA-CRA_ACR', name: 'Canadian Revenue Agency'},
        {value: 'CA-GOV', name: 'List of legal department legalNames (Government of Canada)'},
        {value: 'CA_AB-ABT', name: 'Corporate Registry Office'},
        {value: 'CA_BC-BRC_CBR', name: 'British Columbia Corporate Registry'},
        {value: 'CA_MB-MTB', name: 'Manitoba Companies Office, Department of Entrepreneurship, Training and Trade'},
        {value: 'CA_NB-NWB_NOB', name: 'Corporate Registry'},
        {value: 'CA_NL-NFL_TNL', name: 'Registry of Companies, Department of Government Services'},
        {value: 'CA_NS-NVS_NVE', name: 'Nova Scotia Registry of Joint Stock Companies'},
        {value: 'CA_NT-NWT_TNO', name: 'Canadian Provincial Corporate Registration - Northwest Territories'},
        {value: 'CA_NU-NNV', name: 'Nunavut Department of Justice - Corporate Registries'},
        {value: 'CA_ON-ONT', name: 'ServiceOntario, Ministry of Government Services'},
        {value: 'CA_PE-PEI_IPE', name: 'Prince Edward Island Corporate'},
        {value: 'CA_QC-QBC', name: 'Quebec Business Registrar'},
        {value: 'CA_SK-SKN', name: 'Saskatchewan Corporate Registry'},
        {value: 'CA_YT-YKT', name: 'Yukon Corporate Affairs'},
        {value: 'CH-FDJP', name: 'Commercial Registry, Federal Office of Justice (Switzerland)'},
        {value: 'CN-SAIC', name: 'State Administration for Industry and Commerce (SAIC)'},
        {value: 'CO-CCB', name: 'Bogota Chamber of Commerce'},
        {value: 'CO-RUE', name: 'Unified Commercial and Social Registry (RUES)'},
        {value: 'CY-DRCOR', name: 'Cyprus Department of Registrar of Companies and Official Receiver (DRCOR)'},
        {value: 'CZ-DIC', name: 'Tax ID (DI\\u010C) Czech Republic'},
        {value: 'CZ-ICO', name: 'Access to Registers of Economic Subjects'},
        {value: 'DE-CR', name: 'Common Register Portal of the German Federal States (CRP)'},
        {value: 'DK-CVR', name: 'Danish Central Business Register'},
        {value: 'EE-KMKR', name: 'e-Business Register (Estonia)'},
        {value: 'EE-RIK', name: 'Centre of Registers and Information Systems (RIK)'},
        {value: 'EG-MOSS', name: 'Ministry of Social Solidarity and Justice (Egypt)'},
        {value: 'ES-DIR3', name: 'Common Directory of Organizational Units and Offices - DIR3'},
        {value: 'ES-RMC', name: 'Central Commercial Register of the Kingdom of Spain'},
        {value: 'ET-CSA', name: 'Charities and Societies Agency (Ethiopia)'},
        {value: 'ET-MFA', name: 'Ministry of Foreign Affairs'},
        {value: 'ET-MOT', name: 'Ministry of Trade (Ethiopia)'},
        {value: 'FI-PRO', name: 'Finnish Patent and Registration Office'},
        {value: 'FR-INSEE', name: 'The National Institute of Statistics and Economic Studies'},
        {value: 'FR-RCS', name: 'Trade and Companies Register'},
        {value: 'GB-CHC', name: 'Charity Commission'},
        {value: 'GB-COH', name: 'Companies House'},
        {value: 'GB-EDU', name: 'Register of Schools (England and Wales)'},
        {value: 'GB-GOR', name: 'Government Organisation Register'},
        {value: 'GB-GOV', name: 'UK Government Departments Reference Numbers (IATI Standard)'},
        {value: 'GB-GOVUK', name: 'GOV.UK - UK Government Departments, Agencies &amp; Public Bodies'},
        {value: 'GB-IRN', name: 'Schools Plus, Department of Education (Northern Ireland)'},
        {value: 'GB-LAE', name: 'Local Authorities for England Register'},
        {value: 'GB-LAS', name: 'Scottish Local Authority Register'},
        {value: 'GB-MPR', name: 'Mutuals Public Register'},
        {value: 'GB-NHS', name: 'NHS Digital - Organisation Data Service'},
        {value: 'GB-NIC', name: 'The Charity Commission for Northern Ireland'},
        {value: 'GB-PLA', name: 'Principal Local Authority Register for Wales'},
        {value: 'GB-REV', name: 'HM Revenue and Customs'},
        {value: 'GB-SC', name: 'Scottish Charity Register'},
        {value: 'GB-SHPE', name: 'Registered Social Housing Providers (England)'},
        {value: 'GB-UKPRN', name: 'UK Register of Learning Providers'},
        {value: 'GE-NAPR', name: 'Register of Entrepreneurial and Non-Entrepreneurial Legal Entities, Georgia'},
        {value: 'GG-RCE', name: 'Guernsey Registry'},
        {value: 'GH-DSW', name: 'Department of Social Developments'},
        {value: 'HK-CR', name: 'Hong Kong Companies Register'},
        {value: 'HR-MBS', name: 'Croatian Court Business Register'},
        {value: 'HR-OIB', name: 'Croatia Court Register'},
        {value: 'HU-AFA', name: 'Information and Electronic Company Registration Service'},
        {value: 'ID-KDN', name: 'Ministry of Home Affairs'},
        {value: 'ID-KHH', name: 'Ministry of Justice &amp; Human Rights'},
        {value: 'ID-KLN', name: 'Ministry of Foreign affairs'},
        {value: 'ID-PRO', name: 'Indonesia - NGOs registered at Provinicial Level'},
        {value: 'ID-SMR', name: 'The SMERU Research Institute'},
        {value: 'IE-CHY', name: 'Charities Regulatory Authority of Ireland'},
        {value: 'IE-CRO', name: 'Irish CompaniesRegistration Office'},
        {value: 'IL-ROC', name: 'Registrar of Companies (Israel)'},
        {value: 'IM-CR', name: 'Isle of Man Companies Registry'},
        {value: 'IM-GR', name: 'Isle of Man Index of Registered Charities'},
        {value: 'IN-MCA', name: 'Government of India, Ministry of Corporate Affairs'},
        {value: 'IN-MHA', name: 'Ministry of Home Affairs (India) Foreign Contributions (Regulation) Act Register'},
        {value: 'IT-CF', name: 'Italian Tax Code / VAT Number'},
        {value: 'IT-RI', name: 'Business Register of the Italian Chambers of Commerce'},
        {value: 'JE-FSC', name: 'Jersey Financial Services Commission (JFSC)'},
        {value: 'JE-OAC', name: 'Jersey Overseas Aid Commission'},
        {value: 'JO-CCD', name: 'Companies Control Department (Jordan)'},
        {value: 'JO-MSD', name: 'Register of Associations, Jordan'},
        {value: 'JP-JCN', name: 'National Tax Agency Corporate Number Publication Site'},
        {value: 'KE-NCB', name: "NGO's Coordination Board"},
        {value: 'KE-RCO', name: 'Registar of Companies'},
        {value: 'KE-RSO', name: 'Registrar of Societies'},
        {value: 'KG-ID', name: 'Electronic database of legal entities and branches (Krygyzstan)'},
        {value: 'KG-INN', name: 'Kyrgyz Republic Register of Legal Entities'},
        {value: 'KZ-BIN', name: 'Business Identification Number (BIN)'},
        {value: 'LB-CR', name: 'Lebanese Ministry of Justice, Commercial Register'},
        {value: 'LB-MOI', name: 'Ministry of Interior (Lebanon)'},
        {value: 'LS-LCN', name: 'Lesotho Council of Non Governmental Organisations'},
        {value: 'LT-PVM', name: 'Lithuania Register of Legal Entities'},
        {value: 'LT-RC', name: 'Information Platform of Legal Entities (Lithuania)'},
        {value: 'LV-RE', name: 'Register of Enterprises of the Republic of Latvia'},
        {value: 'MD-IDNO', name: 'Legal entity registration number (IDNO) Moldova'},
        {value: 'ML-CCIM', name: 'The Chamber of Commerce and Industry of Mali'},
        {value: 'ML-NIF', name: 'Tax Identification Number'},
        {value: 'MM-MHA', name: 'Ministry of Home Affairs - Central Committee for the Registration and Supervision of Organisations'},
        {value: 'MU-CR', name: 'Companies and Businesses Registration Integrated System, Mauritius'},
        {value: 'MW-CNM', name: 'The Council for Non Governmental Organisations in Malawi'},
        {value: 'MW-MRA', name: 'Malawi Revenue Authority'},
        {value: 'MW-NBM', name: 'NGO Board of Malawi'},
        {value: 'MW-RG', name: 'Registrar General, Department of Justice'},
        {value: 'MX-CPA', name: 'Budget Classification of Public Entities (Mexico)'},
        {value: 'MX-RFC', name: 'Federal Taxpayers Registry'},
        {value: 'MY-SSM', name: 'Companies Commission of Malaysia'},
        {value: 'MZ-CR', name: 'Mozambique Commercial Registry'},
        {value: 'MZ-MOJ', name: 'Mozambique Ministry of Justice'},
        {value: 'MZ-NUIT', name: 'Taxpayer Single Identification Number (Mozambique)'},
        {value: 'NG-BPP', name: 'Bureau of Public Procurement (BPP) Contractor Registration System (Nigeria)'},
        {value: 'NG-CAC', name: 'Nigerian Corporate Affairs Commission'},
        {value: 'NL-KVK', name: 'Chamber of Commerce (Netherlands)'},
        {value: 'NL-OWMS', name: 'Overheid.nl Web Metadata Standard'},
        {value: 'NO-BRC', name: 'Brønnøysundregistrene'},
        {value: 'NP-CRO', name: 'Company Registrar Office'},
        {value: 'NP-SWC', name: 'Social Welfare Council Nepal'},
        {value: 'PE-SUNARP', name: 'Peruvian National Superintendency of Public Registries - Registered Legal Entities'},
        {value: 'PH-SEC', name: 'Securities and Exchange Commission (Philippines)'},
        {value: 'PK-PCP', name: 'Pakistan Centre for Philanthropy'},
        {value: 'PK-VSWA', name: 'Pakistan Voluntary Social Welfare Agency'},
        {value: 'PL-KRS', name: 'The National Court Register (Poland)'},
        {value: 'PL-NIP', name: 'Tax Identification Number (Poland)'},
        {value: 'PL-REGON', name: 'REGON - Statistical number of an economy entity'},
        {value: 'PS-MOI', name: 'Ministry of Interior (Palestine)'},
        {value: 'PT-NIPPC', name: 'Portal of Public Services'},
        {value: 'PY-PGN', name: 'Classification of Entities in the National Budget for Paraguay'},
        {value: 'PY-RUC', name: 'Unique Taxpayer Registry, Paraguay'},
        {value: 'RO-CUI', name: 'National Trade Register (Romania)'},
        {value: 'RS-APR', name: 'Serbian Business Registrations Agency'},
        {value: 'RS-PIB', name: 'Tax identification number register'},
        {value: 'RU-INN', name: 'Uniform State Register of Legal Entities of Russian Federation'},
        {value: 'RU-OGRN', name: 'Unified State Register of Legal Entities (USRLE), Russian Federation'},
        {value: 'SE-BLV', name: 'Bolagsverket'},
        {value: 'SE-KK', name: 'Legal, Financial and Administrative Services Agency (Sweden)'},
        {value: 'SE-ON', name: 'Business Registration Number (Organisationsnummer), Sweden'},
        {value: 'SG-ACRA', name: 'Accounting and Corporate Regulatory Authority (ACRA)'},
        {value: 'SI-PRS', name: 'Slovenian Business Register'},
        {value: 'SI-TIN', name: 'Tax Identification Number (Slovenia)'},
        {value: 'SK-ORSR', name: 'Ministry of Justice Business Register'},
        {value: 'SK-ZRSR', name: 'Slovakia Ministry Of Interior Trade Register'},
        {value: 'SN-NINEA', name: 'National Identification Number of Companies and Associations (NINEA), Senegal'},
        {value: 'SS-RRC', name: 'South Sudan Relief and Rehabilitation Commission'},
        {value: 'TR-MERSIS', name: 'MERSIS - Central Trade Registry System'},
        {value: 'TR-MOI', name: 'Department of Associations (Ministry of Interior, Turkey)'},
        {value: 'TZ-BRLA', name: 'Tanzania Business Registrations and Licensing Agency'},
        {value: 'TZ-TRA', name: 'Tanzania Revenue Agency'},
        {value: 'UG-NGB', name: 'NGO Board, Ministry of Internal Affairs'},
        {value: 'UG-RSB', name: 'Registration Services Bureau'},
        {value: 'US-DOS', name: 'Corporation registration is the responsibility of each state (see link)'},
        {value: 'US-EIN', name: 'Employer Identification Number - Internal Revenue Service'},
        {value: 'US-USAGOV', name: 'Index of U.S. Government Departments and Agencies'},
        {value: 'UZ-KTUT', name: 'United State Register of Corporate Entites'},
        {value: 'XE-EXAMPLE', name: 'Example Data Prefix'},
        {value: 'XI-EORI', name: 'Economic Operators Identification and Registration system'},
        {value: 'XI-GRID', name: 'Global Research Identifiers Database'},
        {value: 'XI-IATI', name: 'International Aid Transparency Initiative Organisation Identifier'},
        {value: 'XI-PB', name: 'Public Bodies - Open Knowledge Foundation'},
        {value: 'XI-PID', name: 'PermID: Thompson Reuters Permanent Identifier'},
        {value: 'XI-WIKIDATA', name: 'Wikidata'},
        {value: 'XM-DAC', name: 'OECD Development Assistance Committee'},
        {value: 'XM-OCHA', name: 'United Nations Office for the Coordination of Huma…n Affairs Financial Tracking Services Identifiers'},
        {value: 'XR-NUTS', name: 'NUTS - European Union Nomenclature of Territorial Units for Statistics'},
        {value: 'ZA-CIP', name: 'Companies and Intellectual Property Commission (CIPC)'},
        {value: 'ZA-NPO', name: 'Nonprofit Organisation Directorate - South African Department of Social Development'},
        {value: 'ZA-PBO', name: 'SA Revenue Service Tax Exemption Unit'},
        {value: 'ZM-PCR', name: 'Patents and Companies Registration Agency'},
        {value: 'ZW-PVO', name: 'Private Voluntary Organisations Council (Zimbabwe)'},
        {value: 'ZW-ROD', name: 'Registrar of Deeds'},
        {value: 'ME-MNE', name: 'Central Registry of Commercial Entities'},
    ])

    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') && Number.isInteger(data.tenderAttempts) ? data.tenderAttempts : null)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') && Number.isInteger(data.minNumberOfQualifiedBids) ? data.minNumberOfQualifiedBids : 2)
    const [saleType, setSaleType] = useState(checkProp(data, 'saleType') && data.saleType !== null && data.saleType !== 'null' ? data.saleType : 'landSale')
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    //const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') && data.valueAddedTaxCharged !== null ? data.valueAddedTaxCharged : false)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    const [expertMonetaryValuation, setExpertMonetaryValuation] = useState(checkProp(data, 'expertMonetaryValuation') ? data.expertMonetaryValuation : null)
    const [normativeMonetaryValuation, setNormativeMonetaryValuation] = useState(checkProp(data, 'normativeMonetaryValuation') ? data.normativeMonetaryValuation : null)
    const [budgetSpent, setBudgetSpent] = useState(checkProp(data, 'budgetSpent') ? data.budgetSpent : null)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    //const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    //const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    //const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)
    const [restrictions, setRestrictions] = useState(checkProp(data, 'restrictions') ? isMongo(data.restrictions) : null)
    const [conditions, setConditions] = useState(checkProp(data, 'conditions') ? isMongo(data.conditions) : null)
    const [arrests, setArrests] = useState(checkProp(data,'arrests') ? data.arrests : null)

    const [nameCurrentTenants, setNameCurrentTenants] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'name')) ? isMongo(data.currentTenants[0].name) : null)
    const [nameCurrentTenants2, setNameCurrentTenants2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'name')) ? isMongo(data.currentTenants[1].name) : null)
    const [identifierLegalNameCurrentTenants, setIdentifierLegalNameCurrentTenants] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'identifier') && checkProp(data.currentTenants[0].identifier, 'legalName')) ? isMongo(data.currentTenants[0].identifier.legalName) : null)
    const [identifierLegalNameCurrentTenants2, setIdentifierLegalNameCurrentTenants2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'identifier') && checkProp(data.currentTenants[1].identifier, 'legalName')) ? isMongo(data.currentTenants[1].identifier.legalName) : null)
    const [identifierSchemeCurrentTenants, setIdentifierSchemeCurrentTenants] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'identifier') && checkProp(data.currentTenants[0].identifier, 'scheme')) ? data.currentTenants[0].identifier.scheme : null)
    const [identifierSchemeCurrentTenants2, setIdentifierSchemeCurrentTenants2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'identifier') && checkProp(data.currentTenants[1].identifier, 'scheme')) ? data.currentTenants[1].identifier.scheme : null)
    const [identifierIdCurrentTenants, setIdentifierIdCurrentTenants] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'identifier') && checkProp(data.currentTenants[0].identifier, 'id')) ? data.currentTenants[0].identifier.id : null)
    const [identifierIdCurrentTenants2, setIdentifierIdCurrentTenants2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'identifier') && checkProp(data.currentTenants[1].identifier, 'id')) ? data.currentTenants[1].identifier.id : null)
    const [addressCurrentTenants, setAdressCurrentTenants] = useState(
        (checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[0], 'address')
    ) ? data.currentTenants[0].address : null)
    const [addressCurrentTenants2, setAdressCurrentTenants2] = useState(
        (checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[1], 'address')
    ) ? data.currentTenants[1].address : null)

    const [contactRepresentativeInfoCurrentTenants, setContactRepresentativeInfoCurrentTenants] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'representativeInfo')) ? data.currentTenants[0].representativeInfo : null)
    const [contactRepresentativeInfoCurrentTenants2, setContactRepresentativeInfoCurrentTenants2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'representativeInfo')) ? data.currentTenants[1].representativeInfo : null)
    
    
    const [priority, setContactPriority] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'priority')) ? data.currentTenants[0].priority : null)
    const [priority2, setContactPriority2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'priority')) ? data.currentTenants[1].priority : null)


    const [contactValueCurrentTenants, setContactValueCurrentTenants] = useState(
        (checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'currentTenantValue'))
        ? data.currentTenants[0].currentTenantValue
        : null)
    const [contactValueCurrentTenants2, setContactValueCurrentTenants2] = useState(
        (checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'currentTenantValue'))
        ? data.currentTenants[1].currentTenantValue
        : null)
    const [contactTimeDateFromCurrentTenants, setContactTimeDateFromCurrentTenants] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'currentContractTime') && checkProp(data.currentTenants[0].currentContractTime, 'dateFrom')) ? data.currentTenants[0].currentContractTime.dateFrom : null)
    const [contactTimeDateFromCurrentTenants2, setContactTimeDateFromCurrentTenants2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'currentContractTime') && checkProp(data.currentTenants[1].currentContractTime, 'dateFrom')) ? data.currentTenants[1].currentContractTime.dateFrom : null)
    const [contactTimeDateTillCurrentTenants, setContactTimeDateTillCurrentTenants] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[0], 'currentContractTime') && checkProp(data.currentTenants[0].currentContractTime, 'dateTill')) ? data.currentTenants[0].currentContractTime.dateTill : null)
    const [contactTimeDateTillCurrentTenants2, setContactTimeDateTillCurrentTenants2] = useState((checkProp(data, 'currentTenants') && checkProp(data.currentTenants[1], 'currentContractTime') && checkProp(data.currentTenants[1].currentContractTime, 'dateTill')) ? data.currentTenants[1].currentContractTime.dateTill : null)

    const [numberOfCurrentTenants, setNumberOfCurrentTenants] = useState(checkProp(data, 'numberOfCurrentTenants') ? data.numberOfCurrentTenants : 1)


    const [priorityTransferInfoName, setPriorityTransferInfoName] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[0], 'priorityTransferInfo')
        && checkProp(data.currentTenants[0].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[0].priorityTransferInfo.identifier, 'legalName')
    ) ? isMongo(data.currentTenants[0].priorityTransferInfo.identifier.legalName) : null)
    const [priorityTransferInfoName2, setPriorityTransferInfoName2] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[1], 'priorityTransferInfo')
        && checkProp(data.currentTenants[1].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[1].priorityTransferInfo.identifier, 'legalName')
    ) ? isMongo(data.currentTenants[1].priorityTransferInfo.identifier.legalName) : null)
    const [priorityTransferInfoTransferredFrom, setPriorityTransferInfoTransferredFrom] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[0], 'priorityTransferInfo')
        && checkProp(data.currentTenants[0].priorityTransferInfo, 'transferredFrom')
    ) ? data.currentTenants[0].priorityTransferInfo.transferredFrom : null)
    const [priorityTransferInfoTransferredFrom2, setPriorityTransferInfoTransferredFrom2] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[1], 'priorityTransferInfo')
        && checkProp(data.currentTenants[1].priorityTransferInfo, 'transferredFrom')
    ) ? data.currentTenants[1].priorityTransferInfo.transferredFrom : null)

    const [priorityIdentifierScheme, setPriorityIdentifierScheme] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[0], 'priorityTransferInfo')
        && checkProp(data.currentTenants[0].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[0].priorityTransferInfo.identifier, 'scheme')
    ) ? data.currentTenants[0].priorityTransferInfo.identifier.scheme : null)
    const [priorityIdentifierScheme2, setPriorityIdentifierScheme2] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[1], 'priorityTransferInfo')
        && checkProp(data.currentTenants[1].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[1].priorityTransferInfo.identifier, 'scheme')
    ) ? data.currentTenants[1].priorityTransferInfo.identifier.scheme : null)
    const [priorityIdentifierId, setPriorityIdentifierId] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[0], 'priorityTransferInfo')
        && checkProp(data.currentTenants[0].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[0].priorityTransferInfo.identifier, 'id')
    ) ? data.currentTenants[0].priorityTransferInfo.identifier.id : null)
    const [priorityIdentifierId2, setPriorityIdentifierId2] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[1], 'priorityTransferInfo')
        && checkProp(data.currentTenants[1].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[1].priorityTransferInfo.identifier, 'id')
    ) ? data.currentTenants[1].priorityTransferInfo.identifier.id : null)
    const [priorityIdentifierLegalName, setPriorityIdentifierLegalName] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[0], 'priorityTransferInfo')
        && checkProp(data.currentTenants[0].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[0].priorityTransferInfo.identifier, 'legalName')
    ) ? isMongo(data.currentTenants[0].priorityTransferInfo.identifier.legalName) : null)
    const [priorityIdentifierLegalName2, setPriorityIdentifierLegalName2] = useState((
        checkProp(data, 'currentTenants')
        && checkProp(data.currentTenants[1], 'priorityTransferInfo')
        && checkProp(data.currentTenants[1].priorityTransferInfo, 'identifier')
        && checkProp(data.currentTenants[1].priorityTransferInfo.identifier, 'legalName')
    ) ? isMongo(data.currentTenants[1].priorityTransferInfo.identifier.legalName) : null)


    let requestBody = {
        title: title !== ''
            ? { uk_UA: title }
            : null,
        description: description !== ''
            ? { uk_UA: description }
            : null,
        accessDetails:  accessDetails !== ''
            ? { uk_UA: accessDetails }
            : null,
        tenderAttempts: +tenderAttempts,
        lotId: lotId !== '' ? lotId : null,
        sellingMethod: data.sellingMethod,
        x_documentRequirements: {
            uk_UA: x_documentRequirements !== '' ? x_documentRequirements : null
        },
        x_additionalInformation: {
            uk_UA: x_additionalInformation !== '' ? x_additionalInformation : null
        },
        //minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        saleType: saleType,
        auctionPeriod: {
            startDate: startDate
        },
        //valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value,'currency') ? value.currency : null,
            amount: checkProp(value,'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : null
        },
        minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },
        expertMonetaryValuation: {
            currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
            amount: checkProp(expertMonetaryValuation,'amount') ? +expertMonetaryValuation.amount : null
        },
        normativeMonetaryValuation: {
            currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
            amount: checkProp(normativeMonetaryValuation,'amount') ? +normativeMonetaryValuation.amount : null
        },
        budgetSpent: {
            currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
            amount: checkProp(budgetSpent,'amount') ? +budgetSpent.amount : null,
            valueAddedTaxIncluded: checkProp(budgetSpent,'addTax') ? budgetSpent.addTax : null
        },
        previousAuctionId: previousAuctionId,
        numberOfCurrentTenants: numberOfCurrentTenants,
        
        // discount: {
        //     discount: discount,
        //     discountPercent: discountPercent,
        //     previousAuctionValue:{
        //         currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
        //         amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
        //         valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
        //     }
        // },
        bankAccounts: bankAccounts,
        restrictions: restrictions,
        conditions: conditions,
        arrests: arrests !== null && arrests.length !== 0 ? arrests : null,


        currentTenants: {
            identifier: {
                scheme: identifierSchemeCurrentTenants,
                id: `${identifierIdCurrentTenants}`,
                legalName: {
                    uk_UA: identifierLegalNameCurrentTenants
                }
            },
            address: addressCurrentTenants,
            representativeInfo: contactRepresentativeInfoCurrentTenants,
            value: {
                currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                amount: checkProp(contactValueCurrentTenants,'amount') && contactValueCurrentTenants?.amount !== '' ? +contactValueCurrentTenants.amount : null,
            },
            currentContractTime: {
                dateFrom: contactTimeDateFromCurrentTenants,
                dateTill: contactTimeDateTillCurrentTenants
            },
            priority: priority,
            priorityTransferInfo: {
                transferredFrom: priorityTransferInfoTransferredFrom,
                name: {
                    uk_UA: priorityTransferInfoName
                },
                identifier: {
                    scheme: priorityIdentifierScheme,
                    id: `${priorityIdentifierId}`,
                    legalName: {
                        uk_UA: priorityIdentifierLegalName
                    }
                },
            }
        },

        currentTenants2: {
            identifier: {
                scheme: identifierSchemeCurrentTenants2,
                id: `${identifierIdCurrentTenants2}`,
                legalName: {
                    uk_UA: identifierLegalNameCurrentTenants2
                }
            },
            address: addressCurrentTenants,
            representativeInfo: contactRepresentativeInfoCurrentTenants2,
            value: {
                currency: checkProp(contactValueCurrentTenants2,'currency') ? contactValueCurrentTenants2.currency : null,
                amount: checkProp(contactValueCurrentTenants2,'amount') && contactValueCurrentTenants2?.amount !== '' ? +contactValueCurrentTenants2.amount : null,
            },
            currentContractTime: {
                dateFrom: contactTimeDateFromCurrentTenants2,
                dateTill: contactTimeDateTillCurrentTenants2
            },
            priority: priority2,
            priorityTransferInfo: {
                transferredFrom: priorityTransferInfoTransferredFrom2,
                name: {
                    uk_UA: priorityTransferInfoName2
                },
                identifier: {
                    scheme: priorityIdentifierScheme2,
                    id: `${priorityIdentifierId2}`,
                    legalName: {
                        uk_UA: priorityIdentifierLegalName2
                    }
                },
            }
        }  
    }
    let requestBodyCT = createPATCH()

    // useEffect(() => {
	//     combineOptions()
    // }, [])

    // useEffect(() => {
	//     createArr(uaIdentifiers, 1)
    // }, [uaIdentifiers])
    // useEffect(() => {
	//     createArr(worldIdentifiers, 2)
    // }, [worldIdentifiers])

    // async function getIdentifiers(url, func){
    //     //props.changeLoader(true)
    //     const endPoint = url;
    //     const response = await fetch(endPoint, {
    //         method: "GET",
    //         headers: {
    //             'Content-Type': 'application/json'
    //         }
    //     }).catch(console.error)
    //     if(response === undefined || response === 'undefined'){
    //     }else{
    //         const json = await response.json()
    //         await func(json)
    //     }
    // }

    // async function combineOptions(){
    //     if(uaIdentifiers === null){
    //         await getIdentifiers(`https://procedure-sandbox.prozorro.sale/api/classifiers/ua_identifiers`, setIdentifiers)
    //     }
    //     if(worldIdentifiers === null){
    //         await getIdentifiers(`https://procedure-sandbox.prozorro.sale/api/classifiers/identifiers`, setWorldIdentifiers)
    //     }
    // }

    // async function createArr(data, type){
    //     let tmp = []
    //     for (let key in data) {
    //         if(data.hasOwnProperty(key)){
    //             if(type === 1) tmp.push({value: `${key}`, name: data[key].legalNameUa})
    //             if(type === 2) tmp.push({value: `${key}`, name: data[key].legalNameEn})
    //         }
    //     }
    //     if(type === 1) setOptionsId([...tmp])
    //     if(type === 2){
    //         let tmp2 = [...optionsId, ...tmp]
    //         setOptionsId([...tmp2])
    //     }
    // }
    //console.log(priorityIdentifierScheme === optionsId[0].value)
    
    function createPATCH(){
        if(numberOfCurrentTenants === 2){
            let tmp = {
                currentTenants: [
                    {
                        identifier: {
                            scheme: identifierSchemeCurrentTenants,
                            id: `${identifierIdCurrentTenants}`,
                            legalName: {
                                uk_UA: identifierLegalNameCurrentTenants
                            }
                        },
                        address: addressCurrentTenants,
                        representativeInfo: contactRepresentativeInfoCurrentTenants,
                        value: {
                            currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                            amount: checkProp(contactValueCurrentTenants,'amount') && contactValueCurrentTenants?.amount !== '' ? +contactValueCurrentTenants.amount : null,
                        },
                        currentContractTime: {
                            dateFrom: contactTimeDateFromCurrentTenants,
                            dateTill: contactTimeDateTillCurrentTenants
                        },
                        priority: priority,
                        priorityTransferInfo: {
                            transferredFrom: priorityTransferInfoTransferredFrom,
                            // name: {
                            //     uk_UA: priorityTransferInfoName
                            // },
                            identifier: {
                                scheme: priorityIdentifierScheme,
                                id: `${priorityIdentifierId}`,
                                legalName: {
                                    uk_UA: priorityIdentifierLegalName
                                }
                            },
                        }
                    },
                    {
                        identifier: {
                            scheme: identifierSchemeCurrentTenants2,
                            id: `${identifierIdCurrentTenants2}`,
                            legalName: {
                                uk_UA: identifierLegalNameCurrentTenants2
                            }
                        },
                        address: addressCurrentTenants2,
                        representativeInfo: contactRepresentativeInfoCurrentTenants2,
                        value: {
                            currency: checkProp(contactValueCurrentTenants2,'currency') ? contactValueCurrentTenants2.currency : null,
                            amount: checkProp(contactValueCurrentTenants2,'amount') && contactValueCurrentTenants2?.amount !== '' ? +contactValueCurrentTenants2.amount : null,
                        },
                        currentContractTime: {
                            dateFrom: contactTimeDateFromCurrentTenants2,
                            dateTill: contactTimeDateTillCurrentTenants2
                        },
                        priority: priority2,
                        priorityTransferInfo: {
                            transferredFrom: priorityTransferInfoTransferredFrom2,
                            // name: {
                            //     uk_UA: priorityTransferInfoName2
                            // },
                            identifier: {
                                scheme: priorityIdentifierScheme2,
                                id: `${priorityIdentifierId2}`,
                                legalName: {
                                    uk_UA: priorityIdentifierLegalName2
                                }
                            },
                        }
                    }
                ]
            }
            if(tmp.currentTenants[0].currentContractTime.dateFrom === null || tmp.currentTenants[0].currentContractTime.dateTill === null){
                tmp.currentTenants[0].currentContractTime = null
            }
            if(tmp.currentTenants[1].currentContractTime.dateFrom === null || tmp.currentTenants[1].currentContractTime.dateTill === null){
                tmp.currentTenants[1].currentContractTime = null
            }
            if(tmp.currentTenants[0].value.amount === null || tmp.currentTenants[0].value.amount === '' || tmp.currentTenants[0].value.amount === 0){
                tmp.currentTenants[0].value = null
            }
            if(tmp.currentTenants[1].value.amount === null || tmp.currentTenants[1].value.amount === '' || tmp.currentTenants[1].value.amount === 0){
                tmp.currentTenants[1].value = null
            }
            return tmp
        }
        let tmp = {
            currentTenants: [
                {
                    identifier: {
                        scheme: identifierSchemeCurrentTenants,
                        id: `${identifierIdCurrentTenants}`,
                        legalName: {
                            uk_UA: identifierLegalNameCurrentTenants
                        }
                    },
                    address: addressCurrentTenants,
                    representativeInfo: contactRepresentativeInfoCurrentTenants,
                    value: {
                        currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                        amount: checkProp(contactValueCurrentTenants,'amount') && contactValueCurrentTenants?.amount !== '' ? +contactValueCurrentTenants.amount : null,
                    },
                    currentContractTime: {
                        dateFrom: contactTimeDateFromCurrentTenants,
                        dateTill: contactTimeDateTillCurrentTenants
                    },
                    priority: priority,
                    priorityTransferInfo: {
                        transferredFrom: priorityTransferInfoTransferredFrom,
                        /// name: {
                        //     uk_UA: priorityTransferInfoName
                        // },
                        identifier: {
                            scheme: priorityIdentifierScheme,
                            id: `${priorityIdentifierId}`,
                            legalName: {
                                uk_UA: priorityIdentifierLegalName
                            }
                        },
                    }
                }
            ]
        }
        if(tmp.currentTenants[0].currentContractTime.dateFrom === null || tmp.currentTenants[0].currentContractTime.dateTill === null){
            tmp.currentTenants[0].currentContractTime = null
        }
        if(tmp.currentTenants[0].value.amount === null || tmp.currentTenants[0].value.amount === '' || tmp.currentTenants[0].value.amount === 0){
            tmp.currentTenants[0].value = null
        }
        return tmp
    }

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label="Номер попереднього аукціону (аукціону з єдиним учасником)"
                            value={previousAuctionId}
                            onChange={(e)=>{ setPreviousAuctionId(e) }}
                            disabled={disabletElem(auction, '01')}
                            required
                        />
                    </div>
                    {/* discount 
                    ? 
                        <>
                            <Checkbox 
                                value={discount}
                                onChange={setDiscount}
                            >
                            Застосувати знижку
                            </Checkbox>
                            <div 
                            // className={styles.twoInLine}
                                style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true
                                    }}
                                    onChange={(e) => { setPreviousAuctionValue(e) }}
                                    disabled={disabletElem(auction, '01')}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e)=>{ setDiscountPercent(e) }}
                                    disabled={disabletElem(auction, '01')}
                                    required
                                />
                            </div>
                        </>
                    : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                        >
                            Застосувати знижку
                        </Checkbox>
                                */}
                </div>
            )
        } else {
            //requestBody.discount = null
        }
        return null
    }

    function disabledTransferPriorityPeriod(status, time){
        if(status === null) return false
        if(status === "active_rectification") return false
        if(status === "active_tendering"){
            if(time.hasOwnProperty("endDate")){
                let dateNow = new Date()
                let currentTimeZoneOffsetInHours = (dateNow.getTimezoneOffset() / 60) * -1

                let tmpDate = new Date(time.endDate)
                let tmp = tmpDate.getTime()
                tmp = tmpDate.getTime() + ((1000 * 60) * 60) * currentTimeZoneOffsetInHours

                if( tmp > Date.now()){
                    return false
                }
            }
        }
        return true
    }

    function colUch(status){
        /// if(status === null){
        //     return [
        //         {value: 1, name: '1'}
        //     ]
        // }
        return [
            {value: 1, name: '1'},
            {value: 2, name: '2'}
        ]
    }

    return (
        <>
            <Input //"Номер лота"
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={disabletElem(auction, '01')}
                // required
            />
            <Input //Назва аукціону"
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                disabled={disabletElem(auction, '01')}
                required
            />
            <TextArea //"Опис аукціону"
                value={description}
                disabled={disabletElem(auction, '01')}
                label={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />
            <Select //'Лот виставляється',
                data={{
                    label: "Порядковий номер аукціону", 
                    target: tenderAttempts,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabletElem(auction, '01')}
                onChange={(e) => { setTenderAttempts(e) }}
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label="Порядок та можливий час ознайомлення з лотом" 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                disabled={disabletElem(auction, '01')}
            />
            <Input //"Перелік та вимоги до оформлення документів"
                label="Перелік та вимоги до оформлення документів"
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                disabled={disabletElem(auction, '01')}
            />
            <Input
                label="Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={disabletElem(auction, '01')}
            />
            <Select //"Мінімальна кількість заяв" 
                data={{
                    label: "Мінімальна кількість учасників", 
                    target: minNumberOfQualifiedBids,
                    // info: 'Отака от штука-дрюка',
                    // required: true,
                }}
                disabled={true}
                onChange={(e) => { setMinNumberOfQualifiedBids(e) }}
                options={
                    [
                        {value: 'null', name: 'Обрати', selected: true},
                        {value: 1, name: '1'},
                        {value: 2, name: '2'}
                    ]
                }
            />
            <Select //"Кількість учасників з переважним правом" 
                data={{
                    label: "Кількість учасників з переважним правом", 
                    target: numberOfCurrentTenants,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabletElem(auction, '01')}
                onChange={(e) => { setNumberOfCurrentTenants(e) }}
                options={colUch(auction.status)}
            />
            <Select //"Тип продажу" 
                data={{
                    label: "Тип продажу", 
                    target: saleType,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                onChange={(e) => { setSaleType(e) }}
                options={
                    [
                        {value: 'landSale', name: "Продаж земельних ділянок"},
                        {value: 'superficiesSale', name: "Продаж прав суперфіцію"},
                        {value: 'emphyteusisSale', name: "Продаж прав емфітевзису"}
                    ]
                }
                disabled={disabletElem(auction, '11')}
            />
            <Input //"Обтяження прав на земельну ділянку, обмеження у використанні земель"
                label="Обтяження прав на земельну ділянку, обмеження у використанні земель"
                value={restrictions}
                onChange={(e)=>{ setRestrictions(e) }}
                disabled={disabletElem(auction, '01')}
            />
            <Input //"Містобудівні умови земельної ділянки"
                label="Містобудівні умови земельної ділянки"
                value={conditions}
                onChange={(e)=>{ setConditions(e) }}
                disabled={disabletElem(auction, '01')}
            />
            <Arrests arrests={arrests} setArrests={setArrests}/>


            {discountBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                <InputAmount //"Стартова ціна лота"
                    title="Стартова ціна"
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: checkProp(value,'amount') ? value.amount : 0,
                        currency: checkProp(value,'currency') ? value.currency : null,
                        addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : false
                    }}
                    onChange={(e) => {
                        setValue(e)
                        if(Number(e.amount) <= 20000){
                            setRegistrationFee({registrationFee, amount: 17})
                        }else if(Number(e.amount) <= 50000){
                            setRegistrationFee({registrationFee, amount: 119})
                        }else if(Number(e.amount) <= 200000){
                            setRegistrationFee({registrationFee, amount: 340})
                        }else if(Number(e.amount) <= 1000000){
                            setRegistrationFee({registrationFee, amount: 510})
                        }else if(Number(e.amount) > 1000000){
                            setRegistrationFee({registrationFee, amount: 1700})
                        }
                        let tmpMinStep = (Number(e.amount) / 100) * 1
                        setMinimalStep({
                            amount: tmpMinStep,
                            currency: value.currency
                        })
                    }}
                    disabled={disabletElem(auction, '01')}
                    tax
                    required
                />
                <InputAmount //"Гарантійний внесок"
                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.legalName}
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                    data={{
                        amount: checkProp(guarantee,'amount') ? guarantee.amount : 0.01,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    disabled={disabletElem(auction, '01')}
                    required
                />
                <InputAmount //"Розмір кроку аукціону"
                    title="Мінімальний крок"
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.minimalStep.amount.legalName}
                    data={{
                        amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    disabled={disabletElem(auction, '01')}
                    //required
                />
                <InputAmount //"Реєстраційний внесок"
                    title={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.registrationFee.legalName}
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(registrationFee,'amount') ? registrationFee.amount : 0.01,
                        currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                    }}
                    onChange={(e) => { setRegistrationFee(e) }}
                    disabled={disabletElem(auction, '01')}
                    required
                />
                <InputAmount //"Експертна грошова оцінка"
                    title="Експертна грошова оцінка"
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(expertMonetaryValuation,'amount') ? expertMonetaryValuation.amount : 0,
                        currency: checkProp(expertMonetaryValuation,'currency') ? expertMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setExpertMonetaryValuation(e) }}
                    disabled={disabletElem(auction, '01')}
                />
                <InputAmount //"Нормативна грошова оцінка"
                    title="Нормативна грошова оцінка"
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                    data={{
                        amount: checkProp(normativeMonetaryValuation,'amount') ? normativeMonetaryValuation.amount : 0,
                        currency: checkProp(normativeMonetaryValuation,'currency') ? normativeMonetaryValuation.currency : null,
                    }}
                    onChange={(e) => { setNormativeMonetaryValuation(e) }}
                    disabled={disabletElem(auction, '01')}
                />
                <InputAmount //"Сума витрат (видатків), здійснених на підготовку лота"
                    title="Сума витрат (видатків), здійснених на підготовку лота"
                    lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: checkProp(budgetSpent,'amount') ? budgetSpent.amount : 0,
                        currency: checkProp(budgetSpent,'currency') ? budgetSpent.currency : null,
                        addTax: checkProp(budgetSpent,'valueAddedTaxIncluded') ? budgetSpent.valueAddedTaxIncluded : false
                    }}
                    onChange={(e) => {setBudgetSpent(e)}}
                    disabled={disabletElem(auction, '01')}
                    tax
                />

                {/*<div style={{height: '20px'}}></div>
                <Checkbox 
                        value={valueAddedTaxCharged === null ? false : valueAddedTaxCharged}
                        onChange={setValueAddedTaxCharged}
                        disabled={true}
                    >
                        На фінальну суму нараховується ПДВ
                </Checkbox>*/}
            </div>
            <div className={styles.costBlock}>
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Інформація про учасника з переважним правом</div>
                    <Input //Повна юридична назва організації або ПІБ
                        label='Повна юридична назва організації або ПІБ'
                        value={identifierLegalNameCurrentTenants}
                        onChange={(e)=>{ setNameCurrentTenants(e) }}
                        disabled='true'
                    />
                    <div className={styles.orgBlock}>
                        <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                        <Input //Ідентифікатори організації
                            label='Повна юридична назва організації'
                            value={identifierLegalNameCurrentTenants}
                            onChange={(e)=>{ 
                                setIdentifierLegalNameCurrentTenants(e)
                            }}
                            required
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Select 
                            data={{
                                label: 'Ідентифікатори організації',
                                target: identifierSchemeCurrentTenants,
                                // info: 'Отака от штука-дрюка',
                                required: true,
                            }}
                            onChange={(e) => { setIdentifierSchemeCurrentTenants(e) }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                {name: 'Паспорт', value: 'UA-PASSPORT'},
                                {name: 'ID-карта', value: 'UA-ID-CARD'},
                                {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                            ]}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Input //Код ЄДРПОУ або ІПН або паспорт
                            label='Код ЄДРПОУ або ІПН або паспорт'
                            value={identifierIdCurrentTenants}
                            onChange={(e)=>{ setIdentifierIdCurrentTenants(e) }}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            required
                        />
                    </div>
                    <LocationOrg
                        address={addressCurrentTenants}
                        setAdress={setAdressCurrentTenants}
                        dataText='Місцезнаходження учасника'
                        disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                    />
                    <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                        label='Інформація щодо підтвердження повноважень'
                        value={contactRepresentativeInfoCurrentTenants}
                        onChange={(e)=>{ setContactRepresentativeInfoCurrentTenants(e) }}
                        disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                    />
                    <Select 
                        data={{
                            label: 'Учасник',
                            target: priority,
                            // info: 'Отака от штука-дрюка',
                            required: true,
                        }}
                        onChange={(e) => { 
                            setContactPriority(e)
                            if(e === 0){
                                setContactPriority2(1)
                            }else{
                                setContactPriority2(0)
                            }
                        }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'З переважним правом', value: 0},
                            {name: 'З переважним правом другої черги', value: 1},
                        ]}
                        disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                    />
                    <div className={styles.costBlock}>
                        <InputAmount //"Гарантійний внесок"
                            title="Орендна ставка (сума на рік)"
                            lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                            data={{
                                amount: checkProp(contactValueCurrentTenants,'amount') ? contactValueCurrentTenants.amount : '',
                                currency: checkProp(contactValueCurrentTenants,'currency') ? contactValueCurrentTenants.currency : null,
                                auctionType: 'brd'
                            }}
                            onChange={(e) => { setContactValueCurrentTenants(e) }}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                    </div>
                    <div className={styles.startDate}>
                        <div className={styles.costBlockTitle}>Дата початку та закінчення договору оренди</div>
                        <div>Період з</div>
                        <InputDate
                            data={{
                                target: contactTimeDateFromCurrentTenants,
                                time: true
                            }}
                            onChange={ e => setContactTimeDateFromCurrentTenants(e) }
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <div>Період по</div>
                        <InputDate
                            data={{
                                target: contactTimeDateTillCurrentTenants,
                                time: true
                            }}
                            onChange={ e => setContactTimeDateTillCurrentTenants(e) }
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                    </div>
                    <div className={styles.orgBlock}>
                        <div className={styles.costBlockTitle}>Інформація про передачу переважного права</div>
                        <Select 
                            data={{
                                label: 'Право передано від',
                                target: priorityTransferInfoTransferredFrom,
                                // info: 'Отака от штука-дрюка',
                                required: true,
                            }}
                            onChange={(e) => { setPriorityTransferInfoTransferredFrom(e) }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'Орендаря', value: '0'},
                                {name: 'Надрокористувача', value: '1'},
                            ]}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Input //Ідентифікатори організації
                            label='Назва організації'
                            value={priorityTransferInfoName}
                            required
                            disabled={true}
                        />
                        <Input //Ідентифікатори організації
                            label='Повна юридична назва організації'
                            value={priorityIdentifierLegalName}
                            onChange={(e)=>{ 
                                setPriorityIdentifierLegalName(e)
                                setPriorityTransferInfoName(e)
                            }}
                            required
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Select 
                            data={{
                                label: 'Ідентифікатори організації',
                                target: priorityIdentifierScheme,
                                // info: 'Отака от штука-дрюка',
                                required: true,
                            }}
                            onChange={(e) => { setPriorityIdentifierScheme(e) }}
                            options={[{name: 'Обрати', value: null}, ...optionsId]}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Input //Код ЄДРПОУ або ІПН або паспорт
                            label='Код ЄДРПОУ або ІПН або паспорт'
                            value={priorityIdentifierId}
                            onChange={(e)=>{ setPriorityIdentifierId(e) }}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            required
                        />
                    </div>
                </div>
                {numberOfCurrentTenants === 2
                    ? <div className={styles.costBlock}>
                        <div className={styles.costBlockTitle}>Інформація про учасника з переважним правом</div>
                        <Input //Повна юридична назва організації або ПІБ
                            label='Повна юридична назва організації або ПІБ'
                            value={identifierLegalNameCurrentTenants2}
                            onChange={(e)=>{ setNameCurrentTenants2(e) }}
                            disabled='true'
                        />
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Ідентифікатори організації або особи</div>
                            <Input //Ідентифікатори організації
                                label='Повна юридична назва організації'
                                value={identifierLegalNameCurrentTenants2}
                                onChange={(e)=>{ 
                                    setIdentifierLegalNameCurrentTenants2(e)
                                }}
                                required
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <Select 
                                data={{
                                    label: 'Ідентифікатори організації',
                                    target: identifierSchemeCurrentTenants2,
                                    // info: 'Отака от штука-дрюка',
                                    required: true,
                                }}
                                onChange={(e) => { setIdentifierSchemeCurrentTenants2(e) }}
                                options={[
                                    {name: 'Обрати', value: null},
                                    {name: 'ЄДРПОУ', value: 'UA-EDR'},
                                    {name: 'ІПН/РНОКПП', value: 'UA-IPN'},
                                    {name: 'Паспорт', value: 'UA-PASSPORT'},
                                    {name: 'ID-карта', value: 'UA-ID-CARD'},
                                    {name: 'ІПН/РНОКПП (ФОП)', value: 'UA-IPN-FOP'}
                                ]}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <Input //Код ЄДРПОУ або ІПН або паспорт
                                label='Код ЄДРПОУ або ІПН або паспорт'
                                value={identifierIdCurrentTenants2}
                                onChange={(e)=>{ setIdentifierIdCurrentTenants2(e) }}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                                required
                            />
                        </div>
                        <LocationOrg
                            address={addressCurrentTenants2}
                            setAdress={setAdressCurrentTenants2}
                            dataText='Місцезнаходження учасника'
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Input //№ свідоцтва про право на провадження діяльності арбітражного керуючого
                            label='Інформація щодо підтвердження повноважень'
                            value={contactRepresentativeInfoCurrentTenants2}
                            onChange={(e)=>{ setContactRepresentativeInfoCurrentTenants2(e) }}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <Select 
                            data={{
                                label: 'Учасник',
                                target: priority2,
                                /// info: 'Отака от штука-дрюка',
                                required: true,
                            }}
                            onChange={(e) => { 
                                setContactPriority2(e)
                                if(e === 0){
                                    setContactPriority(1)
                                }else{
                                    setContactPriority(0)
                                }
                            }}
                            options={[
                                {name: 'Обрати', value: null},
                                {name: 'З переважним правом', value: 0},
                                {name: 'З переважним правом другої черги', value: 1},
                            ]}
                            disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                        />
                        <div className={styles.costBlock}>
                            <InputAmount //"Гарантійний внесок"
                                title="Орендна ставка (сума на рік)"
                                lable={PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                                data={{
                                    amount: checkProp(contactValueCurrentTenants2,'amount') ? contactValueCurrentTenants2.amount : '',
                                    currency: checkProp(contactValueCurrentTenants2,'currency') ? contactValueCurrentTenants2.currency : null,
                                    auctionType: 'brd'
                                }}
                                onChange={(e) => { setContactValueCurrentTenants2(e) }}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                        </div>
                        <div className={styles.startDate}>
                            <div className={styles.costBlockTitle}>Дата початку та закінчення договору оренди</div>
                            <div>Період з</div>
                            <InputDate
                                data={{
                                    target: contactTimeDateFromCurrentTenants2,
                                    time: true
                                }}
                                onChange={ e => setContactTimeDateFromCurrentTenants2(e) }
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <div>Період по</div>
                            <InputDate
                                data={{
                                    target: contactTimeDateTillCurrentTenants2,
                                    time: true
                                }}
                                onChange={ e => setContactTimeDateTillCurrentTenants2(e) }
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                        </div>
                        
                        <div className={styles.orgBlock}>
                            <div className={styles.costBlockTitle}>Інформація про передачу переважного права</div>
                            <Select 
                                data={{
                                    label: 'Право передано від',
                                    target: priorityTransferInfoTransferredFrom2,
                                    // info: 'Отака от штука-дрюка',
                                    required: true,
                                }}
                                onChange={(e) => { setPriorityTransferInfoTransferredFrom2(e) }}
                                options={[
                                    {name: 'Обрати', value: null},
                                    {name: 'Орендаря', value: '0'},
                                    {name: 'Надрокористувача', value: '1'},
                                ]}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <Input //Ідентифікатори організації
                                label='Назва організації'
                                value={priorityTransferInfoName2}
                                required
                                disabled={true}
                            />
                            <Input //Ідентифікатори організації
                                label='Повна юридична назва організації'
                                value={priorityIdentifierLegalName2}
                                onChange={(e)=>{ 
                                    setPriorityIdentifierLegalName2(e)
                                    setPriorityTransferInfoName2(e)
                                }}
                                required
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <Select 
                                data={{
                                    label: 'Ідентифікатори організації',
                                    target: priorityIdentifierScheme2,
                                    // info: 'Отака от штука-дрюка',
                                    required: true,
                                }}
                                onChange={(e) => { setPriorityIdentifierScheme2(e) }}
                                options={optionsId}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                            />
                            <Input ///Код ЄДРПОУ або ІПН або паспорт
                                label='Код ЄДРПОУ або ІПН або паспорт'
                                value={priorityIdentifierId2}
                                onChange={(e)=>{ setPriorityIdentifierId2(e) }}
                                disabled={disabledTransferPriorityPeriod(auction.status, auction.transferPriorityPeriod)}
                                required
                            />
                        </div>
                    </div>
                : null
                }
            </div>
            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${PROCEDURE_NAME['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={disabletElem(auction, '11')}
                />
            </div>
            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp = null
                            let bodyObj = {
                               base: requestBody,
                            }
                            
                            if(auction.id !== null && auction.id !== ''){
                                if(auction.status === "active_tendering"){
                                    if(auction.transferPriorityPeriod.hasOwnProperty("endDate")){
                                        let dateNow = new Date()
                                        let currentTimeZoneOffsetInHours = (dateNow.getTimezoneOffset() / 60) * -1
                        
                                        let tmpDate = new Date(auction.transferPriorityPeriod.endDate)
                                        let tmp = tmpDate.getTime()
                                        tmp = tmpDate.getTime() + ((1000 * 60) * 60) * currentTimeZoneOffsetInHours
                        
                                        if( tmp > Date.now()){
                                            //bodyObj = requestBodyCT
                                            //tmp = createBodyCT(bodyObj)
                                            editAucCurrentTenant(props.updateAuc, requestBodyCT, null)
                                            //if(numberOfCurrentTenants > 1) editAucCurrentTenant(props.updateAuc, [requestBodyCT.currentTenants[1]], props.updateAuc.auctionsMy.currentTenants[1].id)
                                            //editAucArest(props.updateAuc, tmp)
                                        }else{
                                            tmp = createBody(bodyObj)
                                            editAuc(props.updateAuc, tmp)
                                            //editAucArest(props.updateAuc, tmp)
                                        }
                                    }else{
                                        tmp = createBody(bodyObj)
                                        editAuc(props.updateAuc, tmp)
                                        //editAucArest(props.updateAuc, tmp)
                                    }
                                }else{
                                    tmp = createBody(bodyObj)
                                    editAuc(props.updateAuc, tmp)
                                }
                            }else{
                                tmp = createBody(bodyObj)
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionLAP