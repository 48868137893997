import React, { useEffect } from 'react';
import styles from './createAuction.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import {PROCEDURE_NAME} from '../../redux/procedure_v3.35.2.uk';
import { isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD } from "../../redux/aucTypes";

import CreateAuctionZU from './createAuctionZU'

////////////////////////
import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
} from '../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../redux/createAddress';


import {
    changeCreateItemClassificationAddId,
    getMyAuction
} from '../../redux/actions.js';

import {bankAccountGet, bankAccountShemeGet, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../redux/actions/auctionBid/auctionBid.js';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU} from '../../redux/actions/itemUnits/itemUnits.js';

import {addressesGetMy} from '../../redux/actions/address/address.js';

import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../redux/togetherApi.js';

////////////////////////

import {changeCreateAuctionTab} from '../../redux/actions.js';
import {setPopUpSendAuction, setPopUpErrorAuction} from '../../redux/actions/auctions/auctions.js';
import {classificationsGetByScheme} from '../../redux/actions/classifications/classifications.js';
import {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    changePageNumber,
    setEditAuctionFromId,
    changeLoader,
} from '../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr} from '../../redux/actions/bankAccount/bankAccount.js';
import {oldArrDocumentGet} from '../../redux/actions/documents/documents.js';

import TabMenu from './tabMenu/tabMenu.js';
import TabMenuBid from './tabMenuBid/tabMenu.js';
import WindowAuction from './windowAuction/windowAuction.js';
import WindowBid from './windowBid/windowBid.js';
import WindowItems from './windowItems/windowItems.js';
import WindowDocument from './windowDocument/windowDocument.js';
import WindowAwards from './windowAwards/windowAwards.js';
import WindowContract from './windowContract/windowContract.js';
import WindowAnswer from './windowAnswer/windowAnswer';
import PagingButton from './paging'

//import Button from '@material-ui/core/Button';
//import DeleteIcon from '@material-ui/icons/Delete';
//import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/PlayArrow';
import CopyIcon from '@material-ui/icons/Pages';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
//import Icon from '@material-ui/core/Icon';

import {Redirect} from 'react-router-dom';

//import {AUCTIONS_GET_SEND} from '../../redux/types.js';
import {getAPI} from '../../redux/actions/crud.js';

import CreateAuctionLoader from './createAuctionLoader.js';
import {setLogo} from '../../redux/actions/support.js';

import {
    setPopUpAuctionMyNull,
} from '../../redux/actions/auctions/auctions.js';

import buttonsCancelAndDeleteAuction from './buttonsCancelAndDeleteAuction.js';
import ButtonCreateAuction from './buttonsNewAuction.js';
import ButtonEditAuction from './buttonsEditAuction.js';

import ListAuctions from './listAuction/actions'
import MyAuctionsHeader from './listAuction/myAuctionsHeader/myAuctionsHeader'
import ListMyBids from './listMyBids/myBids'
import { postGetToken } from '../../redux/actions/default/default';

function createArrayBankAccounts1(props, i){
    let tmp = [];
    if(i !== null){
        if(i.bankAccounts !== null || i.bankAccounts.length > 0){
            for(let t = 0; t < i.bankAccounts.length; t++){
                tmp[t] = i.bankAccounts[t].id
            }
        }
    }
    if(tmp !== null){
        props.setBankAccountSave(tmp)
        props.setPopBankAccountIdArr(tmp)
    }
}

const Product = ( props ) => {
    if(props.logo === null){
        props.setLogo(window.location.pathname.length)
    }else{
        if(window.location.pathname.length === 1){
            if(props.logo !== window.location.pathname.length){
                props.setLogo(null)
            }
        }else{
            if(props.logo !== window.location.pathname.length){
                props.setLogo(window.location.pathname.length)
            }
        }
    }

    let tmp = null;
    if(props.token !== null && props.userProfiles !== null){
        if(window.location.href.indexOf('/create_auctions/bridge/') !== -1){
            //http://localhost:3000/create_auctions/bridge/60dee12717d86507828bf9a4
            proxyBrege(props)
        }
        tmp = (
            <div className={styles.auctions__wrap}>
                {switchViewAuctionsMy(props)}
            </div>
        )
    }else{
        if (localStorage.getItem('token') !== null){
            let tmpLocal = JSON.parse(localStorage.getItem('token'))
            let tmpNowTimeStamp = Date.now();
            if(tmpLocal.timeStamp < tmpNowTimeStamp){
                tmp = <Redirect to='/'/>
            }else{
                props.getMyAuction(null)
                props.setPopUpAuctionMyNull()
                props.changeAuctionsSwitchWindow(1)
                
                let header5 = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                let data1 = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: 'timber-english?limit=100'
                }
                props.unitsGetByType(data1, header5)
                props.unitsGetByTypeEnergy(data1, header5)
                props.unitsGetByTypeRailway(data1, header5)
                props.unitsGetByTypeSubsoil(data1, header5)
                props.unitsGetByTypeZU(data1, header5)

                let data5 = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=100'
                }
                props.auctionsGetClassificationsByType(data5, header5)
                // props.auctionsGetClassificationsByTimberSortment(data5, header5)
                // props.auctionsGetClassificationsByTimberSpecies(data5, header5)
                // props.auctionsGetClassificationsByTimberClass(data5, header5)
                // props.auctionsGetClassificationsByTimberDiameter(data5, header5)
                // props.auctionsGetClassificationsByTimberLength(data5, header5)
                // props.auctionsGetClassificationsByTimberStorage(data5, header5)
                // props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
                // props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)
                props.auctionsGetClassificationsByWagonSpecies(data5, header5)
                props.auctionsGetClassificationsByTypeNadraEnglish(data5, header5)
                props.auctionsGetClassificationsByByShemaSubsoilUsageType(data5, header5)
                props.auctionsGetClassificationsByCarryType(data5, header5)
                props.auctionsGetClassificationsByTypeRailwayCargoAdd(data5, header5)
                
                props.auctionsGetClassificationsByTypeRailway(data5, header5)
                props.bankAccountShemeGet(data5, header5)
                props.auctionsGetWagonRestrict(data5, header5)
                props.auctionsGetWagonRestrictType(data5, header5)

                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                //props.auctionsGetMy(data, header)

                //getAucionFromId(props, aucId)
                //TODO: Временно отключено
                //props.auctionsBidsGetMy(data, header)
                let dataBank = {}
                dataBank.domen = process.env.REACT_APP_END_POINT_BD
                dataBank.version = 'v1'
                dataBank.local = props.langFlag
                dataBank.params = `?offset=0`

                //TODO: Нерабочий АПИ 20211018
                props.bankAccountGet(dataBank, header)

                concatDataClassification(props)
                getAdditionalClassificationZU(props)
                getAdditionalClassificationCVZU(props)
            }
        }
    }
    let tmpId = setPageId()
    useEffect(() => {
	    //getAucionFromId(props, tmpId[0])
        getAucionFromId(props, tmpId[0], 1)
    }, [])
    return (
        <>
            {tmp}
        </>
    )
}

function setBaseUrl(){
    if(window.location.pathname.indexOf('/create_auctions/edit/') !== -1){
        return window.location.pathname.split('/create_auctions/edit/');
    }
    return null
}

function setPageId(){
    let tmp = setBaseUrl()
    if(tmp !== null){
        return tmp[1].split('/')
    }
    return null
}


async function getAdditionalClassificationCVZU(props){
    if(props.classificationCVZU === null){
        //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/byScheme/cvzu`;
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
        const response = await fetch(endPoint, {
            method: "GET",
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            await props.setDataAddress(json, 'CREATE_CVZU_DATA')
        }
    }
}

async function proxyBrege(props){
    await objectBrige(props)
}
async function objectBrige(props){
    let testPath = window.location.href.split('/bridge/');
    if(props.cloneObjectId !== testPath[1]){
        props.setDataAddress(true, 'SET_BRIDGE_OPEN_POPUP')
        props.setDataAddress(testPath[1], 'SET_ID_OBJECT_CLONE_AUCTIONS')
        let url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/bridge/${testPath[1]}`
        const response = await fetch(url, {
            method: "POST",
            headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
            }
        })
        /*.then((response) => {
            return response.json();
        })//+++
        .then((data) => {
            bridgeAuction(props, data)
        })*/
        .catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json.message[0])})
                props.setUniversalError(tmp)
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                return <Redirect to='/create_auctions'/>
            }else{
                bridgeAuction(props, json)
            }
        }
        return <Redirect to='/create_auctions'/>
    }
}

///////////////////////////////////////////
//Новый аук
async function concatDataClassification(props){
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '06121000-6')
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '06111000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '06112000-0'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '06122000-3'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '06123000-0'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '06124000-7'))
    let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '06125000-4'))
    let tmpArr7 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '06126000-1'))
    let tmpArr8 = await tmpArr7.concat(await getDictionaryJointPropertyComplex(props, '06127000-8'))
    let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '06128000-5'))
    let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '06129000-2'))
    props.setDataAddress(tmpArr10, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}
///////////////////////////////////////////


async function bridgeAuction(props, data){
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    //props.changeAuctionsSwitchWindow(1)



    let dataPP = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag
    }
    let headers = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    //props.auctionsGetAwards(dataPP, headers, data.id)
    //props.contractsAuctionIdGet(dataPP, headers, data.id)
    await createArrayBankAccounts1(props, data)

    /*if(data.documents.length > 0){
        props.oldArrDocumentGet(process.env.REACT_APP_END_POINT_BD, data.procedureId)
    }*/ //16/8/21
    if(data.discount !== null){
        props.changeDiscountPercent(data.discount.discountPercent)
        props.changeDiscountId(data.discount.id)
        props.discountAuc(data.discount.discount)
        if(data.discount.previousAuctionValue !== null){
            props.changeDiscountPreviousAuctionCurrency(data.previousAuctionValue.currency)
            props.changeDiscountPreviousAuctionAmount(data.previousAuctionValue.amount)
        }
    }
    props.changeDiscountPreviousAuctionId(data.previousAuctionId)
    props.setEditAuctionFromId(null)
    await getAucionFromId(props, data.id, 1)
    return <Redirect to='/create_auctions'/>
}

function switchViewAuctionsMy(props){
    let tmp = null;
    let tmpCreateAuc = null;
    let tmpCreateBid = null;
    if(
        props.userProfiles.canCreateAuction === true ||
        props.userProfiles.canCreateLandAuction === true ||
        props.userProfiles.canCreateLeaseAuction === true
    ){
        tmpCreateAuc = listsMyAuctions(props)
    }
    if(props.userProfiles.canCreateBid === true){
        tmpCreateBid = listsMyAuctionsBids(props)
    }
    if(props.auctionsSwitchWindow === 0){
        tmp = (
            <>
                <ListAuctions />
                {tmpCreateBid}
            </>
        )
    }else if(props.auctionsSwitchWindow >= 1 && props.auctionsSwitchWindow <= 3){
        if(props.auctionsMySingl !== null){

            if(props.auctionsMySingl.status === 'active_tendering'){
                tmp = windowCreateEditMyAuction(props, true)
            }else{
                tmp = windowCreateEditMyAuction(props, false)
            }
        }else{
            tmp = windowCreateEditMyAuction(props, false)
        }
    }else if(props.auctionsSwitchWindow === 4 ){
        tmp = windowCreateEditMyAuctionBid(props)
    }
    return tmp
}

//myBids

function listsMyAuctions(props){
    let tmp = null;
    if(props.auctionsMy !== null){
        if(props.auctionsMy.length > 0){
            tmp = props.auctionsMy
            .map(
                (i, count) => {
                    return (
                        <div key={i.id} className={styles.auctionsLine}>
                            <div className={styles.auctionsLineElem}>
                                <div className={styles.documentsId + ' ' + styles.aucID}>{i.id}</div>
                                <div className={styles.documentsId + ' ' + styles.aucTitle}>{i.title}</div>
                                <div className={styles.documentsId + ' ' + styles.aucProzorroId}>{i.auctionId}</div>
                                <div className={styles.documentsId + ' ' + styles.aucStatus}>{viewTextStatusUkr(i.status)}</div>
                                <div className={styles.documentsId + ' ' + styles.aucStatus + ' ' + styles.warningData  + ' ' + styles.document}>
                                    <div>{checkDocuments(i)}</div>
                                    <div>{checkItems(i)}</div>
                                </div>
                            </div>
                            <div className={styles.auctionsLineElem}>
                                {linkAuction(i)}
                                <ButtonEditAuction i={i} count={count}/>
                                {buttonPublishAuctionNew(props, i)}
                                <buttonsCancelAndDeleteAuction i={i} />
                                <div
                                    title="Створення копії аукціону"
                                    onClick={
                                        ()=>{
                                            //props.changeAuctionsSwitchWindow(3)
                                            getCopyAuc(props, props.auctionsMy[count].id)
                                            props.getMyAuction(props.auctionsMy[count])
                                            createArrayBankAccounts(props, i)
                                            props.changeCreateAuctionTenderAttempts(i.tenderAttempts)
                                            if(i.discount !== null){
                                                //props.changeDiscountPercent(i.discount.discountPercent)
                                                //props.changeDiscountPreviousAuctionCurrency(i.discount.previousAuctionValue.currency)
                                                //props.changeDiscountPreviousAuctionAmount(i.discount.previousAuctionValue.amount)
                                                //props.changeDiscountPreviousAuctionAmount(i.value.amount)
                                            }


                                            props.changeDiscountPreviousAuctionId(i.auctionId)
                                            //props.changeDiscountPreviousAuctionId(i.procedureId)
                                            
                                            
                                            props.setEditAuctionFromId(null)
                                            getAucionFromId(props, i.id, 3)
                                            //getAddressOrg(props)
                                        }
                                    }
                                >
                                    <IconButton aria-label="copy">
                                        <CopyIcon />
                                    </IconButton>
                                </div>
                                {cloneZU(props, i, count)}
                                {viewcompleteAuction(props, i, count)}
                            </div>
                        </div>
                    )
                }
            )
        }
    }else{
        tmp = <CreateAuctionLoader />
    }
    return (
        <div>
            {combineButtons(props)}
            <div key={-1} className={styles.auctionsLine}>
                <div className={styles.auctionsLineElem}>
                    <div className={styles.documentsId + ' ' + styles.aucID}>ID аукціону</div>
                    <div className={styles.documentsId + ' ' + styles.aucTitle}>Назва аукціону</div>
                    <div className={styles.documentsId + ' ' + styles.aucProzorroId}>ID аукціону Prozorro</div>
                    <div className={styles.documentsId + ' ' + styles.aucStatus}>Статус аукціону</div>
                    <div className={styles.documentsId + ' ' + styles.aucStatus + ' ' + styles.document}>Необхідно додати</div>
                </div>
            </div>
            {tmp}
            <PagingButton />
        </div>
    )
}

function combineButtons(props){
    let tmpCreateAuction = null
    let tmpCreateAuctionZU = null
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateAuction === true) tmpCreateAuction = <ButtonCreateAuction />
        if(props.userProfiles.canCreateLeaseAuction === true) tmpCreateAuctionZU = <CreateAuctionZU />
    }
    return(
        <div>
            {tmpCreateAuction}
            {tmpCreateAuctionZU}
        </div>
    )
}

function cloneZU(props, i, count){
    if(
        i.status === 'cancelled' ||
        i.status === 'unsuccessful' ||
        i.status === 'complete'
    ){
        if(
            i.sellingMethod === "legitimatePropertyLease-english" ||
            i.sellingMethod === "legitimatePropertyLease-english-fast" ||
            i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
        ){
            if(i.tenderAttempts === 1){
                return (
                    <div
                        className={styles.zuCopy}
                        title="Створення копії аукціону"
                        onClick={
                            ()=>{
                                //props.changeAuctionsSwitchWindow(3)
                                getCopyAuc(props, props.auctionsMy[count].id)
                                props.getMyAuction(props.auctionsMy[count])
                                createArrayBankAccounts(props, i)
                                props.changeCreateAuctionTenderAttempts(i.tenderAttempts)
                                if(i.discount !== null){
                                    //props.changeDiscountPercent(i.discount.discountPercent)
                                    //props.changeDiscountPreviousAuctionCurrency(i.discount.previousAuctionValue.currency)
                                    //props.changeDiscountPreviousAuctionAmount(i.discount.previousAuctionValue.amount)
                                    //props.changeDiscountPreviousAuctionAmount(i.value.amount)
                                }

                                props.changeDiscountPreviousAuctionId(i.auctionId)
                                
                                props.setEditAuctionFromId(null)
                                getAucionFromId(props, i.id, 3, '2')
                                //getAddressOrg(props)
                            }
                        }
                    >
                        Створити 2й англ. аукціон
                    </div>
                )
            }else if(i.tenderAttempts === 2){
                return (
                    <div
                        className={styles.zuCopy}
                        title="Створення копії аукціону"
                        onClick={
                            ()=>{
                                //props.changeAuctionsSwitchWindow(3)
                                getCopyAuc(props, props.auctionsMy[count].id)
                                props.getMyAuction(props.auctionsMy[count])
                                createArrayBankAccounts(props, i)
                                props.changeCreateAuctionTenderAttempts(i.tenderAttempts)
                                if(i.discount !== null){
                                    //props.changeDiscountPercent(i.discount.discountPercent)
                                    //props.changeDiscountPreviousAuctionCurrency(i.discount.previousAuctionValue.currency)
                                    //props.changeDiscountPreviousAuctionAmount(i.discount.previousAuctionValue.amount)
                                    //props.changeDiscountPreviousAuctionAmount(i.value.amount)
                                }

                                props.changeDiscountPreviousAuctionId(i.auctionId)
                                
                                props.setEditAuctionFromId(null)
                                getAucionFromId(props, i.id, 3, '3')
                                //getAddressOrg(props)
                            }
                        }
                    >
                        Створити голандський аукціон
                    </div>
                )
            }
        }
    }
}

function linkAuction(i){
    if(i.sellingMethod !== null && i.sellingMethod !== ''){
        return (
            <Link to={`/tenders/${i.id}`} title="Посилання на аукціон">
                <IconButton aria-label="edit">
                    <LinkIcon/>
                </IconButton>
            </Link>
        )
    }
    return null
}

function checkDocuments(i){
    let tmpTechnicalSpecifications = 'Технічна специфікація'
    let tmpContractProforma = ' Проект договору'

    let tmpX_auctionDocumentationPackage = ' Пакет аукціонної документації'
    let tmpX_submissionBidProcedure = ' Порядок подання заявок для участі в аукціоні'
    let tmpX_nonSanctionedRequirements = ' Вимоги до заявників (санкції)'
    let tmpX_brokerPaymentConditions = ' Порядок та строки сплати винагороди оператору'
    let tmpX_qualificationSigningTerms = ' Строки підписання протоколу проведення аукціону та договорів купівлі-продажу'
    let tmpEvaluationCriteria = ' Перелік даних, які надаються заявниками'

    let tmpContractProformaZU = ' Проект договору оренди'
    let tmpIllustrationZU = ' Фотографічне зображення майна'

    if(
        i.sellingMethod === "timber-english" ||
        i.sellingMethod === "timber-english-fast" ||
        i.sellingMethod === "timber-english-fast-manual" ||
        i.sellingMethod === "timber-english-initial-auction" ||
        i.sellingMethod === "timber-english-initial-qualification" ||
        i.sellingMethod === "timber-english-initial-qualification-fast"
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = ''
                if(i.documents[tr].documentType === "contractProforma") tmpContractProforma = ''
            }
        }
        return (
            <>
                <div>{tmpTechnicalSpecifications}</div>
                <div>{tmpContractProforma}</div>
            </>
        )
    }else if(
        i.sellingMethod === "railwayCargo-english" ||
        i.sellingMethod === "railwayCargo-english-fast" ||
        i.sellingMethod === "railwayCargo-english-fast-manual" ||
        i.sellingMethod === "railwayCargo-english-initial-auction" ||
        i.sellingMethod === "railwayCargo-english-initial-qualification" ||
        i.sellingMethod === "railwayCargo-dutch" ||
        i.sellingMethod === "railwayCargo-dutch-fast" ||
        i.sellingMethod === "railwayCargo-dutch-fast-manual" ||
        i.sellingMethod === "railwayCargo-dutch-initial-auction" ||
        i.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
        i.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
        i.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
    ){ 
        tmpContractProforma = ''
        tmpTechnicalSpecifications = ''
        return (
            <>
                <div>{tmpTechnicalSpecifications}</div>
                <div>{tmpContractProforma}</div>
            </>
        )
    }else if(
        i.sellingMethod === "subsoil-english" ||
        i.sellingMethod === "subsoil-english-fast" ||
        i.sellingMethod === "subsoil-english-fast-manual" ||
        i.sellingMethod === "subsoil-english-initial-auction" ||
        i.sellingMethod === "subsoil-english-initial-qualification" ||
        i.sellingMethod === "subsoil-dutch" ||
        i.sellingMethod === "subsoil-dutch-fast" ||
        i.sellingMethod === "subsoil-dutch-fast-manual" ||
        i.sellingMethod === "subsoil-dutch-initial-auction" ||
        i.sellingMethod === "subsoil-dutch-initial-qualification" ||
        i.sellingMethod === "subsoil-dutch-initial-auction-manual" ||
        i.sellingMethod === "subsoil-english-fast-auction-manual-qualification"
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "x_auctionDocumentationPackage") tmpX_auctionDocumentationPackage = ''
                if(i.documents[tr].documentType === "x_submissionBidProcedure") tmpX_submissionBidProcedure = ''
                if(i.documents[tr].documentType === "x_nonSanctionedRequirements") tmpX_nonSanctionedRequirements = ''
                if(i.documents[tr].documentType === "x_brokerPaymentConditions") tmpX_brokerPaymentConditions = ''
                if(i.documents[tr].documentType === "x_qualificationSigningTerms") tmpX_qualificationSigningTerms = ''
                if(i.documents[tr].documentType === "evaluationCriteria") tmpEvaluationCriteria = ''
            }
        }
        return (
            <>
                <div>{tmpX_auctionDocumentationPackage}</div>
                <div>{tmpX_submissionBidProcedure}</div>
                <div>{tmpX_nonSanctionedRequirements}</div>
                <div>{tmpX_brokerPaymentConditions}</div>
                <div>{tmpX_qualificationSigningTerms}</div>
                <div>{tmpEvaluationCriteria}</div>
            </>
        )
    }else if(
        i.sellingMethod === "legitimatePropertyLease-english" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        i.sellingMethod === "legitimatePropertyLease-dutch" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "contractProforma") tmpContractProformaZU = ''
                if(i.documents[tr].documentType === "illustration") tmpIllustrationZU = ''
            }
        }
        return(
            <>
                <div>{tmpContractProformaZU}</div>
                <div>{tmpIllustrationZU}</div>
            </>
        )
    }else{
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = ''
            }
        }
        return <div>{tmpTechnicalSpecifications}</div>
    }
}
function checkItems(i){
    if(i.hasOwnProperty('items') === true){
        if(i.items !== null){
            if(i.items.length > 0){
                return null
            }
        }
    }
    return 'Лот аукціону'
}

function viewTextStatusUkr(status){
    if(status === 'active_rectification'){
        return 'Редагування доступне'
    }else if(status === 'active_tendering'){
        return 'Прийняття заяв на участь'
    }else if(status === 'active_auction'){
        return 'Аукціон'
    }else if(status === 'active_qualification'){
        return 'Очікується опублікування протоколу'
    }else if(status === 'active_awarded'){
        return 'Очікується підписання договору'
    }else if(status === 'pending_payment'){
        return 'Очікується сплата до бюджету'
    }else if(status === 'pending_admission'){
        return 'Аукціон завершено. Очікується виконання умов договору'
    }else if(status === 'pending_payment'){
        return 'Очікується оплата'
    }else if(status === 'cancelled'){
        return 'Аукціон скасовано'
    }else if(status === 'unsuccessful'){
        return 'Аукціон не відбувся'
    }else if(status === 'complete'){
        return 'Аукціон завершено'
    }else if(status === null){
        return 'Чернетка'
    }else{
        return status
    }
}

function createArrayBankAccounts(props, i){
    let tmp = [];
    if(i !== null){
        if(i.bankAccounts !== null || i.bankAccounts.length > 0){
            for(let t = 0; t < i.bankAccounts.length; t++){
                tmp[t] = i.bankAccounts[t].id
            }
        }
    }
    if(tmp !== null){
        props.setBankAccountSave(tmp)
        props.setBankAccountAdd([])
        props.setBankAccountDelete([])
        props.setPopBankAccountIdArr(tmp)
    }
}

function viewcompleteAuction(props, i){
    let tmp = <div className={styles.disabledButton}>Завершення аукціону</div>
    let tmpSellingMethod = null
    if(i.sellingMethod !== null){
        tmpSellingMethod = i.sellingMethod.indexOf('railwayCargo')
    }
    if( tmpSellingMethod === -1){
        if(i.hasOwnProperty('contracts') === true){
            if(i.contracts.length > 0){
                if(i.contracts[0].status === 'active'){
                    tmp = <div className={styles.enabledButton}
                        onClick={
                            () => {
                                completeAuction(props, i.id)
                            }
                        }
                    >
                        Завершення аукціону
                    </div>
                }
            }
        }
    }else{
        if(i.status === 'active_qualification'){
        //if(i.hasOwnProperty('awards') === true){
            //if(i.awards.length > 0){
                //if(i.awards[0].status === 'active'){
                    tmp = <div className={styles.enabledButton}
                        onClick={
                            () => {
                                completeAuction(props, i.id)
                            }
                        }
                    >
                        Завершення аукціону
                    </div>
                //}
            //}
        //}
        }
    }
    if(i.status === "unsuccessful" || i.status === "cancelled" || i.status === "complete") tmp = <div className={styles.disabledButton}>Завершення аукціону</div>

    let tmpContractDoc = false
    let tmpContractStatus = false
    /*if(i.contracts !== null){
        if(i.contracts.length > 0){
            for(let t = 0; t < i.contracts.length; t++){
                if(i.contracts[t].status === 'active'){
                    tmpContractStatus = true
                }
            }
        }
    }*/
    return tmp
}

function listsMyAuctionsBids(props){
    let tmp = null;
    if(props.myBids !== null){
        if (props.myBids.length > 0) {
            return <ListMyBids />
        }
    }else{
        tmp = <CreateAuctionLoader />
    }
    return <div>{tmp}</div>
}

/////////////////////////////////
function checkDocumentsPublish(i){
    let tmpTechnicalSpecifications = false
    let tmpContractProforma = false
    let tmpX_auctionDocumentationPackage = false
    let tmpX_submissionBidProcedure = false
    let tmpX_nonSanctionedRequirements = false
    let tmpX_brokerPaymentConditions = false
    let tmpX_qualificationSigningTerms = false
    let tmpEvaluationCriteria = false
    if(
        i.sellingMethod === "timber-english" ||
        i.sellingMethod === "timber-english-fast" ||
        i.sellingMethod === "timber-english-fast-manual" ||
        i.sellingMethod === "timber-english-initial-auction" ||
        i.sellingMethod === "timber-english-initial-qualification" ||
        i.sellingMethod === "timber-english-initial-qualification-fast"
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = true
                if(i.documents[tr].documentType === "contractProforma") tmpContractProforma = true
            }
        }
    }else if(
        i.sellingMethod === "subsoil-english" ||
        i.sellingMethod === "subsoil-english-fast" ||
        i.sellingMethod === "subsoil-english-fast-manual" ||
        i.sellingMethod === "subsoil-english-initial-auction" ||
        i.sellingMethod === "subsoil-english-initial-qualification" ||
        i.sellingMethod === "subsoil-dutch" ||
        i.sellingMethod === "subsoil-dutch-fast" ||
        i.sellingMethod === "subsoil-dutch-fast-manual" ||
        i.sellingMethod === "subsoil-dutch-initial-auction" ||
        i.sellingMethod === "subsoil-dutch-initial-qualification" ||
        i.sellingMethod === "subsoil-dutch-initial-auction-manual" ||
        i.sellingMethod === "subsoil-english-fast-auction-manual-qualification"
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "x_auctionDocumentationPackage") tmpX_auctionDocumentationPackage = true
                if(i.documents[tr].documentType === "x_submissionBidProcedure") tmpX_submissionBidProcedure = true
                if(i.documents[tr].documentType === "x_nonSanctionedRequirements") tmpX_nonSanctionedRequirements = true
                if(i.documents[tr].documentType === "x_brokerPaymentConditions") tmpX_brokerPaymentConditions = true
                if(i.documents[tr].documentType === "x_qualificationSigningTerms") tmpX_qualificationSigningTerms = true
                if(i.documents[tr].documentType === "evaluationCriteria") tmpEvaluationCriteria = true
            }
        }
        if(
            tmpX_auctionDocumentationPackage === true &&
            tmpX_submissionBidProcedure === true &&
            tmpX_nonSanctionedRequirements === true &&
            tmpX_brokerPaymentConditions === true &&
            tmpX_qualificationSigningTerms === true &&
            tmpEvaluationCriteria === true
        ){
            return true
        }else{
            return false
        }
    }else if(
        i.sellingMethod === "legitimatePropertyLease-english" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        i.sellingMethod === "legitimatePropertyLease-dutch" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        i.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        let tmpContractProformaZU = false
        let tmpIllustrationZU = false
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "contractProforma") tmpContractProformaZU = true
                if(i.documents[tr].documentType === "illustration") tmpIllustrationZU = true
            }
        }
        if(
            tmpContractProformaZU === true &&
            tmpIllustrationZU === true
        ){
            return true
        }else{
            return false
        }
    }


	if(tmpTechnicalSpecifications === true && tmpContractProforma === true){
		return true
	}else{
		return false
	}
}

function checkItemsPublish(i){
    if(i.items !== null){
        if(i.items.length > 0){
            return true
        }
    }
    return false
}

function buttonPublishAuctionNew(props, i){
    let tmp = <div title="Публікація аукціону">
        <IconButton
            disabled='disabled'
            aria-label="add"
        >
            <AddIcon />
        </IconButton>
    </div>
    if(i.status === null){
        switch(i.sellingMethod){
            case "railwayCargo-english":
            case "railwayCargo-english-fast":
            case "railwayCargo-english-fast-manual":
            case "railwayCargo-english-initial-auction":
            case "railwayCargo-english-initial-qualification":
            case "railwayCargo-dutch":
            case "railwayCargo-dutch-fast":
            case "railwayCargo-dutch-fast-manual":
            case "railwayCargo-dutch-initial-auction":
            case "railwayCargo-dutch-initial-qualification":
            case "railwayCargo-dutch-fast-auction-manual-qualification":
            case "railwayCargo-dutch-initial-auction-manual":
                if(checkItemsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            case "timber-english":
            case "timber-english-fast":
            case "timber-english-fast-manual":
            case "timber-english-initial-auction":
            case "timber-english-initial-qualification":
            case "timber-english-initial-qualification-fast":
                if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
            case "subsoil-dutch":
            case "subsoil-dutch-fast":
            case "subsoil-dutch-fast-manual":
            case "subsoil-dutch-initial-auction":
            case "subsoil-dutch-initial-qualification":
            case "subsoil-dutch-initial-auction-manual":
            case "subsoil-english-fast-auction-manual-qualification":
                if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            case "legitimatePropertyLease-english":
            case "legitimatePropertyLease-english-fast":
            case "legitimatePropertyLease-english-fast-manual":
            case "legitimatePropertyLease-english-fast-auction-manual-qualification":
            case "legitimatePropertyLease-english-initial-auction":
            case "legitimatePropertyLease-english-initial-auction-manual":
            case "legitimatePropertyLease-english-initial-qualification":
            case "legitimatePropertyLease-english-initial-qualification-prod":
            case "legitimatePropertyLease-dutch":
            case "legitimatePropertyLease-dutch-fast":
            case "legitimatePropertyLease-dutch-fast-manual":
            case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
            case "legitimatePropertyLease-dutch-initial-auction":
            case "legitimatePropertyLease-dutch-initial-auction-manual":
            case "legitimatePropertyLease-dutch-initial-qualification-prod":
            case "legitimatePropertyLease-priorityEnglish":
            case "legitimatePropertyLease-priorityEnglish-fast":
            case "legitimatePropertyLease-priorityEnglish-fast-manual":
            case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-auction":
            case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{patchZURegistry(props, i.id, i.registryId)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            default:
                if(checkItemsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                }
                break;
        }
    }
    return tmp
}

function windowCreateEditMyAuction(props, type){
    let tmpTab = [];
    if(type === true){
        let tmpStartData = null
        let tmpEndData = null
        for(let tt = 0; tt < props.auctionsMySingl.auctionPeriods.length; tt++){
            if(props.auctionsMySingl.auctionPeriods[tt].status === 'rectificationPeriod'){
                tmpStartData = new Date(props.auctionsMySingl.auctionPeriods[tt].startDate).getTime()
                tmpEndData = new Date(props.auctionsMySingl.auctionPeriods[tt].endDate).getTime()
            }
        }
        if(
            (isBSE(props.auctionsMySingl.sellingMethod) || isBSD(props.auctionsMySingl.sellingMethod) ||
            isALE(props.auctionsMySingl.sellingMethod) ||
            isCSE(props.auctionsMySingl.sellingMethod) || isCSD(props.auctionsMySingl.sellingMethod) ||
            isBRE(props.auctionsMySingl.sellingMethod) || isBRD(props.auctionsMySingl.sellingMethod)
            ) &&
            (tmpStartData < Date.now() && tmpEndData > Date.now())
        ){
            tmpTab[0] = <TabMenu count={0} state={true}/>
            tmpTab[1] = <TabMenu count={1} state={true}/>
            tmpTab[2] = <TabMenu count={2} state={true}/>
        }else{
            tmpTab[0] = <TabMenu count={0} state={true}/>
            tmpTab[1] = <TabMenu count={2} state={true}/>
        }
    }else{
        if(props.auctionsSwitchWindow === 1){
            for(let i = 0; i < 3; i++){
                if(props.auctionsMySingl !== null){
                    if(i === 5){
                        if(
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-fast" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-fast-manual" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-initial-auction" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-initial-qualification" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-fast" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
                        ){
                            continue
                        }
                    }
                }
                tmpTab[i] = <TabMenu count={i} state={true}/>
            }
        }else if(props.auctionsSwitchWindow === 2){
            for(let i = 0; i < 3; i++){
                if(props.auctionsMySingl !== null){
                    if(i === 5){
                        if(
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-fast" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-fast-manual" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-initial-auction" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-english-initial-qualification" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-fast" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                            props.auctionsMySingl.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
                        ){
                            continue
                        }
                    }
                }
                tmpTab[i] = <TabMenu count={i} state={false}/>
            }
        }
    }
    return (
        <>
            <div className={styles.tab__wrap}>
                {tmpTab}
            </div>
            {viewWindowChoiseTab(props)}
        </>
    )
}

function windowCreateEditMyAuctionBid(props){
    let tmpTab = [];
    for(let i = 0; i < 3; i++){
        tmpTab[i] = <TabMenuBid count={i} />
    }
    return (
        <>
            <div className={styles.tab__wrap}>
                {tmpTab}
            </div>
            {viewWindowChoiseTabBid(props)}
        </>
    )
}

function viewWindowChoiseTab(props){
    let tmp = null;
    switch(props.createAuctionTab){
        case 0:
            tmp = <WindowAuction />
            break;
        case 1:
            tmp = <WindowItems />
            break;
        case 2:
            tmp = <WindowDocument />
            break;
        case 3:
            tmp = <WindowAnswer />
            //tmp = ''
            break;
        case 4:
            tmp = <WindowAwards />
            //tmp = ''
            break;
        case 5:
            tmp = <WindowContract />
            //tmp = ''
            break
        default:
            tmp = ''
            break;
    }
    return tmp
}

function viewWindowChoiseTabBid(props){
    let tmp = null;
    switch(props.createAuctionTab){
        case 0:
            tmp = <WindowBid />
            break;
        case 1:
            tmp = <WindowAwards />
            break;
        case 2:
            tmp = <WindowContract />
            break;
        default:
            tmp = ''
            break;
    }
    return tmp
}

async function completeAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`;
            const response2 = await fetch(url2, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                const json2 = await response2.json()
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }else{
                const json2 = await response2.json()
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }
        }
        props.changeLoader(false)
    }
}

async function patchZURegistry(props, idAuc, idZu){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${idAuc}/bridge/${idZu}`
    const response1 = await fetch(url, {
        method: "PATCH",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при оновленні данних через брідж', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            await sendAuction(props, idAuc)
        }
    }
}

async function sendAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/send`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
            props.changeLoader(false)
	        props.setUniversalError(tmp)
        }else{
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`;
            const response2 = await fetch(url2, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.changeLoader(false)
            }else{
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.changeLoader(false)
            }
        }
    }
}

////////////////////////////
async function getAucionFromId(props, aucId, type, tenderAttempts=null){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(tenderAttempts === '2'){
                props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
            }else if(tenderAttempts === '3'){
                props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                    props.setDataAddress("legitimatePropertyLease-dutch", 'CREATE_AUCTION_TYPE')
                }else{
                    props.setDataAddress("legitimatePropertyLease-dutch-fast-manual", 'CREATE_AUCTION_TYPE')
                }
            }
            /*if(json.value !== null){
                props.changeDiscountPreviousAuctionAmount(json.value.amount)
            }*/
            await getAddressOrg(props, json, type)
        }
    }
}

async function getAddressOrg(props, auction, type){
    //props.changeCreateAuctionValueTax(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = json
            if(auction.relatedOrganizations !== null && auction.relatedOrganizations !== 'null'){
                if(auction.relatedOrganizations.currentTenant !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.currentTenant.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.currentTenant
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.sellingEntity !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.propertyOwner !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.propertyOwner.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.propertyOwner
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
            }
            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
    props.changeAuctionsSwitchWindow(type)
    props.changeLoader(false)
    props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
}

async function getCopyAuc(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDataAddress(json, 'CREATE_AUCTIONS_ZU_ATTEMP')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
        classificationCVZU: state.start.classificationCVZU,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,

    classificationsGetByScheme,
    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    getAuctionsBidsId,
    changePageNumber,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    oldArrDocumentGet,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,

    setLogo,

    setPopUpAuctionMyNull,

    setUniversalError,
    setEditAuctionFromId,

    changeLoader,

    //////////////////////////
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    changeCreateItemClassificationAddId,
    getMyAuction,
	bankAccountGet,
	bankAccountShemeGet,
	auctionsBidsGetMy,
	unitsGetByType,
	unitsGetByTypeEnergy,
	unitsGetByTypeRailway,
	unitsGetByTypeSubsoil,
    unitsGetByTypeZU,
    addressesGetMy,
    
    workMyPageCurrentAuction,
    workMyPageNextAuction,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)