import React, {useEffect, useState} from 'react';
import styles from './renewables.module.css';

import {connect} from 'react-redux';

import 'date-fns';

// import ItemsList from './itemsList/itemsList.js';
import ReturnButton from '../../returnButton'

import {setDataAddress} from '../../../../redux/createAddress';
import RenewablesItemProps from "./renewablesItemProps/RenewablesItemProps";
import Sidebar from "../../auctionEditNew/components/sidebar/sidebar";

const RenewablesItem = (props) => {

    const [generationType, setGenerationTypeList] = useState([]);
    const [currentItem, setCurrentItem] = useState((props.auctionsMy?.items !== null ||
        props.auctionsMy?.items.length > 0) ? props.auctionsMy?.items[0] : [])

    // props.changeCreateItemAuctionId(props.auctionsMy.id)

    useEffect( () => {
        async function getGenerationTypeList() {
            await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/generationType`)
                .then( (response) => {if (response.status == 200) return response.json()})
                .then( data => setGenerationTypeList(data))
        }
        if (generationType.length === 0) getGenerationTypeList()
    }, []);

    useEffect(() => {
        props.setDataAddress(currentItem?.description?.uk_UA, 'CREATE_ITEMS_DESCRIPTION')
        props.setDataAddress(currentItem?.classification, 'CREATE_ITEMS_CLASSIFICATION')
        props.setDataAddress(currentItem?.classification?.id, 'CREATE_ITEMS_CLASSIFICATION_ID')
        // props.setDataAddress(currentItem?.itemProps?.techParams, 'CREATE_ITEMS_DESCRIPTION')  //@TODO REGIONS
        props.setDataAddress(currentItem?.itemProps?.techParams, 'CREATE_ITEMS_TECH_PARAMS')
        props.setDataAddress(currentItem?.itemProps?.timeSlots, 'CREATE_ITEMS_TIME_SLOTS')
        props.setDataAddress(currentItem?.itemProps?.loadProfiles, 'CREATE_ITEMS_LOAD_PROFILES')
        props.setDataAddress(currentItem?.quantity, 'CREATE_ITEMS_QUANTITY')
        props.setDataAddress(currentItem?.unitCode, 'CREATE_ITEMS_UNIT_CODE')
        props.setDataAddress(currentItem?.itemProps?.regions, 'CREATE_ITEMS_REGION')
        // getAdditionalClassificationCVZU(props)
        // getAdditionalClassificationZU(props)
    }, []);

    return (
        <div>
            <div className={styles.mainWrap}>
                <Sidebar />
                {/*<div className={styles.flexBlockElem}>*/}
                {/*    <div className={styles.headers}>*/}
                {/*    </div>*/}
                {/*    <ItemsList/>*/}
                {/*</div>*/}
                <div className={styles.wrapBlock}>
                    <RenewablesItemProps data={currentItem}/>
                </div>
            </div>
            <div className={styles.bottonBlock}>
                <ReturnButton/>
            </div>
        </div>
    )
}

async function getAdditionalClassificationCVZU(props) {
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/byScheme/cvzu`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/classifications/byScheme/CVZU`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_CVZU_DATA')
        //await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

async function getAdditionalClassificationZU(props) {
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if (response === undefined || response === 'undefined') {
    } else {
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}


const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        setMask: state.start.setMask,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewablesItem)